const { API } = require('../../helpers/api');
const { API_URL } = require('../../config');
const { buffers, END, eventChannel } = require('redux-saga');

function getTrackingPageDetail(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/tracking_page`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function saveTrackingPageDetail(accessToken, shopifyId, requestBody) {
  return API.request({
    method: 'put',
    url: `${API_URL}/stores/${shopifyId}/tracking_page`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getGeoLocations(accessToken, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/geo/locations?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getGeoCities(accessToken, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/geo/cities/search?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getUpsells(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/upsells_crosssells?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getUpsellsCollections(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/upsells_crosssells/collections?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getUpsellsCollectionById(accessToken, shopifyId, collectionId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/upsells_crosssells/collections/${collectionId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putUpsells(accessToken, shopifyId, serviceUpSellsId, requestBody) {
  return API.request({
    method: 'put',
    url: `${API_URL}/stores/${shopifyId}/upsells_crosssells/${serviceUpSellsId}`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getMerchantNote(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/merchant_notes?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function putMerchantNote(accessToken, shopifyId, merchantNoteId, requestBody) {
  return API.request({
    method: 'put',
    url: `${API_URL}/stores/${shopifyId}/merchant_notes/${merchantNoteId}`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getHTMLTemplate(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'get',
    url: `${API_URL}/stores/${shopifyId}/tracking_page/template?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function addCustomLanguage(accessToken, shopifyId, data) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/languages`,
    data: data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en',
    },
  });
}

function updateCustomLanguage(accessToken, shopifyId, data, languageUuid) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/languages/${languageUuid}`,
    data: data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function deleteCustomLanguage(accessToken, shopifyId, languageUuid) {
  return API.request({
    method: 'delete',
    url: `${API_URL}/stores/${shopifyId}/languages/${languageUuid}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getCountriesData(accessToken) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/languages/meta`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getTrackingPageLanguages(accessToken) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/products/tracking-page/languages`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getPrivateLanguages(accessToken, storeUuid) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${storeUuid}/products/tracking-page/languages`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getLanguageData(
  accessToken,
  storeUuid,
  language,
  isForUpdate = false
) {
  let url = `${API_URL}/products/tracking-page/languages/single?language=${language}`;
  if (isForUpdate) {
    url += `&store_uuid=${storeUuid}`;
  }

  return API.request({
    method: 'GET',
    url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getLanguageDataNew(accessToken, languageUuid, locale) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/languages?language_uuids=${languageUuid}&with_translations=1`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': `${locale}`,
    },
  });
}

function createUploadFileChannel(endpoint, accessToken, file, path) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e) => {
      if (e.lengthComputable) {
        // const progress = e.loaded / e.total;
        // emitter({ progress });
      }
    };
    const onFailure = () => {
      emitter({ error: new Error('Upload failed. Please try again.') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, responseText } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true, responseText: responseText });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    let data = new FormData();
    xhr.open('POST', endpoint, true);
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    data.append('path', path);
    data.append('file', file, file.name);
    xhr.send(data);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

module.exports = {
  getTrackingPageDetail,
  saveTrackingPageDetail,
  getGeoLocations,
  getGeoCities,
  getUpsells,
  getUpsellsCollections,
  getUpsellsCollectionById,
  putUpsells,
  getMerchantNote,
  putMerchantNote,
  getHTMLTemplate,
  addCustomLanguage,
  updateCustomLanguage,
  deleteCustomLanguage,
  getCountriesData,
  getTrackingPageLanguages,
  getPrivateLanguages,
  getLanguageData,
  getLanguageDataNew,
  createUploadFileChannel,
};

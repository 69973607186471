import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  TextField,
  FormLayout,
  Select,
  ChoiceList,
} from '@shopify/polaris';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import stringHelper from 'helpers/string';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function TopMenuLinkModal({
  dispatchForTrackingPage,
  showTopMenuLinkModal,
  topMenuLinkIndex,
  topMenuLinkSection,
  trackingDetailMeta,
  ...props
}) {
  const OPTION_ICON_LABEL = 'icon';
  const OPTION_TEXT_LABEL = 'label';

  const [t] = useTranslation();

  const [labelType, setLabelType] = useState(OPTION_ICON_LABEL);
  const handleLabelTypeChange = useCallback(
    (value) => setLabelType(value[0]),
    []
  );

  const [labelTextError, setLabelTextError] = useState(null);
  const [labelText, setLabelText] = useState(null);
  const handleLabelTextChange = useCallback((value) => setLabelText(value), []);

  const [linkTextError, setLinkTextError] = useState(null);
  const [linkText, setLinkText] = useState(null);
  const handleLinkTextChange = useCallback((value) => setLinkText(value), []);

  const [icon, setIcon] = useState(
    trackingDetailMeta.top_menu_links.icons[0].link
  );
  const handleIconChange = useCallback((value) => setIcon(value), []);

  useEffect(() => {
    setLabelTextError(null);
    setLinkTextError(null);
    setLabelText(null);
    setLinkText(null);
  }, [showTopMenuLinkModal]);

  const submit = () => {
    if (
      (_.isNull(labelText) || _.isEmpty(_.trim(labelText))) &&
      labelType === OPTION_TEXT_LABEL
    ) {
      setLabelTextError(
        t('settings.tracking_page.header_links_modal.label_invalid_text')
      );
      return;
    }
    if (_.isNull(linkText) || !stringHelper.validateUrl(linkText)) {
      setLinkTextError(
        t('settings.tracking_page.header_links_modal.link_invalid_text')
      );
      return;
    }

    let tempTopMenuLinkSection = topMenuLinkSection.links;
    if (!_.isNull(topMenuLinkIndex)) {
      if (labelType === OPTION_ICON_LABEL) {
        tempTopMenuLinkSection[topMenuLinkIndex] = {
          icon: icon,
          link: _.trim(linkText),
        };
      } else {
        tempTopMenuLinkSection[topMenuLinkIndex] = {
          label: _.trim(labelText),
          link: _.trim(linkText),
        };
      }
    } else {
      let tempLabel = {};
      if (labelType === OPTION_ICON_LABEL) {
        tempLabel = { icon: icon, link: linkText };
      } else {
        tempLabel = { label: labelText, link: linkText };
      }
      tempTopMenuLinkSection = [...topMenuLinkSection.links, tempLabel];
    }
    const tempTopMenuLinkSectionSection = {
      ...topMenuLinkSection,
      links: [...tempTopMenuLinkSection],
    };
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        top_menu_links: tempTopMenuLinkSectionSection,
      },
    });
    props.handleShowTopMenuLinkModalChange();
  };

  useEffect(() => {
    if (!_.isNull(topMenuLinkIndex) && topMenuLinkSection.links.length > 0) {
      const tempTopMenuLinkSection = topMenuLinkSection.links[topMenuLinkIndex];
      if (tempTopMenuLinkSection) {
        if (_.isUndefined(tempTopMenuLinkSection.label)) {
          setLabelType(OPTION_ICON_LABEL);
          setIcon(tempTopMenuLinkSection.icon);
        } else {
          setLabelType(OPTION_TEXT_LABEL);
          setLabelText(tempTopMenuLinkSection.label);
        }
        setLinkText(tempTopMenuLinkSection.link);
      }
    }
  }, [topMenuLinkIndex, topMenuLinkSection]);

  return (
    <Modal
      open={showTopMenuLinkModal}
      onClose={props.handleShowTopMenuLinkModalChange}
      title={t('settings.tracking_page.header_links_modal.title')}
      primaryAction={{
        content: t('settings.tracking_page.header_links_modal.save_btn_label'),
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: t(
            'settings.tracking_page.header_links_modal.cancel_btn_label'
          ),
          onAction: props.handleShowTopMenuLinkModalChange,
        },
      ]}
    >
      <Modal.Section>
        <div className="">
          <FormLayout>
            <ChoiceList
              title={t(
                'settings.tracking_page.header_links_modal.options_title'
              )}
              choices={[
                {
                  label: t(
                    'settings.tracking_page.header_links_modal.icon_option'
                  ),
                  value: OPTION_ICON_LABEL,
                },
                {
                  label: t(
                    'settings.tracking_page.header_links_modal.text_option'
                  ),
                  value: OPTION_TEXT_LABEL,
                },
              ]}
              selected={labelType}
              onChange={handleLabelTypeChange}
            />

            {labelType === OPTION_ICON_LABEL && (
              <div>
                <Select
                  label={t(
                    'settings.tracking_page.header_links_modal.content.icon_label'
                  )}
                  options={trackingDetailMeta.top_menu_links.icons.map(
                    (type) => ({ label: type.name, value: type.link })
                  )}
                  onChange={handleIconChange}
                  value={icon}
                  id="select-top-menu-links-icon"
                />
              </div>
            )}
            {labelType === OPTION_TEXT_LABEL && (
              <TextField
                value={labelText}
                label={t(
                  'settings.tracking_page.header_links_modal.content.text_label'
                )}
                onChange={handleLabelTextChange}
                error={labelTextError}
                placeholder={t(
                  'settings.tracking_page.header_links_modal.content.label_input_text'
                )}
                id={`input-top-menu-links-modal-label`}
              />
            )}
            <TextField
              label={t(
                'settings.tracking_page.header_links_modal.content.link_label'
              )}
              onChange={handleLinkTextChange}
              value={linkText}
              error={linkTextError}
              placeholder={t(
                'settings.tracking_page.header_links_modal.content.link_input_text'
              )}
              id={`input-top-menu-links-modal-link`}
            />
          </FormLayout>
        </div>
      </Modal.Section>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Banner,
  Button,
  Card,
  Layout,
  SkeletonBodyText,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import AddPageWizard from './AddPageWizard';
import ThemesStatusModal from './ThemesStatusModal';
import TranslateLink from 'components/TranslateLink';
import PageItems from './PageItems';
import { useTranslation } from 'react-i18next';
import { useGetOS2TrackingPagesQuery } from '../os2TrackingApi';
import CloneModal from './CloneModal';
import RemoveModal from './RemoveModal';

export default function OS2Card({ showTrackingPageToaster }) {
  const [t] = useTranslation();

  const [showWizard, setShowWizard] = useState(false);
  const [themes, setThemes] = useState([]);
  const [themeStatus, setThemeStatus] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState('');
  const [toCloneRemoveId, setToCloneRemoveId] = useState('');
  const [toCloneRemoveIsPublished, setToCloneRemoveIsPublished] =
    useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleCloneClick = (id) => {
    toggleThemeStatus();
    setToCloneRemoveId(id);
    toggleCloneModal();
  };

  const handleRemoveClick = (id, isPublished) => {
    toggleThemeStatus();
    setToCloneRemoveId(id);
    setToCloneRemoveIsPublished(isPublished);
    toggleRemoveModal();
  };

  const toggleCloneModal = () => {
    setShowCloneModal((show) => !show);
  };

  const toggleRemoveModal = () => {
    setShowRemoveModal((show) => !show);
  };

  const {
    data = [],
    isFetching,
    refetch,
  } = useGetOS2TrackingPagesQuery() || {};

  useEffect(() => {
    refetch();
  }, []);

  const toggleWizard = () => {
    setShowWizard(!showWizard);
  };

  const toggleThemeStatus = () => {
    setThemeStatus(!themeStatus);
  };

  const getRushPages = () => {
    return (data || []).filter(({ template }) =>
      template.includes('app-rush-tracking-page')
    );
  };
  const handleAddClick = (id) => {
    toggleThemeStatus();
    setSelectedThemeId(id);
    toggleWizard();
  };

  const handleAddPage = () => {
    setSelectedThemeId('');
    toggleWizard();
  };

  return (
    <>
      <Layout.AnnotatedSection
        title={t('settings.os2_tracking_page.layout.title')}
        description={t('settings.os2_tracking_page.layout.description')}
      >
        <Card
          title={t('settings.os2_tracking_page.layout.title')}
          actions={[
            {
              content: t('settings.os2_tracking_page.main_card.action'),
              onAction: toggleThemeStatus,
            },
          ]}
        >
          <Card.Section>
            <Banner status="info">
              <TranslateLink
                text={t('settings.os2_tracking_page.banner.description')}
              />
              <br />
              <TranslateLink
                text={t('settings.os2_tracking_page.banner.video_guide')}
              />
            </Banner>
          </Card.Section>

          <Card.Section
            title={t(`settings.tracking_page.tracking_pages.title`)}
            actions={
              isFetching || getRushPages().length === 0
                ? []
                : [{ content: t('common.add'), onAction: handleAddPage }]
            }
          >
            {isFetching && <SkeletonBodyText size="medium" />}
            {!isFetching && getRushPages().length === 0 ? (
              <Stack alignment="center" distribution="center" vertical>
                <TextStyle variation="subdued">
                  {t(
                    'settings.os2_tracking_page.banner.no_tracking_page_message'
                  )}
                </TextStyle>
                <Button primary onClick={handleAddPage}>
                  {t('settings.os2_tracking_page.wizard.add_tracking_page')}
                </Button>
              </Stack>
            ) : (
              !isFetching && <PageItems pages={getRushPages()}></PageItems>
            )}
          </Card.Section>

          <Card.Section subdued>
            <TextStyle variation="subdued">
              <p>{t('settings.os2_tracking_page.main_card.footer.message1')}</p>
              <br />
              <p>{t('settings.os2_tracking_page.main_card.footer.message2')}</p>
            </TextStyle>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>

      {showWizard && (
        <AddPageWizard
          onClose={toggleWizard}
          showToast={showTrackingPageToaster}
          selectedThemeId={selectedThemeId}
        />
      )}

      {themeStatus && (
        <ThemesStatusModal
          onClose={toggleThemeStatus}
          onAdd={handleAddClick}
          onClone={handleCloneClick}
          onRemove={handleRemoveClick}
          setThemes={setThemes}
        />
      )}
      {showCloneModal && (
        <CloneModal
          onClose={toggleCloneModal}
          selectedThemeId={toCloneRemoveId}
          themes={themes}
          showToast={showTrackingPageToaster}
        />
      )}

      {showRemoveModal && (
        <RemoveModal
          onClose={toggleRemoveModal}
          selectedThemeId={toCloneRemoveId}
          isPublishedTheme={toCloneRemoveIsPublished}
          themes={themes}
          showToast={showTrackingPageToaster}
        />
      )}
    </>
  );
}

import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import PaypalConfig from './PaypalConfig';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    paypalLoading: state.settings.paypalLoading,
    paypalSyncTrackingConfig: state.settings.paypalSyncTrackingConfig,
    paypalButtonConfiguration: state.settings.paypalButtonConfiguration,
    paypalDisputeResolutionConfig: state.settings.paypalDisputeResolutionConfig,
    paypalMetaTrackingNumberType: state.settings.paypalMetaTrackingNumberType,
    paypalMetaUpdateMode: state.settings.paypalMetaUpdateMode,
    paypalToaster: state.settings.paypalToaster,
    paypalError: state.settings.paypalError,
  };
}

const mapDispatchToProps = {
  getPaypalConfig: settingsActions.Creators.getPaypalConfig,
  postPaypalAccount: settingsActions.Creators.postPaypalAccount,
  resetPaypalToaster: settingsActions.Creators.resetPaypalToaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalConfig);

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Banner, Card, Link, Stack, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';
import SpinnerWithMessage from 'components/SpinnerWithMessage';
import { useAddTrackingPageMutation } from '../../os2TrackingApi';
import './styles.scss';

function Step3({ wizardData, onClose, showToast, handleBack }) {
  const { pagesPrefix, pageTitle, pageHandle, style, layout } = wizardData;
  const [t] = useTranslation();
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [addPage, addPageResponse] = useAddTrackingPageMutation();
  const { data = {}, isSuccess, isLoading } = addPageResponse;

  useEffect(() => {
    pageTitle && setTitle(pageTitle);
    pageHandle && setValue(pageHandle);
  }, [pageTitle, pageHandle]);

  useEffect(() => {
    if (isSuccess && data) {
      showToast({
        message: t(
          'settings.os2_tracking_page.wizard.step3.page_added_message'
        ),
      });
    }
  }, [data, isSuccess]);

  const handleAddPage = () => {
    if (value !== '' && title !== '') {
      addPage({ handle: value, title, style, layout });
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    title && setValue(kebabCase(title));
  }, [title]);

  const handleCustomize = () => {
    if (data && isSuccess) {
      window.open(data?.editor_url);
      onClose();
    }
  };

  const primaryAction = useMemo(() => {
    return isSuccess || isLoading
      ? {
          content: t('settings.os2_tracking_page.wizard.customize'),
          onAction: handleCustomize,
          disabled: isLoading,
        }
      : {
          content: t('settings.os2_tracking_page.wizard.step3.add_page'),
          onAction: handleAddPage,
        };
  }, [handleAddPage, isSuccess, isLoading, handleCustomize]);

  const secondaryAction = useMemo(() => {
    return isSuccess || isLoading
      ? []
      : [
          {
            content: t('settings.os2_tracking_page.wizard.secondary_action'),
            onAction: handleBack,
          },
        ];
  }, [handleBack, isSuccess, isLoading]);

  return (
    <div className="step3-container">
      <Card
        title={
          isSuccess ? '' : t('settings.os2_tracking_page.wizard.step3.title')
        }
        secondaryFooterActions={secondaryAction}
        primaryFooterAction={primaryAction}
      >
        {!isSuccess && !isLoading && (
          <>
            <Card.Section>
              <Banner
                title={t(
                  'settings.os2_tracking_page.wizard.step3.override_message'
                )}
                status="info"
              ></Banner>
              <br />
              <Stack vertical>
                <TextField
                  label={t(
                    'settings.os2_tracking_page.wizard.step3.name_box.title'
                  )}
                  value={title}
                  onChange={setTitle}
                  error={
                    showError &&
                    !title &&
                    t(
                      'settings.os2_tracking_page.wizard.step3.page_title_required'
                    )
                  }
                  autoFocus
                />
                <TextField
                  label={t(
                    'settings.os2_tracking_page.wizard.step3.handle_box.title'
                  )}
                  value={value}
                  onChange={setValue}
                  prefix={pagesPrefix}
                  error={
                    showError &&
                    !value &&
                    t(
                      'settings.os2_tracking_page.wizard.step3.page_handle_required'
                    )
                  }
                />
              </Stack>
            </Card.Section>
            <Card.Section></Card.Section>
          </>
        )}
        {isSuccess && (
          <Card.Section>
            <Stack>
              <p>{t('settings.os2_tracking_page.wizard.step3.pageUrl')}</p>
              <Link url={data.url} external>
                {data.url}
              </Link>
            </Stack>
          </Card.Section>
        )}

        {isLoading && (
          <Card.Section>
            <SpinnerWithMessage
              variation="subdued"
              spinMessage={t(
                'settings.os2_tracking_page.wizard.step3.installation_inprogress'
              )}
            />
          </Card.Section>
        )}
      </Card>
    </div>
  );
}

Step3.propTypes = {
  onChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  wizardData: PropTypes.object.isRequired,
};

export default Step3;

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  TextField,
  DropZone,
  TextStyle,
  Thumbnail,
  Button,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function StoreInformation({
  trackingPageState,
  dispatchForTrackingPage,
  uploadLogoRequest,
  uploadLogoReset,
  storeLogoURL,
  logoUploaded,
}) {
  const [t] = useTranslation();
  const [storeName, setStoreName] = useState(null);
  const [storeNameError, setStoreNameError] = useState(null);
  const [storeInformation, setStoreInformation] = useState('');
  const [phoneIconUri, setPhoneIconUri] = useState('');
  const [linkIconUri, setLinkIconUri] = useState('');
  const [goToShopUri, setGoToShopUri] = useState('');

  const [fieldUpdated, setFieldUpdated] = useState(false);

  const handleStoreNameChange = useCallback((newValue) => {
    setStoreName(newValue);
  }, []);
  const handleStoreInformationChange = useCallback((newValue) => {
    setStoreInformation(newValue);
    setFieldUpdated(true);
  }, []);
  const handlePhoneIconUriChange = useCallback((newValue) => {
    setPhoneIconUri(newValue);
    setFieldUpdated(true);
  }, []);
  const handleLinkIconUriChange = useCallback((newValue) => {
    setLinkIconUri(newValue);
    setFieldUpdated(true);
  }, []);
  const handleGoToShopUriChange = useCallback((newValue) => {
    setGoToShopUri(newValue);
    setFieldUpdated(true);
  }, []);

  const handleBlurStoreName = useCallback(() => {
    if (!storeName) {
      setStoreNameError(
        t('settings.tracking_page.store_info.card.store_name_error_label')
      );
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.VALIDATION,
        payload: {
          storeName: false,
        },
      });
    } else if (trackingPageState.store_information.name !== storeName) {
      setStoreNameError(null);
      const tempTrackingSection = {
        name: storeName,
      };
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.STORE_INFO_UPDATE,
        payload: tempTrackingSection,
        validation: {
          storeName: true,
        },
      });
    }
  }, [dispatchForTrackingPage, t, storeName, trackingPageState]);
  const handleBlur = useCallback(() => {
    if (!fieldUpdated) {
      return;
    }
    const tempTrackingSection = {
      information: storeInformation,
      phone_icon_uri: phoneIconUri,
      link_icon_uri: linkIconUri,
      go_to_shop_uri: goToShopUri,
    };
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.STORE_INFO_UPDATE,
      payload: tempTrackingSection,
    });
    setFieldUpdated(false);
  }, [
    dispatchForTrackingPage,
    storeInformation,
    phoneIconUri,
    linkIconUri,
    goToShopUri,
    fieldUpdated,
  ]);

  useEffect(() => {
    setStoreName(trackingPageState.store_information.name);
    setStoreInformation(trackingPageState.store_information.information);
    setPhoneIconUri(trackingPageState.store_information.phone_icon_uri);
    setLinkIconUri(trackingPageState.store_information.link_icon_uri);
    setGoToShopUri(trackingPageState.store_information.go_to_shop_uri);
    setFieldUpdated(false);
  }, [trackingPageState.store_information]);

  useEffect(() => {
    if (storeLogoURL && logoUploaded) {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: {
          store_logo_link: storeLogoURL,
        },
      });
      uploadLogoReset();
    }
  }, [dispatchForTrackingPage, storeLogoURL, t, uploadLogoReset, logoUploaded]);

  const deleteStoreLogoLink = () => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        store_logo_link: null,
      },
    });
  };

  function uploadLogo(file) {
    uploadLogoRequest(file);
  }

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.store_info.title')}
      description={t('settings.tracking_page.store_info.description')}
    >
      <Card>
        <Card.Header
          title={t('settings.tracking_page.store_info.card.title')}
        />
        <Card.Section>
          <TextField
            value={storeName}
            label={t('settings.tracking_page.store_info.card.store_name_label')}
            onChange={handleStoreNameChange}
            onBlur={handleBlurStoreName}
            placeholder={t(
              'settings.tracking_page.store_info.card.store_name_default_input_text'
            )}
            error={storeNameError}
            id={`input-store-info-name`}
          />
          <TextStyle variation="subdued">
            <TranslateLink
              text={t(
                'settings.tracking_page.store_info.card.store_name_input_helper'
              )}
            />
          </TextStyle>
          <div className="PayLayout-DropZone">
            <div className="Heading">
              <TextStyle>
                {t(
                  'settings.tracking_page.section_page_layout.style.logo_title'
                )}
              </TextStyle>
            </div>
            <div>
              {trackingPageState.store_logo_link && (
                <div className="PayLayout-DropZone-Div">
                  <div>
                    <Thumbnail
                      source={trackingPageState.store_logo_link}
                      size="large"
                    />
                    <div>
                      <Button plain destructive onClick={deleteStoreLogoLink}>
                        {t(
                          'settings.tracking_page.section_page_layout.style.logo_remove'
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {!trackingPageState.store_logo_link && (
                <DropZone
                  accept="image/*"
                  type="image"
                  allowMultiple={false}
                  onDrop={uploadLogo}
                >
                  <DropZone.FileUpload
                    actionTitle={t(
                      'settings.tracking_page.section_page_layout.style.logo_action_title'
                    )}
                  />
                </DropZone>
              )}
            </div>
          </div>
        </Card.Section>
        <Card.Section>
          <TextField
            value={storeInformation}
            label={t('settings.tracking_page.store_info.card.store_info_label')}
            onChange={handleStoreInformationChange}
            onBlur={handleBlur}
            placeholder={t(
              'settings.tracking_page.store_info.card.store_info_default_input_text'
            )}
            multiline={4}
            id={`input-store-info-description`}
          />
        </Card.Section>
        <Card.Section>
          <TextField
            value={phoneIconUri}
            label={t('settings.tracking_page.store_info.card.phone_icon_label')}
            onChange={handlePhoneIconUriChange}
            onBlur={handleBlur}
            placeholder={t(
              'settings.tracking_page.store_info.card.phone_icon_default_input_text'
            )}
            id={`input-store-info-phone`}
          />
          <TextStyle variation="subdued">
            <TranslateLink
              text={t(
                'settings.tracking_page.store_info.card.phone_icon_input_helper'
              )}
            />
          </TextStyle>
        </Card.Section>
        <Card.Section>
          <TextField
            value={linkIconUri}
            label={t('settings.tracking_page.store_info.card.chain_icon_label')}
            onChange={handleLinkIconUriChange}
            onBlur={handleBlur}
            placeholder={t(
              'settings.tracking_page.store_info.card.chain_icon_default_input_text'
            )}
            id={`input-store-info-link-icon`}
          />
        </Card.Section>
        <Card.Section>
          <TextField
            value={goToShopUri}
            label={t(
              'settings.tracking_page.store_info.card.go_to_store_label'
            )}
            onChange={handleGoToShopUriChange}
            onBlur={handleBlur}
            placeholder={t(
              'settings.tracking_page.store_info.card.go_to_store_default_input_text'
            )}
            id={`input-store-info-go-to-store`}
          />
          <TextStyle variation="subdued">
            <TranslateLink
              text={t(
                'settings.tracking_page.store_info.card.go_to_store_input_herlper'
              )}
            />
          </TextStyle>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoresPage } from '@rush-app-admin/storybooks';
import ShopifyIcon from 'images/shopify-icon.svg';
import { Loading } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { USER_URLS } from 'config/urls';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from '../../Constants';
import i18next from 'i18next';
import { useGetOrganizationsQuery } from 'redux/store/commonBaseApis';

export default function Stores({ setStoreUUID }) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const { data: organizations = [], isLoading: organizationsLoading } =
    useGetOrganizationsQuery();

  let translations;
  try {
    translations = require(`@shopify/polaris/locales/${i18next.language}.json`);
  } catch (e) {
    translations = require('@shopify/polaris/locales/en.json');
  }

  const stores = [];
  organizations && organizations.forEach((org) => stores.push(...org.stores));

  const storesMarkup = stores
    .map((store) => ({
      id: store.id?.toString(),
      uuid: store.uuid,
      name: store.store_name,
      // TODO: Fire an FS event when store is switched (uuid, name, domain)
      domain: store.website,
      error:
        store.status?.type === 'critical' ? store.status?.message : undefined,
      warning:
        store.status?.type !== 'critical' ? store.status?.message : undefined,
    }))
    .sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );

  if (storesMarkup.length === 1 && params.get('isRedirected')) {
    const storeUUID = storesMarkup[0].uuid;
    setStoreUUID(storeUUID);
    ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeUUID);
    history.push(`/s/${storeUUID}`);
    return null;
  }

  const handleItemClick = (uuid) => {
    setStoreUUID(uuid);
    ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, uuid);
    history.push(`/s/${uuid}`);
  };

  return (
    <>
      {organizationsLoading && <Loading />}
      <StoresPage
        loading={organizationsLoading}
        title={t('stores.card.title')}
        translationFile={translations}
        defaultIcon={ShopifyIcon}
        emptyText={t('stores.card.empty_text')}
        button={{
          text: t('stores.card.primary_button'),
          action: () => {
            history.push(USER_URLS.ADD_STORE);
          },
        }}
        stores={storesMarkup}
        footer={<TranslateLink text={t('stores.footer')} />}
        onItemClick={handleItemClick}
      />
    </>
  );
}

import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  Spinner,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import {
  ArrowDownMinor,
  ArrowUpMinor,
  EditMinor,
} from '@shopify/polaris-icons';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';
import '../styles.scss';

function TransitTimesCard({
  times,
  onAdd,
  onEdit,
  onChange,
  orderFulfillmentDays,
}) {
  const [t] = useTranslation();

  const { data: countriesList = [], isLoading } = useGetCountriesQuery();

  const orderedTimes = useMemo(() => {
    return orderBy(times, 'order');
  }, [times]);

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...orderedTimes], index, index - 1);
    syncUpdates(updated);
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...orderedTimes], index, index + 1);
    syncUpdates(updated);
  };

  const syncUpdates = (updated) => {
    onChange(
      updated.map((t, i) => {
        return {
          ...t,
          order: i + 1,
        };
      })
    );
  };

  const emptyStatemarkup = (
    <div className="empty_markup">
      <TextStyle variation="subdued">
        {t('smart-edd.transit-time-rules.no-transit-time-selected')}
      </TextStyle>
    </div>
  );

  const getCountryNames = (selected = []) => {
    return selected
      .map((sel) => {
        return (
          (countriesList.find(({ code_iso3 }) => sel === code_iso3) || {})
            .label || ''
        );
      })
      .filter((c) => !!c)
      .join(', ');
  };

  return (
    <Card
      sectioned
      title={t('smart-edd.transit-time-rules.card2.title')}
      actions={[{ content: t('common.add'), onAction: onAdd }]}
    >
      <Stack vertical>
        <TextStyle variation="subdued">
          {t('smart-edd.transit-time-rules.card2.description')}
        </TextStyle>
        {orderedTimes.length ? (
          <Card>
            {isLoading ? (
              <Stack distribution="center">
                <Spinner size="small" />{' '}
              </Stack>
            ) : (
              orderedTimes.map((time, index) => {
                const overriderNum = time.override_order_fulfillment_days
                  ? time.override_order_fulfillment_days_value
                  : orderFulfillmentDays;
                return (
                  <div key={time.order + index} className="transit-time-item">
                    <Card.Section>
                      <Stack distribution="fill">
                        <Stack.Item>
                          {time.geo_location_targeted &&
                          time.geo_location_targeted.length ? (
                            getCountryNames(time.geo_location_targeted)
                          ) : (
                            <i>
                              <TextStyle variation="subdued">
                                {' '}
                                {t(
                                  'smart-edd.transit-time-rules.all-locations'
                                )}
                              </TextStyle>
                            </i>
                          )}
                        </Stack.Item>

                        <Stack distribution="trailing">
                          <Stack alignment="center">
                            <TextStyle>
                              <b>
                                {' '}
                                {time.date_range ? (
                                  <span>
                                    <i>{overriderNum}</i>
                                    {` + ${time.date_range.from}`}
                                    <span>&nbsp; - &nbsp;</span>
                                    <i>{overriderNum}</i>
                                    {` + ${time.date_range.to}`}
                                  </span>
                                ) : (
                                  <span>
                                    <i>{overriderNum}</i>
                                    {` + ${time.single_day}`}
                                  </span>
                                )}{' '}
                              </b>
                              <i>
                                {' '}
                                <TextStyle variation="subdued">
                                  {t(
                                    'smart-edd.transit-time-rules.business_days'
                                  )}
                                </TextStyle>
                              </i>
                            </TextStyle>
                            <div className="transit-times-btns">
                              <ButtonGroup segmented>
                                <Button
                                  size="slim"
                                  onClick={() => handleMoveUp(index, 'up')}
                                  icon={<Icon source={ArrowUpMinor} />}
                                  disabled={index === 0}
                                />
                                <Button
                                  size="slim"
                                  onClick={() => handleMoveDown(index, 'down')}
                                  icon={<Icon source={ArrowDownMinor} />}
                                  disabled={index === orderedTimes?.length - 1}
                                />
                                <Button
                                  size="slim"
                                  onClick={() => onEdit(index)}
                                  icon={<Icon source={EditMinor} />}
                                />
                              </ButtonGroup>
                            </div>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Card.Section>
                  </div>
                );
              })
            )}
          </Card>
        ) : (
          <Card>{emptyStatemarkup}</Card>
        )}
      </Stack>
    </Card>
  );
}

export default TransitTimesCard;

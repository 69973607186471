import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { useHistory, useLocation } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';

export default function RecoverPassword({
  getVisitorLanguages,
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  resetPassword,
  loading,
  visitorToast,
  resetVisitorToast,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  let email = params.get('email');
  if (!email || !token) {
    history.push(VISITOR_URLS.LOGIN);
  } else {
    email = email.replaceAll(' ', '+');
  }

  const [password, setPassword] = useState('');
  const [showPasswordError, setShowPasswordError] = useState(false);

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowPasswordError(false);

    let hasErrors = false;

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    resetPassword({ email, password, token });
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('visitor-app:charge_password_recovery_screen.title')}
          subtitle={
            <TranslateLink
              text={t('visitor-app:charge_password_recovery_screen.sub_title', {
                email,
              })}
            />
          }
          inputFields={[
            {
              placeholder: t(
                'visitor-app:charge_password_recovery_screen.password_placeholder'
              ),
              error: showPasswordError
                ? t('visitor-app:errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={t('visitor-app:charge_password_recovery_screen.continue_btn')}
          onSubmit={handleSubmit}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

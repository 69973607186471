import { connect } from 'react-redux';
import TrackingManager from './TrackingManager';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    base: state.base,
    accessToken: state.visitor.accessToken,
  };
}

const mapDispatchToProps = {
  getIpAddress: visitorActions.Creators.getIpAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingManager);

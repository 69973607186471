import { storeApi } from '../../redux/store/storeApi';

export const dashboardApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getNeedActions: builder.query({
      query: () => {
        return {
          url: `/shipments/need-action`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getReports: builder.mutation({
      query: (params) => {
        return {
          url: `/reports`,
          method: 'POST',
          body: { ...params },
        };
      },
    }),
  }),
});

export const { useGetNeedActionsQuery, useGetReportsMutation } = dashboardApi;

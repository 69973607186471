import { Card, SkeletonBodyText } from '@shopify/polaris';
import React from 'react';

function SkeletonPreShipmentTimelines() {
  return (
    <Card>
      <Card.Section>
        <SkeletonBodyText />
        <SkeletonBodyText />
        <SkeletonBodyText lines={4} />
      </Card.Section>
    </Card>
  );
}

export default SkeletonPreShipmentTimelines;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storeTitle: null,
  storeUUID: null,
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setStoreTitle(state, action) {
      state.storeTitle = action.payload;
    },
    setStoreUUID(state, action) {
      state.storeUUID = action.payload;
    },
  },
});

// Actions
export const shopActions = shopSlice.actions;

// Selectors
export const StoreUUID = (state) => !!state.shop.storeUUID;

// Reducer
const shop = shopSlice.reducer;
export default shop;

import React, { useEffect, useState, useCallback } from 'react';
import { Modal, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';

import _ from 'lodash';
export default function EmailDeleteModal({
  testEmailTriggerById,
  emailToaster,
  closeEmailTestModal,
  emailTriggerById,
  showEmailTestModal,
  emailTriggerDetail,
  getEmailTriggerById,
  loading,
  emailSubject,
  emailBody,
}) {
  const [t] = useTranslation();
  const text = t('email.modal.test_trigger.description');

  useEffect(() => {
    if (!_.isNull(emailToaster.toastText)) {
      closeEmailTestModal();
    }
  }, [emailToaster, closeEmailTestModal]);

  useEffect(() => {
    if (emailTriggerById && !emailSubject && showEmailTestModal) {
      getEmailTriggerById(emailTriggerById);
    }
  }, [getEmailTriggerById, emailTriggerById, emailSubject, showEmailTestModal]);

  const [testEmailAddressText, setTestEmailAddressText] = useState('');
  const [testEmailAddressError, setTestEmailAddressError] = useState(null);
  const handleTestEmailAddressTextChange = useCallback((value) => {
    setTestEmailAddressText(value);
    setTestEmailAddressError(null);
  }, []);
  const handleTestEmailAddressTextBlur = useCallback(() => {
    if (testEmailAddressText.search(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i) < 0) {
      setTestEmailAddressError(t('email.modal.test_trigger.email_error'));
    } else {
      setTestEmailAddressError(null);
    }
  }, [testEmailAddressText, t]);

  const sendTestEmail = (e) => {
    e.preventDefault();
    if (!testEmailAddressError && emailTriggerDetail) {
      const testObject = {
        to_email: testEmailAddressText,
        trigger_slug: emailTriggerDetail.trigger,
        email_subject: emailSubject
          ? emailSubject
          : emailTriggerDetail.email_subject,
        email_body: emailBody ? emailBody : emailTriggerDetail.email_body,
      };
      testEmailTriggerById(testObject);
    }
  };

  return (
    <Modal
      open={showEmailTestModal}
      onClose={() => {
        setTestEmailAddressText('');
        closeEmailTestModal();
      }}
      title={t('email.modal.test_trigger.title')}
      primaryAction={{
        content: t('email.modal.test_trigger.action_delete'),
        onAction: sendTestEmail,
        loading: loading,
      }}
    >
      <Modal.Section>
        <div className="EmailTest-Text">
          <TranslateLink text={text} />
        </div>
        <TextField
          type="text"
          value={testEmailAddressText}
          placeholder={t('email.modal.test_trigger.email_label')}
          onChange={handleTestEmailAddressTextChange}
          onBlur={handleTestEmailAddressTextBlur}
          error={testEmailAddressError}
          disabled={loading}
          id={`input-email-notification-test-email`}
        />
      </Modal.Section>
    </Modal>
  );
}

import { connect } from 'react-redux';
import TemplateModal from './TemplateModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetailMeta: state.trackingDetail.meta,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateModal);

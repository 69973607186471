import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import stringHelper from 'helpers/string';

export default function Custom({ to, ...props }) {
  const history = useHistory();

  let queryString = '';
  if (history.location && history.location.search) {
    const cookieParams = stringHelper.parseQueryString(history.location.search);
    if (cookieParams) {
      queryString = Object.keys(cookieParams)
        .map((key) => key + '=' + cookieParams[key])
        .join('&');
    }
  }

  let customURL = `${to}?${queryString}`;
  if (to.indexOf('?') > 0) {
    customURL = `${to}&${queryString}`;
  }

  return <Redirect to={`${customURL}`} {...props} />;
}

import { connect } from 'react-redux';
import RecoverPassword from './RecoverPassword';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
    loading: state.visitor.loading,
    visitorToast: state.visitor.toast,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
  resetPassword: visitorActions.Creators.resetPassword,
  resetVisitorToast: visitorActions.Creators.resetVisitorToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);

import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import Localization from './Localization';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.trackingDetail.loading,
    trackingPageLanguages: state.trackingDetail.languages,
    privateLanguages: state.trackingDetail.privateLanguages,
  };
}

const mapDispatchToProps = {
  deleteCustomLanguage: trackingActions.Creators.deleteCustomLanguage,
  getTrackingPageLanguages: trackingActions.Creators.getTrackingPageLanguages,
  getPrivateLanguages: trackingActions.Creators.getPrivateLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Localization);

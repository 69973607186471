import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, TextStyle, Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { isEmailValid } from 'Utils/utils';
import { useHistory } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import { API_URL } from 'config';

export default function Register({
  getVisitorLanguages,
  getAffiliatesCampaign,
  visitorLanguages,
  changeVisitorLanguage,
  selectedVisitorLanguage,
  visitorToast,
  resetVisitorToast,
  register,
  loading,
  newEmail,
  verificationToken,
  campaignMessage,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [firstname, setFirstname] = useState(params.get('firstname') || '');
  const [lastname, setLastname] = useState(params.get('lastname') || '');
  const [email, setEmail] = useState(params.get('email') || '');
  const [password, setPassword] = useState('');

  const [showFirstnameError, setShowFirstnameError] = useState(false);
  const [showLastnameError, setShowLastnameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }

    const utmCampaign = params.get('utm_campaign');
    if (!utmCampaign) {
      return;
    }
    getAffiliatesCampaign(utmCampaign);
  }, []);

  const handleFirstnameChange = useCallback((value) => {
    setFirstname(value);
  }, []);

  const handleLastnameChange = useCallback((value) => {
    setLastname(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowFirstnameError(false);
    setShowLastnameError(false);
    setShowEmailError(false);
    setShowPasswordError(false);

    let hasErrors = false;

    if (firstname.length === 0) {
      setShowFirstnameError(true);
      hasErrors = true;
    }

    if (lastname.length === 0) {
      setShowLastnameError(true);
      hasErrors = true;
    }

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    register({
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      get_params: params,
      http_referrer: document.referrer || undefined,
    });
  };

  const handleGoogleClick = () => {
    let url = `${API_URL}/social/google/redirect?${params.toString()}`;
    if (document.referrer) {
      url += `&http_referrer=${document.referrer}`;
    }
    window.open(url, '_self');
  };
  const handleFacebookClick = () => {
    const url = `${API_URL}/social/facebook/redirect?${params.toString()}`;
    window.open(url, '_self');
  };
  const handleLinkedIdClick = () => {
    const url = `${API_URL}/social/linkedin/redirect?${params.toString()}`;
    window.open(url, '_self');
  };

  const handleSignInClick = () => {
    history.push(`${VISITOR_URLS.LOGIN}`);
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  if (newEmail) {
    history.push(
      `${VISITOR_URLS.EMAIL_VALIDATION}?token=${verificationToken}&email=${newEmail}`
    );
  }

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          banner={campaignMessage}
          title={t('visitor-app:registration_screen.title')}
          subtitle={t('visitor-app:registration_screen.sub_title')}
          brandButtons={{
            googleLabel: t(
              'visitor-app:registration_screen.sign_up_with_google'
            ),
            onGoogleClick: handleGoogleClick,
            onFacebookClick: handleFacebookClick,
            onLinkedInClick: handleLinkedIdClick,
          }}
          inputFields={[
            {
              placeholder: t(
                'visitor-app:registration_screen.firstname_placeholder'
              ),
              error: showFirstnameError
                ? t('visitor-app:registration_screen.firstname_required')
                : null,
              value: firstname,
              onChange: handleFirstnameChange,
              type: 'text',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.lastname_placeholder'
              ),
              error: showLastnameError
                ? t('visitor-app:registration_screen.lastname_required')
                : null,
              value: lastname,
              onChange: handleLastnameChange,
              type: 'text',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.email_placeholder'
              ),
              error: showEmailError
                ? t('visitor-app:change_email_screen.modal.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.password_placeholder'
              ),
              error: showPasswordError
                ? t('visitor-app:errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={t('visitor-app:registration_screen.register_btn')}
          footer1={
            <TranslateLink
              text={t('visitor-app:registration_screen.footer_message1')}
            />
          }
          footer2={
            <TextStyle>
              {t('visitor-app:registration_screen.footer_message2')}{' '}
              <Link onClick={handleSignInClick}>
                {t(
                  'visitor-app:registration_screen.footer_action_message_link'
                )}
              </Link>
            </TextStyle>
          }
          onSubmit={handleSubmit}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

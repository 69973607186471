import React, { useEffect, useState } from 'react';
import { Spinner } from '@shopify/polaris';
import './style.scss';
import { useHistory, useLocation } from 'react-router-dom';
import {
  LOCAL_STORAGE,
  REDIRECT_ACTION_BILLING,
  REDIRECT_ACTION_SHIPBOB,
  REDIRECT_ACTION_SHIPMENT_DETAIL,
} from 'Constants';
import { useTranslation } from 'react-i18next';
import CustomRedirect from 'components/CustomRedirect/CustomRedirect';
import { STORE_URLS } from 'config/urls';
import { ClientStorage } from 'ClientStorage';

export default function LoadingPage({
  storeUUID,
  setShipBobToaster,
  setBillingToasterMessage,
}) {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!storeUUID) {
      history.push('/s?isRedirected=true');
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    switch (searchParams.get('action')) {
      case REDIRECT_ACTION_SHIPBOB:
        setShipBobToaster(t(searchParams.get('message_id')));
        setRedirect(STORE_URLS.SHIP_BOB_CONFIG);
        return;
      case REDIRECT_ACTION_BILLING: // TODO: remove in billing cleanup
        setBillingToasterMessage(t(searchParams.get('message_id')));
        setRedirect(STORE_URLS.BILLING);
        return;
      case REDIRECT_ACTION_SHIPMENT_DETAIL:
        setRedirect(
          `${STORE_URLS.SHIPMENTS}/${searchParams.get('shipmentId')}`
        );
        history.replace({ search: '' }); // remove all params
        return;
      default:
        break;
    }

    if (ClientStorage.get(LOCAL_STORAGE.SAW_ONBOARDING) !== true) {
      setRedirect(STORE_URLS.ONBOARDING);
      return;
    }

    setRedirect(STORE_URLS.DASHBOARD);
  }, []);

  if (redirect) {
    return <CustomRedirect to={`/s/${storeUUID}${redirect}`} />;
  }

  return (
    <div className="LoadingPage-Wrapper">
      <div className="LoadingPage-Spinner">
        <Spinner accessibilityLabel="Loading" size="large" color="teal" />
      </div>
      <div className="LoadingPage-LoadingMessage">Loading</div>
    </div>
  );
}

const { API_URL } = require('../../config');
const { API } = require('../../helpers/api');

function getPerformanceReport(accessToken, shopifyId) {
  const url = `${API_URL}/stores/${shopifyId}/reports/performance`;
  return API.request({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getReport(accessToken, shopifyId, params) {
  const url = `${API_URL}/stores/${shopifyId}/reports`;
  return API.request({
    method: 'POST',
    url: url,
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

module.exports = {
  getPerformanceReport,
  getReport,
};

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, TextStyle, Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { isEmailValid } from 'Utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';

export default function ChangeEmail({
  getVisitorLanguages,
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  loading,
  updateEmail,
  visitorToast,
  resetVisitorToast,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get('token');
  let oldEmail = params.get('email');

  if (!token || !oldEmail) {
    history.push(VISITOR_URLS.LOGIN);
  } else {
    oldEmail = oldEmail.replaceAll(' ', '+');
  }

  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
  }, []);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handleSubmit = () => {
    setShowEmailError(false);

    let hasErrors = false;

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    updateEmail({ token, email });
  };

  const handleBackToSignUp = () => {
    history.push(VISITOR_URLS.REGISTER);
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  if (visitorToast.redirectToEmailValidation) {
    history.push(
      `${VISITOR_URLS.EMAIL_VALIDATION}?token=${token}&email=${email}`
    );
  }

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('visitor-app:change_email_screen.title')}
          subtitle={
            <TranslateLink
              text={t('visitor-app:change_email_screen.sub_title', {
                email: oldEmail,
              })}
            />
          }
          inputFields={[
            {
              placeholder: t('visitor-app:signin_screen.email_placeholder'),
              error: showEmailError
                ? t('visitor-app:change_email_screen.modal.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
          ]}
          button={t(
            'visitor-app:email_validation_screen.continue_to_email_confirmation_btn'
          )}
          footer1={
            <TextStyle>
              <Link onClick={handleBackToSignUp}>
                {t('visitor-app:email_validation_screen.sign_up_again')}
              </Link>
            </TextStyle>
          }
          onSubmit={handleSubmit}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

import { connect } from 'react-redux';
import VisitorRoutes from './VisitorRoutes';
import { visitorActions } from '../../redux/visitor';
import { shopActions } from 'redux/store/shopSlice';

function mapStateToProps(state) {
  return {
    accessTokenLoading: state.visitor.accessTokenLoading,
  };
}

const mapDispatchToProps = {
  getAccessToken: visitorActions.Creators.getAccessToken,
  setStoreUUID: shopActions.setStoreUUID,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorRoutes);

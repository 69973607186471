import React, { useState, useEffect, useCallback } from 'react';
import { Page, Layout, FooterHelp, Loading } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ViewMinor } from '@shopify/polaris-icons';
import SmallTopMessage from 'components/SmallTopMessage';
import ShipmentSection from './ShipmentSection';
import RealTimeStatusSection from './RealTimeStatusSection';
import ThankyouPageCustomization from './ThankyouPageCustomization';
import SettingsToaster from '../SettingsToaster';
import SaveBar from './SaveBar';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import OrderUpdateSection from './OrderUpdateSection';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import { STORE_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import i18next from 'i18next';

export default function ThankyouPage({
  getOrderStatusPageConfigurations,
  putOrderStatusPageConfigurations,
  getOrderStatusPagePreviewLink,
  orderStatusConfigurations,
  loadingThankYouPage = true,
  featureList,
  showUnsavedModal,
  orderStatusPagePreviewLink,
  featureRequest,
  resetFeatureRequest,
  shop,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);

  const [orderPageConfigObj, setOrderPageConfigObj] = useState(null);
  useEffect(() => {
    getOrderStatusPageConfigurations();
  }, [getOrderStatusPageConfigurations, featureList, i18next.language]);

  useEffect(() => {
    if (orderStatusConfigurations && !orderPageConfigObj) {
      setOrderPageConfigObj({
        configuration: orderStatusConfigurations,
        isUpdated: false,
      });
    }
  }, [
    orderStatusConfigurations,
    getOrderStatusPagePreviewLink,
    orderPageConfigObj,
  ]);

  useEffect(() => {
    if (orderStatusPagePreviewLink) {
      return;
    }

    if (orderStatusConfigurations?.is_active) {
      getOrderStatusPagePreviewLink();
    }
  }, [
    orderStatusConfigurations,
    getOrderStatusPagePreviewLink,
    orderStatusPagePreviewLink,
  ]);

  useEffect(() => {
    if (featureRequest) {
      getOrderStatusPageConfigurations();
      resetFeatureRequest();
    }
  }, [getOrderStatusPageConfigurations, featureRequest, resetFeatureRequest]);

  const submitData = () => {
    putOrderStatusPageConfigurations({
      configuration: orderPageConfigObj.configuration,
    });
    setOrderPageConfigObj({ ...orderPageConfigObj, isUpdated: false });
    setShowLoading(true);
  };

  const discardChanges = useCallback(() => {
    setOrderPageConfigObj({
      configuration: orderStatusConfigurations,
      isUpdated: false,
    });
  }, [orderStatusConfigurations]);

  const redirectBackToPrevious = useCallback(() => {
    history.push(`/s/${shop.storeUUID}${STORE_URLS.ADDONS}`);
  }, [history, shop.storeUUID]);

  const callbackFunction = useCallback(() => {
    discardChanges();
    redirectBackToPrevious();
  }, [discardChanges, redirectBackToPrevious]);

  const redirectBack = useCallback(() => {
    if (orderPageConfigObj.isUpdated) {
      showUnsavedModal(callbackFunction);
      return;
    }

    redirectBackToPrevious();
  }, [
    showUnsavedModal,
    redirectBackToPrevious,
    callbackFunction,
    orderPageConfigObj,
  ]);

  if (orderPageConfigObj === null) {
    return <LoadingLayoutPage />;
  }

  const handleViewYourPage = () => {
    getOrderStatusPagePreviewLink();
  };

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          { content: t('settings.breadcrumbs'), onAction: redirectBack },
        ]}
        title={t('settings.thankyou_page.title')}
        subtitle={
          <Subtitle
            text={t('settings.thankyou_page.description')}
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source:
                  'https://support.rush.app/shopify-order-status/thank-you-page-modification',
              },
            ]}
          />
        }
        secondaryActions={
          orderPageConfigObj &&
          orderPageConfigObj.configuration.is_active &&
          orderStatusPagePreviewLink
            ? [
                {
                  content: t('settings.thankyou_page.header.button_label'),
                  icon: ViewMinor,
                  url: orderStatusPagePreviewLink,
                  external: true,
                  onClick: handleViewYourPage,
                },
              ]
            : []
        }
      >
        <Layout>
          <SaveBar
            isSaveBarEnabled={
              orderPageConfigObj ? orderPageConfigObj.isUpdated : false
            }
            submitData={submitData}
            discardAction={discardChanges}
          />
          <ThankyouPageCustomization
            orderPageConfigObj={orderPageConfigObj}
            setOrderPageConfigObj={setOrderPageConfigObj}
          />
          {JSON.stringify(orderStatusConfigurations) !==
            JSON.stringify(orderPageConfigObj.configuration) &&
            showLoading && <Loading />}

          {JSON.stringify(orderStatusConfigurations) !==
            JSON.stringify(orderPageConfigObj.configuration) &&
            loadingThankYouPage && <Loading />}
          {orderPageConfigObj.configuration.is_active && (
            <>
              <Layout.AnnotatedSection
                title={t('settings.thankyou_page.shipment_section.title')}
                description={t(
                  'settings.thankyou_page.shipment_section.description'
                )}
              >
                <ShipmentSection
                  orderPageConfigObj={orderPageConfigObj}
                  setOrderPageConfigObj={setOrderPageConfigObj}
                />
                <OrderUpdateSection
                  orderStatusConfigurations={orderStatusConfigurations}
                  orderPageConfigObj={orderPageConfigObj}
                  setOrderPageConfigObj={setOrderPageConfigObj}
                />
              </Layout.AnnotatedSection>
              <RealTimeStatusSection
                orderStatusConfigurations={orderStatusConfigurations}
                orderPageConfigObj={orderPageConfigObj}
                setOrderPageConfigObj={setOrderPageConfigObj}
              />
            </>
          )}

          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
        <UnsavedChangesModal />
      </Page>
      <SettingsToaster />
    </>
  );
}

import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  Stack,
  Layout,
  DisplayText,
} from '@shopify/polaris';
import {
  SUBSCRIPTION_PLAN_GROUP_MONTHLY,
  SUBSCRIPTION_PLAN_GROUP_ANNUAL,
  SUBSCRIPTION_PLAN_GROUP_SPECIAL,
  BILLING_INTERVAL_30_DAYS,
  LOCAL_STORAGE,
} from 'Constants';
import _ from 'lodash';
import PlanRangeSection from './PlanRangeSection';
import { useTranslation } from 'react-i18next';
import { ClientStorage } from 'ClientStorage';
import { FULL_STORY_ORG, GOOGLE_TAG_ID } from 'config';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import i18next from 'i18next';
export default function ModalExample({
  showSubscriptionPlansModal,
  handleSubscriptionPlansModalChange,
  getSubscriptionPlans,
  subscriptionPlanGroups,
  subscriptionPlanMeta,
  postSubscriptionPlan,
  subscriptionPlanLoading,
  currentPlan,
  chargeDetail,
  resetSubscriptionPlan,
  getCurrentBillingPlan,
  ...props
}) {
  const [t] = useTranslation();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (
      _.isNull(chargeDetail.charge_url) &&
      !_.isNull(chargeDetail.type) &&
      !props.loading &&
      showSubscriptionPlansModal
    ) {
      resetSubscriptionPlan();
      getCurrentBillingPlan({});
      handleSubscriptionPlansModalChange();
      getSubscriptionPlans({});
    }
  }, [
    chargeDetail,
    getCurrentBillingPlan,
    props,
    showSubscriptionPlansModal,
    resetSubscriptionPlan,
    handleSubscriptionPlansModalChange,
    getSubscriptionPlans,
    i18next.language,
  ]);

  useEffect(() => {
    getSubscriptionPlans({});
  }, [getSubscriptionPlans, showSubscriptionPlansModal, i18next.language]);

  const [basicSelectedPlan, setBasicSelectedPlan] = useState(null);
  const [growthSelectedPlan, setGrowthSelectedPlan] = useState(null);

  const [planGroup, setPlanGroup] = useState(SUBSCRIPTION_PLAN_GROUP_MONTHLY);
  const selectPlanGroup = useCallback((value) => {
    setPlanGroup(value);
  }, []);

  useEffect(() => {
    if (
      subscriptionPlanMeta.current_subscription_plan_billing_interval ===
      BILLING_INTERVAL_30_DAYS
    ) {
      setPlanGroup(SUBSCRIPTION_PLAN_GROUP_MONTHLY);
    } else {
      setPlanGroup(
        subscriptionPlanMeta.current_subscription_plan_billing_interval
      );
    }
  }, [subscriptionPlanMeta, showSubscriptionPlansModal]);

  const handleFooterBtn = useCallback(
    (btn) => {
      if (
        btn.config.subscription_plan_id === 3 &&
        ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true
      ) {
        if (FULL_STORY_ORG && FULL_STORY_ORG !== 'null' && window.FS) {
          window.FS.event('subscriptionChangeAny');
          window.FS.event('subscriptionToFree');
        }

        if (GOOGLE_TAG_ID && GOOGLE_TAG_ID !== 'null') {
          sendDataToGTM({
            category: 'AppEvent',
            event: 'subscriptionToFree',
            action: 'subscriptionToFree',
            value: currentPlan.billing_plan.features.find(
              ({ feature_id }) => feature_id === 1
            ).price,
          });
        }
      }
      postSubscriptionPlan(btn.config.subscription_plan_id);
    },
    [postSubscriptionPlan]
  );

  if (chargeDetail.charge_url) {
    window.top.location.replace(chargeDetail.charge_url);
  }

  return (
    <Modal
      title={''}
      instant
      open={showSubscriptionPlansModal}
      onClose={handleSubscriptionPlansModalChange}
      titleHidden
      secondaryActions={
        subscriptionPlanMeta &&
        subscriptionPlanMeta.footer_buttons &&
        subscriptionPlanMeta.footer_buttons.map((btn) => {
          return {
            content: btn.content,
            external: btn.external,
            url: btn.url,
            onAction: () => (!btn.external ? handleFooterBtn(btn) : null),
            loading: subscriptionPlanLoading,
            disabled:
              subscriptionPlanLoading ||
              (btn.config &&
                currentPlan.billing_plan.id &&
                btn.config.subscription_plan_id ===
                  currentPlan.billing_plan.subscription_plan.id),
          };
        })
      }
      large={true}
    >
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <Stack distribution="center">
              <DisplayText size="medium" variation="strong">
                {subscriptionPlanMeta.title}
              </DisplayText>
            </Stack>
          </Layout.Section>
          <Layout.Section>
            <Stack distribution="center">
              <ButtonGroup
                segmented={true}
                fullWidth={false}
                connectedTop={true}
              >
                {subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_MONTHLY] && (
                  <Button
                    outline={planGroup !== SUBSCRIPTION_PLAN_GROUP_MONTHLY}
                    pressed={planGroup !== SUBSCRIPTION_PLAN_GROUP_MONTHLY}
                    primary={planGroup === SUBSCRIPTION_PLAN_GROUP_MONTHLY}
                    onClick={() =>
                      selectPlanGroup(SUBSCRIPTION_PLAN_GROUP_MONTHLY)
                    }
                  >
                    {
                      subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_MONTHLY]
                        .caption
                    }
                  </Button>
                )}
                {subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_ANNUAL] && (
                  <Button
                    outline={planGroup !== SUBSCRIPTION_PLAN_GROUP_ANNUAL}
                    pressed={planGroup !== SUBSCRIPTION_PLAN_GROUP_ANNUAL}
                    primary={planGroup === SUBSCRIPTION_PLAN_GROUP_ANNUAL}
                    onClick={() =>
                      selectPlanGroup(SUBSCRIPTION_PLAN_GROUP_ANNUAL)
                    }
                  >
                    {
                      subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_ANNUAL]
                        .caption
                    }
                  </Button>
                )}
                {subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_SPECIAL] && (
                  <Button
                    outline={planGroup !== SUBSCRIPTION_PLAN_GROUP_SPECIAL}
                    pressed={planGroup !== SUBSCRIPTION_PLAN_GROUP_SPECIAL}
                    primary={planGroup === SUBSCRIPTION_PLAN_GROUP_SPECIAL}
                    onClick={() =>
                      selectPlanGroup(SUBSCRIPTION_PLAN_GROUP_SPECIAL)
                    }
                  >
                    {
                      subscriptionPlanGroups[SUBSCRIPTION_PLAN_GROUP_SPECIAL]
                        .caption
                    }
                  </Button>
                )}
              </ButtonGroup>
            </Stack>
          </Layout.Section>
          <Layout.Section />
        </Layout>
        {planGroup === SUBSCRIPTION_PLAN_GROUP_MONTHLY && (
          <Layout>
            <PlanRangeSection
              planGroup={SUBSCRIPTION_PLAN_GROUP_MONTHLY}
              planKey={'basic'}
              title={t(
                'billing.subscription_plans_modal.billing_monthly_category.basic_title'
              )}
              caption={t(
                'billing.subscription_plans_modal.billing_monthly_category.basic_caption'
              )}
              setPlan={setBasicSelectedPlan}
              listText={t(
                'billing.subscription_plans_modal.billing_monthly_category.basic_list_text'
              )}
            />
            <PlanRangeSection
              planGroup={SUBSCRIPTION_PLAN_GROUP_MONTHLY}
              planKey={'growth'}
              title={t(
                'billing.subscription_plans_modal.billing_monthly_category.growth_title'
              )}
              caption={t(
                'billing.subscription_plans_modal.billing_monthly_category.growth_caption'
              )}
              listText={t(
                'billing.subscription_plans_modal.billing_monthly_category.growth_list_text'
              )}
              groupSelectedPlan={basicSelectedPlan}
              setPlan={setGrowthSelectedPlan}
            />
            <PlanRangeSection
              planGroup={SUBSCRIPTION_PLAN_GROUP_MONTHLY}
              planKey={'advanced'}
              title={t(
                'billing.subscription_plans_modal.billing_monthly_category.advanced_title'
              )}
              caption={t(
                'billing.subscription_plans_modal.billing_monthly_category.advanced_caption'
              )}
              listText={t(
                'billing.subscription_plans_modal.billing_monthly_category.advanced_list_text'
              )}
              groupSelectedPlan={growthSelectedPlan}
            />
          </Layout>
        )}
        {planGroup === SUBSCRIPTION_PLAN_GROUP_ANNUAL && (
          <Layout>
            <PlanRangeSection
              planGroup={SUBSCRIPTION_PLAN_GROUP_ANNUAL}
              planKey={'ungrouped'}
              listText={t(
                'billing.subscription_plans_modal.billing_annual_category.list_text'
              )}
            />
          </Layout>
        )}
        {planGroup === SUBSCRIPTION_PLAN_GROUP_SPECIAL && (
          <Layout>
            <PlanRangeSection
              planGroup={SUBSCRIPTION_PLAN_GROUP_SPECIAL}
              planKey={'ungrouped'}
            />
          </Layout>
        )}
      </Modal.Section>
    </Modal>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  SettingToggle,
  TextStyle,
  TextField,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import stringHelper from 'helpers/string';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function TrackingOptions({
  trackingPageState,
  dispatchForTrackingPage,
}) {
  const [t] = useTranslation();
  const [isTrackingOptionsEnabled, setIsTrackingOptionsEnabled] =
    useState(null);
  const [googleAnalytics, setGoogleAnalytics] = useState('');
  const [googleAnalyticsError, setGoogleAnalyticsError] = useState('');
  const [googleConversionId, setGoogleConversionId] = useState('');
  const [googleConversionIdError, setGoogleConversionIdError] = useState('');
  const [googleTagManager, setGoogleTagManager] = useState('');
  const [googleTagManagerError, setGoogleTagManagerError] = useState('');
  const [facebookPixel, setFacebookPixel] = useState('');
  const [facebookPixelError, setFacebookPixelError] = useState('');

  const handleGoogleAnalyticsChange = useCallback(
    (newValue) => setGoogleAnalytics(newValue),
    []
  );
  const handleGoogleConversionIdChange = useCallback(
    (newValue) => setGoogleConversionId(newValue),
    []
  );
  const handleGoogleTagManagerChange = useCallback(
    (newValue) => setGoogleTagManager(newValue),
    []
  );
  const handleFacebookPixelChange = useCallback(
    (newValue) => setFacebookPixel(newValue),
    []
  );

  const handleBlurFacebookPixel = useCallback(() => {
    if (facebookPixel && !stringHelper.isNumeric(facebookPixel)) {
      setFacebookPixelError(
        t('settings.tracking_page.tracking.card.fb.error_label')
      );
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.VALIDATION,
        payload: {
          facebook_pixel: false,
        },
      });
    } else {
      setFacebookPixelError(null);
      const tempTrackingSection = {
        facebook_pixel: facebookPixel,
      };
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE,
        payload: tempTrackingSection,
        validation: {
          facebook_pixel: true,
        },
      });
    }
  }, [dispatchForTrackingPage, t, facebookPixel]);
  const handleBlurGoogleAnalytics = useCallback(() => {
    if (googleAnalytics && googleAnalytics.search(/^ua-\d{4,9}-\d{1,4}$/i)) {
      setGoogleAnalyticsError(
        t('settings.tracking_page.tracking.card.ga.error_label')
      );
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.VALIDATION,
        payload: {
          google_analytics: false,
        },
      });
    } else {
      setGoogleAnalyticsError(null);
      const tempTrackingSection = {
        google_analytics: googleAnalytics,
      };
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE,
        payload: tempTrackingSection,
        validation: {
          google_analytics: true,
        },
      });
    }
  }, [dispatchForTrackingPage, t, googleAnalytics]);
  const handleBlurGoogleConversionId = useCallback(() => {
    if (googleConversionId && !stringHelper.isNumeric(googleConversionId)) {
      setGoogleConversionIdError(
        t(
          'settings.tracking_page.tracking.card.ga_remarketing_code.error_label'
        )
      );
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.VALIDATION,
        payload: {
          google_conversion_id: false,
        },
      });
    } else {
      setGoogleConversionIdError(null);
      const tempTrackingSection = {
        google_conversion_id: googleConversionId,
      };
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE,
        payload: tempTrackingSection,
        validation: {
          google_conversion_id: true,
        },
      });
    }
  }, [dispatchForTrackingPage, t, googleConversionId]);
  const handleBlurGoogleTagManager = useCallback(() => {
    if (googleTagManager && googleTagManager.search(/^GTM-[A-Z0-9]{1,7}$/)) {
      setGoogleTagManagerError(
        t('settings.tracking_page.tracking.card.gtm.error_label')
      );
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.VALIDATION,
        payload: {
          google_tag_manager: false,
        },
      });
    } else {
      setGoogleTagManagerError(null);
      const tempTrackingSection = {
        google_tag_manager: googleTagManager,
      };
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE,
        payload: tempTrackingSection,
        validation: {
          google_tag_manager: true,
        },
      });
    }
  }, [dispatchForTrackingPage, t, googleTagManager]);

  const handleIsTrackingOptionsEnabledChange = useCallback(() => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE,
      payload: { is_enabled: !isTrackingOptionsEnabled },
    });
    setIsTrackingOptionsEnabled((active) => !active);
  }, [dispatchForTrackingPage, isTrackingOptionsEnabled]);

  const contentIsTrackingOptionsEnabledStatus = isTrackingOptionsEnabled
    ? t('settings.tracking_page.tracking.enable.button_on')
    : t('settings.tracking_page.tracking.enable.button_off');

  useEffect(() => {
    if (trackingPageState.tracking_instrumentation) {
      setIsTrackingOptionsEnabled(
        trackingPageState.tracking_instrumentation.is_enabled
      );
      setGoogleAnalytics(
        trackingPageState.tracking_instrumentation.google_analytics
      );
      setGoogleTagManager(
        trackingPageState.tracking_instrumentation.google_tag_manager
      );
      if (trackingPageState.tracking_instrumentation.google_conversion_id) {
        setGoogleConversionId(
          `${trackingPageState.tracking_instrumentation.google_conversion_id}`
        );
      }
      if (trackingPageState.tracking_instrumentation.facebook_pixel) {
        setFacebookPixel(
          `${trackingPageState.tracking_instrumentation.facebook_pixel}`
        );
      }
    }
  }, [trackingPageState.tracking_instrumentation, isTrackingOptionsEnabled]);

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.tracking.title')}
      description={t('settings.tracking_page.tracking.description')}
    >
      <SettingToggle
        action={{
          content: contentIsTrackingOptionsEnabledStatus,
          onAction: handleIsTrackingOptionsEnabledChange,
        }}
        enabled={isTrackingOptionsEnabled}
      >
        <TextStyle>
          {t('settings.tracking_page.tracking.enable.title')}
        </TextStyle>
      </SettingToggle>
      {isTrackingOptionsEnabled && (
        <Card>
          <Card.Header
            title={t('settings.tracking_page.tracking.card.title')}
          />
          <Card.Section>
            <TextField
              value={googleAnalytics}
              label={t('settings.tracking_page.tracking.card.ga.title')}
              onChange={handleGoogleAnalyticsChange}
              onBlur={handleBlurGoogleAnalytics}
              placeholder={t(
                'settings.tracking_page.tracking.card.ga.input.default_text'
              )}
              error={googleAnalyticsError}
              id={`input-tracking-ga`}
            />
            <TextStyle variation="subdued">
              <TranslateLink
                text={t(
                  'settings.tracking_page.tracking.card.ga.input.helper_text'
                )}
              />
            </TextStyle>
          </Card.Section>
          <Card.Section>
            <TextField
              value={googleTagManager}
              label={t('settings.tracking_page.tracking.card.gtm.title')}
              onChange={handleGoogleTagManagerChange}
              onBlur={handleBlurGoogleTagManager}
              placeholder={t(
                'settings.tracking_page.tracking.card.gtm.input.default_text'
              )}
              error={googleTagManagerError}
              id={`input-tracking-gtm`}
            />
            <TextStyle variation="subdued">
              <TranslateLink
                text={t(
                  'settings.tracking_page.tracking.card.gtm.input.helper_text'
                )}
              />
            </TextStyle>
          </Card.Section>
          <Card.Section>
            <TextField
              value={googleConversionId}
              label={t(
                'settings.tracking_page.tracking.card.ga_remarketing_code.title'
              )}
              onChange={handleGoogleConversionIdChange}
              onBlur={handleBlurGoogleConversionId}
              placeholder={t(
                'settings.tracking_page.tracking.card.ga_remarketing_code.input.default_text'
              )}
              error={googleConversionIdError}
              id={`input-tracking-ga-remarketing`}
            />
            <TextStyle variation="subdued">
              <TranslateLink
                text={t(
                  'settings.tracking_page.tracking.card.ga_remarketing_code.input.helper_text'
                )}
              />
            </TextStyle>
          </Card.Section>
          <Card.Section>
            <TextField
              value={facebookPixel}
              label={t('settings.tracking_page.tracking.card.fb.title')}
              onChange={handleFacebookPixelChange}
              onBlur={handleBlurFacebookPixel}
              placeholder={t(
                'settings.tracking_page.tracking.card.fb.input.default_text'
              )}
              error={facebookPixelError}
              id={`input-tracking-fb`}
            />
            <TextStyle variation="subdued">
              <TranslateLink
                text={t(
                  'settings.tracking_page.tracking.card.fb.input.helper_text'
                )}
              />
            </TextStyle>
          </Card.Section>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

import React from 'react';
import { Card, Heading, TextContainer, TextStyle } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const TrackingPageCard = ({ trackingPage }) => {
  const [t] = useTranslation();

  return (
    <Card
      sectioned
      primaryFooterAction={{
        content: t('settings.tracking_page.tracking_pages.btn_primary'),
        onAction: () => {},
      }}
      secondaryFooterActionsDisclosureText={t(
        'settings.tracking_page.tracking_pages.btn_secondary'
      )}
      secondaryFooterActions={[
        {
          content: t('settings.tracking_page.tracking_pages.preview'),
          onAction: () => {},
        },
        {
          content: t('settings.tracking_page.tracking_pages.publish'),
          onAction: () => {},
        },
        {
          content: t('settings.tracking_page.tracking_pages.rename'),
          onAction: () => {},
        },
        {
          content: t('settings.tracking_page.tracking_pages.duplicate'),
          onAction: () => {},
        },
        {
          content: t('settings.tracking_page.tracking_pages.remove'),
          onAction: () => {},
        },
      ]}
    >
      <TextContainer>
        <Heading>{trackingPage.name}</Heading>
        <TextStyle variation="subdued">
          <section>
            <span>
              {t('settings.tracking_page.tracking_pages.created')}{' '}
              {trackingPage.createdAt}
            </span>
            ,
            <span>
              {t('settings.tracking_page.tracking_pages.updated')}{' '}
              {trackingPage.updatedAt}
            </span>
          </section>
          <p>{trackingPage.kind}</p>
        </TextStyle>
      </TextContainer>
    </Card>
  );
};

export default TrackingPageCard;

import React, { useState, useCallback } from 'react';
import { Layout, Card, Checkbox } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function ShipmentTrackingBy({
  dispatchForTrackingPage,
  trackingPageState,
}) {
  const [t] = useTranslation();
  const [renderBetweenHeaderAndFooter, setRenderBetweenHeaderAndFooter] =
    useState(trackingPageState.render_between_header_and_footer);
  const handleChange = useCallback(
    (newChecked) => {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: { render_between_header_and_footer: newChecked },
      });
      setRenderBetweenHeaderAndFooter(newChecked);
    },
    [dispatchForTrackingPage]
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.configuration.section_title')}
      description={t(
        'settings.tracking_page.configuration.section_description'
      )}
    >
      <Card
        title={t('settings.tracking_page.configuration.card_title')}
        sectioned
      >
        <Checkbox
          label={t('settings.tracking_page.configuration.render_label')}
          checked={renderBetweenHeaderAndFooter}
          onChange={handleChange}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import {
  Layout,
  Card,
  Heading,
  Page,
  TextStyle,
  FooterHelp,
  Badge,
  Banner,
  Stack,
  Loading,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import './style.scss';
import SmallTopMessage from 'components/SmallTopMessage';
import SettingsLoadingPage from './SettingsLoadingPage';
import SlackModal from './SlackModal';
import * as CONSTANTS from 'Constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { openHubSpotChat } from 'Utils/utils';
import { STORE_URLS, USER_URLS } from 'config/urls';
import Subtitle from 'components/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  useActivateFeatureMutation,
  useDeactivateFeatureMutation,
  useGetSettingsQuery,
} from './settingsApis';
import { settingsActions } from 'redux/settings';
import LoadingCard from './SettingsLoadingPage/LoadingCard';
import { baseActions } from 'redux/store/baseSlice';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';
import { billingActions } from 'pages/Billing1/billingSlice';
import UpdatePlanModal from 'pages/Billing1/components/UpdatePlanModal';

export default function Settings() {
  const [t] = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const path = location.pathname.split('/');
  const page = path[path.length - 1];
  const dispatch = useDispatch();

  const { data: user = {} } = useGetUserDetailsQuery();

  const subscriptionPlanLoading = useSelector(
    (state) => state.billing.subscriptionPlanLoading
  );
  const [currentFeatureId, setCurrentFeatureId] = useState('');
  const [currentModule, setCurrentModule] = useState('');

  const queryParams = useMemo(() => {
    const p = { type: 'service' };

    if (page === 'notifications') {
      p.type = 'notification';
    }
    return p;
  }, [page]);

  const {
    data: { feature_group: featureList = [] } = {},
    isLoading: loadingSettings,
    isFetching: fetchingSettings,
    isSuccess: settingsLoaded,
  } = useGetSettingsQuery(queryParams);

  const [
    activateFeature,
    { isLoading: featureActivating, isSuccess: featureActivated },
  ] = useActivateFeatureMutation();

  useEffect(() => {
    if (!featureActivating && featureActivated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.feature.enable.toast'),
          type: 'success',
        })
      );
    }
  }, [featureActivating, featureActivated]);

  const [
    deactivateFeature,
    { isLoading: featureDeactivating, isSuccess: featureDctivated },
  ] = useDeactivateFeatureMutation();

  useEffect(() => {
    if (!featureDeactivating && featureDctivated) {
      dispatch(
        baseActions.setToastMessage({
          message: t('settings.feature.disable.toast'),
          type: 'success',
        })
      );
    }
  }, [featureDeactivating, featureDctivated]);

  useEffect(() => {
    if (!fetchingSettings && settingsLoaded) {
      setCurrentFeatureId('');
      setCurrentModule(page);
    }
  }, [fetchingSettings, settingsLoaded, page]);

  const getButton = (featureId, featureButtonItem) => {
    let buttonObject = { content: featureButtonItem.content };
    if (featureButtonItem.external) {
      buttonObject.external = true;
      buttonObject.url = featureButtonItem.url;
    }
    buttonObject.disabled =
      featureButtonItem.style === CONSTANTS.SETTINGS_BUTTON_DISABLED;
    buttonObject.destructive =
      featureButtonItem.style === CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE;

    if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_TRACKING_PAGE_GOTO_CONFIGURATION
    ) {
      buttonObject.url = '/settings/tracking';
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_FORECASTED_DELIVERY_DATE_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SMART_EDD}`;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_EMAIL_CHANNEL_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.EMAIL_NOTIFICATION}`;
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_THANKYOU_PAGE) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.THANK_YOU}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SMS_CHANNEL_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SMS_NOTIFICATION}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_UPSELLS_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.UPSELLS}`;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_MERCHANTS_NOTE_GOTO_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.MERCHANT_NOTE}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_PAYPAL_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.PAYPAL_CONFIG}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SHIPBOB_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.SHIP_BOB_CONFIG}`;
    } else if (featureButtonItem.id.indexOf('_activate') > 0) {
      buttonObject.onAction = () => {
        activateFeature(featureId);
        setCurrentFeatureId(featureId);
      };
    } else if (featureButtonItem.id.indexOf('_deactivate') > 0) {
      buttonObject.onAction = () => {
        deactivateFeature(featureId);
        setCurrentFeatureId(featureId);
      };
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_SLACK_OPEN_CONFIGURATION
    ) {
      buttonObject.onAction = () =>
        dispatch(settingsActions.Creators.handleShowSlackModal());
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_KLAVIYO_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.KLAVIYO}`;
    } else if (
      featureButtonItem.id === CONSTANTS.SETTINGS_OMNISEND_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.OMNISEND}`;
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_UPGRADE_TO_PLAN) {
      buttonObject.onAction = () =>
        dispatch(billingActions.toggleUpdateModal());
    } else if (featureButtonItem.id === CONSTANTS.SETTINGS_CUSTOMER_CHAT_OPEN) {
      buttonObject.onAction = openHubSpotChat;
    } else if (
      featureButtonItem.id ===
      CONSTANTS.SETTINGS_PRODUCT_REVIEW_OPEN_CONFIGURATION
    ) {
      buttonObject.url = `/s/${params.uuid}${STORE_URLS.PRODUCT_REVIEWS}`;
    }

    if (
      featureButtonItem.id === CONSTANTS.SETTINGS_UPGRADE_TO_PLAN &&
      subscriptionPlanLoading
    ) {
      buttonObject.disabled = true;
      buttonObject.loading = true;
    }

    // Disable buttons if on notifications page and user email is not verified
    buttonObject.disabled =
      buttonObject.disabled ||
      (page === 'notifications' && !user.email_verified);
    return buttonObject;
  };
  const getPrimaryButtons = (featureId, featureButtons) => {
    let primaryButtons = null;
    if (!featureButtons) {
      return primaryButtons;
    }
    featureButtons.map((button) => {
      if (button.style === CONSTANTS.SETTINGS_BUTTON_PRIMARY) {
        primaryButtons = getButton(featureId, button);
      }
      if (button.style === CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE) {
        primaryButtons = getButton(featureId, button);
      }

      return null;
    });
    return primaryButtons;
  };

  const getSecondaryButtons = (featureId, featureButtons) => {
    let secondaryButtons = [];
    if (!featureButtons) {
      return secondaryButtons;
    }
    featureButtons.map((button) => {
      let btn = null;
      if (
        button.style !== CONSTANTS.SETTINGS_BUTTON_PRIMARY &&
        button.style !== CONSTANTS.SETTINGS_BUTTON_DESTRUCTIVE
      ) {
        btn = getButton(featureId, button);
      }
      if (btn) {
        secondaryButtons.push(btn);
      }
      return null;
    });
    return secondaryButtons;
  };

  return (
    <>
      {fetchingSettings ||
      featureDeactivating ||
      featureActivating ||
      subscriptionPlanLoading ? (
        <Loading />
      ) : (
        ''
      )}
      {page === 'notifications' && !user.email_verified ? (
        <div className="Banners-Wrapper">
          <Banner
            title={t('settings_page.banner.title')}
            action={{
              content: t('settings_page.banner.action'),
              onAction: () => history.push(USER_URLS.DETAILS),
            }}
            status="warning"
          >
            <TextStyle>{t('settings_page.banner.description')}</TextStyle>
          </Banner>
        </div>
      ) : (
        <SmallTopMessage />
      )}

      <Page
        title={
          page === 'notifications'
            ? t('navigation.item.notifications')
            : t('navigation.item.addons')
        }
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('shipments.subtitle.get_started'),
                source:
                  page === 'notifications'
                    ? 'https://support.rush.app/about-notifications'
                    : 'https://support.rush.app/about-add-ons',
              },
            ]}
          />
        }
      >
        {loadingSettings || (fetchingSettings && currentModule !== page) ? (
          <SettingsLoadingPage />
        ) : (
          <Layout>
            {!loadingSettings &&
              featureList &&
              featureList.map((feature, index) => (
                <Layout.AnnotatedSection
                  title={feature.title}
                  description={feature.description}
                  key={index}
                >
                  {feature.features &&
                    feature.features.map((featureDetail, index) => {
                      if (
                        currentFeatureId === featureDetail.feature_id &&
                        (fetchingSettings ||
                          featureDeactivating ||
                          featureActivating)
                      ) {
                        return <LoadingCard key={index} />;
                      }
                      const primaryButtons = getPrimaryButtons(
                        featureDetail.feature_id,
                        featureDetail.buttons
                      );
                      const secondaryButtons = getSecondaryButtons(
                        featureDetail.feature_id,
                        featureDetail.buttons
                      );
                      return (
                        <Card
                          primaryFooterAction={primaryButtons}
                          secondaryFooterActions={secondaryButtons}
                          key={index}
                          sectioned
                          subdued={
                            page === 'notifications' && !user.email_verified
                          }
                        >
                          <div className="SettingCard ">
                            <div className="SettingCard-Thumbnail">
                              <img
                                src={featureDetail.icon_link}
                                className="SettingCard-Thumbnail-Image"
                                alt={featureDetail.title}
                              />
                            </div>
                            <div className="SettingCard-Heading">
                              <Stack>
                                <Heading>
                                  <TranslateLink text={featureDetail.title} />
                                </Heading>
                              </Stack>
                            </div>
                            {featureDetail.status_label &&
                              featureDetail.status_badge_prop && (
                                <div className="SettingCard-Status">
                                  <Badge
                                    status={
                                      featureDetail.status_badge_prop.status
                                    }
                                    progress={
                                      featureDetail.status_badge_prop.progress
                                    }
                                  >
                                    {featureDetail.status_label}
                                  </Badge>
                                </div>
                              )}
                          </div>
                          <div>
                            <TextStyle>
                              <TranslateLink text={featureDetail.description} />
                            </TextStyle>
                          </div>
                        </Card>
                      );
                    })}
                </Layout.AnnotatedSection>
              ))}

            <Layout.Section>
              <FooterHelp>
                <TranslateLink text={t('footer_help.dashboard')} />
              </FooterHelp>
            </Layout.Section>
          </Layout>
        )}
      </Page>
      <SlackModal />
      <UpdatePlanModal />
    </>
  );
}

import { connect } from 'react-redux';
import RegisterByInvite from './RegisterByInvite';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterByInvite);

import React, { useMemo, useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Navigation } from '@shopify/polaris';
// import { STAGE } from 'config';
// import { ENV_PRODUCTION } from 'Constants';
import {
  HomeMajor,
  ShipmentMajor,
  CreditCardSecureMajor,
  GiftCardMajor,
  ThemesMajor,
  AppsMajor,
  NotificationMajor,
  CircleInformationMajor,
  StoreMajor,
  ProfileMajor,
  MarketingMinor,
} from '@shopify/polaris-icons';
import SubscriptionBox from 'components/SubscriptionBox';
import { STORE_URLS, USER_URLS } from 'config/urls';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE, CHANGELOG_RELEASE_DATE } from 'Constants';
import moment from 'moment';
import './styles.scss';
import { useGetPerksQuery } from 'pages/Perks/perksApi';

function LeftNavigation({ storeUUID }) {
  const [t] = useTranslation();

  const perksResponse = useGetPerksQuery();
  const {
    data: perksList = [],
    isFetching: isPerksLoading,
    isSuccess: isPerksLoaded,
  } = perksResponse || {};

  const showPerksNew = useMemo(() => {
    if (perksList && isPerksLoaded && !isPerksLoading) {
      return !!perksList.find((p) => p.is_new_badge);
    }
    return false;
  }, [isPerksLoaded, isPerksLoading, perksList]);

  const [changeLogExplored, setChangeLogExplored] = useState(
    ClientStorage.get(LOCAL_STORAGE.CHANGELOG_EXPLORED)
  );
  const showChangeLogBadges =
    moment
      .duration(
        new moment().diff(new moment(CHANGELOG_RELEASE_DATE, 'MM-DD-YYYY'))
      )
      .asMonths() <= 1;

  const history = useHistory();
  const location = useLocation();
  let items;

  let shipmentSubItems = [
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_BLACKLISTING}`,
      label: t('navigation.item.shipments_blacklisting'),
      selected: location.pathname.includes(STORE_URLS.SHIPMENTS_BLACKLISTING),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.CARRIER_MASKING}`,
      label: t('navigation.item.shipments_carriermasking'),
      selected: location.pathname.includes(STORE_URLS.CARRIER_MASKING),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}`,
      label: t('navigation.item.shipments_pre_shipment_timeline'),
      selected: location.pathname.includes(
        STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
      ),
    },
    {
      url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS_CONFIGURATIONS}`,
      label: t('navigation.item.shipments_configuration'),
      selected: location.pathname.includes(STORE_URLS.SHIPMENTS_CONFIGURATIONS),
    },
  ].filter((item) => !item.hidden);

  if (location.pathname === USER_URLS.DETAILS) {
    items = [
      {
        url: USER_URLS.DETAILS,
        label: t('navigation.item.account'),
        icon: ProfileMajor,
        selected: location.pathname.includes(USER_URLS.DETAILS),
      },
      {
        url: '/s',
        label: t('navigation.item.stores'),
        icon: StoreMajor,
        selected: location.pathname.includes('/s'),
      },
    ];
  } else {
    items = [
      {
        url: `/s/${storeUUID}${STORE_URLS.ONBOARDING}`,
        label: t('navigation.item.onboarding'),
        icon: CircleInformationMajor,
        selected: location.pathname.includes('/onboarding'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.DASHBOARD}`,
        label: t('navigation.item.dashboard'),
        icon: HomeMajor,
        selected: location.pathname.includes('/dashboard'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.SHIPMENTS}`,
        label: t('navigation.item.shipments'),
        icon: ShipmentMajor,
        selected: location.pathname.includes('/shipment'),
        onClick: () => history.push(`/s/${storeUUID}${STORE_URLS.SHIPMENTS}`),
        subNavigationItems: shipmentSubItems,
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.TRACKING_PAGES}`,
        label: t('settings.upsell_page.title'),
        icon: ThemesMajor,
        selected: location.pathname.includes('/tracking-pages'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.NOTIFICATIONS}`,
        label: t('navigation.item.notifications'),
        icon: NotificationMajor,
        selected: location.pathname.includes('/notifications'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.CONVERSIONS}`,
        label: t('navigation.item.conversions'),
        icon: MarketingMinor,
        selected: location.pathname.includes('/conversions'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.ADDONS}`,
        label: t('navigation.item.addons'),
        icon: AppsMajor,
        selected: location.pathname.includes('/addons'),
      },
      {
        url: `/s/${storeUUID}${STORE_URLS.BILLING}`,
        label: t('navigation.item.billing'),
        icon: CreditCardSecureMajor,
        selected: location.pathname.includes('/billing'),
      },
    ];
    items.push({
      url: `/s/${storeUUID}${STORE_URLS.PERKS}`,
      label: t('navigation.item.perks'),
      icon: GiftCardMajor,
      selected: location.pathname.includes('/perks'),
      badge: showPerksNew ? (
        <Badge status="success" size="small">
          {t('navigation.item.new')}
        </Badge>
      ) : undefined,
    });
  }

  return (
    <>
      <Navigation location={`/s/${storeUUID}/`}>
        {location.pathname !== USER_URLS.DETAILS && (
          <React.Fragment>
            <SubscriptionBox />
            <Navigation.Section separator items={[]} />
          </React.Fragment>
        )}
        <Navigation.Section title="" items={items} fill />
        <Navigation.Section
          title=""
          items={[
            {
              label: t('navigation.item.whats-new'),
              icon: NotificationMajor,
              onClick: () => {
                ClientStorage.set(LOCAL_STORAGE.CHANGELOG_EXPLORED, true);
                setChangeLogExplored(true);
                window.open(
                  'https://feedback.rush.app/f/changelog/?utm_source=app.rush.app&utm_medium=app.rush.app',
                  '_blank'
                );
              },
              subNavigationItems: !changeLogExplored
                ? [
                    {
                      new: true,
                    },
                  ]
                : [],
            },
          ]}
        />
      </Navigation>
    </>
  );
}
export default withRouter(LeftNavigation);

import React, { useCallback } from 'react';
import { ContextualSaveBar } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function SaveBar({
  settingsLoading,
  discardAction,
  isSaveBarEnabled,
  submitData,
}) {
  const [t] = useTranslation();
  const saveThankyouPage = useCallback(() => {
    submitData();
  }, [submitData]);

  return (
    <>
      {isSaveBarEnabled && (
        <ContextualSaveBar
          alignContentFlush
          message={t('settings.thankyou.top_bar_rule_unsaved_message')}
          saveAction={{
            onAction: () => saveThankyouPage(),
            loading: settingsLoading,
            disabled: settingsLoading,
            content: t('common.save'),
          }}
          discardAction={{
            onAction: () => discardAction(),
            loading: settingsLoading,
            disabled: settingsLoading,
            content: t('common.discard'),
          }}
        />
      )}
    </>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  SettingToggle,
  Card,
  Form,
  FormLayout,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import _ from 'lodash';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
export default function Customization({
  trackingPageState,
  dispatchForTrackingPage,
}) {
  const [t] = useTranslation();
  const [customizationActive, setCustomizationActive] = useState(null);
  const handleCustomizationActiveToggle = useCallback(
    () => setCustomizationActive((active) => !active),
    []
  );
  const contentCustomizationActiveStatus = customizationActive
    ? t('settings.tracking_page.customizations.enable.button_on')
    : t('settings.tracking_page.customizations.enable.button_off');

  const [customCSS, setCustomCSS] = useState('');
  const handleCustomCSSChange = useCallback(
    (newValue) => setCustomCSS(newValue),
    []
  );
  const handleCustomCSSBlur = useCallback(() => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        custom: { css: customCSS, is_enabled: customizationActive, js: null },
      },
    });
  }, [customizationActive, customCSS, dispatchForTrackingPage]);

  useEffect(() => {
    if (trackingPageState.custom && _.isNull(customizationActive)) {
      setCustomizationActive(trackingPageState.custom.is_enabled);
      setCustomCSS(trackingPageState.custom.css);
    }
  }, [trackingPageState, customizationActive]);

  useEffect(() => {
    setCustomizationActive(trackingPageState?.custom?.is_enabled || null);
    setCustomCSS(trackingPageState?.custom?.css || '');
  }, [trackingPageState?.custom]);

  useEffect(() => {
    if (
      trackingPageState.custom &&
      !_.isNull(customizationActive) &&
      trackingPageState.custom.is_enabled !== customizationActive
    ) {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: {
          custom: { css: customCSS, is_enabled: customizationActive, js: null },
        },
      });
    }
  }, [
    dispatchForTrackingPage,
    customizationActive,
    // trackingPageState,
    customCSS,
  ]);

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.customizations.title')}
      description={t('settings.tracking_page.customizations.description')}
    >
      <SettingToggle
        action={{
          content: contentCustomizationActiveStatus,
          onAction: handleCustomizationActiveToggle,
        }}
        enabled={customizationActive}
      >
        <TextStyle>
          {t('settings.tracking_page.customizations.enable.title')}
        </TextStyle>
      </SettingToggle>
      {customizationActive && (
        <Card sectioned>
          <TextStyle>
            {t('settings.tracking_page.customizations.card.custom_css')}
          </TextStyle>
          <div className="CustomTextField-Label-HelpText">
            <TranslateLink
              text={t(
                `settings.tracking_page.customizations.card.custom_css_help`
              )}
            />
          </div>
          <Form>
            <FormLayout>
              <TextField
                value={customCSS}
                onChange={handleCustomCSSChange}
                onBlur={handleCustomCSSBlur}
                multiline={4}
                disabled={!customizationActive}
                id={`input-customization-custom-css`}
              />
            </FormLayout>
          </Form>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

import React, { useMemo, useState } from 'react';
import {
  FormLayout,
  Icon,
  Modal,
  TextField,
  TextStyle,
  Toast,
} from '@shopify/polaris';
import stringHelper from 'helpers/string';
import { SearchMinor } from '@shopify/polaris-icons';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';

import CarriersAutoComplete from './CarriersAutoComplete';
import { isEmpty } from 'lodash';
import { useApplyToPendingShipmentsMutation } from '../ShipmentSettingsApi';

function ShipmentSettingRuleModal({
  rule = {
    regex: '',
    carrier_uuid: '',
    countries: [],
  },
  onSave,
  onClose,
  carriersList = [],
  countriesList = [],
}) {
  const [t] = useTranslation();
  const [applyToPendingShipments, response = {}] =
    useApplyToPendingShipmentsMutation();
  const { data, isLoading, isSuccess } = response;
  const [selectedCarrier, setSelectedCarrier] = useState(
    rule.carrier_uuid ? [rule.carrier_uuid] : []
  );
  const [regEx, setRegEx] = useState(rule.regex);
  const [selectedCountries, setSelectedCountries] = useState(rule.countries);
  const [formErrors, setFormErrors] = useState({
    regEx: false,
    carrier: false,
  });

  const isValidData = () => {
    let fErr = { ...formErrors };
    fErr.regEx = !stringHelper.isRegexValid(regEx);
    fErr.carrier = isEmpty(selectedCarrier);

    setFormErrors(fErr);
    return !(fErr.regEx || fErr.carrier);
  };
  const handleSubmit = () => {
    if (isValidData()) {
      onSave({
        regex: regEx,
        carrier_uuid: selectedCarrier?.length ? selectedCarrier[0] : '',
        countries: selectedCountries,
      });
    }
  };

  const handleRegexChange = (value) => {
    setFormErrors((err) => ({ ...err, regEx: false }));
    setRegEx(value);
  };

  const handleCarrierChange = (value) => {
    setFormErrors((err) => ({ ...err, carrier: false }));
    setSelectedCarrier(value);
  };
  const handeApplyToPendingShipments = () => {
    if (isValidData()) {
      const payload = {
        new_firstmile_carrier_uuid: selectedCarrier[0],
        filter: {
          search: regEx,
          search_category: 'tracking_number',
          statuses_uuid: ['94e7763d-3ddc-48c6-995a-cd320e9e456d'],
          countries_location_ids:
            selectedCountries.length > 0 ? selectedCountries : undefined,
        },
      };
      applyToPendingShipments(payload);
    }
  };

  const toastMarkup = useMemo(() => {
    if (isSuccess && data) {
      return (
        <Toast content={data.message} onDismiss={() => response?.reset()} />
      );
    }
  }, [isSuccess, data]);

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t('shipments.settings_modal.title')}
      primaryAction={{
        content: t('shipments.settings_modal.save_btn_label'),
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          content: t('shipments.settings_modal.apply_shipments'),
          onAction: handeApplyToPendingShipments,
          loading: isLoading,
        },
      ]}
    >
      <Modal.Section>
        <TextStyle variation="subdued">
          <TranslateLink text={t('shipments.settings_modal.description')} />
          <br />
        </TextStyle>

        <FormLayout>
          <TextField
            value={regEx}
            label={t('shipments.settings_modal.regex_label')}
            onChange={handleRegexChange}
            placeholder={t('shipments.settings_modal.regex_placeholder')}
            id={`input-shipments-settings-modal-regex`}
            error={
              formErrors.regEx
                ? t('shipments.settings_modal.regex_invalid_value')
                : ''
            }
          />
          <CarriersAutoComplete
            listOptions={carriersList}
            selected={selectedCarrier}
            onSelect={handleCarrierChange}
            error={formErrors.carrier}
          />
          <AutoCompleteWithTags
            optionList={countriesList}
            selected={selectedCountries}
            onChange={setSelectedCountries}
            emptyState={t('shipments.settings_modal.location_default_text')}
            error={''}
            errorMessage={t(
              'settings.tracking_page.section_blacklist_location.empty_error_label'
            )}
            label={t('shipments.settings_modal.location_input_label')}
            placeholder={t('shipments.settings_modal.location_default_text')}
            id="input-blacklist-region"
            prefix={<Icon source={SearchMinor} color="inkLighter" />}
          />
        </FormLayout>
      </Modal.Section>
      {toastMarkup}
    </Modal>
  );
}

export default ShipmentSettingRuleModal;

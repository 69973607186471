import { connect } from 'react-redux';
import ResetTemplateModal from './ResetTemplateModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    unsavedModalOpen: state.settings.unsavedModalOpen,
    unsavedModalFns: state.settings.unsavedModalFns,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ResetTemplateModal);

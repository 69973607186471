import React from 'react';
import { Tabs } from '@shopify/polaris';

export function TabsWrapper({ tabs, selected, onSelect, ...props }) {
  const _tabsMarkup = tabs?.map((tab) => ({
    id: tab,
    content: tab,
    accessibilityLeve: tab,
    panelID: tab,
  }));

  return (
    <Tabs tabs={_tabsMarkup} selected={selected} onSelect={onSelect}>
      {props?.children}
    </Tabs>
  );
}

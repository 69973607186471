import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, DatePicker, Popover } from '@shopify/polaris';
import moment from 'moment';
import PropTypes from 'prop-types'
function DatePickerButton({
  value,
  onChange,
  label,
  alignment = 'left',
  range = false,
}) {
  const [{ month, year }, setDate] = useState({ month: 10, year: 2022 });
  const [active, setActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start: moment(),
    end: moment(),
  });

  useEffect(() => {
    let dateRange = {};
    if (!range) {
      dateRange = {
        start: value ? moment(value) : moment(),
        end: value ? moment(value) : moment(),
      };
    } else {
      dateRange = {
        start: value ? moment(value.start) : moment(),
        end: value ? moment(value.end) : moment(),
      };
    }

    setSelectedDate(dateRange);
    setDate({
      month: Number(dateRange.start.format('M')) - 1,
      year: Number(dateRange.start.format('Y')),
    });
  }, [value, range]);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );
  const activator = useMemo(() => {
    const content = range
      ? `${selectedDate.start.format('YYYY-MM-DD')} -
      ${selectedDate.end.format('YYYY-MM-DD')}`
      : selectedDate.start.format('YYYY-MM-DD');
    return <Button onClick={handleChange}>{content}</Button>;
  }, [selectedDate, handleChange]);

  const onDateChange = ({ start, end }) => {
    if (range) {
      onChange({
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      });
    } else {
      onChange(moment(start).format('YYYY-MM-DD'));
    }
  };

  return (
    <Popover
      key={'date-picker-button-key'}
      id={'date-picker-button-key'}
      iFrameName={'date-picker-button-key'}
      title={label}
      activator={activator}
      active={active}
      onClose={handleChange}
      preferredAlignment={alignment}
    >
      <Popover.Section>
        <DatePicker
          month={month}
          year={year}
          onChange={onDateChange}
          selected={{
            start: new Date(selectedDate.start.format('L')),
            end: new Date(selectedDate.end.format('L')),
          }}
          onMonthChange={handleMonthChange}
          allowRange={range}
        />
      </Popover.Section>
    </Popover>
  );
}

DatePickerButton.propTypes = {
  value: PropTypes.string | PropTypes.object
}
export default DatePickerButton;

// TODO: have to remove while cleanup
import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  RangeSlider,
  Card,
  Stack,
  Layout,
  TextStyle,
  List,
  DisplayText,
  Tooltip,
  ExceptionList,
  Icon,
} from '@shopify/polaris';
import { MobileAcceptMajor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { ClientStorage } from 'ClientStorage';
import stringHelper from 'helpers/string';
import {
  FEATURE_SHIPMENTS,
  FEATURE_SUBSCRIPTION_PRICE,
  SUBSCRIPTION_PLAN_GROUP_SPECIAL,
  SUBSCRIPTION_PLAN_HIDDEN_FEATURES,
  SUBSCRIPTION_PLAN_GROUP_ANNUAL,
  LOCAL_STORAGE,
} from 'Constants';
import { useTranslation } from 'react-i18next';
import { LineWithBreak } from 'components/CommonUI/LineWithBreak';

export default function PlanRangeSection({
  caption,
  subscriptionPlanGroups,
  subscriptionPlans,
  planKey,
  planGroup,
  subscriptionPlanLoading,
  postSubscriptionPlan,
  subscriptionPlanMeta,
  listText,
  setPlan,
  groupSelectedPlan,
}) {
  const [t] = useTranslation();
  const [featuresToCompare, setFeaturesToCompare] = useState(null);
  useEffect(() => {
    if (groupSelectedPlan) {
      let tempFeatures = groupSelectedPlan.plan_features.map((feature) => {
        return feature.feature_id;
      });
      setFeaturesToCompare(tempFeatures);
    }
  }, [groupSelectedPlan]);

  const [plansInThisGroup, setPlansInThisGroup] = useState(null);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState(null);
  const [rangeValue, setRangeValue] = useState(0);
  const handleRangeSliderChange = useCallback(
    (value) => {
      setRangeValue(value);
      setSelectedSubscriptionPlan(plansInThisGroup[value - 1]);
    },
    [plansInThisGroup]
  );
  const suffixStyles = {
    minWidth: '24px',
    textAlign: 'right',
  };
  useEffect(() => {
    let tempPlansInThisGroup = subscriptionPlans
      .map((plan) => {
        if (
          subscriptionPlanGroups[planGroup]['subscription_plan_groups'][
            planKey
          ].includes(plan.id)
        ) {
          const tempPlan = { ...plan };

          tempPlan.shipmentQuota = plan.plan_features
            .map((feature) => {
              if (feature.feature_id === FEATURE_SHIPMENTS) {
                return feature.quota;
              }
              return undefined;
            })
            .filter((item) => !_.isUndefined(item))[0];

          tempPlan.price = plan.plan_features
            .map((feature) => {
              if (feature.feature_id === FEATURE_SUBSCRIPTION_PRICE) {
                return feature.price;
              }
              return undefined;
            })
            .filter((item) => !_.isUndefined(item))[0];
          return tempPlan;
        }
        return undefined;
      })
      .filter((item) => !_.isUndefined(item));
    const sortedTempPlansInThisGroup = _.sortBy(
      tempPlansInThisGroup,
      'shipmentQuota'
    );
    setPlansInThisGroup(sortedTempPlansInThisGroup);
    setSelectedSubscriptionPlan(sortedTempPlansInThisGroup[0]);
    if (setPlan) {
      setPlan(
        sortedTempPlansInThisGroup[sortedTempPlansInThisGroup.length - 1]
      );
    }

    if (subscriptionPlanMeta.featured_subscription_plan_id) {
      const rangeIndex = sortedTempPlansInThisGroup.findIndex(
        (obj) => obj.id === subscriptionPlanMeta.featured_subscription_plan_id
      );

      const selectedSubscription = sortedTempPlansInThisGroup.find(
        (obj) => obj.id === subscriptionPlanMeta.featured_subscription_plan_id
      );

      if (rangeIndex !== -1) {
        setRangeValue(rangeIndex + 1);
        setSelectedSubscriptionPlan(selectedSubscription);
      }
    }
  }, [
    subscriptionPlanMeta,
    subscriptionPlans,
    subscriptionPlanGroups,
    planGroup,
    planKey,
    setPlan,
  ]);

  const setSubscriptionPlan = (planId, planName, planPrice) => {
    if (
      window.analytics &&
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true
    ) {
      window.analytics.track('Shopify App Subscription Plan Request', {
        plan: planName,
        plan_id: planId,
        price: planPrice,
      });
    }
    postSubscriptionPlan(planId);
    // setSelectedSubscriptionPlan(planId)
  };
  const tickMarkIcon = () => {
    return <Icon source={MobileAcceptMajor} color="success" width={5} />;
  };
  return (
    <Layout.Section oneThird>
      <Card sectioned>
        {selectedSubscriptionPlan && (
          <Stack distribution="center">
            <DisplayText size="small" variation="strong">
              {selectedSubscriptionPlan.name}
            </DisplayText>
          </Stack>
        )}
        {caption && (
          <Stack distribution="center">
            <TextStyle variation="subdued">{caption}</TextStyle>
          </Stack>
        )}
        <Card.Section>
          <Stack distribution="center">
            <DisplayText size="large" variation="strong">
              {selectedSubscriptionPlan &&
                selectedSubscriptionPlan.currency === 'USD' &&
                '$'}
              {selectedSubscriptionPlan &&
                t(
                  `billing.subscription_plans_modal.billing_intervals.${selectedSubscriptionPlan.billing_interval}`,
                  {
                    price: stringHelper.formatAmount(
                      selectedSubscriptionPlan.price
                    ),
                  }
                )}
            </DisplayText>
          </Stack>
        </Card.Section>
        {planGroup &&
          planGroup === SUBSCRIPTION_PLAN_GROUP_SPECIAL &&
          selectedSubscriptionPlan && (
            <TextStyle>
              {t(
                `billing.subscription_plans_model.shipments_${selectedSubscriptionPlan.billing_interval}`,
                {
                  shipments: stringHelper.formatAmount(
                    selectedSubscriptionPlan.shipmentQuota
                  ),
                }
              )}
            </TextStyle>
          )}
        {plansInThisGroup && plansInThisGroup.length > 1 && (
          <RangeSlider
            label={
              selectedSubscriptionPlan
                ? t(
                    `billing.subscription_plans_model.shipments_${selectedSubscriptionPlan.billing_interval}`,
                    {
                      shipments: stringHelper.formatAmount(
                        selectedSubscriptionPlan.shipmentQuota
                      ),
                    }
                  )
                : null
            }
            min={1}
            max={plansInThisGroup ? plansInThisGroup.length : 2}
            step={1}
            value={rangeValue}
            onChange={handleRangeSliderChange}
            prefix={
              <p>
                {plansInThisGroup &&
                  stringHelper.formatAmount(plansInThisGroup[0].shipmentQuota)}
              </p>
            }
            suffix={
              <p style={suffixStyles}>
                {plansInThisGroup &&
                  stringHelper.formatAmount(
                    plansInThisGroup[plansInThisGroup.length - 1].shipmentQuota
                  )}
              </p>
            }
          />
        )}
        <Card.Section>
          <Stack distribution="center">
            <Button
              fullWidth={true}
              loading={subscriptionPlanLoading}
              disabled={
                subscriptionPlanLoading ||
                (selectedSubscriptionPlan &&
                  subscriptionPlanMeta.current_subscription_plan_id ===
                    selectedSubscriptionPlan.id)
              }
              primary={
                selectedSubscriptionPlan &&
                selectedSubscriptionPlan.id ===
                  subscriptionPlanMeta.featured_subscription_plan_id
              }
              outline={
                selectedSubscriptionPlan &&
                selectedSubscriptionPlan.id !==
                  subscriptionPlanMeta.featured_subscription_plan_id
              }
              onClick={() =>
                setSubscriptionPlan(
                  selectedSubscriptionPlan.id,
                  selectedSubscriptionPlan.name,
                  selectedSubscriptionPlan.price
                )
              }
            >
              {t(subscriptionPlanMeta.subscribe_button_cta)}
            </Button>
          </Stack>
        </Card.Section>
        <LineWithBreak />
        {listText && (
          <>
            <TextStyle variation="strong">{listText}</TextStyle>
            <br />
            <br />
          </>
        )}

        <List>
          {(planKey === 'basic' ||
            planGroup === SUBSCRIPTION_PLAN_GROUP_ANNUAL) && (
            <>
              <ExceptionList
                items={[
                  {
                    icon: tickMarkIcon,
                    description: t(
                      'billing.subscription_plans_modal.billing_monthly_category.basic_upsells_text'
                    ),
                  },
                ]}
              />
              <ExceptionList
                items={[
                  {
                    icon: tickMarkIcon,
                    description: t(
                      'billing.subscription_plans_modal.billing_monthly_category.basic_carriers_text'
                    ),
                  },
                ]}
              />
            </>
          )}

          {selectedSubscriptionPlan &&
            selectedSubscriptionPlan.plan_features.map((feature) => {
              if (
                SUBSCRIPTION_PLAN_HIDDEN_FEATURES.includes(
                  feature.feature_id
                ) ||
                (featuresToCompare &&
                  featuresToCompare.includes(feature.feature_id))
              ) {
                return <React.Fragment key={feature.feature_id} />;
              }
              const featureTitle = subscriptionPlanMeta.features
                .map((featureFromMeta) => {
                  const { name = '' } = featureFromMeta;
                  if (
                    feature.feature_id === featureFromMeta.id &&
                    name !== ''
                  ) {
                    return featureFromMeta;
                  }
                  return undefined;
                })
                .filter((item) => !_.isUndefined(item));
              if (featureTitle[0] !== undefined) {
                return featureTitle[0].description ? (
                  <Tooltip
                    content={featureTitle[0].description}
                    key={feature.feature_id}
                  >
                    <div className="plan-tooltip-list">
                      <ExceptionList
                        key={featureTitle[0].id}
                        items={[
                          {
                            icon: tickMarkIcon,
                            description: featureTitle[0].name,
                          },
                        ]}
                      />
                    </div>
                    {/* <List.Item key={featureTitle[0].id}>{featureTitle[0].name}</List.Item> */}
                  </Tooltip>
                ) : (
                  <ExceptionList
                    key={featureTitle[0].id}
                    items={[
                      {
                        icon: tickMarkIcon,
                        description: featureTitle[0].name,
                      },
                    ]}
                  />
                );
              }
              return false;
            })}
        </List>
      </Card>
    </Layout.Section>
  );
}

import React from 'react';
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
} from '@shopify/polaris';

function SkeletonBlackListPage() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.AnnotatedSection
          title={<SkeletonBodyText />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <br />
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonBlackListPage;

import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import PlanRangeSection from './PlanRangeSection';

function mapStateToProps(state) {
  return {
    subscriptionPlanLoading: state.billing.subscriptionPlanLoading,
    planLoading: state.billing.planLoading,
    loading: state.billing.loading,
    billing: state.billing,
    subscriptionPlans: state.billing.subscriptionPlans,
    subscriptionPlanMeta: state.billing.subscriptionPlanMeta,
    subscriptionPlanGroups: state.billing.subscriptionPlanGroups,
    currentPlan: state.billing.currentPlan,
    chargeDetail: state.billing.chargeDetail,
  };
}

const mapDispatchToProps = {
  getSubscriptionPlans: billingActions.Creators.getSubscriptionPlans,
  postSubscriptionPlan: billingActions.Creators.postSubscriptionPlan,
  resetSubscriptionPlan: billingActions.Creators.resetSubscriptionPlan,
  getCurrentBillingPlan: billingActions.Creators.getCurrentBillingPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanRangeSection);

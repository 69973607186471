import React, { useCallback, useEffect, useState } from 'react';
import {
  TopBar,
  TextField,
  Icon,
  Stack,
  TextStyle,
  Heading,
  Loading,
} from '@shopify/polaris';
import {
  MobileBackArrowMajor,
  LogOutMinor,
  SearchMinor,
  ProfileMajor,
  StoreMajor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import {
  LanguageSelector,
  SearchCategorySelector,
  StoresList,
} from '@rush-app-admin/storybooks';
import { getSearchCategories } from 'pages/Shipments/components/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { STORE_URLS, USER_URLS } from 'config/urls';
import ShopifyIcon from 'images/shopify-icon.svg';
import RushLogo from 'images/logo-complete.svg';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from '../../Constants';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalSearch, shipmentsActions } from 'pages/Shipments/shipmentSlice';
import {
  useGetUserDetailsQuery,
  useGetOrganizationsQuery,
  useGetUserLanguagesQuery,
  useUpdateUserLocaleMutation,
} from 'redux/store/commonBaseApis';

export default function TopBarMarkup({
  accessToken,
  shop,
  logout,
  setStoreUUID,
  toggleMobileNavigationActive,
}) {
  const [t] = useTranslation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const search = useSelector(GlobalSearch);

  const { isLoading: isUserLoading, data: user = {} } =
    useGetUserDetailsQuery();
  const [updateUserLocale, { isLoading: isUpdatingLanguage }] =
    useUpdateUserLocaleMutation();
  const { data: languages = [], isLoading: isLoadingLanguages } =
    useGetUserLanguagesQuery();
  const { data: organizations } = useGetOrganizationsQuery();

  useEffect(() => {
    setInputValue(search.text);
  }, [search.text]);

  const setSearch = (obj) => {
    dispatch(shipmentsActions.setGlobalSearch(obj));
  };

  useEffect(() => {
    if (!organizations) {
      return;
    }

    let store;
    organizations &&
      organizations.find((org) => {
        store = org.stores.find((store) => store.uuid === shop.storeUUID);
        return !!store;
      });

    if (shop.storeUUID && !store) {
      setStoreUUID(null);
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, null);
      history.push('/s');
    }
  }, [organizations]);

  // Context Control
  const handleStoreChange = (uuid) => {
    setStoreUUID(uuid);
    ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, uuid);
    history.push(`/s/${uuid}`);
  };

  // Search Bar
  const SEARCH_CATEGORIES = getSearchCategories(t);
  const handleCategoryChange = useCallback((index) => {
    setSearch({ categoryIndex: index });
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue === '') {
      return;
    }

    setSearch({ text: inputValue });
    const path = location.pathname.split('/');
    const isUserOnShipmentsPage =
      path.length === 4 && '/' + path[3] === STORE_URLS.SHIPMENTS;

    if (!isUserOnShipmentsPage) {
      history.push(`/s/${shop.storeUUID}${STORE_URLS.SHIPMENTS}?page=1`);
    }
  };

  let storeName = '';
  if (accessToken) {
    storeName = shop.shopTitle;
  }

  const searchFieldMarkup =
    location.pathname.split('/').length > 3 ? (
      <form onSubmit={handleSearch}>
        <div style={{ maxWidth: '40rem' }}>
          <TextField
            label={''}
            autoComplete={'off'}
            prefix={<Icon source={SearchMinor} />}
            placeholder={t('shipments.search.input.placeholder')}
            value={inputValue}
            onChange={setInputValue}
            onClearButtonClick={() => setInputValue('')}
            clearButton
            connectedLeft={
              <SearchCategorySelector
                categories={SEARCH_CATEGORIES}
                onChangeCategory={handleCategoryChange}
                selectedIndex={search.categoryIndex}
              />
            }
          />
        </div>
      </form>
    ) : undefined;

  const handleTopLinkClick = (url) => {
    if (window.event?.ctrlKey || window.event?.metaKey) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  const getUserMenuMarkup = () => {
    let store;
    organizations &&
      organizations.find((org) => {
        store = org.stores.find((store) => store.uuid === shop.storeUUID);
        return !!store;
      });

    const primaryActions = [
      {
        content: t('user_menu.item.manage_account'),
        icon: ProfileMajor,
        onAction: () => {
          handleTopLinkClick(USER_URLS.DETAILS);
        },
      },
      {
        content: t('user_menu.item.stores'),
        icon: StoreMajor,
        onAction: () => {
          handleTopLinkClick('/s');
        },
      },
      {
        content: t('user_menu.item.logout'),
        icon: LogOutMinor,
        onAction: () => {
          ClientStorage.remove(LOCAL_STORAGE.ACCESS_TOKEN);
          ClientStorage.remove(LOCAL_STORAGE.LAST_STORE_UUID);
          window.FS && window.FS.anonymize();
          logout(i18next.language);
        },
      },
    ];

    store?.myshopify_domain &&
      primaryActions.push({
        content: t('user_menu.item.back_to_shopify'),
        icon: MobileBackArrowMajor,
        onAction: () => {
          const url = `https://${store.myshopify_domain}/admin?utm_source=app.rush.app&utm_medium=app.rush.app`;
          if (window.event?.ctrlKey || window.event?.metaKey) {
            window.open(url, '_blank');
          } else {
            window.open(url, '_self');
          }
        },
      });

    return (
      <TopBar.UserMenu
        name={user.first_name}
        detail={storeName}
        initials={(user.first_name || '')[0]}
        open={isUserMenuOpen}
        onToggle={() => {
          if (!(window.event?.ctrlKey || window.event?.metaKey))
            setIsUserMenuOpen(!isUserMenuOpen);
        }}
        actions={[
          {
            items: primaryActions,
          },
          {
            items: [
              {
                content: t('user_menu.item.rush_help_center'),
                onAction: () =>
                  window.open(
                    'https://support.rush.app/?utm_source=app.rush.app&utm_medium=app.rush.app',
                    '_blank'
                  ),
              },
              {
                content: t('user_menu.item.community_group'),
                onAction: () =>
                  window.open(
                    'https://www.facebook.com/groups/tryrush?utm_source=app.rush.app&utm_medium=app.rush.app',
                    '_blank'
                  ),
              },
              {
                content: t('user_menu.item.hire_expert'),
                onAction: () =>
                  window.open(
                    'https://www.heycarson.com/ambassadors?ref=rush&utm_source=app.rush.app&utm_medium=app.rush.app',
                    '_blank'
                  ),
              },
            ],
          },
        ]}
      />
    );
  };

  const getContextControlMarkup = () => {
    const storesMarkup = [];
    organizations &&
      organizations.forEach((org) => {
        org.stores.forEach((store) => {
          storesMarkup.push({
            id: store.id?.toString(),
            uuid: store.uuid,
            name: store.store_name,
            domain: store.website,
            org: org.name,
          });
        });
      });
    storesMarkup.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          marginLeft: '1rem',
        }}
      >
        {['/s', '/s/', '/u/details', '/u/add-store'].includes(
          location.pathname
        ) ? (
          <img src={RushLogo} alt={'logo'} width={'70'} />
        ) : isUserLoading ? (
          <Stack spacing={'tight'} alignment={'center'}>
            <img src={ShopifyIcon} alt={'logo'} width={'30'} />
            <TextStyle>
              <Heading>{t('app.loading')}</Heading>
            </TextStyle>
          </Stack>
        ) : (
          <StoresList
            stores={storesMarkup}
            selectedUuid={shop.storeUUID}
            onSelectStore={handleStoreChange}
            placeholder={t('shipments.settings_modal.location_default_text')}
            icon={ShopifyIcon}
          />
        )}
      </div>
    );
  };

  const getSecondaryMenuMarkup = () => {
    const lang = languages
      ? languages.find(
          ({ language_iso_639 }) => language_iso_639 === i18next.language
        )
      : null;

    return lang ? (
      <LanguageSelector
        languages={
          languages?.map((lang) => ({
            uuid: lang.uuid,
            label: lang.label_local,
            flagUrl: lang.svg_flag_url,
          })) || []
        }
        selectedUuid={lang?.uuid}
        onChange={(uuid) => {
          const newLang = languages.find((lang) => lang.uuid === uuid);
          ClientStorage.set(LOCAL_STORAGE.LANGUAGE, newLang.language_iso_639);
          i18next.changeLanguage(newLang.language_iso_639);
          updateUserLocale({ language: newLang.language_iso_639 });
        }}
      />
    ) : null;
  };

  return (
    <>
      <TopBar
        showNavigationToggle
        contextControl={getContextControlMarkup()}
        searchField={searchFieldMarkup}
        userMenu={getUserMenuMarkup()}
        secondaryMenu={getSecondaryMenuMarkup()}
        onNavigationToggle={toggleMobileNavigationActive}
      />
      {isLoadingLanguages || isUpdatingLanguage ? <Loading /> : ''}
    </>
  );
}

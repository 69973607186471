// this file will be deleted once we will have APIs ready

export const getTrackingPages = () => {
  return [
    {
      name: 'Active tracking page',
      id: 'Tracking page 1',
      createdAt: '2 months ago',
      updatedAt: '1m ago',
      kind: 'Modern theme',
      isPublished: true,
    },
    // {
    //   name: 'Tracking page 2',
    //   id: 'Tracking page 2',
    //   createdAt: '2 months ago',
    //   updatedAt: '1m ago',
    //   kind: 'Traditional theme',
    // },
    // {
    //   name: 'Tracking page 3',
    //   id: 'Tracking page 3',
    //   createdAt: '2 months ago',
    //   updatedAt: '1m ago',
    //   kind: 'Modern theme',
    // },
  ];
};

export const getOtherTrackingPages = () => {
  return getTrackingPages().filter((trackingPage) => !trackingPage.isPublished);
};

export const getPublishedTrackingPage = () => {
  return getTrackingPages().find((trackingPage) => trackingPage.isPublished);
};

import { combineReducers } from 'redux';
import { settingsReducer } from '../settings';
import { trackingReducer } from '../tracking';
import { billingReducer } from '../billing';
import { reportsReducer } from '../reports';
import { visitorReducer } from '../visitor';
import { storeApi } from './storeApi';
import { baseApi } from './baseApi';
import shipmentsReducer from 'pages/Shipments/shipmentSlice';
import billingReducer1 from 'pages/Billing1/billingSlice';
import base from './baseSlice';
import shop from './shopSlice';

export default combineReducers({
  settings: settingsReducer,
  shipments: shipmentsReducer,
  base: base,
  shop: shop,
  trackingDetail: trackingReducer,
  billing: billingReducer,
  billing1: billingReducer1,
  reports: reportsReducer,
  visitor: visitorReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [storeApi.reducerPath]: storeApi.reducer,
});

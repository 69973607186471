const { createActions } = require('reduxsauce');

module.exports = createActions({
  getPerformanceReport: ['params'],
  getPerformanceReportSuccess: ['data'],
  getPerformanceReportFailure: null,

  getReport: ['language', 'params'],
  getReportSuccess: ['data', 'reportType'],
  getReportFailure: null,
});

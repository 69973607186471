import React, { useState, useCallback } from 'react';
import { Modal, TextField, Checkbox, TextStyle } from '@shopify/polaris';
import './style.scss';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import stringHelper from 'helpers/string';
export default function FindReplaceModal({ item, onChange, onClose }) {
  const [t] = useTranslation();
  const { find = '', replace = '', is_regexp = false } = item;
  const [regexChecked, setRegexChecked] = useState(is_regexp);
  const handleRegexCheckedChange = useCallback(
    (newChecked) => setRegexChecked(newChecked),
    []
  );

  const [findTextError, setFindTextError] = useState(null);
  const [findText, setFindText] = useState(find);
  const handleFindTextChange = useCallback((value) => setFindText(value), []);

  const [replaceTextError, setReplaceTextError] = useState(null);
  const [replaceText, setReplaceText] = useState(replace);
  const handleReplaceTextChange = useCallback(
    (value) => setReplaceText(value),
    []
  );

  const submit = () => {
    if (_.isNull(_.trim(findText)) || _.isEmpty(_.trim(findText))) {
      setFindTextError(
        t('settings.tracking_page.find_and_replace_modal.invalid_text')
      );
      return;
    } else if (
      _.isNull(_.trim(replaceText)) ||
      _.isEmpty(_.trim(replaceText))
    ) {
      setReplaceTextError(
        t('settings.tracking_page.find_and_replace_modal.invalid_text')
      );
      return;
    }

    if (regexChecked && !stringHelper.isRegexValid(findText)) {
      setFindTextError(
        t('settings.tracking_page.find_and_replace_modal.invalid_regex', {
          regex_value: findText,
        })
      );
      return;
    }

    onChange({
      find: findText,
      replace: replaceText,
      is_regexp: regexChecked,
    });
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t('settings.tracking_page.find_and_replace_modal.title')}
      primaryAction={{
        content: t(
          'settings.tracking_page.find_and_replace_modal.save_btn_label'
        ),
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: t(
            'settings.tracking_page.find_and_replace_modal.cancel_btn_label'
          ),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <div className="FindReplace-Row">
          <div className="FindReplace-Checkbox">
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <TextStyle>
                  {t(
                    'settings.tracking_page.find_and_replace_modal.refexp_label'
                  )}
                </TextStyle>
              </div>
            </div>
            <Checkbox
              label=""
              checked={regexChecked}
              onChange={handleRegexCheckedChange}
            />
          </div>
          <div className="FindReplace-TextField">
            <TextField
              value={findText}
              label={t(
                'settings.tracking_page.find_and_replace_modal.find_label'
              )}
              onChange={handleFindTextChange}
              error={findTextError}
              id={`input-find-replace-modal-find`}
            />
          </div>
          <div className="FindReplace-TextField">
            <TextField
              label={t(
                'settings.tracking_page.find_and_replace_modal.replace_label'
              )}
              onChange={handleReplaceTextChange}
              value={replaceText}
              error={replaceTextError}
              id={`input-find-replace-modal-replace`}
            />
          </div>
        </div>
        <div className="FindReplace-Row">
          <TextStyle variation="subdued">
            <TranslateLink
              text={t(
                'setting.tracking_page.find_and_replace_modal.regex_help_text'
              )}
            />
          </TextStyle>
        </div>
      </Modal.Section>
    </Modal>
  );
}

import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import OmnisendPage from './OmnisendPage';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    loading: state.settings.loading,
    omnisendConfig: state.settings.omnisendConfig,
    omnisendMeta: state.settings.omnisendMeta,
    omnisendError: state.settings.omnisendError,
    // omnisendTestingConfirmation: state.settings.omnisendTestingConfirmation,
    // omnisendTestingConfirmationMessage:
    // state.settings.omnisendTestingConfirmationMessage,
    omnisendConfigSaved: state.settings.omnisendConfigSaved,
    featureRequest: state.settings.featureRequest,
    loadingFeatureId: state.settings.loadingFeatureId,
  };
}

const mapDispatchToProps = {
  getOmnisend: settingsActions.Creators.getOmnisend,
  saveOmnisend: settingsActions.Creators.saveOmnisend,
  // testOmnisend: settingsActions.Creators.testOmnisend,
  resetOmnisend: settingsActions.Creators.resetOmnisend,
  resetFeatureRequest: settingsActions.Creators.resetFeatureRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(OmnisendPage);

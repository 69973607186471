import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ChoiceList,
  Card,
  Checkbox,
  TextStyle,
  Stack,
  DropZone,
  Spinner,
  Button,
  Icon,
} from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { useUploadLogoMutation } from '../../maskingApi';
import '../styles.scss';

function OverrideSetupCard({
  currCarrierRule,
  handleChoiceChange,
  formValidations,
}) {
  const [t] = useTranslation();
  const [uploadLogo, uploadLogoResponse] = useUploadLogoMutation();
  const {
    data: uploadData,
    isFetching: isLogoUploading,
    isSuccess: isLogoUploaded,
  } = uploadLogoResponse;
  const renderOverrideName = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) => handleChoiceChange('override_name_value', value)}
          value={currCarrierRule.override_name_value}
          autoComplete="off"
          error={
            !formValidations.override_name
              ? t('carrier-maskings.override-name-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  useEffect(() => {
    if (isLogoUploaded && uploadData) {
      handleChoiceChange('override_logo_value', uploadData?.data?.url);
    }
  }, [isLogoUploaded, uploadData]);

  const handleLogoRemove = (event) => {
    event.stopPropagation();
    handleChoiceChange('override_logo_value', null);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => {
      uploadLogo(acceptedFiles[0]);
    },
    [uploadLogo]
  );

  const fileUpload = !currCarrierRule.override_logo_value && (
    <DropZone.FileUpload />
  );
  const uploadedFile = currCarrierRule.override_logo_value && (
    <div className="override-logo-container">
      <img
        width={'100px'}
        height={'100px'}
        src={currCarrierRule.override_logo_value}
      />
    </div>
  );

  const renderOverrideLogo = useCallback(
    (isSelected) =>
      isSelected && (
        <Stack vertical>
          <Stack vertical spacing="tight">
            <Stack>
              <DropZone
                accept="image/*"
                type="image"
                allowMultiple={false}
                onDrop={handleDropZoneDrop}
              >
                {isLogoUploading ? (
                  <Spinner />
                ) : (
                  <>
                    {uploadedFile}
                    {fileUpload}
                  </>
                )}
              </DropZone>
            </Stack>
            {currCarrierRule.override_logo_value && (
              <Stack alignment="center">
                <div className="remove-logo-buttom">
                  <Button onClick={handleLogoRemove} plain destructive>
                    Remove
                  </Button>
                </div>
              </Stack>
            )}
          </Stack>

          {!formValidations.override_logo && (
            <Stack spacing="tight">
              <Icon size="small" source={AlertMinor} color="critical" />
              <TextStyle variation="negative">
                {t('carrier-maskings.override-logo-required')}
              </TextStyle>
            </Stack>
          )}
        </Stack>
      ),
    [currCarrierRule, handleChoiceChange]
  );

  const renderOverrideWeb = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_website_value', value)
          }
          value={currCarrierRule.override_website_value}
          autoComplete="off"
          placeholder="https://"
          helpText={
            <p>
              {t('carrier-maskings.use-shortcodes')}
              {':'}
              <br />
              <TextStyle variation="code">
                &#123;&#123;tracking_number&#125;&#125;{' '}
              </TextStyle>{' '}
              <TextStyle variation="code">
                {' '}
                &#123;&#123;carrier_uuid&#125;&#125;
              </TextStyle>{' '}
              <TextStyle variation="code">
                {' '}
                &#123;&#123;carrier_name&#125;&#125;
              </TextStyle>{' '}
            </p>
          }
          error={
            !formValidations.override_website
              ? t('carrier-maskings.override-website-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  const renderOverrideLink = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_tracking_link_value', value)
          }
          value={currCarrierRule.override_tracking_link_value}
          autoComplete="off"
          placeholder="https://"
          helpText={
            <p>
              {t('carrier-maskings.use-shortcodes')}
              {':'}
              <br />
              <TextStyle variation="code">
                {' '}
                &#123;&#123;tracking_number&#125;&#125;
              </TextStyle>{' '}
              <TextStyle variation="code">
                {' '}
                &#123;&#123;carrier_uuid&#125;&#125;
              </TextStyle>{' '}
              <TextStyle variation="code">
                {' '}
                &#123;&#123;carrier_name&#125;&#125;
              </TextStyle>{' '}
            </p>
          }
          error={
            !formValidations.override_tracking_link
              ? t('carrier-maskings.override-tracking-link-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange]
  );
  const renderOverridePhone = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelHidden
          onChange={(value) =>
            handleChoiceChange('override_phone_value', value)
          }
          value={currCarrierRule.override_phone_value}
          autoComplete="off"
          placeholder="+"
          error={
            !formValidations.override_phone
              ? t('carrier-maskings.override-phone-required')
              : ''
          }
        />
      ),
    [currCarrierRule, handleChoiceChange, formValidations]
  );

  return (
    <Card title={t('carrier-maskings.override-setup')}>
      <Card.Section>
        <Stack vertical>
          <Checkbox
            label={t('carrier-maskings.completely-hide-carrier')}
            onChange={(value) =>
              handleChoiceChange('completely_hide_carrier', value)
            }
            checked={currCarrierRule.completely_hide_carrier}
          ></Checkbox>
          <TextStyle variation="subdued">
            {t('carrier-maskings.completely-hide-info')}
          </TextStyle>
        </Stack>
      </Card.Section>
      {!currCarrierRule.completely_hide_carrier && (
        <>
          <Card.Section
            title={t('carrier-maskings.override-or-hide-properties')}
          >
            <ChoiceList
              title={t('carrier-maskings.name')}
              choices={[
                { label: t('carrier-maskings.keep-it'), value: 0 },
                { label: t('carrier-maskings.hide-it'), value: 1 },
                {
                  label: t('carrier-maskings.override-it-with'),
                  value: 2,
                  renderChildren: renderOverrideName,
                },
              ]}
              selected={[currCarrierRule.override_name_option]}
              onChange={([value]) =>
                handleChoiceChange('override_name_option', value)
              }
            />
          </Card.Section>
          <Card.Section>
            <ChoiceList
              title={t('carrier-maskings.logo')}
              choices={[
                { label: t('carrier-maskings.keep-it'), value: 0 },
                { label: t('carrier-maskings.hide-it'), value: 1 },
                {
                  label: t('carrier-maskings.override-it-with'),
                  value: 2,
                  renderChildren: renderOverrideLogo,
                },
              ]}
              selected={[currCarrierRule.override_logo_option]}
              onChange={([value]) =>
                handleChoiceChange('override_logo_option', value)
              }
            />
          </Card.Section>
          <Card.Section>
            <ChoiceList
              title={t('carrier-maskings.website')}
              choices={[
                { label: t('carrier-maskings.keep-it'), value: 0 },
                { label: t('carrier-maskings.hide-it'), value: 1 },
                {
                  label: t('carrier-maskings.override-it-with'),
                  value: 2,
                  renderChildren: renderOverrideWeb,
                },
              ]}
              selected={[currCarrierRule.override_website_option]}
              onChange={([value]) =>
                handleChoiceChange('override_website_option', value)
              }
            />
          </Card.Section>
          <Card.Section>
            <ChoiceList
              title={t('carrier-maskings.tracking-link')}
              choices={[
                { label: t('carrier-maskings.keep-it'), value: 0 },
                { label: t('carrier-maskings.hide-it'), value: 1 },
                {
                  label: t('carrier-maskings.override-it-with'),
                  value: 2,
                  renderChildren: renderOverrideLink,
                },
              ]}
              selected={[currCarrierRule.override_tracking_link_option]}
              onChange={([value]) =>
                handleChoiceChange('override_tracking_link_option', value)
              }
            />
          </Card.Section>
          <Card.Section>
            <ChoiceList
              title={t('carrier-maskings.phone')}
              choices={[
                { label: t('carrier-maskings.keep-it'), value: 0 },
                { label: t('carrier-maskings.hide-it'), value: 1 },
                {
                  label: t('carrier-maskings.override-it-with'),
                  value: 2,
                  renderChildren: renderOverridePhone,
                },
              ]}
              selected={[currCarrierRule.override_phone_option]}
              onChange={([value]) =>
                handleChoiceChange('override_phone_option', value)
              }
            />
          </Card.Section>
        </>
      )}
    </Card>
  );
}
OverrideSetupCard.propTypes = {
  currCarrierRule: PropTypes.object,
  handleChoiceChange: PropTypes.func,
};
export default OverrideSetupCard;

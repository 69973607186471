import { storeApi } from './storeApi';

const commonStoreAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersTags: builder.query({
      query: (queryString) => {
        return {
          url: `/orders/tags?search=${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProducts: builder.query({
      query: (queryString) => {
        return {
          url: `/products?search=${queryString}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getProductsByIds: builder.query({
      query: (ids) => {
        return {
          url: `/products?external_ids=${ids}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getStatuses: builder.query({
      query: () => {
        return {
          url: `/shipments/statuses`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getCurrentBillingPlan: builder.query({
      query: () => {
        return {
          url: `/billing/plans/current`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getCountries: builder.query({
      query: () => {
        return {
          url: '/geo/countries',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getSmallTopMessages: builder.query({
      query: () => {
        return {
          url: '/small-top-messages',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getFeatures: builder.query({
      query: () => {
        return {
          url: `/features/features`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetStatusesQuery,
  useGetCurrentBillingPlanQuery,
  useGetOrdersTagsQuery,
  useLazyGetOrdersTagsQuery,
  useLazyGetProductsQuery,
  useGetCountriesQuery,
  useLazyGetProductsByIdsQuery,
  useGetSmallTopMessagesQuery,
  useGetFeaturesQuery,
} = commonStoreAPIs;

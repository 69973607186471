import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Modal,
  OptionList,
  Popover,
  Spinner,
  Stack,
  TextField,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useCloneTemplateMutation } from '../os2TrackingApi';
import './styles.scss';

function CloneModal({ onClose, selectedThemeId, themes, showToast }) {
  const [t] = useTranslation();
  const [openCloneDD, setOpenCloneDD] = useState(false);
  const [cloneToThemeId, setCloneToThemeId] = useState('');
  const [cloneTemplate, cloneresponse] = useCloneTemplateMutation();
  const { isSuccess: cloneSuccess, isLoading: clonning } = cloneresponse;

  const toggleOpenCloneDD = useCallback(
    () => setOpenCloneDD((openCloneDD) => !openCloneDD),
    []
  );

  useEffect(() => {
    if (cloneSuccess) {
      showToast({
        message: t('settings.os2_tracking_page.wizard.cloned_toast'),
      });
      onClose();
    }
  }, [cloneSuccess]);

  const handleClone = () => {
    cloneTemplate({
      fromID: selectedThemeId,
      toID: cloneToThemeId,
    });
  };

  const getLabel = (theme) => {
    return theme.is_published ? `${theme.name} (Published)` : theme.name;
  };

  const renderDropdown = () => {
    let theme = themes.find(({ id }) => id === selectedThemeId);
    return (
      <TextField
        label={t('settings.os2_tracking_page.wizard.selected_theme')}
        disabled
        value={getLabel(theme)}
      />
    );
  };

  const cloneDDactivator = (
    <Button onClick={toggleOpenCloneDD} disclosure fullWidth textAlign="left">
      {cloneToThemeId
        ? getLabel(themes.find((t) => t.id === cloneToThemeId))
        : t('settings.os2_tracking_page.wizard.select_theme')}
    </Button>
  );

  const renderCloneDropdown = () => {
    let themeOptions = selectedThemeId
      ? themes.filter(({ id }) => id !== selectedThemeId)
      : themes;
    const options = themeOptions.map((theme) => ({
      label: getLabel(theme),
      value: theme.id,
    }));
    return (
      <Stack vertical spacing="extraTight">
        <p>{t('settings.os2_tracking_page.wizard.destination_theme')}</p>
        <Popover
          fullWidth
          activator={cloneDDactivator}
          active={openCloneDD}
          onClose={toggleOpenCloneDD}
        >
          <OptionList
            allowMultiple={false}
            title={t('settings.os2_tracking_page.wizard.select_theme')}
            onChange={([selected]) => {
              toggleOpenCloneDD();
              setCloneToThemeId(selected);
            }}
            options={options}
            selected={[cloneToThemeId]}
          />
        </Popover>
      </Stack>
    );
  };

  return (
    <Modal
      title={
        clonning
          ? t('settings.os2_tracking_page.wizard.step3.clone_inprogress')
          : t('settings.os2_tracking_page.wizard.step3.clone_title')
      }
      open={true}
      onClose={onClose}
    >
      <div className="clonecard-container">
        <Card
          secondaryFooterActions={
            clonning ? [] : [{ content: 'Close', onAction: onClose }]
          }
          primaryFooterAction={
            clonning
              ? null
              : {
                  disabled: !cloneToThemeId,
                  content: 'Clone',
                  onAction: handleClone,
                }
          }
        >
          <Card.Section>
            {clonning ? (
              <Stack distribution="center">
                <Spinner size="large" />
              </Stack>
            ) : (
              <Stack distribution="fillEvenly">
                <Stack.Item>{renderDropdown()}</Stack.Item>
                <Stack.Item>{renderCloneDropdown()}</Stack.Item>
              </Stack>
            )}
          </Card.Section>
          <Card.Section></Card.Section>
        </Card>
      </div>
    </Modal>
  );
}
export default CloneModal;

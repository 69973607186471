import React from 'react';
import { Route } from 'react-router-dom';
import Custom from 'components/CustomRedirect';

function StoreRoute({ storeUUID, ...rest }) {
  if (storeUUID && rest?.location?.pathname?.includes('_current')) {
    return (
      <Custom to={rest?.location?.pathname?.replace('_current', storeUUID)} />
    );
  }
  return storeUUID ? <Route {...rest} /> : <Custom to="/s" />;
}

export default StoreRoute;

const initialState = require('./visitor.initialState');
const visitorActions = require('./visitor.actions');
const visitorReducer = require('./visitor.reducer');
const visitorWebServices = require('./visitor.webServices');
const { watchVisitorRequests } = require('./visitor.sagas.js');

module.exports = {
  initialState,
  visitorActions,
  visitorReducer,
  visitorWebServices,
  watchVisitorRequests,
};

import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  Heading,
  List,
  ChoiceList,
  TextContainer,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
import SyncImage from 'images/mobile-outer.png';
import TranslateLink from 'components/TranslateLink';
export default function UnsavedChangesModal({
  templateModalOpen,
  handleTemplateModalOpenChange,
  trackingDetailMeta,
  dispatchForTrackingPage,
}) {
  const [t] = useTranslation();
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const handleThemeChange = useCallback(
    (themeParam) => {
      const tempSelectedTheme = trackingDetailMeta.themes.filter(
        (theme) => theme.slug === themeParam
      )[0];
      setSelectedTheme(tempSelectedTheme);
      setSelectedStyle(tempSelectedTheme.styles[0]);
    },
    [trackingDetailMeta]
  );

  const handleStyleChange = useCallback(
    (value) => {
      const temp = selectedTheme.styles.filter(
        (theme) => theme.slug === value[0]
      )[0];
      setSelectedStyle(temp);
    },
    [selectedTheme]
  );

  useEffect(() => {
    setSelectedTheme(null);
    setSelectedStyle(null);
  }, [templateModalOpen]);

  const unSelectTheme = useCallback(() => {
    setSelectedTheme(null);
    setSelectedStyle(null);
  }, []);

  const handleThemeStyleChange = useCallback(() => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        theme: selectedTheme.slug,
        page_layout: selectedStyle.slug,
        page_source: null,
        customizeTheme: true,
      },
      validation: { themeSelection: true },
    });
    handleTemplateModalOpenChange();
  }, [
    dispatchForTrackingPage,
    selectedTheme,
    selectedStyle,
    handleTemplateModalOpenChange,
  ]);

  return (
    <Modal
      open={templateModalOpen}
      onClose={handleTemplateModalOpenChange}
      title={
        selectedTheme
          ? t('settings.tracking_page.card_theme.style_title', {
              theme_style: selectedTheme.name,
            })
          : t('settings.tracking_page.card_theme.title')
      }
      primaryAction={
        selectedTheme
          ? {
              content: t('settings.tracking_page.card_theme.btn_select'),
              onAction: () => handleThemeStyleChange(),
            }
          : null
      }
      secondaryActions={
        selectedTheme
          ? [
              {
                content: t('settings.tracking_page.card_theme.btn_back'),
                onAction: () => unSelectTheme(),
              },
            ]
          : [
              {
                content: t('unsaved_changes_modal.cancel_btn'),
                onAction: () => handleTemplateModalOpenChange(),
              },
            ]
      }
      large={true}
    >
      <Modal.Section>
        <>
          {!selectedTheme && trackingDetailMeta && (
            <ul className="Themes-List">
              {trackingDetailMeta.themes.map((theme) => (
                <li key={theme.slug}>
                  <button
                    className="Themes-List-Button"
                    type="button"
                    onClick={() => handleThemeChange(theme.slug)}
                  >
                    <img
                      className="Themes-List-Image"
                      src={theme.image_desktop_link}
                      alt=""
                    />
                    <div className="Themes-List-Title-Wrapper">
                      <span className="Themes-List-Title">{theme.name}</span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          )}
          {selectedTheme && (
            <div className="Themes-Detail">
              <div className="Themes-Detail-Image-Wrapper">
                <img
                  className="Themes-Detail-Desktop-Image"
                  src={selectedStyle.image_desktop_link}
                  alt=""
                />
                <div className="Themes-Detail-Mobile-Image-Wrapper">
                  <img
                    src={SyncImage}
                    alt=""
                    className="Themes-Detail-Mobile-Skeleton-Image"
                  />
                  <img
                    className="Themes-Detail-Mobile-Image"
                    src={selectedStyle.image_mobile_link}
                    alt=""
                  />
                </div>
              </div>
              <div className="Themes-Detail-SideBar">
                <TextContainer>
                  <TranslateLink text={selectedTheme.description} />

                  <Heading>
                    {t('settings.tracking_page.card_theme.features_title')}
                  </Heading>
                  <List type="bullet">
                    {selectedTheme.features.map((feature, index) => (
                      <List.Item key={index}>{feature}</List.Item>
                    ))}
                  </List>

                  <Heading>
                    {t('settings.tracking_page.card_theme.styles_title', {
                      styles_count: selectedTheme.styles.length,
                    })}
                  </Heading>
                  <ChoiceList
                    title=""
                    choices={selectedTheme.styles.map((theme) => ({
                      label: theme.name,
                      value: theme.slug,
                    }))}
                    selected={selectedStyle.slug}
                    onChange={handleStyleChange}
                  />
                </TextContainer>
              </div>
            </div>
          )}
        </>
      </Modal.Section>
    </Modal>
  );
}

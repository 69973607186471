import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  Banner,
  Stack,
  TextStyle,
  Form,
  FormLayout,
  TextField,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetChargeLimitQuery,
  useUpdateChargeLimitMutation,
} from '../billingApis';
import './styles.scss';
import { billingActions } from '../billingSlice';

export default function IncreaseLimitModal() {
  const [t] = useTranslation();
  const [newLimit, setNewLimitText] = useState(null);
  const dispatch = useDispatch();
  const { showChangeLimitModal } = useSelector((state) => state.billing1);

  const { data: chargeLimit = {} } = useGetChargeLimitQuery(undefined, {
    skip: !showChangeLimitModal,
  });
  const [
    updateChargeLimit,
    {
      isLoading: isUpdating,
      error: { data: { errors: chargeLimitError } = {} } = {},
      data: chargeLimitResponse = {},
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateChargeLimitMutation();

  useEffect(() => {
    if (!isUpdating && isUpdateSuccess) {
      window.top.location.replace(chargeLimitResponse.charge_url);
    }
  }, [isUpdating, isUpdateSuccess, chargeLimitResponse]);

  const handleClose = () => {
    reset();
    dispatch(billingActions.toggleChargeLimitModal());
  };

  const handleNewLimitChange = useCallback((value) => {
    if (value > 9999) {
      return;
    }
    setNewLimitText(value);
  }, []);

  useEffect(() => {
    if (chargeLimit.charge_limit && showChangeLimitModal) {
      let forecastedUsage = chargeLimit?.charge_limit?.forecasted_usage;
      let chargeLimitValue = chargeLimit?.charge_limit?.charge_limit;

      const value =
        Math.ceil(Math.max(forecastedUsage * 1.5, chargeLimitValue) * 0.1) * 10;
      if (value > 0) {
        setNewLimitText(`${value}`);
      }
    }
  }, [chargeLimit, showChangeLimitModal, handleNewLimitChange]);

  return (
    <Modal
      open={showChangeLimitModal}
      onClose={handleClose}
      title={t('billing.charge_limit_modal.title')}
      primaryAction={{
        content: t('billing.charge_limit_modal.apply_button_label'),
        onClick: () => updateChargeLimit(newLimit),
        disabled:
          isUpdating ||
          !newLimit ||
          Number(newLimit) <= 0 ||
          Number(newLimit) === chargeLimit?.charge_limit?.charge_limit,
        loading: isUpdating,
      }}
    >
      <Modal.Section>
        {chargeLimit.charge_limit && (
          <>
            {chargeLimit.charge_status && (
              <>
                <Banner
                  title=""
                  status={chargeLimit.charge_status.badge_prop.status}
                >
                  <p>{chargeLimit.charge_status.label}</p>
                </Banner>
                <br />
              </>
            )}
            <Stack vertical>
              <Stack>
                <div className="ChangeLimit-Description">
                  {t('billing.charge_limit_modal.description')}
                </div>
              </Stack>
              <Stack distribution="fillEvenly">
                <Stack vertical spacing="tight">
                  <TextStyle variation="strong">
                    {t('billing.charge_limit_modal.charge_limit_field')}
                  </TextStyle>
                  <TextStyle variation="subdued">
                    ${chargeLimit?.charge_limit?.charge_limit.toFixed(2)}
                  </TextStyle>
                </Stack>
                <Form onSubmit={() => updateChargeLimit(newLimit)}>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField
                        label={
                          <TextStyle variation="strong">
                            {t('billing.charge_limit_modal.new_charge_limit')}
                          </TextStyle>
                        }
                        type="number"
                        value={newLimit}
                        onChange={handleNewLimitChange}
                        autoFocus={true}
                        disabled={isUpdating}
                        error={chargeLimitError?.display?.description}
                        id="input-new-limit"
                        prefix={'$'}
                        min={1}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              </Stack>
            </Stack>
          </>
        )}
      </Modal.Section>
    </Modal>
  );
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  FooterHelp,
  Stack,
  Tooltip,
  Icon,
  ChoiceList,
  Badge,
  TextStyle,
  Filters,
  Select,
  RangeSlider,
  Page,
  Loading,
} from '@shopify/polaris';
import {
  ClipboardMinor,
  ExportMinor,
  OrdersMajor,
  RedoMajor,
  RiskMinor,
} from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';

import SmallTopMessage from 'components/SmallTopMessage';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import ExternalLink from 'components/ExternalLink';
import { ShipmentsPage, DraggableChoiceList } from '@rush-app-admin/storybooks';
import _ from 'lodash';
import './style.scss';
import OrderImportModal from 'components/OrderImportModal';
import { SearchableChoiceList } from '@rush-app-admin/storybooks';
import UpdateCarrierModal from './UpdateCarrierModal';
import {
  getQueryObject,
  getSearchCategories,
  getShipmentSortOptions,
  getSubStatusLabelFromId,
  getCarrierNameFromUuid,
  formatDate,
  getQueryString,
  getCarrierChoices,
  getHumanDate,
  getStatusLabelFromId,
  getStatusIdFromUuid,
  getShipmentUuidFromIndex,
  getStatusIdFromSubStatusUuid,
  MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
  MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
  MIN_TRANSIT_DAYS,
  MAX_TRANSIT_DAYS,
  getColumns,
  getTextForFilterTag,
  DEFAULT_SHIPMENT_SORT_OPTION,
  getDatesFromValue,
  COLUMN_MAPPINGS,
} from './helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { TabsWrapper } from './TabsWrapper';
import {
  DATE_RANGE_VALUE_CUSTOM,
  DATE_RANGE_VALUE_LAST_120_DAYS,
  LOCAL_STORAGE,
} from 'Constants';
import { ClientStorage } from 'ClientStorage';
import { STORE_URLS } from 'config/urls';
import DateRange from './DateRange';
import {
  useDeleteShipmentsMutation,
  useGetShipmentsQuery,
  useGetShipmentStatusesQuery,
  useLazyExportShipmentsQuery,
  useMarkAsDeliveredMutation,
  useReTrackShipmentsMutation,
  useGetShipmentCarriersQuery,
} from '../shipmentsApi';
import i18next from 'i18next';
import TabsTableSkeleton from './TabsTableSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  GlobalSearch,
  shipmentsActions,
  ShipmentsQueryString,
} from '../shipmentSlice';
import ShipmentsLoader from './ShipmentsLoader';
import { baseActions } from 'redux/store/baseSlice';
import TableSkeleton from './TableSkeleton';

export default function Shipments() {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // State
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [openOrderImportModal, setOpenOrderImportModal] = useState(false);
  const [tabsList, setTabsList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showCarrierModal, setShowCarrierModal] = useState(false);

  const shipmentsQueryString = useSelector(ShipmentsQueryString);
  const search = useSelector(GlobalSearch);

  const setSearch = (obj) => {
    dispatch(shipmentsActions.setGlobalSearch(obj));
  };

  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 920px)').matches
  );
  useEffect(() => {
    window
      .matchMedia('(min-width: 920px)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  const SEARCH_CATEGORIES = getSearchCategories(t);
  const SHIPMENTS_SORT_OPTIONS = getShipmentSortOptions(t);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Toolkit Hooks

  const [markShipmentAsDelivered, deliveredResponse] =
    useMarkAsDeliveredMutation();
  const {
    data: markDeliveredData,
    isLoading: isMarkingDelivered,
    isSuccess: isMarkedDelivered,
  } = deliveredResponse;
  useEffect(() => {
    if (!isMarkingDelivered && isMarkedDelivered) {
      dispatch(
        baseActions.setToastMessage({
          message: markDeliveredData.message,
          type: 'success',
        })
      );
      setSelectedItems([]);
    }
  }, [isMarkingDelivered, isMarkedDelivered]);

  const [deleteShipment, deleteResponse] = useDeleteShipmentsMutation();
  const { isLoading: isDeleting, isSuccess: isDeleted } = deleteResponse;

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipments.delete.toster_message'),
          type: 'success',
        })
      );
      setSelectedItems([]);
    }
  }, [isDeleting, isDeleted]);

  const [reTrackShipment, reTrackShipmentResponse] =
    useReTrackShipmentsMutation();
  const { isLoading: isReTracking, isSuccess: isReTracked } =
    reTrackShipmentResponse;

  useEffect(() => {
    if (!isReTracking && isReTracked) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipment_detail.retrack.toster_message'),
          type: 'success',
        })
      );
      setSelectedItems([]);
    }
  }, [isReTracking, isReTracked]);
  const [exportShipments, exportResponse] = useLazyExportShipmentsQuery();
  const {
    data: exportData,
    isFetching: isExporting,
    isSuccess: isExportSucess,
  } = exportResponse;
  const {
    data: statusesRes = [],
    isFetching: isStatusesLoading,
    isSuccess: isStatusesLoaded,
  } = useGetShipmentStatusesQuery();

  const shipmentStatuses = useMemo(() => {
    if (!isStatusesLoading && isStatusesLoaded) {
      return statusesRes.statuses;
    }
    return [];
  }, [statusesRes, isStatusesLoading, isStatusesLoaded]);

  const {
    data: carriersRes = [],
    isFetching: isCarriersLoading,
    isSuccess: isCarriersLoaded,
    refetch: refetchShipmentCarriers,
  } = useGetShipmentCarriersQuery();

  const shipmentCarriers = useMemo(() => {
    if (!isCarriersLoading && isCarriersLoaded) {
      return carriersRes.carriers;
    }
    return [];
  }, [carriersRes, isCarriersLoading, isCarriersLoaded]);

  const debounceDaysPassedWithoutCarrierUpdate = useCallback(
    _.debounce((value) => {
      setFilters({
        daysPassedWithoutCarrierUpdate: value,
      });
    }, 500),

    []
  );

  const debounceTransitDaysBetween = useCallback(
    _.debounce((value) => {
      setFilters({
        transitDaysBetween: value,
      });
    }, 500),

    []
  );

  // Handlers
  const handleTabChange = useCallback(
    (index) => {
      setSelectedTab(index);
      handleSubStatusesChange([]);
      if (index === 0) {
        handleStatusesChange([]);
        setFilters({ status: undefined });
        return;
      }
      handleStatusesChange([shipmentStatuses[index - 1].slug]);
    },

    [shipmentStatuses]
  );
  const handlePageChange = useCallback((page) => {
    setPage(page);
    setFilters({ page });
  }, []);
  const handleSelectedItemsChange = useCallback((values) => {
    setSelectedItems(values);
  }, []);

  // Filters
  const [filters, setFilters] = useReducer(
    (state, newState) => {
      if (!newState.page && paramsLoaded) {
        // When any filter other than page is changed, set the page = 1
        handlePageChange(1);
      }
      return { ...state, ...newState };
    },
    {
      search_category: SEARCH_CATEGORIES[search.categoryIndex].value,
      search: search.text,
      sort:
        ClientStorage.get(LOCAL_STORAGE.SHIPMENTS_SORTBY) ||
        DEFAULT_SHIPMENT_SORT_OPTION,
      statuses: [],
      subStatuses: [],
      firstMileCarriers: [],
      lastMileCarriers: [],
      daysPassedWithoutCarrierUpdate: [
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      ],
      transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
      ordersCreatedIn: DATE_RANGE_VALUE_LAST_120_DAYS,
      ordersCreatedInFrom: undefined,
      ordersCreatedInTo: undefined,
      page: 1,
    }
  );

  // Filters State
  const [columns, setColumns] = useState(
    ClientStorage.get(LOCAL_STORAGE.SHIPMENT_COLUMNS) || getColumns(t)
  );
  const [statuses, setStatuses] = useState([]);
  const [sortBy, setSortBy] = useState(
    ClientStorage.get(LOCAL_STORAGE.SHIPMENTS_SORTBY) ||
      DEFAULT_SHIPMENT_SORT_OPTION
  );
  const [subStatuses, setSubStatuses] = useState({});
  const [firstMileCarriers, setFirstMileCarriers] = useState([]);
  const [lastMileCarriers, setLastMileCarriers] = useState([]);
  const [daysPassedWithoutCarrierUpdate, setDaysPassedWithoutCarrierUpdate] =
    useState([
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
    ]);
  const [transitDaysBetween, setTransitDaysBetween] = useState([
    MIN_TRANSIT_DAYS,
    MAX_TRANSIT_DAYS,
  ]);
  const [ordersCreatedIn, setOrdersCreatedIn] = useState(
    DATE_RANGE_VALUE_LAST_120_DAYS
  );
  const [ordersCreatedInFrom, setOrdersCreatedInFrom] = useState(null);
  const [ordersCreatedInTo, setOrdersCreatedInTo] = useState(null);

  const shipmentsResponse = useGetShipmentsQuery(
    getQueryString(page, filters, shipmentStatuses, columns),
    {
      skip: _.isEmpty(shipmentStatuses) || !paramsLoaded,
    }
  );

  const {
    data: { data: shipments = [], meta: shipmentsMeta = {} } = {},
    isLoading: shipmentsLoading,
    isFetching: shipmentsFetching,
    refetch: refreshShipments,
  } = shipmentsResponse;

  // Filters State Handlers
  const handleColumnsChange = useCallback((values) => {
    setColumns(values);
    ClientStorage.set(LOCAL_STORAGE.SHIPMENT_COLUMNS, values);
  }, []);
  const handleCategoryChange = useCallback((index) => {
    setSearch({ categoryIndex: index });
  }, []);
  const handleQueryChange = (text) => {
    setSearch({ text });
  };
  const handleSortChange = useCallback((value) => {
    setSortBy(value);
    setFilters({ sort: value });
    ClientStorage.set(LOCAL_STORAGE.SHIPMENTS_SORTBY, value);
  }, []);
  const handleStatusesChange = useCallback((value) => {
    setStatuses(value);
    setFilters({ statuses: value });
  }, []);
  const handleSubStatusesChange = (value, name) => {
    const newSubStatuses = { ...subStatuses, [name]: value };
    setSubStatuses(newSubStatuses);
    setFilters({ subStatuses: Object.values(newSubStatuses).flat(1) });
  };
  const handleSubStatusesClear = () => {
    setSubStatuses({});
    setFilters({ subStatuses: [] });
  };
  const handleFirstMileCarriersChange = useCallback((value) => {
    setFirstMileCarriers(value);
    setFilters({ firstMileCarriers: value });
  }, []);
  const handleLastMileCarriersChange = useCallback((value) => {
    setLastMileCarriers(value);
    setFilters({ lastMileCarriers: value });
  }, []);
  const handleDaysPassedWithoutCarrierUpdateChange = useCallback(
    (value) => {
      setDaysPassedWithoutCarrierUpdate(value);
      debounceDaysPassedWithoutCarrierUpdate(value);
    },
    [debounceDaysPassedWithoutCarrierUpdate]
  );
  const handleTransitDaysBetweenChange = useCallback(
    (value) => {
      setTransitDaysBetween(value);
      debounceTransitDaysBetween(value);
    },
    [debounceTransitDaysBetween]
  );
  const handleOrdersCreatedInChange = useCallback(
    (value, from = undefined, to = undefined) => {
      setOrdersCreatedIn(value);
      setOrdersCreatedInFrom(from);
      setOrdersCreatedInTo(to);
      setFilters({
        ordersCreatedIn: value,
        ordersCreatedInFrom: from,
        ordersCreatedInTo: to,
      });
    },
    []
  );

  const handleClearAllFilters = () => {
    const { start, end } = getDatesFromValue(DATE_RANGE_VALUE_LAST_120_DAYS);

    setSearch({ categoryIndex: 0, text: '' });
    setSortBy(DEFAULT_SHIPMENT_SORT_OPTION);
    setStatuses([]);
    setSubStatuses({});
    setFirstMileCarriers([]);
    setLastMileCarriers([]);
    setSelectedTab(0);
    setDaysPassedWithoutCarrierUpdate([
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
    ]);
    setTransitDaysBetween([MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS]);
    setOrdersCreatedIn(DATE_RANGE_VALUE_LAST_120_DAYS);
    setOrdersCreatedInFrom(start || null);
    setOrdersCreatedInTo(end || null);

    setFilters({
      search_category: SEARCH_CATEGORIES[0].value,
      search: '',
      sort: DEFAULT_SHIPMENT_SORT_OPTION,
      statuses: [],
      subStatuses: [],
      firstMileCarriers: [],
      lastMileCarriers: [],
      daysPassedWithoutCarrierUpdate: [
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
      ],
      transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
      ordersCreatedIn: DATE_RANGE_VALUE_LAST_120_DAYS,
      ordersCreatedInFrom: start,
      ordersCreatedInTo: end,
      page: 1,
    });
  };

  useEffect(() => {
    if (!isStatusesLoading && isStatusesLoaded) {
      const list = [t('shipments.filter.shipment_statuses.all')];
      setTabsList(list.concat(shipmentStatuses.map((status) => status.label)));
    }
  }, [shipmentStatuses, isStatusesLoading, isStatusesLoaded, t]);

  useEffect(() => {
    if (_.isEmpty(shipmentStatuses) || isStatusesLoading || paramsLoaded) {
      return;
    }

    let tempParams = params;
    if (params.toString() === '' && shipmentsQueryString) {
      tempParams = new URLSearchParams(`?${shipmentsQueryString}`);
    }

    tempParams.get('page') &&
      handlePageChange(parseInt(tempParams.get('page')));

    const search = tempParams.get('search');
    if (search) {
      const searchCategory = tempParams.get('search_category');
      const searchCategoryIndex = SEARCH_CATEGORIES.findIndex(
        ({ value }) => value === searchCategory
      );

      if (searchCategoryIndex < 0) {
        return;
      }
      setSearch({ categoryIndex: searchCategoryIndex, text: search });
      setFilters({
        search: search,
        search_category: SEARCH_CATEGORIES[searchCategoryIndex].value,
      });
    }

    tempParams.get('statuses_uuid') &&
      handleStatusesChange(
        tempParams
          .get('statuses_uuid')
          .split(',')
          .map((uuid) => getStatusIdFromUuid(shipmentStatuses, uuid))
      );
    const tIndex = shipmentStatuses.findIndex(
      ({ uuid }) => uuid === tempParams.get('statuses_uuid')
    );
    setSelectedTab(tIndex + 1);

    if (tempParams.get('sub_statuses_uuid')) {
      const object = {};
      tempParams
        .get('sub_statuses_uuid')
        .split(',')
        .forEach((uuid) => {
          const { status, subStatus } = getStatusIdFromSubStatusUuid(
            shipmentStatuses,
            uuid
          );
          object[status]
            ? object[status].push(subStatus)
            : (object[status] = [subStatus]);
        });

      setSubStatuses(object);
      setFilters({ subStatuses: Object.values(object).flat(1) });
    }

    tempParams.get('firstmile_carriers_uuid') &&
      handleFirstMileCarriersChange(
        tempParams.get('firstmile_carriers_uuid').split(',')
      );

    tempParams.get('lastmile_carriers_uuid') &&
      handleLastMileCarriersChange(
        tempParams.get('lastmile_carriers_uuid').split(',')
      );

    tempParams.get('sort') && handleSortChange(tempParams.get('sort'));

    if (
      tempParams.get('days_without_carrier_update_min') ||
      tempParams.get('days_without_carrier_update_max')
    ) {
      const min =
        tempParams.get('days_without_carrier_update_min') ||
        MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE;
      const max =
        tempParams.get('days_without_carrier_update_max') ||
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE;

      setDaysPassedWithoutCarrierUpdate([min, max]);
      setFilters({
        daysPassedWithoutCarrierUpdate: [min, max],
      });
    }

    if (
      tempParams.get('days_in_transit_min') ||
      tempParams.get('days_in_transit_max')
    ) {
      const min = tempParams.get('days_in_transit_min') || MIN_TRANSIT_DAYS;
      const max = tempParams.get('days_in_transit_max') || MAX_TRANSIT_DAYS;

      setTransitDaysBetween([min, max]);
      setFilters({
        transitDaysBetween: [min, max],
      });
    }

    const ordersCreatedIn = tempParams.get('orders_created_in');
    if (ordersCreatedIn) {
      if (ordersCreatedIn === DATE_RANGE_VALUE_CUSTOM) {
        const start = tempParams.get('orders_created_from');
        const end = tempParams.get('orders_created_to');
        if (start && end) {
          handleOrdersCreatedInChange(DATE_RANGE_VALUE_CUSTOM, start, end);
        } else {
          handleOrdersCreatedInChange(DATE_RANGE_VALUE_LAST_120_DAYS);
        }
      } else {
        handleOrdersCreatedInChange(ordersCreatedIn);
      }
    }

    setTimeout(() => {
      setParamsLoaded(true);
    }, 1);
  }, [shipmentStatuses, isStatusesLoading]);

  useEffect(() => {
    if (paramsLoaded) {
      setSelectedItems([]);
      const queryObject = getQueryObject(
        page,
        filters,
        shipmentStatuses,
        columns
      );
      const queryString = getQueryString(
        page,
        filters,
        shipmentStatuses,
        columns
      );

      dispatch(
        shipmentsActions.setShipmentsQuery({ queryString, queryObject })
      );
      window.history.replaceState(
        null,
        '',
        `.${STORE_URLS.SHIPMENTS}?${queryString}`
      );
    }
  }, [filters, shipmentStatuses, paramsLoaded, columns]);

  useEffect(() => {
    if (!isExporting && isExportSucess && exportData) {
      const link = document.createElement('a');
      link.href = exportData.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [isExportSucess, isExporting]);

  useEffect(() => {
    if (params.has('message_id')) {
      dispatch(
        baseActions.setToastMessage({
          message: t(params.get('message_id')),
          type: 'success',
        })
      );
    }
  }, []);

  useEffect(() => {
    const translatedColumns = getColumns(t);

    const newLabels = [];
    translatedColumns.forEach((col) => {
      newLabels[col.value] = col.label;
    });

    const newColumns = [...columns];
    newColumns.forEach((newColumn) => {
      newColumn.label = newLabels[newColumn.value];
    });

    handleColumnsChange(newColumns);
  }, [t]);

  const onRefreshAction = useCallback(() => {
    refreshShipments();
  }, [refreshShipments]);

  const onImportAction = useCallback(() => {
    setOpenOrderImportModal((oIModal) => !oIModal);
  }, []);

  const onExportAction = useCallback(() => {
    if (isExporting) {
      return;
    }

    let exportParams = new URLSearchParams(`?${shipmentsQueryString}`);
    const fields = columns
      .filter(({ selected }) => selected)
      .map(({ value }) => COLUMN_MAPPINGS[value])
      .join(',');

    exportParams.set('fields', fields);
    exportShipments(exportParams.toString());
  }, [shipmentsQueryString, columns]);

  // Helpers
  useEffect(() => {
    if (paramsLoaded) {
      debounceQuery(search.text);
    }
  }, [search.text]);

  useEffect(() => {
    return () => {
      setSearch({ text: '', categoryIndex: 0 });
    };
  }, []);

  const debounceQuery = useCallback(
    _.debounce((text) => {
      setFilters({
        search: text,
        search_category: SEARCH_CATEGORIES[search.categoryIndex].value,
      });
    }, 500),
    [search.categoryIndex]
  );

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    dispatch(
      baseActions.setToastMessage({
        message: 'Copied to clipboard',
        type: 'success',
      })
    );
  };

  const getAppliedFilters = useCallback(() => {
    const appliedFilters = [];

    !_.isEmpty(statuses) &&
      appliedFilters.push({
        key: 'statuses',
        label: `${t('shipments.filter.status_label')}: ${statuses
          .map((id) => getStatusLabelFromId(shipmentStatuses, id))
          .join(', ')}`,
        onRemove: () => {
          handleStatusesChange([]);
          setSelectedTab(0);
        },
      });

    const temp = Object.values(subStatuses).flat(1);
    !_.isEmpty(temp) &&
      appliedFilters.push({
        key: 'SubStatus',
        label: `${t('shipments.filter.substatus_label')}: ${temp
          .map((id) => getSubStatusLabelFromId(shipmentStatuses, id))
          .join(', ')}`,
        onRemove: () => handleSubStatusesClear(),
      });

    !_.isEmpty(firstMileCarriers) &&
      appliedFilters.push({
        key: 'FirstMileCarriers',
        label: `${t('shipments.filter.first_mile_carrier')}: ${firstMileCarriers
          .map(
            (uuid) =>
              getCarrierNameFromUuid(shipmentCarriers, uuid) ||
              t('shipments.filter.carrier_not_assigned')
          )
          .join(', ')}`,
        onRemove: () => handleFirstMileCarriersChange([]),
      });

    !_.isEmpty(lastMileCarriers) &&
      appliedFilters.push({
        key: 'LastMileCarriers',
        label: `${t('shipments.filter.last_mile_carrier')}: ${lastMileCarriers
          .map(
            (uuid) =>
              getCarrierNameFromUuid(shipmentCarriers, uuid) ||
              t('shipments.filter.carrier_not_assigned')
          )
          .join(', ')}`,
        onRemove: () => handleLastMileCarriersChange([]),
      });

    (daysPassedWithoutCarrierUpdate[0] >
      MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE ||
      daysPassedWithoutCarrierUpdate[1] <
        MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE) &&
      appliedFilters.push({
        key: 'DaysPassedWithoutCarrierUpdate',
        label: `${t(
          'shipments.filter.days_passed_without_carrier_update_title'
        )}: ${daysPassedWithoutCarrierUpdate[0]} - ${
          daysPassedWithoutCarrierUpdate[1]
        }`,
        onRemove: () => {
          setDaysPassedWithoutCarrierUpdate([
            MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
            MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
          ]);
          setFilters({
            daysPassedWithoutCarrierUpdate: [
              MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
              MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE,
            ],
          });
        },
      });

    (transitDaysBetween[0] > MIN_TRANSIT_DAYS ||
      transitDaysBetween[1] < MAX_TRANSIT_DAYS) &&
      appliedFilters.push({
        key: 'TransitDaysBetween',
        label: `${t('shipments.filter.transit_days_between_title')}: ${
          transitDaysBetween[0]
        } - ${transitDaysBetween[1]}`,
        onRemove: () => {
          setTransitDaysBetween([MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS]);
          setFilters({
            transitDaysBetween: [MIN_TRANSIT_DAYS, MAX_TRANSIT_DAYS],
          });
        },
      });

    appliedFilters.push({
      key: 'OrdersCreatedIn',
      label: `${t(
        'shipments.filter.orders_created_in_title'
      )}: ${getTextForFilterTag(
        ordersCreatedIn,
        t,
        ordersCreatedInFrom,
        ordersCreatedInTo
      )}`,
      onRemove: () =>
        handleOrdersCreatedInChange(DATE_RANGE_VALUE_LAST_120_DAYS),
    });

    return appliedFilters;
  }, [
    statuses,
    subStatuses,
    firstMileCarriers,
    lastMileCarriers,
    daysPassedWithoutCarrierUpdate,
    transitDaysBetween,
    ordersCreatedIn,
    ordersCreatedInFrom,
    ordersCreatedInTo,
    shipmentStatuses,
    shipmentCarriers,
  ]);

  let translations;
  try {
    translations = require(`@shopify/polaris/locales/${i18next.language}.json`);
  } catch (e) {
    translations = require('@shopify/polaris/locales/en.json');
  }

  const getStatusChoices = () => {
    if (!shipmentStatuses) {
      return [];
    }

    return shipmentStatuses.map((status) => ({
      label: status.label,
      value: status.id,
      renderChildren: status.substatuses
        ? () => (
            <ChoiceList
              choices={status.substatuses.map((substatus) => ({
                label: substatus.label,
                value: substatus.id,
              }))}
              onChange={handleSubStatusesChange}
              selected={subStatuses[status.id] || []}
              title={''}
              name={status.id}
              titleHidden
              allowMultiple
            />
          )
        : undefined,
    }));
  };

  const filtersData = {
    placeholder: t('shipments.search.input.placeholder'),
    query: search.text,
    onQueryChange: handleQueryChange,
    appliedFilters: getAppliedFilters(),
    sortOptions: SHIPMENTS_SORT_OPTIONS,
    selectedSortOption: sortBy,
    onChangeSortOption: handleSortChange,
    filters: [
      {
        key: 'columns',
        label: t('shipments.filter.columns'),
        filter: (
          <DraggableChoiceList
            title={t('shipments.filter.columns')}
            choices={columns}
            onChange={handleColumnsChange}
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
      {
        key: 'status',
        label: t('shipments.filter.status_label'),
        filter: (
          <ChoiceList
            name={'status'}
            title={''}
            titleHidden={true}
            choices={getStatusChoices()}
            selected={statuses}
            onChange={handleStatusesChange}
            allowMultiple
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
      {
        key: 'first-mile-carrier',
        label: t('shipments.filter.first_mile_carrier'),
        filter: (
          <>
            <SearchableChoiceList
              name={'first-mile-carrier'}
              choices={getCarrierChoices(
                shipmentCarriers,
                t('shipments.filter.carrier_not_assigned')
              )}
              onChange={handleFirstMileCarriersChange}
              selected={firstMileCarriers}
              onClear={() => handleFirstMileCarriersChange([])}
            />
          </>
        ),
        shortcut: true,
        hideClearButton: true,
      },
      {
        key: 'last-mile-carrier',
        label: t('shipments.filter.last_mile_carrier'),
        filter: (
          <SearchableChoiceList
            name={'last-mile-carrier'}
            choices={getCarrierChoices(
              shipmentCarriers,
              t('shipments.filter.carrier_not_assigned')
            )}
            onChange={handleLastMileCarriersChange}
            selected={lastMileCarriers}
            onClear={() => handleLastMileCarriersChange([])}
          />
        ),
        shortcut: true,
        hideClearButton: true,
      },
      {
        key: 'daysPassedWithoutCarrierUpdate',
        label: t('shipments.filter.days_passed_without_carrier_update_title'),
        filter: (
          <RangeSlider
            label={t(
              'shipments.filter.days_passed_without_carrier_update_description'
            )}
            value={daysPassedWithoutCarrierUpdate}
            prefix={<p>{MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}</p>}
            suffix={<p>{MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}+</p>}
            output
            min={MIN_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}
            max={MAX_DAYS_PASSED_WITHOUT_CARRIER_UPDATE}
            step={1}
            onChange={handleDaysPassedWithoutCarrierUpdateChange}
          />
        ),
        hideClearButton: true,
      },
      {
        key: 'transitDaysBetween',
        label: t('shipments.filter.transit_days_between_title'),
        filter: (
          <RangeSlider
            label={t('shipments.filter.transit_days_between_description')}
            value={transitDaysBetween}
            prefix={<p>{MIN_TRANSIT_DAYS}</p>}
            suffix={<p>{MAX_TRANSIT_DAYS}+</p>}
            output
            min={MIN_TRANSIT_DAYS}
            max={MAX_TRANSIT_DAYS}
            step={1}
            onChange={handleTransitDaysBetweenChange}
          />
        ),
        hideClearButton: true,
      },
      {
        key: 'ordersCreatedIn',
        label: t('shipments.filter.orders_created_in_title'),
        filter: (
          <DateRange
            selectedOption={ordersCreatedIn}
            start={ordersCreatedInFrom}
            end={ordersCreatedInTo}
            onChange={handleOrdersCreatedInChange}
          />
        ),
        hideClearButton: true,
      },
    ],
  };

  const selectedItemUuids = selectedItems.map((item) =>
    getShipmentUuidFromIndex(shipments, item)
  );

  return (
    <>
      <SmallTopMessage />
      {isMarkingDelivered || isReTracking || isDeleting ? <Loading /> : ''}
      <Page
        title={t('shipments.header.title')}
        secondaryActions={[
          {
            content: t('shipment_detail.actions.refresh'),
            icon: RedoMajor,
            onAction: onRefreshAction,
          },
          {
            content: t('shipments.header.export_btn_label'),
            icon: ExportMinor,
            onAction: onExportAction,
            disabled: !shipments.length,
            loading: isExporting,
          },
          {
            content: t('shipments.header.import_order_btn_label'),
            icon: OrdersMajor,
            onAction: onImportAction,
          },
        ]}
        subtitle={
          <Subtitle
            text={t('shipments.header.subtitle')}
            actions={[
              {
                text: t('shipments.subtitle.get_started'),
                source: 'https://support.rush.app/about-shipments',
              },
            ]}
          />
        }
        fullWidth
      >
        {isCarriersLoading || isStatusesLoading || !paramsLoaded ? (
          <TabsTableSkeleton />
        ) : (
          <div className="shipments-outer-container">
            <ShipmentsLoader show={shipmentsFetching && !shipmentsLoading} />
            <ShipmentsPage
              page={{
                translationFile: translations,
              }}
              tabs={tabsList}
              selectedTabIndex={selectedTab}
              searchCategory={{
                categories: SEARCH_CATEGORIES,
                selectedCategoryIndex: search.categoryIndex,
                onFilterCategoryChange: handleCategoryChange,
              }}
              filterControl={
                <Filters
                  queryPlaceholder={filtersData.placeholder}
                  queryValue={filtersData.query}
                  filters={filtersData.filters}
                  appliedFilters={filtersData.appliedFilters}
                  onQueryChange={filtersData.onQueryChange}
                  onQueryClear={() => filtersData.onQueryChange('')}
                  onClearAll={handleClearAllFilters}
                >
                  {matches && (
                    <div style={{ marginLeft: 8 }}>
                      <Stack spacing={'tight'} wrap={false}>
                        <Select
                          label={t('shipments.filter.sort_by')}
                          labelInline
                          options={filtersData.sortOptions}
                          onChange={filtersData.onChangeSortOption}
                          value={filtersData.selectedSortOption}
                        />
                      </Stack>
                    </div>
                  )}
                </Filters>
              }
              resourceList={{
                loading: false,
                TableSkeleton: shipmentsLoading ? (
                  <div>
                    <TableSkeleton />
                  </div>
                ) : undefined,
                name: {
                  singular: t('shipments.filter.shipment_singular'),
                  plural: t('shipments.filter.shipment_plural'),
                },
                headings: columns,
                items: shipments.map((shipment) => {
                  const status = shipmentStatuses.find(
                    ({ uuid }) => uuid === shipment.shipment_status_uuid
                  );
                  const subStatus = status?.substatuses?.find(
                    (sub) => sub.uuid === shipment.shipment_sub_status_uuid
                  );
                  const firstMileCarrier = shipmentCarriers.find(
                    ({ uuid }) => uuid === shipment.first_mile_carrier_uuid
                  );
                  const lastMileCarrier = shipmentCarriers.find(
                    ({ uuid }) => uuid === shipment.last_mile_carrier_uuid
                  );

                  const isFinal = status?.is_final;
                  const variation = isFinal ? 'subdued' : undefined;
                  const lastSyncFormatted = isFinal
                    ? {
                        variation,
                        text: '-',
                      }
                    : getHumanDate(shipment.last_sync_at, isFinal);
                  const lastCheckFormatted = getHumanDate(
                    shipment.shipment_last_checkpoint_at,
                    isFinal
                  );

                  return {
                    shipment_quota: shipment.shipment_quota,
                    uuid: shipment.uuid,
                    order: (
                      <Stack spacing={'tight'} wrap={false}>
                        <ExternalLink url={shipment.order_external_link}>
                          {shipment.order_label}
                        </ExternalLink>
                        {shipment.shipment_quota === 'over_quota' && (
                          <Tooltip content={t('shipments.over_quota.tooltip')}>
                            <Icon source={RiskMinor} color="warning" />
                          </Tooltip>
                        )}
                      </Stack>
                    ),
                    status: (
                      <Badge
                        status={status?.badge_prop?.status}
                        progress={status?.badge_prop?.progress}
                      >
                        {status?.label}
                      </Badge>
                    ),
                    subStatus: (
                      <TextStyle variation={variation}>
                        {subStatus?.label}
                      </TextStyle>
                    ),
                    eta: (
                      <TextStyle variation={variation}>
                        {formatDate(shipment.shipment_estimated_delivery_date)}
                      </TextStyle>
                    ),
                    firstMileCarrier: (
                      <TextStyle variation={variation}>
                        {firstMileCarrier?.label}
                      </TextStyle>
                    ),
                    firstMileTrackingNumber: (
                      <Stack spacing="extraTight">
                        <div className="tracking_num_container">
                          {shipment.first_mile_tracking_link ? (
                            <ExternalLink
                              url={shipment.first_mile_tracking_link}
                            >
                              {shipment.first_mile_tracking_number}
                            </ExternalLink>
                          ) : (
                            <TextStyle variation={variation}>
                              {shipment.first_mile_tracking_number}
                            </TextStyle>
                          )}
                          {shipment.first_mile_tracking_number ? (
                            <div
                              className="copy-icon"
                              title={t('common.copy')}
                              onClick={(event) => {
                                event.stopPropagation();
                                copyToClipboard(
                                  shipment.first_mile_tracking_number
                                );
                              }}
                            >
                              <Icon color="subdued" source={ClipboardMinor} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Stack>
                    ),
                    lastMileCarrier: (
                      <TextStyle variation={variation}>
                        {lastMileCarrier?.label}
                      </TextStyle>
                    ),

                    lastMileTrackingNumber: (
                      <Stack spacing="extraTight">
                        <div className="tracking_num_container">
                          {shipment.last_mile_tracking_link ? (
                            <ExternalLink
                              url={shipment.last_mile_tracking_link}
                            >
                              {shipment.last_mile_tracking_number}
                            </ExternalLink>
                          ) : (
                            <TextStyle variation={variation}>
                              {shipment.last_mile_tracking_number}
                            </TextStyle>
                          )}
                          {shipment.last_mile_tracking_number ? (
                            <div
                              className="copy-icon"
                              title={t('common.copy')}
                              onClick={(event) => {
                                event.stopPropagation();
                                copyToClipboard(
                                  shipment.last_mile_tracking_number
                                );
                              }}
                            >
                              <Icon color="subdued" source={ClipboardMinor} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Stack>
                    ),
                    carrierLastUpdate: (
                      <TextStyle variation={lastCheckFormatted.variation}>
                        {lastCheckFormatted.text}
                      </TextStyle>
                    ),
                    destinationCountry: (
                      <TextStyle variation={variation}>
                        {shipment.destination_country}
                      </TextStyle>
                    ),
                    destinationCity: (
                      <TextStyle variation={variation}>
                        {shipment.destination_city}
                      </TextStyle>
                    ),
                    customerName: (
                      <TextStyle variation={variation}>
                        {shipment.customer_name}
                      </TextStyle>
                    ),
                    customerEmail: (
                      <TextStyle variation={variation}>
                        {shipment.customer_email}
                      </TextStyle>
                    ),
                    customerPhone: (
                      <TextStyle variation={variation}>
                        {shipment.customer_phone}
                      </TextStyle>
                    ),
                    orderDate: (
                      <TextStyle variation={variation}>
                        {formatDate(shipment.order_created_at)}
                      </TextStyle>
                    ),
                    shipmentDate: (
                      <TextStyle variation={variation}>
                        {formatDate(shipment.shipment_created_at)}
                      </TextStyle>
                    ),
                    inTransitDate: (
                      <TextStyle variation={variation}>
                        {formatDate(shipment.shipment_in_transit_at)}
                      </TextStyle>
                    ),
                    deliveredDate: (
                      <TextStyle variation={variation}>
                        {formatDate(shipment.shipment_delivered_at)}
                      </TextStyle>
                    ),
                    orderPrice: (
                      <TextStyle variation={variation}>
                        {shipment.order_price_label}
                      </TextStyle>
                    ),
                    lastCarrierCheck: (
                      <TextStyle variation={lastSyncFormatted.variation}>
                        {lastSyncFormatted.text}
                      </TextStyle>
                    ),
                  };
                }),
                selectedItems: selectedItems,
                onSelectionChange: handleSelectedItemsChange,
                emptyState: {
                  title: t('shipments.list.empty.title'),
                  description: t('shipments.list.empty.description'),
                },
                promotedBulkActions: [
                  {
                    content: t('shipments.mark_as_delivered.bulk_button'),
                    onAction: () =>
                      markShipmentAsDelivered(
                        selectedItemUuids,
                        t('shipments.mark_as_delivered.toster_message')
                      ),
                  },
                  {
                    content: t('shipments.change_carrier.bulk_button'),
                    onAction: () => setShowCarrierModal(true),
                  },
                  {
                    content: t('shipments.retrack.bulk_button'),
                    onAction: () => {
                      reTrackShipment(selectedItemUuids);
                    },
                  },
                  {
                    content: t('shipments.delete.bulk_button'),
                    onAction: () => {
                      deleteShipment(selectedItemUuids);
                    },
                  },
                ],
                onItemClick: (uuid, openInNewTab) => {
                  openInNewTab
                    ? window.open(`${location.pathname}/${uuid}`, '_blank')
                    : history.push(`${location.pathname}/${uuid}`);
                },
              }}
              onTabChange={handleTabChange}
              pagination={{
                label: t('shipments.summary.pagination_label', {
                  startResult: shipmentsMeta.from + 1,
                  endResult: shipmentsMeta.to,
                  total_results: shipmentsMeta.total?.toLocaleString(),
                }),
                hasNext: shipmentsMeta.current_page < shipmentsMeta.last_page,
                hasPrevious: shipmentsMeta.current_page > 1,
                onNext: () => {
                  handlePageChange(page + 1);
                },
                onPrevious: () => {
                  handlePageChange(page - 1);
                },
              }}
              TabsWrapper={TabsWrapper}
              filterActions={
                !matches ? (
                  <Select
                    label={t('shipments.filter.sort_by')}
                    labelInline
                    options={filtersData.sortOptions}
                    onChange={filtersData.onChangeSortOption}
                    value={filtersData.selectedSortOption}
                  />
                ) : undefined
              }
            />
          </div>
        )}
        <OrderImportModal
          openOrderImportModal={openOrderImportModal}
          onClose={onImportAction}
        />
        {showCarrierModal && (
          <UpdateCarrierModal
            shipmentIds={selectedItemUuids}
            onClose={(isUpdated) => {
              if (isUpdated) {
                refetchShipmentCarriers();
                setSelectedItems([]);
              }
              setShowCarrierModal(false);
            }}
            initializer={'shipments'}
          />
        )}

        <FooterHelp>
          <TranslateLink text={t('footer_help.dashboard')} />
        </FooterHelp>
      </Page>
    </>
  );
}

import React, { useCallback } from 'react';
import moment from 'moment';
import {
  DATE_RANGE_VALUE_CUSTOM,
  DATE_RANGE_VALUE_LAST_120_DAYS,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import DateRangeNew from 'components/DateRangeNew';

export default function DateRange({ selectedOption, start, end, onChange }) {
  const handleSelectedDate = useCallback((dateRange) => {
    const { start, end, selectedOption = DATE_RANGE_VALUE_CUSTOM } = dateRange;
    onChange(
      selectedOption,
      moment(start).format(DB_DATE_FORMAT_STRING),
      moment(end).format(DB_DATE_FORMAT_STRING)
    );
  }, []);

  return (
    <DateRangeNew
      onChange={handleSelectedDate}
      selectedRange={{
        start: moment(start)._d,
        end: moment(end)._d,
      }}
      fullWidth={false}
      selectedOption={selectedOption || DATE_RANGE_VALUE_LAST_120_DAYS}
    />
  );
}

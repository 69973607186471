const { API } = require('helpers/api');
const { API_URL } = require('config');

function getVisitorLanguages() {
  return API.request({
    method: 'get',
    url: `${API_URL}/products/visitor-app/languages`,
  });
}

function getAffiliatesCampaign(params) {
  return API.request({
    method: 'get',
    url: `${API_URL}/affiliates/campaign/${params}`,
  });
}

function login(params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/auth/login`,

    data: params,
  });
}

function register(params) {
  const get_params = {};
  params.get_params.forEach((value, key) => {
    get_params[key] = value;
  });

  const data = {
    ...params,
    get_params,
  };

  return API.request({
    method: 'POST',
    url: `${API_URL}/auth/register?${params.get_params.toString()}`,

    data,
  });
}

function resendVerificationCode(verificationToken) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/verifications/resend`,

    data: { token: verificationToken },
  });
}

function verifyCode(params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/verifications/code`,

    data: { token: params.token, verification_code: params.code },
  });
}

function forgotPassword(email) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/password/forgot`,

    data: { email },
  });
}

function resetPassword(params) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/password/reset`,

    data: {
      email: params.email,
      password: params.password,
      token: params.token,
    },
  });
}

function updateEmail(params) {
  return API.request({
    method: 'PATCH',
    url: `${API_URL}/verifications`,

    data: {
      token: params.token,
      new_contact: {
        email: params.email,
      },
    },
  });
}

function getAccessToken(token) {
  return API.request({
    method: 'post',
    url: `${API_URL}/auth/login-token`,
    data: { token },
  });
}

function logout(accessToken) {
  return API.request({
    method: 'DELETE',
    url: `${API_URL}/auth/logout`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getJobStatus(jobId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/public/job-status/${jobId}`,
  });
}

module.exports = {
  getVisitorLanguages,
  getAffiliatesCampaign,
  login,
  register,
  resendVerificationCode,
  verifyCode,
  forgotPassword,
  resetPassword,
  updateEmail,
  getAccessToken,
  logout,
  getJobStatus,
};

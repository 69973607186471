import React from 'react';
import { Modal, Stack } from '@shopify/polaris';
import './style.scss';
export default function VideoModal(props) {
  return (
    <Modal
      open={props.showVideoModal}
      onClose={props.handleModalChange}
      title={props.videoTitle}
    >
      <div>
        <Modal.Section>
          <Stack distribution="center" spacing="extraLoose">
            <iframe
              width="560"
              height="315"
              src={props.videoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="video-youtube"
            ></iframe>
          </Stack>
        </Modal.Section>
      </div>
    </Modal>
  );
}

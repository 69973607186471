import { baseApi } from './baseApi';

const commonBaseAPIs = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStoreStatistics: builder.query({
      query: (storeUUID) => {
        return {
          
          url: `/stores/${storeUUID}?include=statistics`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getJobStatus: builder.query({
      query: (jobId) => {
        return {
          url: `/stores/public/job-status/${jobId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    allCarriers: builder.query({
      query: () => {
        return {
          url: `/carriers`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),

    getHealth: builder.query({
      query: () => {
        return {
          url: `/status/health`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getUserLanguages: builder.query({
      query: () => {
        return {
          url: `/products/rush-app/languages`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getUserDetails: builder.query({
      query: () => {
        return {
          url: `/user/me`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['userinfo'],
    }),
    getOrganizations: builder.query({
      query: () => {
        return {
          url: `/user/me/organizations`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getChatIdentifier: builder.query({
      query: () => {
        return {
          url: `/user/me/chat-identifier`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateUserLocale: builder.mutation({
      query: (params) => {
        return {
          url: `/user/me`,
          method: 'PATCH',
          body: { ...params },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useAllCarriersQuery,
  useGetHealthQuery,
  useGetUserLanguagesQuery,
  useGetUserDetailsQuery,
  useGetOrganizationsQuery,
  useGetChatIdentifierQuery,
  useUpdateUserLocaleMutation,
  useGetJobStatusQuery,
  useGetStoreStatisticsQuery,
} = commonBaseAPIs;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import ExternalLink from '../ExternalLink';

export default function TranslateLink(props) {
  return (
    <ReactMarkdown
      children={props.text}
      components={{
        a: ({ ...renderProps }) => {
          return (
            <ExternalLink url={renderProps.href}>
              {renderProps.children[0]}
            </ExternalLink>
          );
        },
      }}
    />
  );
}

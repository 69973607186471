import { connect } from 'react-redux';
import BaseLanguageDropdown from './BaseLanguageDropdown';

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseLanguageDropdown);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Filters,
  Modal,
  OptionList,
  Spinner,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useLazyGetProductsQuery } from 'redux/store/commonStoreApis';
import './styles.scss';

function ProductsPicker({
  selectedProducts = [],
  onAdd,
  onCancel,
  queryString = '',
}) {
  const [t] = useTranslation();

  const [getOptions, optionsResponse] = useLazyGetProductsQuery();
  const { data: optionList = {}, isFetching, isSuccess } = optionsResponse;

  const [selected, setSelected] = useState([]); // will save array of objects
  const [list, setList] = useState([]);
  const [query, setQuery] = useState(queryString);

  useEffect(() => {
    if (isSuccess && optionList) {
      setList(optionList?.products || []);
    }
  }, [optionList, isSuccess]);

  useEffect(() => {
    getOptions('');
  }, []);

  const handleAdd = () => {
    onAdd([...selectedProducts, ...selected]);
  };

  const debouncesGetOptions = useCallback(
    debounce((searchVal) => {
      getOptions(searchVal);
    }, 500),
    []
  );

  useEffect(() => {
    try {
      debouncesGetOptions(query);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [query]);

  const onSelectedChange = (newSelected) => {
    const prevSel = selected.map((p) => p.id);
    const currList = list.map((p) => p.id);
    const absentSelIds = prevSel.filter((id) => !currList.includes(id));
    const absentSel = selected.filter(({ id }) => absentSelIds.includes(id));
    const selectedProds = list.filter((p) => newSelected.includes(p.id));
    setSelected([...selectedProds, ...absentSel]);
  };

  const listOptions = useMemo(() => {
    const selectedIds = selectedProducts.map((p) => Number(p.id));
    return list.filter(({ id }) => !selectedIds.includes(Number(id)));
  }, [selectedProducts, list]);

  return (
    <Modal
      title={t('common.pick-products')}
      open={true}
      onClose={onCancel}
      primaryAction={{
        content: t('common.add'),
        onAction: handleAdd,
        disabled: !selected.length,
      }}
      secondaryActions={[
        {
          content: t('common.cancel'),
          onAction: onCancel,
        },
      ]}
      footer={
        <TextStyle variation="subdued">{`${selected.length} selected`}</TextStyle>
      }
    >
      <Modal.Section>
        <Filters
          focused={true}
          queryValue={query}
          filters={[]}
          onQueryChange={setQuery}
          onQueryClear={() => {
            setQuery('');
          }}
          onClearAll={() => {
            setQuery('');
          }}
          queryPlaceholder={t('common.filter-products-by-name')}
        />
      </Modal.Section>
      {isFetching ? (
        <Stack distribution="center" alignment="center">
          {' '}
          <Spinner />
        </Stack>
      ) : (
        ''
      )}
      <div className="products-picker">
        <OptionList
          onChange={onSelectedChange}
          options={listOptions.map((p) => {
            return {
              value: p.id,
              label: (
                <Stack spacing="extraTight" vertical>
                  <TextStyle>{p.title}</TextStyle>
                  <TextStyle variation="subdued">{p.id}</TextStyle>
                </Stack>
              ),
              media: <Avatar shape="square" source={p.image} />,
            };
          })}
          selected={selected.map((s) => s.id)}
          allowMultiple
        />
      </div>
    </Modal>
  );
}

export default ProductsPicker;

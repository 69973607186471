import React, { useCallback } from 'react';

import { ContextualSaveBar } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function SaveBar({
  trackingPageState,
  saveTrackingPageDetail,
  trackingDetailLoading,
  discardChanges,
}) {
  const [t] = useTranslation();
  const saveTrackingPage = useCallback(() => {
    saveTrackingPageDetail(trackingPageState);
  }, [trackingPageState, saveTrackingPageDetail]);

  return (
    <>
      {trackingPageState.isUpdated &&
        Object.keys(trackingPageState.validationFields).filter(
          (item) => !trackingPageState.validationFields[item]
        ).length === 0 && (
          <ContextualSaveBar
            alignContentFlush
            message={t('settings.tracking_page.top_bar_rule_unsaved_message')}
            saveAction={{
              onAction: () => saveTrackingPage(),
              loading: trackingDetailLoading,
              disabled: trackingDetailLoading,
              content: t('common.save'),
            }}
            discardAction={{
              onAction: () => discardChanges(),
              loading: trackingDetailLoading,
              disabled: trackingDetailLoading,
              content: t('common.discard'),
            }}
          />
        )}
    </>
  );
}

import React from 'react';
import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonTabs,
  TextField,
} from '@shopify/polaris';
import './style.scss';

function TabsTableSkeleton() {
  return (
    <div className="shipmentsSkeleton">
      <Card>
        <SkeletonTabs count={5} />
        <Card.Section>
          <div className="skeleton-filters">
            <SkeletonDisplayText />
            <TextField disabled autoComplete="off" />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
      </Card>
    </div>
  );
}

export default TabsTableSkeleton;

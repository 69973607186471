import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import { storeApi } from './storeApi';
import { baseActions } from './baseSlice';

export const rtkMiddleware = (api) => (next) => (action) => {
  try {
    if (isRejectedWithValue(action)) {
      api.dispatch(
        baseActions.setToastMessage({
          message:
            action?.payload?.data?.errors?.display?.description ||
            action?.payload?.data?.errors?.display?.title ||
            action?.payload?.data?.errors?.message ||
            'Something went wrong!',
          type: 'error',
        })
      );
    } else {
      /**
       * reset caches API's data on store change and on Lang update
       */

      if (
        action?.type === 'shop/setStoreUUID' ||
        action?.meta?.arg?.endpointName === 'updateUserLocale'
      ) {
        api.dispatch(storeApi.util.resetApiState());
        if (action?.type === 'shop/setStoreUUID') {
          api.dispatch(baseActions.setSkipPermissions(false));
        }
      }

      const successToastEndpoints = [
        'updateUserDetails',
        'resendCode',
        'verifyCodeUser',
        'postPromoCharges',
      ];
      if (
        isFulfilled(action) &&
        successToastEndpoints.includes(action.meta?.arg?.endpointName) &&
        action?.payload?.message
      ) {
        api.dispatch(
          baseActions.setToastMessage({
            message: action?.payload?.message,
            type: 'success',
          })
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
  return next(action);
};

import React from 'react';

import { Card, Stack, Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

export default function KlaviyoActions({
  sendAllEventsTestKlaviyo,
  publicApiKey,
  setPublicApiKeyError,
  selectedLanguage,
}) {
  const [t] = useTranslation();

  const sendTestKlaviyoNotification = () => {
    if (!publicApiKey || isEmpty(trim(publicApiKey))) {
      setPublicApiKeyError(t('klaviyo.modal.public_api_key_error_text'));
      return;
    }
    setPublicApiKeyError(null);
    sendAllEventsTestKlaviyo({
      public_api_key: trim(publicApiKey),
      language: selectedLanguage,
    });
  };
  const text = t('settings.klaviyo.actions.section.description');

  return (
    <Card
      className="klaviyoAction"
      title={t('settings.klaviyo.actions.section.title')}
      sectioned
    >
      <Stack vertical>
        <TranslateLink text={text} />
        <Stack vertical spacing="extraTight">
          <Button
            onClick={sendTestKlaviyoNotification}
            class="alignRight"
            outline
          >
            {t('settings.klaviyo.actions.section.button')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Layout,
  Card,
  Autocomplete,
  Icon,
  Page,
  FooterHelp,
  ContextualSaveBar,
  TextStyle,
  ButtonGroup,
  Button,
  Toast,
  ChoiceList,
} from '@shopify/polaris';
import {
  SearchMinor,
  PlayCircleMajor,
  DeleteMinor,
  EditMinor,
} from '@shopify/polaris-icons';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import SmallTopMessage from 'components/SmallTopMessage';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import {
  useGetGeoLocationsQuery,
  useLazyGetGeoCitiesQuery,
  useGetBlacklistQuery,
  useUpdateBlackListingMutation,
} from '../blackListingApi';
import SkeletonBlackListPage from './SkeletonBlackListPage';
import FindReplaceModal from './FindReplaceModal';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';

export default function Blacklisting() {
  const [t] = useTranslation();

  /*
   * Component States
   * replaceWithOption: emptyLocation | otherCity
   */
  const [replaceWithOption, setReplaceWithOption] = useState('emptyLocation');
  const [showModal, setShowModal] = useState(false);
  const [selectedLocations, setSelectedLocation] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cityInputValue, setCityInputValue] = useState('');
  const [findAndReplace, setFindAndReplace] = useState([]);
  const [findAndReplaceIndex, setFindAndReplaceIndex] = useState(-1);
  const [toastMsg, setToastMsg] = useState('');
  const [formErrors, setFormErrors] = useState({
    geoLocation: false,
    geoCities: false,
  });

  /*
   * Component API calls
   */
  const blackListResponse = useGetBlacklistQuery();
  const {
    data: blacklist,
    isLoading: isBlackListLoading,
    isFetching: isBlackListFetching,
    isSuccess: isBlackListSuccess,
  } = blackListResponse;
  const geoLocationResponse = useGetGeoLocationsQuery();
  const {
    data: geoLocations = [],
    isLoading: isGeoLocationsLoading,
    isSuccess: isGeoLocationsSuccess,
  } = geoLocationResponse;

  const [getGeoCities, citiesResponse] = useLazyGetGeoCitiesQuery();
  const {
    data: geoCities = {},
    isLoading: isCitiesLoading,
    isSuccess: isCitiesSuccess,
  } = citiesResponse;

  const [updateBlackListing, updateResponse] = useUpdateBlackListingMutation();
  const { isLoading: isBlackListUpdating, isSuccess: isBlackListUpdated } =
    updateResponse;

  /*
   * Component Handlers
   */

  useEffect(() => {
    isBlackListUpdated &&
      setToastMsg(t('settings.tracking_page.section_blacklist_updated-toast'));
  }, [isBlackListUpdated]);

  const geoLocationOptions = useMemo(() => {
    if (isGeoLocationsSuccess && Array.isArray(geoLocations)) {
      return geoLocations.map((item) => ({
        value: item.loc_id,
        label: item.label,
      }));
    } else {
      return [];
    }
  }, [geoLocations, isGeoLocationsSuccess]);

  const cityOptions = useMemo(() => {
    if (
      isCitiesSuccess &&
      Array.isArray(geoCities) &&
      cityInputValue &&
      cityInputValue.length > 2
    ) {
      return geoCities.map((item) => ({
        value: item.place_id,
        label: item.label,
      }));
    } else {
      return [];
    }
  }, [geoCities, isCitiesSuccess, cityInputValue]);

  useEffect(() => {
    if (isBlackListSuccess && Object.keys(blacklist).length) {
      setSelectedLocation(blacklist.blacklist_geo || []);
      if (blacklist?.blacklist_location?.place_id) {
        setSelectedCities([blacklist.blacklist_location.place_id]);
        setReplaceWithOption('otherCity');
        blacklist.blacklist_location?.label &&
          setCityInputValue(blacklist.blacklist_location.label);
      } else {
        setSelectedCities([]);
        setReplaceWithOption('emptyLocation');
        setCityInputValue('');
      }

      setFindAndReplace(blacklist.find_and_replace || []);
    }
  }, [isBlackListSuccess, blacklist]);

  const getEditItem = () => {
    if (findAndReplaceIndex === -1) {
      return {
        find: '',
        is_regexp: false,
        replace: '',
      };
    }
    return findAndReplace[findAndReplaceIndex];
  };

  const debouncesGetCities = useCallback(
    debounce((searchVal) => {
      getGeoCities(searchVal);
    }, 500),
    []
  );

  useEffect(() => {
    try {
      if (cityInputValue && cityInputValue.length > 2) {
        debouncesGetCities(cityInputValue);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }, [cityInputValue]);

  const handleLocationsChange = useCallback(
    (selection) => {
      setSelectedLocation(selection);
    },
    [setSelectedLocation]
  );

  const handleCityChange = useCallback(
    (selection) => {
      try {
        const matchedOption = cityOptions.find((option) => {
          return option.value === selection[0];
        });
        setCityInputValue(matchedOption.label);
        setSelectedCities(selection);
      } catch (error) {
        console.log(error);
      }
    },
    [setSelectedCities, setCityInputValue, cityOptions]
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleFindAndReplaceChange = (updated) => {
    const updatedArr = [...findAndReplace];
    if (findAndReplaceIndex === -1) {
      updatedArr.push({ ...updated });
    } else {
      updatedArr.splice(findAndReplaceIndex, 1, {
        ...updated,
      });
    }
    setFindAndReplace(updatedArr);
    toggleModal();
  };

  const validateData = () => {
    let errors = { ...formErrors };
    const geoLocationFilled = !!selectedLocations.length;
    if (replaceWithOption === 'emptyLocation') {
      return true;
    } else {
      const geoCitiesFilled = !!selectedCities.length;
      if (geoLocationFilled !== geoCitiesFilled) {
        if (geoLocationFilled && !geoCitiesFilled) {
          errors.geoCities = true;
        } else if (geoCitiesFilled && !geoLocationFilled) {
          errors.geoLocation = true;
        }
        setFormErrors(errors);

        return false;
      } else {
        setFormErrors({ geoLocation: false, geoCities: false });
        return true;
      }
    }
  };

  const isDirtyState = () => {
    try {
      if (isBlackListSuccess) {
        const bListObj = Object.keys(blacklist).length ? blacklist : {};
        const prev = {
          geoLocation: bListObj.blacklist_geo || [],
          geoCity: bListObj.blacklist_location?.place_id
            ? [bListObj.blacklist_location?.place_id]
            : [],
          findReplace: bListObj.find_and_replace || [],
        };

        const current = {
          geoLocation: selectedLocations,
          geoCity: selectedCities,
          findReplace: findAndReplace,
        };

        return !isEqual(prev, current);
      }
    } catch {
      return false;
    }
  };

  const handleSave = () => {
    if (validateData()) {
      updateBlackListing({
        blacklist: {
          blacklist_geo: selectedLocations || [],
          blacklist_location:
            (Array.isArray(geoCities) &&
              geoCities.length &&
              geoCities.find((option) => {
                return option.place_id === selectedCities[0];
              })) ||
            {},
          find_and_replace: findAndReplace || [],
        },
      });
    }
  };
  const handleDiscard = () => {
    try {
      if (isBlackListSuccess && Object.keys(blacklist).length) {
        const cities = blacklist.blacklist_location?.place_id
          ? [blacklist.blacklist_location?.place_id]
          : [];
        setSelectedLocation(blacklist.blacklist_geo);
        setFindAndReplace(blacklist.find_and_replace);
        if (cities.length) {
          setSelectedCities(cities);
          setCityInputValue(
            (
              (geoCities || []).find((city) => city.place_id === cities[0]) ||
              {}
            ).label || ''
          );
          setReplaceWithOption('otherCity');
        } else {
          setSelectedCities([]);
          setCityInputValue('');
          setReplaceWithOption('emptyLocation');
        }
      } else {
        setSelectedLocation([]);
        setSelectedCities([]);
        setFindAndReplace([]);
        setCityInputValue('');
      }
    } catch {
      return false;
    }
  };

  const deleteFindAndReplace = (index) => {
    let tempFindAndReplace = [...findAndReplace];
    tempFindAndReplace.splice(index, 1);
    setFindAndReplace(tempFindAndReplace);
  };

  const onAddFindAndReplace = () => {
    setFindAndReplaceIndex(-1);
    toggleModal();
  };

  const handleClearButtonClick = () => {
    setCityInputValue('');
    setSelectedCities([]);
  };

  const handleReplaceWithChange = ([option]) => {
    setReplaceWithOption(option);
    if (option === 'emptyLocation') {
      setCityInputValue('');
      setSelectedCities([]);
    }
  };

  /*
   * Component Renderers
   */

  const toastMarkup = useMemo(() => {
    return toastMsg ? (
      <Toast
        content={toastMsg}
        onDismiss={() => {
          setToastMsg('');
        }}
      />
    ) : null;
  }, [toastMsg, setToastMsg]);

  const cityTextField = (
    <Autocomplete.TextField
      onChange={setCityInputValue}
      clearButton
      onClearButtonClick={handleClearButtonClick}
      label={t('settings.tracking_page.section_blacklist_location2_title')}
      value={cityInputValue}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder={t(
        'settings.tracking_page.section_blacklist_location2_title'
      )}
      type="search"
      id="input-blacklist-city"
      error={
        formErrors.geoCities && !selectedCities?.length
          ? t(
              'settings.tracking_page.section_blacklist_location2.empty_error_label'
            )
          : null
      }
    />
  );

  const citiesEmptyState = (
    <TextStyle variation="subdued">
      <div style={{ padding: '2rem' }}>
        <Icon source={SearchMinor} color="subdued" />
        <div style={{ textAlign: 'center' }}>
          {!cityInputValue ||
          (cityInputValue && cityInputValue.length < 3) ||
          isCitiesLoading
            ? 'Keep typing to find any city location you want'
            : 'Could not find any results'}
        </div>
      </div>
    </TextStyle>
  );

  const locationsEmptyState = (
    <TextStyle variation="subdued">
      <div style={{ padding: '2rem' }}>
        <Icon source={SearchMinor} color="subdued" />
        <div style={{ textAlign: 'center' }}>
          {'Could not find any results'}
        </div>
      </div>
    </TextStyle>
  );

  const renderStatusString = () => {
    try {
      if (selectedLocations.length) {
        const tobeReplacedStr = selectedLocations
          .map(
            (option) =>
              ((geoLocationOptions || []).find((c) => c.value === option) || {})
                .label
          )
          .join(', ');

        if (selectedCities.length && replaceWithOption === 'otherCity') {
          return (
            <>
              <br />
              <TextStyle variation="subdued">
                {t(
                  'settings.tracking_page.section_blacklist_replace_with_other_status',
                  {
                    replace_list: tobeReplacedStr,
                    replace_location:
                      (
                        cityOptions.find(
                          (city) => city.value === selectedCities[0]
                        ) || {}
                      ).label || '',
                  }
                )}
              </TextStyle>
            </>
          );
        } else if (replaceWithOption === 'emptyLocation') {
          return (
            <>
              <br />
              <TextStyle variation="subdued">
                {t(
                  'settings.tracking_page.section_blacklist_replace_with_empty_status',
                  {
                    replace_list: tobeReplacedStr,
                  }
                )}
              </TextStyle>
            </>
          );
        }
      }
    } catch {
      console.log('error in renderStatusString');
    }
  };

  return (
    <React.Fragment>
      <SmallTopMessage />
      {isBlackListLoading || isGeoLocationsLoading ? (
        <SkeletonBlackListPage />
      ) : (
        <Page
          title={t('settings.tracking_page.section_blacklist_title')}
          subtitle={
            <Subtitle
              text={t('settings.tracking_page.section_blacklist_subtitle')}
              actions={[
                {
                  text: t(
                    'settings.tracking_page.section_blacklist_get_started'
                  ),
                  source:
                    'https://support.rush.app/hide-and-replace-country-of-origin-from-shipments',
                },
                {
                  text: t(
                    'settings.tracking_page.section_blacklist_watch_video'
                  ),
                  source:
                    'https://www.youtube.com/watch?v=-B7APTjvJC0&ab_channel=Rush',
                  icon: PlayCircleMajor,
                },
              ]}
            />
          }
        >
          <Layout>
            <Layout.AnnotatedSection
              title={t('navigation.item.shipments_configuration')}
              description={t(
                'settings.tracking_page.section_blacklist_description'
              )}
            >
              <Card
                title={t(
                  'settings.tracking_page.section_blacklist_location_title'
                )}
              >
                <Card.Section>
                  <ChoiceList
                    title={t(
                      'settings.tracking_page.section_blacklist_replace_with_title'
                    )}
                    choices={[
                      {
                        label: t(
                          'settings.tracking_page.section_blacklist_empty_location'
                        ),
                        value: 'emptyLocation',
                      },
                      {
                        label: t(
                          'settings.tracking_page.section_blacklist_other_city'
                        ),
                        value: 'otherCity',
                      },
                    ]}
                    selected={[replaceWithOption]}
                    onChange={handleReplaceWithChange}
                  />
                  <br />
                  <AutoCompleteWithTags
                    optionList={geoLocationOptions}
                    selected={selectedLocations}
                    onChange={handleLocationsChange}
                    emptyState={locationsEmptyState}
                    label={t(
                      'settings.tracking_page.section_blacklist_location_input_title'
                    )}
                    placeholder={t(
                      'settings.tracking_page.section_blacklist_location_input_title'
                    )}
                    error={formErrors.geoLocation && !selectedLocations?.length}
                    errorMessage={t(
                      'settings.tracking_page.section_blacklist_location.empty_error_label'
                    )}
                  />
                  {replaceWithOption !== 'emptyLocation' ? (
                    <>
                      <br />
                      <Autocomplete
                        options={cityOptions}
                        selected={selectedCities}
                        onSelect={handleCityChange}
                        textField={cityTextField}
                        loading={isCitiesLoading}
                        emptyState={!isCitiesLoading && citiesEmptyState}
                      />
                    </>
                  ) : (
                    ''
                  )}
                  {renderStatusString()}
                </Card.Section>
                <Card.Section
                  title={t('settings.tracking_page.find_and_replace.title')}
                  actions={[
                    {
                      content: t(
                        'settings.tracking_page.find_and_replace.add_btn_label'
                      ),
                      onAction: onAddFindAndReplace,
                    },
                  ]}
                >
                  <>
                    {findAndReplace &&
                      findAndReplace.map((find_replace, index) => (
                        <div className="SearchReplace-List" key={index}>
                          <div className="SearchReplace-List-Heading">
                            <TextStyle>
                              <i>{find_replace.find}</i>{' '}
                              {t(
                                'settings.tracking_page.find_and_replace_modal.with'
                              )}{' '}
                              <i>{find_replace.replace}</i>
                            </TextStyle>
                          </div>
                          <div className="SearchReplace-List-Buttons">
                            <ButtonGroup segmented>
                              <Button
                                size="slim"
                                onClick={() => {
                                  setFindAndReplaceIndex(index);
                                  toggleModal();
                                }}
                                icon={<Icon source={EditMinor} />}
                              />
                              <Button
                                size="slim"
                                onClick={() => deleteFindAndReplace(index)}
                                icon={<Icon source={DeleteMinor} />}
                              />
                            </ButtonGroup>
                          </div>
                        </div>
                      ))}
                  </>
                  {(!findAndReplace || findAndReplace.length === 0) && (
                    <div className="CustomEmptyState">
                      <TextStyle variation="subdued">
                        {t(
                          'settings.tracking_page.section_blacklist_no_replace_message'
                        )}
                      </TextStyle>
                    </div>
                  )}
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.Section>
              <FooterHelp>
                <TranslateLink text={t('footer_help.dashboard')} />
              </FooterHelp>
            </Layout.Section>
          </Layout>
          {isDirtyState() && (
            <ContextualSaveBar
              message={t('carrier-maskings.unsaved-changes')}
              saveAction={{
                content: t('carrier-maskings.save'),
                onAction: handleSave,
                loading: isBlackListFetching || isBlackListUpdating,
              }}
              discardAction={
                isBlackListFetching || isBlackListUpdating
                  ? null
                  : {
                      onAction: handleDiscard,
                      content: t('common.discard'),
                    }
              }
            />
          )}
          {showModal && (
            <FindReplaceModal
              item={getEditItem()}
              onChange={handleFindAndReplaceChange}
              onClose={toggleModal}
            />
          )}
          {toastMarkup}
        </Page>
      )}
    </React.Fragment>
  );
}

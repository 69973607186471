import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import Billing from './Billing';

function mapStateToProps(state) {
  return {
    billing: state.billing,
    currentPlan: state.billing.currentPlan,
    chargeLimit: state.billing.chargeLimit,
    billingSummary: state.billing.billingSummary,
    loading: state.billing.loading,
    loadingCurrentBillingPlan: state.billing.loadingCurrentBillingPlan,
    billingPlan: state.billing.currentPlan.billing_plan,
    chargeLimitLoading: state.billing.chargeLimitLoading,
    billingSummaryLoading: state.billing.billingSummaryLoading,
    showPromoCode: state.billing.showPromoCode,
    billingPlanError: state.billing.billingPlanError,
    canWriteFeedbackReview: state.billing.canWriteFeedbackReview,
    canWriteFeedbackReviewLoading: state.billing.canWriteFeedbackReviewLoading,
    billingToasterMessage: state.billing.billingToasterMessage,
  };
}

const mapDispatchToProps = {
  getChargeLimit: billingActions.Creators.getChargeLimit,
  getBillingSummary: billingActions.Creators.getBillingSummary,
  disableBillingPlan: billingActions.Creators.disableBillingPlan,
  showPromoCodeModal: billingActions.Creators.showPromoCodeModal,
  showPromoCodeConfirmationModal:
    billingActions.Creators.showPromoCodeConfirmationModal,
  getFeedbackModalCheck: billingActions.Creators.getFeedbackModalCheck,
  showFeedbackModal: billingActions.Creators.showFeedbackModal,
  handleSubscriptionPlansModalChange:
    billingActions.Creators.handleSubscriptionPlansModalChange,
  resetBillingToasterMessage:
    billingActions.Creators.resetBillingToasterMessage,
  getCurrentBillingPlan: billingActions.Creators.getCurrentBillingPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);

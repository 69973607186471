import { createSlice } from '@reduxjs/toolkit';

const defaultToast = {
  message: '',
  type: '',
};

const initialState = {
  appToast: defaultToast,
  skipPermissions: null,
  gtmAppInstall: false,
  gtmAppVisit: false,
  gtmDataSent: false,
  segmentIdentified: false,
  isFullstoryLoaded: false,
};

const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setToastMessage(state, action) {
      state.appToast = action.payload;
    },
    dismissToast(state) {
      state.appToast = defaultToast;
    },
    setSkipPermissions(state, action) {
      state.skipPermissions = action.payload;
    },
    markSegmentIdentified(state, action) {
      state.segmentIdentified = action.payload;
    },
    markGtmDataSentTrue(state, action) {
      state.gtmDataSent = action.payload;
    },
    markGtmAppVisitTrue(state, action) {
      state.gtmAppVisit = action.payload;
    },
    markFullstoryLoaded(state, action) {
      state.isFullstoryLoaded = action.payload;
    },
  },
});

// Actions
export const baseActions = baseSlice.actions;

// Selectors
export const ShowToast = (state) => !!state.base.appToast.message;
export const ToastMessage = (state) => state.base.appToast.message;
export const IsErrorToast = (state) => state.base.appToast.type === 'error';

// Reducer
const base = baseSlice.reducer;
export default base;

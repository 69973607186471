import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  TextStyle,
  Icon,
  Thumbnail,
  Button,
  Badge,
  FooterHelp,
  Stack,
  Loading,
} from '@shopify/polaris';
import Banner from 'components/Banner';
import DetailsSkeleton from './DetailsSkeleton';
import { useTranslation } from 'react-i18next';
import {
  RefreshMinor,
  TickMinor,
  DeleteMinor,
  RedoMajor,
} from '@shopify/polaris-icons';
import { SHIPMENT_ACTION_DELIVERED, SHIPMENT_ACTION_DELETE } from 'Constants';
import _ from 'lodash';
import TranslateLink from 'components/TranslateLink';
import './style.scss';
import ActionModal from './ActionModal';
import SmallTopMessage from 'components/SmallTopMessage';
import moment from 'moment';
import UpdateCarrierModal from '../UpdateCarrierModal';
import ExternalLink from 'components/ExternalLink';
import { STORE_URLS } from 'config/urls';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ShipmentsQueryString } from 'pages/Shipments/shipmentSlice';
import { baseActions } from 'redux/store/baseSlice';
import {
  useGetShipmentsQuery,
  useGetShipmentStatusesQuery,
  useReTrackShipmentsMutation,
  useDeleteShipmentsMutation,
  useMarkAsDeliveredMutation,
} from 'pages/Shipments/shipmentsApi';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { getColumns, getDefaultFilters, getQueryString } from '../helpers';
import { useGetShipmentDetailsQuery } from 'pages/Shipments/shipmentsApi';

export default function ShipmentDetail() {
  const [t] = useTranslation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const shipmentsQueryString = useSelector(ShipmentsQueryString);

  const [showActionModal, setShowActionModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [showCarrierModal, setShowCarrierModal] = useState(false);
  const [refreshLoader, setManualRefreshLoader] = useState(false);
  const {
    data: statusesRes = [],
    isFetching: isStatusesLoading,
    isSuccess: isStatusesLoaded,
  } = useGetShipmentStatusesQuery();

  const shipmentStatuses = useMemo(() => {
    if (!isStatusesLoading && isStatusesLoaded) {
      return statusesRes.statuses;
    }
    return [];
  }, [statusesRes, isStatusesLoading, isStatusesLoaded]);

  const columns =
    ClientStorage.get(LOCAL_STORAGE.SHIPMENT_COLUMNS) || getColumns(t);
  const shpmentsQuery = getQueryString(
    1,
    getDefaultFilters(),
    shipmentStatuses,
    columns
  );
  const shipmentsResponse = useGetShipmentsQuery(
    shipmentsQueryString || shpmentsQuery,
    {
      skip: _.isEmpty(shipmentStatuses),
    }
  );

  const { data: { data: shipments = [] } = {}, isLoading: shipmentsLoading } =
    shipmentsResponse;

  const {
    data: { shipment: shipmentDetail = {} } = {},
    isLoading: isDetailLoading,
    isFetching: isDetailFetching,
    isSuccess: isDetailLoaded,
    refetch: getShipmentDetails,
  } = useGetShipmentDetailsQuery(params.shipmentId);

  const [markShipmentAsDelivered, deliveredResponse] =
    useMarkAsDeliveredMutation();
  const {
    data: markDeliveredData,
    isLoading: isMarkingDelivered,
    isSuccess: isMarkedDelivered,
  } = deliveredResponse;
  useEffect(() => {
    if (!isMarkingDelivered && isMarkedDelivered) {
      dispatch(
        baseActions.setToastMessage({
          message: markDeliveredData.message,
          type: 'success',
        })
      );
    }
  }, [isMarkingDelivered, isMarkedDelivered]);

  const [deleteShipment, deleteResponse] = useDeleteShipmentsMutation();
  const { isLoading: isDeleting, isSuccess: isDeleted } = deleteResponse;

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipments.delete.toster_message'),
          type: 'success',
        })
      );
      history.push(`/s/${params.uuid}${STORE_URLS.SHIPMENTS}`);
    }
  }, [isDeleting, isDeleted]);

  const [reTrackShipment, reTrackShipmentResponse] =
    useReTrackShipmentsMutation();
  const { isLoading: isReTracking, isSuccess: isReTracked } =
    reTrackShipmentResponse;

  useEffect(() => {
    if (!isReTracking && isReTracked) {
      dispatch(
        baseActions.setToastMessage({
          message: t('shipment_detail.retrack.toster_message'),
          type: 'success',
        })
      );
    }
  }, [isReTracking, isReTracked]);

  const handleModalChange = useCallback(
    () => setShowActionModal(!showActionModal),
    [showActionModal]
  );

  const handleRefresh = () => {
    setManualRefreshLoader(true);
    getShipmentDetails();
  };

  const secondaryActions = useMemo(() => {
    if (isDetailLoaded && shipmentDetail && !isDetailLoading) {
      let actions = [];

      if (shipmentDetail.actions?.includes('can_refresh')) {
        actions.push({
          content: t('shipment_detail.actions.refresh'),
          accessibilityLabel: t('shipment_detail.actions.refresh'),
          icon: () => {
            return <Icon source={RefreshMinor} color="inkLighter" />;
          },
          onAction: handleRefresh,
          loading: refreshLoader,
        });
      }
      if (shipmentDetail.actions?.includes('can_retrack')) {
        actions.push({
          content: t('shipment_detail.actions.retrack'),
          accessibilityLabel: t('shipment_detail.actions.retrack'),
          icon: () => {
            return <Icon source={RedoMajor} color="inkLighter" />;
          },
          onAction: () => {
            reTrackShipment([params.shipmentId]);
          },
        });
      }
      if (shipmentDetail.actions?.includes('can_mark_as_delivered')) {
        actions.push({
          content: t('shipment_detail.actions.mark_as_delivered'),
          accessibilityLabel: t('shipment_detail.actions.mark_as_delivered'),
          icon: () => {
            return <Icon source={TickMinor} color="inkLighter" />;
          },
          onAction: () => {
            setModalAction(SHIPMENT_ACTION_DELIVERED);
            handleModalChange();
          },
        });
      }
      if (shipmentDetail.actions?.includes('can_delete')) {
        actions.push({
          content: t('shipment_detail.actions.delete'),
          accessibilityLabel: t('shipment_detail.actions.delete'),
          icon: () => {
            return <Icon source={DeleteMinor} color="inkLighter" />;
          },
          onAction: () => {
            setModalAction(SHIPMENT_ACTION_DELETE);
            handleModalChange();
          },
        });
      }
      return actions;
    }
    return [];
  }, [isDetailLoaded, shipmentDetail, isDetailLoading, refreshLoader]);

  useEffect(() => {
    if (!isDetailFetching && isDetailLoaded) {
      setManualRefreshLoader(false);
    }
  }, [isDetailLoaded, isDetailFetching]);
  const getCurrentShipmentIndex = () => {
    return shipments.findIndex(({ uuid }) => uuid === params.shipmentId);
  };
  const hasPreviousShipment = () => {
    return (
      !isDetailFetching && shipments?.length && getCurrentShipmentIndex() > 0
    );
  };
  const hasNextShipment = () => {
    return (
      !isDetailFetching &&
      shipments?.length &&
      getCurrentShipmentIndex() < shipments?.length
    );
  };

  const getPreviousShipment = () => {
    try {
      const prevShipment = shipments[getCurrentShipmentIndex() - 1];
      history.push(
        `/s/${params.uuid}${STORE_URLS.SHIPMENTS}/${prevShipment?.uuid}`
      );

      // eslint-disable-next-line no-empty
    } catch {}
  };
  const getNextShipment = () => {
    try {
      const nextShipment = shipments[getCurrentShipmentIndex() + 1];
      history.push(
        `/s/${params.uuid}${STORE_URLS.SHIPMENTS}/${nextShipment?.uuid}`
      );
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const handleModalAction = () => {
    if (modalAction === SHIPMENT_ACTION_DELIVERED) {
      markShipmentAsDelivered([params.shipmentId]);
    } else if (modalAction === SHIPMENT_ACTION_DELETE) {
      deleteShipment([params.shipmentId]);
    }
    handleModalChange();
  };

  return isDetailLoading || shipmentsLoading ? (
    <DetailsSkeleton />
  ) : (
    <>
      <SmallTopMessage />
      {isMarkingDelivered || isDeleting || isReTracking || isDetailFetching ? (
        <Loading />
      ) : (
        ''
      )}
      <Page
        title={shipmentDetail.title}
        secondaryActions={secondaryActions}
        fullWidth={false}
        narrowWidth={false}
        pagination={{
          hasPrevious: hasPreviousShipment(),
          onPrevious: getPreviousShipment,
          hasNext: hasNextShipment(),
          onNext: getNextShipment,
        }}
        breadcrumbs={[
          {
            content: t('navigation.item.shipments'),
            url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            {shipmentDetail.checkpoints && !_.isEmpty(shipmentStatuses) && (
              <Card sectioned>
                {shipmentDetail.checkpoints?.map((checkpoint, index) => {
                  const status = shipmentStatuses
                    .map((mapStatus) => {
                      if (mapStatus.id === checkpoint.status) {
                        return mapStatus;
                      }
                      return undefined;
                    })
                    .filter((item) => !_.isUndefined(item))[0];
                  let statusLabel = checkpoint.status_label;

                  return (
                    <div className="ShipmentDetail-Checkpoint" key={index}>
                      <Stack vertical spacing="extraTight">
                        <Stack alignment="trailing" spacing="tight">
                          <Badge
                            status={status.badge_prop.status}
                            progress={status.badge_prop.progress}
                          >
                            {statusLabel}
                          </Badge>
                          <div>
                            {moment(checkpoint.timestamp).format(
                              'MMMM DD, YYYY [at] hh:mm A'
                            )}
                          </div>
                        </Stack>
                        <Stack>
                          <TextStyle variation="subdued">
                            <div>
                              {checkpoint.message}
                              {checkpoint.location && <> - </>}
                              {checkpoint.location && checkpoint.location.city
                                ? `${checkpoint.location.city}, `
                                : ''}
                              {checkpoint.location && checkpoint.location.state
                                ? `${checkpoint.location.state}, `
                                : ''}
                              {checkpoint.location &&
                              checkpoint.location.country
                                ? `${checkpoint.location.country}, `
                                : ''}
                              {checkpoint.description
                                ? checkpoint.description
                                : ''}
                            </div>
                          </TextStyle>
                        </Stack>
                      </Stack>
                    </div>
                  );
                })}
              </Card>
            )}
            {shipmentDetail.banner && (
              <Banner bannerObject={shipmentDetail.banner} />
            )}
            {!shipmentDetail.checkpoints && !shipmentDetail.banner && (
              <Card sectioned>
                <div className="CustomEmptyState">
                  <TextStyle variation="subdued">
                    {t('shipment_detail.no_checkpoints_message')}
                  </TextStyle>
                </div>
              </Card>
            )}
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              {shipmentDetail.carrier && (
                <Card.Section>
                  <div className="ShipmentDetail-RightSection">
                    {shipmentDetail.carrier.logo && (
                      <div className="ShipmentDetail-CarrierDetail-Image">
                        <Thumbnail
                          source={shipmentDetail.carrier.logo}
                          alt={shipmentDetail.carrier.name}
                          size="small"
                        />
                      </div>
                    )}
                    <div className="ShipmentDetail-InfoSection">
                      <div className="ShipmentDetail-InfoHeader">
                        <div className="ShipmentDetail-CarrierHeading">
                          <div>
                            {shipmentDetail.carrier && (
                              <TextStyle variation="strong">
                                {shipmentDetail.carrier.name}
                              </TextStyle>
                            )}
                          </div>
                          {shipmentDetail.actions?.includes(
                            'can_update_carrier'
                          ) && (
                            <div className="ShipmentDetail-InfoAction">
                              <Button
                                plain
                                onClick={() => {
                                  setShowCarrierModal(true);
                                }}
                              >
                                {t('shipment_detail.change_carrier.title')}
                              </Button>
                            </div>
                          )}
                        </div>
                        {shipmentDetail.carrier?.tracking_number && (
                          <div>
                            {shipmentDetail.carrier.tracking_link && (
                              <ExternalLink
                                url={shipmentDetail.carrier.tracking_link}
                              >
                                {shipmentDetail.carrier.tracking_number}
                              </ExternalLink>
                            )}
                            {!shipmentDetail.carrier.tracking_link && (
                              <>{shipmentDetail.carrier.tracking_number}</>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Section>
              )}
              {shipmentDetail.last_mile_carrier && (
                <Card.Section
                  title={t('shipment_detail.last_mile_carrier.title')}
                >
                  <div className="ShipmentDetail-RightSection">
                    {shipmentDetail.last_mile_carrier.logo && (
                      <div className="ShipmentDetail-CarrierDetail-Image">
                        <Thumbnail
                          source={shipmentDetail.last_mile_carrier.logo}
                          alt={shipmentDetail.last_mile_carrier.name}
                          size="small"
                        />
                      </div>
                    )}
                    <div className="ShipmentDetail-InfoSection">
                      <div className="ShipmentDetail-InfoHeader">
                        <div className="ShipmentDetail-CarrierHeading">
                          <div>
                            <TextStyle variation="strong">
                              {shipmentDetail.last_mile_carrier?.name}
                            </TextStyle>
                          </div>
                        </div>
                        {shipmentDetail.last_mile_carrier?.tracking_number && (
                          <div>
                            {shipmentDetail.last_mile_carrier.tracking_link && (
                              <ExternalLink
                                url={
                                  shipmentDetail.last_mile_carrier.tracking_link
                                }
                              >
                                {
                                  shipmentDetail.last_mile_carrier
                                    .tracking_number
                                }
                              </ExternalLink>
                            )}
                            {!shipmentDetail.last_mile_carrier
                              .tracking_link && (
                              <>
                                {
                                  shipmentDetail.last_mile_carrier
                                    .tracking_number
                                }
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Section>
              )}
              {shipmentDetail.sidebar?.sections.map((section, index) => (
                <Card.Section title={section.title} key={index}>
                  {section.key_value_pairs &&
                    section.key_value_pairs.map(
                      (sectionItems, sectionIndex) => (
                        <div
                          className="ShipmentDetail-LeftSection-Section"
                          key={sectionIndex}
                        >
                          <div>
                            <TextStyle
                              variation={
                                sectionItems.label_subdued ? 'subdued' : ''
                              }
                            >
                              {sectionItems.label}:
                            </TextStyle>
                          </div>
                          <div className="ShipmentDetail-LeftSection-Value">
                            <TextStyle
                              variation={
                                sectionItems.value_subdued ? 'subdued' : ''
                              }
                            >
                              <TranslateLink text={sectionItems.value} />
                            </TextStyle>
                          </div>
                        </div>
                      )
                    )}
                  {section.text_container && (
                    <TranslateLink
                      text={section.text_container.replace(/\n\r/g, '<br />')}
                    />
                  )}
                </Card.Section>
              ))}
            </Card>
          </Layout.Section>
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>

      {showActionModal && (
        <ActionModal
          modalAction={modalAction}
          onClose={handleModalChange}
          shipmentTrackNumber={shipmentDetail.carrier?.tracking_number}
          onAction={handleModalAction}
        />
      )}

      {showCarrierModal && (
        <UpdateCarrierModal
          initializer={'shipment_detail'}
          shipmentTrackNumber={shipmentDetail.carrier?.tracking_number}
          shipmentCarrier={shipmentDetail.carrier.slug}
          shipmentId={params.shipmentId}
          onClose={() => {
            setShowCarrierModal(false);
          }}
        />
      )}
    </>
  );
}

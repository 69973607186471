import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import SaveBar from './SaveBar';

function mapStateToProps(state) {
  return {
    trackingDetail: state.trackingDetail,
    trackingDetailLoading: state.trackingDetail.loading,
  };
}

const mapDispatchToProps = {
  saveTrackingPageDetail: trackingActions.Creators.saveTrackingPageDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveBar);

const { createReducer } = require('reduxsauce');
const initialState = require('./visitor.initialState');
const visitorActions = require('./visitor.actions');
const { VISITOR_URLS } = require('config/urls');
const { LOCAL_STORAGE } = require('Constants');
const { ClientStorage } = require('ClientStorage');
const i18n = require('i18n').default;
const stringHelper = require('helpers/string').default;

const getIpAddress = (state) => ({ ...state, loading: false });
const getIpAddressSuccess = (state, action) => ({
  ...state,
  ipAddress: action.ip,
  loading: false,
});
const getIpAddressFailure = (state) => ({
  ...state,
  ipAddress: null,
  loading: false,
});

const getVisitorLanguages = (state) => ({ ...state, loading: true });
const getVisitorLanguagesSuccess = (state, action) => {
  let language = ClientStorage.get(LOCAL_STORAGE.LANGUAGE);

  if (!language) {
    language = 'en';
    ClientStorage.set(LOCAL_STORAGE.LANGUAGE, 'en');
  }

  const lang = action.data.find(
    ({ language_iso_639 }) => language_iso_639 === language
  );

  return {
    ...state,
    loading: false,
    languages: action.data,
    selectedLanguage: lang?.uuid,
    selectedLanguageCode: lang?.language_iso_639,
  };
};
const getVisitorLanguagesFailure = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error?.message,
  };
};

const getAffiliatesCampaign = (state) => ({
  ...state,
  loading: true,
  campaignMessage: null,
});
const getAffiliatesCampaignSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    campaignMessage: action.data.message,
  };
};
const getAffiliatesCampaignFailure = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error?.message,
  };
};

const changeVisitorLanguage = (state, action) => {
  const newLanguage = state.languages.find(
    ({ uuid }) => uuid === action.params
  );
  i18n.changeLanguage(newLanguage.language_iso_639);
  ClientStorage.set(LOCAL_STORAGE.LANGUAGE, newLanguage.language_iso_639);

  return {
    ...state,
    selectedLanguage: action.params,
    selectedLanguageCode: newLanguage.language_iso_639,
  };
};

const login = (state) => ({ ...state, loading: true, registeredUser: null });
const loginSuccess = (state, action) => ({
  ...state,
  loading: false,
  accessToken: action.data.access_token,
  refreshToken: action.data.refresh_token,
});
const loginFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const resetVisitorToast = (state) => ({
  ...state,
  toast: {
    message: null,
    error: null,
  },
});

const register = (state) => ({ ...state, loading: true });
const registerSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    newEmail: action.data.new_contact.email,
    registeredUser: {
      email: action.data.new_contact.email,
      password: action.data.password,
      verified: false,
    },
    verificationToken: action.data.token,
  };
};
const registerFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const resendVerificationCode = (state) => ({ ...state, loading: true });
const resendVerificationCodeSuccess = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: null, message: action.data.message },
});
const resendVerificationCodeFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const verifyCode = (state) => ({ ...state, loading: true });
const verifyCodeSuccess = (state, action) => ({
  ...state,
  loading: false,
  toast: {
    error: null,
    message: action.data.message,
    redirect: VISITOR_URLS.LOGIN,
  },
  newEmail: null,
  verificationToken: null,
  registeredUser: {
    ...state.registeredUser,
    verified: true,
  },
});
const verifyCodeFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const forgotPassword = (state) => ({ ...state, loading: true });
const forgotPasswordSuccess = (state, action) => ({
  ...state,
  loading: false,
  newEmail: action.data.email,
  toast: { error: null, message: action.data.message },
});
const forgotPasswordFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const resetPassword = (state) => ({ ...state, loading: true });
const resetPasswordSuccess = (state, action) => ({
  ...state,
  loading: false,
  accessToken: action.data.access_token,
  refreshToken: action.data.refresh_token,
});
const resetPasswordFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const resetNewEmail = (state) => ({ ...state, newEmail: null });

const updateEmail = (state) => ({ ...state, loading: true });
const updateEmailSuccess = (state, action) => ({
  ...state,
  loading: false,
  toast: {
    error: null,
    message: action.data.message,
    redirectToEmailValidation: true,
  },
  registeredUser: {
    ...state.registeredUser,
    email: action.data.email,
  },
});
const updateEmailFailure = (state, action) => ({
  ...state,
  loading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const getAccessToken = (state) => ({ ...state, accessTokenLoading: true });
const getAccessTokenSuccess = (state, action) => ({
  ...state,
  accessTokenLoading: false,
  accessToken: action.data,
});
const getAccessTokenFailure = (state, action) => ({
  ...state,
  accessTokenLoading: false,
  toast: { error: stringHelper.getErrorText(action), message: null },
});

const logout = (state, action) => {
  const newLanguage = state.languages?.find(
    ({ language_iso_639 }) => language_iso_639 === action.params
  );

  return {
    ...state,
    loading: true,
    selectedLanguage: newLanguage?.uuid,
    selectedLanguageCode: action.params,
  };
};
const logoutSuccess = (state) => ({
  ...state,
  loading: false,
  accessToken: null,
  toast: {
    error: null,
    message_id: 'visitor-app:signin_screen.logout_successful',
  },
});
const logoutFailure = (state) => ({ ...state, loading: false });

const { Types } = visitorActions;
const HANDLERS = {
  [Types.GET_IP_ADDRESS]: getIpAddress,
  [Types.GET_IP_ADDRESS_SUCCESS]: getIpAddressSuccess,
  [Types.GET_IP_ADDRESS_FAILURE]: getIpAddressFailure,

  [Types.GET_VISITOR_LANGUAGES]: getVisitorLanguages,
  [Types.GET_VISITOR_LANGUAGES_SUCCESS]: getVisitorLanguagesSuccess,
  [Types.GET_VISITOR_LANGUAGES_FAILURE]: getVisitorLanguagesFailure,

  [Types.GET_AFFILIATES_CAMPAIGN]: getAffiliatesCampaign,
  [Types.GET_AFFILIATES_CAMPAIGN_SUCCESS]: getAffiliatesCampaignSuccess,
  [Types.GET_AFFILIATES_CAMPAIGN_FAILURE]: getAffiliatesCampaignFailure,

  [Types.CHANGE_VISITOR_LANGUAGE]: changeVisitorLanguage,

  [Types.RESET_VISITOR_TOAST]: resetVisitorToast,

  [Types.LOGIN]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.REGISTER]: register,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,

  [Types.RESEND_VERIFICATION_CODE]: resendVerificationCode,
  [Types.RESEND_VERIFICATION_CODE_SUCCESS]: resendVerificationCodeSuccess,
  [Types.RESEND_VERIFICATION_CODE_FAILURE]: resendVerificationCodeFailure,

  [Types.VERIFY_CODE]: verifyCode,
  [Types.VERIFY_CODE_SUCCESS]: verifyCodeSuccess,
  [Types.VERIFY_CODE_FAILURE]: verifyCodeFailure,

  [Types.FORGOT_PASSWORD]: forgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.RESET_NEW_EMAIL]: resetNewEmail,

  [Types.RESET_PASSWORD]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.UPDATE_EMAIL]: updateEmail,
  [Types.UPDATE_EMAIL_SUCCESS]: updateEmailSuccess,
  [Types.UPDATE_EMAIL_FAILURE]: updateEmailFailure,

  [Types.GET_ACCESS_TOKEN]: getAccessToken,
  [Types.GET_ACCESS_TOKEN_SUCCESS]: getAccessTokenSuccess,
  [Types.GET_ACCESS_TOKEN_FAILURE]: getAccessTokenFailure,

  [Types.LOGOUT]: logout,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGOUT_FAILURE]: logoutFailure,
};

module.exports = createReducer(initialState, HANDLERS);

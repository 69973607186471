import {
  Button,
  ButtonGroup,
  Card,
  Icon,
  Link,
  Loading,
  Modal,
  RangeSlider,
  Spinner,
  Stack,
  TextStyle,
  Tooltip,
} from '@shopify/polaris';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPlansQuery, useSubscripPlanMutation } from '../billingApis';
import { billingActions } from '../billingSlice';
import stringHelper from 'helpers/string';
import { featuresList, transformToNumStr } from './utils';
import { useGetStoreStatisticsQuery } from 'redux/store/commonBaseApis';
import {
  CircleCancelMajor,
  MinusMinor,
  TickSmallMinor,
} from '@shopify/polaris-icons';
import './styles.scss';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';

function UpdatePlanModal() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const storeUUID = useSelector((state) => state.shop?.storeUUID);
  const { showUpdateModal } = useSelector((state) => state.billing1);

  const [selectedPeriod, setSelectedPeriod] = useState('annual');
  const [rangeValue, setRangeValue] = useState(1);

  const { data: currentPlan = {} } = useGetCurrentBillingPlanQuery();

  const [
    subscripPlan,
    { isLoading: isSubscripingPlan, isSuccess, data: subscriptionResponse },
  ] = useSubscripPlanMutation();

  useEffect(() => {
    if (!isSubscripingPlan && isSuccess) {
      window.top.location.replace(subscriptionResponse.charge_url);
    }
  }, [isSubscripingPlan, isSuccess, subscriptionResponse]);

  const {
    data: plansInfo = {},
    isLoading: isPlansLoading,
    isSuccess: isPlansLoaded,
  } = useGetPlansQuery();

  const {
    data: statisticsData = {},
    isSuccess: isStatisticsLoaded,
    isLoading: isStatisticsLoading,
    isError,
  } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID || !showUpdateModal,
  });

  const isMonthlyPlan = useMemo(() => {
    return currentPlan?.billing_plan?.subscription_plan
      ?.is_charge_limit_supported;
  }, [currentPlan, showUpdateModal]);

  const appliedPeriod = useMemo(() => {
    return isMonthlyPlan ? '30_days' : 'annual';
  }, [isMonthlyPlan]);

  useEffect(() => {
    if (isMonthlyPlan) {
      setSelectedPeriod('30_days');
    } else {
      setSelectedPeriod('annual');
    }
  }, [isMonthlyPlan]);

  const freePlanId = useMemo(() => {
    return plansInfo?.meta?.footer_buttons?.find(
      ({ id }) => id === 'downgrade_to_free_plan'
    )?.config?.subscription_plan_id;
  }, [plansInfo]);

  const planGroups = useMemo(() => {
    if (isPlansLoaded && plansInfo && showUpdateModal) {
      return groupBy(plansInfo?.subscription_plans, 'billing_interval');
    }
  }, [isPlansLoaded, plansInfo, showUpdateModal]);

  const currentPlanGroup = useMemo(() => {
    if (planGroups) {
      return planGroups[selectedPeriod];
    } else {
      return [];
    }
  }, [planGroups, selectedPeriod]);

  const quotaPriceGroupRanges = useMemo(() => {
    return (currentPlanGroup || []).reduce(
      (prev, next) => {
        const quotaFeature = next?.plan_features?.find(
          ({ feature_id }) => feature_id === 2
        );
        const priceFeature = next?.plan_features?.find(
          ({ feature_id }) => feature_id === 1
        );
        prev[quotaFeature.quota] = {
          price: priceFeature.price,
          group: next?.group_slug,
          name: next?.name,
          planId: next?.id,
        };
        return prev;
      },
      selectedPeriod === '30_days'
        ? {
            [50]: {
              price: 0,
              group: 'basic',
              name: 'Free',
              planId: freePlanId,
            },
          }
        : {}
    );
  }, [currentPlanGroup]);

  const suggestedIndex = useMemo(() => {
    if (
      !isStatisticsLoading &&
      isStatisticsLoaded &&
      statisticsData?.statistics &&
      showUpdateModal
    ) {
      try {
        const ms = selectedPeriod === 'annual' ? 12 : 1;
        const currentUsage =
          statisticsData.statistics?.platform_orders_per_month || 1;
        const nextShipments = currentUsage * 1.1 * ms;
        const suggestedIndex = Object.keys(quotaPriceGroupRanges).findIndex(
          (quota) => quota > nextShipments
        );
        return suggestedIndex;
      } catch (e) {
        return 0;
      }
    }
  }, [
    isStatisticsLoading,
    isStatisticsLoaded,
    statisticsData,
    selectedPeriod,
    quotaPriceGroupRanges,
    showUpdateModal,
  ]);

  const appliedPlanInfo = useMemo(() => {
    const appliedQuota = currentPlan.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === 2
    )?.quota;
    return {
      ...currentPlan.billing_plan?.subscription_plan,
      quota: appliedQuota,
    };
  }, [currentPlan, showUpdateModal]);

  const appliedPlanIndex = useMemo(() => {
    const currentPlanIndex = Object.keys(quotaPriceGroupRanges).findIndex(
      (quota) => Number(quota) === Number(appliedPlanInfo.quota)
    );
    return currentPlanIndex > -1 ? currentPlanIndex : 0;
  }, [appliedPlanInfo]);

  useEffect(() => {
    if (currentPlan.billing_plan && showUpdateModal) {
      if (freePlanId === appliedPlanInfo?.id) {
        setRangeValue(suggestedIndex);
      } else {
        setRangeValue(appliedPlanIndex);
      }
    }
  }, [appliedPlanInfo, showUpdateModal, appliedPlanIndex]);

  const quotaRanges = useMemo(() => {
    return Object.keys(quotaPriceGroupRanges || {});
  }, [quotaPriceGroupRanges]);

  const handleClose = useCallback(() => {
    dispatch(billingActions.toggleUpdateModal(false));
  }, []);

  const handleRangeSliderChange = useCallback(
    (value) => setRangeValue(value),
    []
  );

  const startButtonLabel = useMemo(() => {
    return plansInfo?.meta?.subscribe_button_cta;
  }, [plansInfo]);

  const setSuggestedQuota = () => {
    setRangeValue(suggestedIndex);
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [isError]);

  return (
    <>
      {isSubscripingPlan ? <Loading /> : ''}

      <Modal open={showUpdateModal} onClose={handleClose} large>
        {isPlansLoading ? (
          <Card sectioned>
            <br />
            <br />
            <Stack alignment="center" distribution="center">
              <Spinner />
            </Stack>
            <br />
            <br />
          </Card>
        ) : (
          <Card
            title={
              <Stack>
                <Stack.Item fill>
                  <Stack alignment="center">
                    <h2 className="Polaris-Heading">
                      {t('billing.update_plan_title', {
                        period_label:
                          selectedPeriod === '30_days'
                            ? t('billing_intervals.monthly')
                            : t('billing_intervals.yearly'),
                      })}
                      ?
                    </h2>
                    <div className="m-close-button">
                      <Button
                        plain
                        icon={CircleCancelMajor}
                        onClick={handleClose}
                      />
                    </div>
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <ButtonGroup segmented>
                    <Button
                      primary={selectedPeriod === '30_days'}
                      onClick={() => {
                        setSelectedPeriod('30_days');
                        setRangeValue(isMonthlyPlan ? appliedPlanIndex : 0);
                      }}
                    >
                      {plansInfo?.groups?.monthly?.caption}
                    </Button>
                    <Button
                      primary={selectedPeriod === 'annual'}
                      onClick={() => {
                        setSelectedPeriod('annual');
                        setRangeValue(!isMonthlyPlan ? appliedPlanIndex : 0);
                      }}
                    >
                      {plansInfo?.groups?.annual?.caption}
                    </Button>
                  </ButtonGroup>
                </Stack.Item>
              </Stack>
            }
          >
            <Card.Section>
              <RangeSlider
                label=""
                min={0}
                max={quotaRanges.length - 1}
                value={rangeValue}
                onChange={handleRangeSliderChange}
                prefix={<p>{transformToNumStr(quotaRanges[0])}</p>}
                suffix={
                  <p>
                    {transformToNumStr(quotaRanges[quotaRanges.length - 1])}
                  </p>
                }
              />
              <Stack vertical>
                <TextStyle variation="subdued">
                  <Trans
                    i18nKey="billing.quota_message"
                    values={{
                      quota: transformToNumStr(quotaRanges[suggestedIndex]),
                    }}
                    components={[<Link onClick={setSuggestedQuota} />]}
                  ></Trans>
                </TextStyle>
                {selectedPeriod === '30_days' ? (
                  <TextStyle>
                    <Trans
                      i18nKey="billing.payment_stats_extra"
                      values={{
                        quota: transformToNumStr(quotaRanges[rangeValue]),
                        price: stringHelper.transformToCurrency(
                          quotaPriceGroupRanges[quotaRanges[rangeValue]]?.price
                        ),
                        extra: '$0.07',
                        period: t('billing_intervals.monthly'),
                      }}
                      components={[<strong />]}
                    ></Trans>
                  </TextStyle>
                ) : (
                  <TextStyle>
                    <Trans
                      i18nKey="billing.payment_stats"
                      values={{
                        quota: transformToNumStr(quotaRanges[rangeValue]),
                        price: stringHelper.transformToCurrency(
                          quotaPriceGroupRanges[quotaRanges[rangeValue]]?.price
                        ),
                        period: t('billing_intervals.yearly'),
                      }}
                      components={[<strong />]}
                    ></Trans>
                  </TextStyle>
                )}
                <ButtonGroup>
                  <Button
                    primary
                    onClick={() => {
                      subscripPlan(
                        quotaPriceGroupRanges[quotaRanges[rangeValue]]?.planId
                      );
                    }}
                    loading={isSubscripingPlan}
                    disabled={
                      appliedPlanIndex === rangeValue &&
                      selectedPeriod === appliedPeriod
                    }
                  >
                    {startButtonLabel}
                  </Button>
                  <Button
                    onClick={() => {
                      window.open(
                        ' https://web.rush.app/contact-sales?utm_source=app.rush.app&utm_medium=app.rush.app',
                        '_blank'
                      );
                    }}
                  >
                    {t('billing.talk_to_sales')}
                  </Button>
                </ButtonGroup>
              </Stack>
            </Card.Section>

            <Card.Section>
              <Stack>
                <Stack.Item fill>
                  <TextStyle variation="strong">
                    {t('billing.included_in_plan', {
                      plan: quotaPriceGroupRanges[quotaRanges[rangeValue]]
                        ?.name,
                    })}
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <Button
                    plain
                    onClick={() => {
                      window.open(
                        'https://web.rush.app/pricing?shipments=2000&period=monthly&utm_source=app.rush.app&utm_medium=app.rush.app',
                        '_blank'
                      );
                    }}
                  >
                    {t('billing.full_comparison')}
                  </Button>
                </Stack.Item>
              </Stack>
              <br />
              <div className="features-container">
                {featuresList(
                  quotaPriceGroupRanges[quotaRanges[rangeValue]]?.group
                ).map(({ title, tooltip, enabled }) => (
                  <Tooltip content={tooltip}>
                    <Stack spacing="extraTight" alignment="center">
                      <Icon
                        source={enabled ? TickSmallMinor : MinusMinor}
                        color={enabled ? 'primary' : 'subdued'}
                      />
                      <TextStyle variation={enabled ? 'positive' : 'subdued'}>
                        <div className="usage">{title}</div>
                      </TextStyle>
                    </Stack>
                  </Tooltip>
                ))}
              </div>
            </Card.Section>
          </Card>
        )}
      </Modal>
    </>
  );
}

export default UpdatePlanModal;

import { connect } from 'react-redux';
import TopBarMarkup from './TopBarMarkup';
import { visitorActions } from 'redux/visitor';
import { shopActions } from 'redux/store/shopSlice';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    accessToken: state.visitor.accessToken,
  };
}

const mapDispatchToProps = {
  logout: visitorActions.Creators.logout,
  setStoreUUID: shopActions.setStoreUUID,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopBarMarkup);

import React, { useState, useCallback, useEffect } from 'react';
import { Page, Layout, Loading, FooterHelp } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import SmallTopMessage from 'components/SmallTopMessage';
import { useHistory } from 'react-router-dom';
import SaveBar from '../KlaviyoPage/SaveBar';
import OmnisendDetails from './OmnisendDetails';
import OmnisendActions from './OmnisendActions';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import OmnisendPageCustomization from './OmnisendPageCustomization';
import isNull from 'lodash/isNull';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import SettingsToaster from '../SettingsToaster';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import i18next from 'i18next';

export default function OmnisendPage({
  getOmnisend,
  saveOmnisend,
  loading,
  omnisendConfig,
  omnisendMeta,
  omnisendError,
  resetOmnisend,
  omnisendConfigSaved,
  // omnisendTestingConfirmation,
  // omnisendTestingConfirmationMessage,
  shop,
  featureRequest,
  resetFeatureRequest,
  loadingFeatureId,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  const [localOmnisendObj, setLocalOmnisendObj] = useState({});

  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);

  const [selectedTrigger, setSelectedTrigger] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (omnisendConfigSaved) {
      getOmnisend();
      // privateApiKey && getKlaviyoSummaryFlow(privateApiKey);
      resetOmnisend();
    }
  }, [
    omnisendConfigSaved,
    t,
    resetOmnisend,
    getOmnisend,
    // getKlaviyoSummaryFlow,
    // privateApiKey,
  ]);

  useEffect(() => {
    resetOmnisend();
    getOmnisend();
  }, [getOmnisend, resetOmnisend, i18next.language]);

  useEffect(() => {
    if (featureRequest) {
      setIsUpdated(false);
      getOmnisend();
      resetFeatureRequest();
      setApiKeyError(false);
    }
  }, [getOmnisend, resetFeatureRequest, featureRequest]);

  // useEffect(() => {
  //   if (klaviyoFlowSummary) {
  //     const { refreshed_at = '' } = klaviyoFlowSummary;
  //     setRefreshedAtDate(refreshed_at);
  //   }
  // }, [klaviyoFlowSummary]);

  // useEffect(() => {
  //   if (
  //     !loadingFlowError &&
  //     !loadingFlow &&
  //     klaviyoFlowSummary === undefined &&
  //     !isEmpty(privateApiKey)
  //   ) {
  //     privateApiKey !== null &&
  //       privateKeyValidator(trim(privateApiKey)) &&
  //       getKlaviyoSummaryFlow(privateApiKey);
  //   }
  // }, [
  //   loadingFlow,
  //   getKlaviyoSummaryFlow,
  //   loadingFlowError,
  //   klaviyoFlowSummary,
  //   privateApiKey,
  //   klaviyoConfigSaved,
  // ]);

  useEffect(() => {
    if (omnisendConfig?.hasOwnProperty('is_active') && !loading && !isUpdated) {
      setLocalOmnisendObj({
        config: omnisendConfig,
        meta: omnisendMeta,
        isUpdated: false,
      });
    }
  }, [omnisendMeta, omnisendConfig, loading, isUpdated]);

  useEffect(() => {
    if (!isEmpty(localOmnisendObj.config) && localOmnisendObj.config.triggers) {
      const { config } = localOmnisendObj;
      const temp = config.triggers
        .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
        .filter((item) => !isNull(item));
      setApiKey(config.api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [omnisendConfig, localOmnisendObj]);

  // const [toasterActive, setToasterActive] = useState(false);
  // const [toasterText, setToasterText] = useState(false);
  // const [isToasterError, setIsToasterError] = useState(null);
  // const closeToasterActive = useCallback(() => {
  //   setToasterActive(false);
  //   resetOmnisend();
  // }, [resetOmnisend]);
  //
  // const ToastToasterActiveMarkup = toasterActive ? (
  //   <Toast
  //     error={isToasterError}
  //     content={toasterText}
  //     onDismiss={closeToasterActive}
  //   />
  // ) : null;
  //
  // const [publicApiKeyError, setPublicApiKeyError] = useState(null);
  // const [publicApiKey, setPublicApiKey] = useState(null);
  // const handlePublicApiKeyChange = useCallback(
  //   (value) => setPublicApiKey(value),
  //   []
  // );
  //
  // // const [selectedLanguage, setSelectedLanguage] = useState('en');
  // // const handleSelectedLanguageChange = useCallback((value) => setSelectedLanguage(value), []);
  //
  // const [selectedTrigger, setSelectedTrigger] = useState([]);
  // const handleSelectedTriggerChange = useCallback(
  //   (value) => setSelectedTrigger(value),
  //   []
  // );
  //
  // const submit = () => {
  //   if (
  //     !publicApiKey ||
  //     _.isEmpty(_.trim(publicApiKey)) ||
  //     publicApiKey.search(/^[A-Z0-9]{24}-[A-Z0-9]{50}$/i)
  //   ) {
  //     setPublicApiKeyError(t('omnisend.modal.public_api_key_error_text'));
  //     return;
  //   }
  //   setPublicApiKeyError(null);
  //   const tempTriggers = omnisendConfig.triggers.map((trigger) => {
  //     if (selectedTrigger.includes(trigger.trigger_slug)) {
  //       return { ...trigger, is_active: true };
  //     } else {
  //       return { ...trigger, is_active: false };
  //     }
  //   });
  //
  //   saveOmnisend({
  //     api_key: _.trim(publicApiKey),
  //     triggers: tempTriggers,
  //     language: 'en',
  //   });
  //   // handleShowOmnisendModal()
  // };
  //
  // useEffect(() => {
  //   if (omnisendTestingConfirmation) {
  //     setToasterText(omnisendTestingConfirmationMessage);
  //     setIsToasterError(false);
  //     setToasterActive(true);
  //     resetOmnisend();
  //   }
  // }, [
  //   omnisendTestingConfirmation,
  //   omnisendTestingConfirmationMessage,
  //   resetOmnisend,
  // ]);
  //
  // useEffect(() => {
  //   if (!_.isNull(omnisendError)) {
  //     setToasterText(omnisendError);
  //     setIsToasterError(true);
  //     setToasterActive(true);
  //   }
  // }, [omnisendError]);
  //
  // useEffect(() => {
  //   if (omnisendConfigSaved) {
  //     setToasterText(t('omnisend.modal.save_success'));
  //     setIsToasterError(false);
  //     setToasterActive(true);
  //     const timer = setTimeout(() => {
  //       resetOmnisend();
  //       handleShowOmnisendModal();
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [omnisendConfigSaved, t, handleShowOmnisendModal, resetOmnisend]);
  //
  // useEffect(() => {
  //   if (!loading && showOmnisendModal && _.isEmpty(omnisendConfig)) {
  //     getOmnisend();
  //   }
  // }, [getOmnisend, loading, showOmnisendModal, omnisendConfig]);
  //
  // useEffect(() => {
  //   if (!_.isEmpty(omnisendConfig) && omnisendConfig.triggers) {
  //     const temp = omnisendConfig.triggers
  //       .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
  //       .filter((item) => !_.isNull(item));
  //     setPublicApiKey(omnisendConfig.api_key);
  //     // setSelectedLanguage(omnisendConfig.language)
  //     setSelectedTrigger(temp);
  //   }
  // }, [omnisendConfig]);
  //
  // const testOmnisendNotification = () => {
  //   if (!publicApiKey || _.isEmpty(_.trim(publicApiKey))) {
  //     setPublicApiKeyError(t('omnisend.modal.public_api_key_error_text'));
  //     return;
  //   }
  //   setPublicApiKeyError(null);
  //   const tempTriggers = omnisendConfig.triggers.map((trigger) => {
  //     if (selectedTrigger.includes(trigger.trigger_slug)) {
  //       return { ...trigger, is_active: true };
  //     } else {
  //       return { ...trigger, is_active: false };
  //     }
  //   });
  //   testOmnisend({
  //     api_key: _.trim(publicApiKey),
  //     triggers: tempTriggers,
  //     language: 'en',
  //   });
  // };

  const updateOmnisendPayload = () => {
    setApiKeyError(null);
    setIsUpdated(true);
  };

  const discardChanges = useCallback(() => {
    setIsUpdated(false);
    setApiKeyError(false);

    setLocalOmnisendObj({
      config: omnisendConfig,
      meta: omnisendMeta,
      isUpdated: false,
    });
  }, [omnisendMeta, omnisendConfig]);

  const redirectBackToPrevious = useCallback(() => {
    history.push(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
  }, [history, shop.storeUUID]);

  // const callbackFunction = useCallback(() => {
  //   discardChanges();
  //   redirectBackToPrevious();
  // }, [discardChanges, redirectBackToPrevious]);

  const redirectBack = useCallback(() => {
    resetOmnisend();

    if (omnisendConfigSaved) {
      // showUnsavedModal(callbackFunction);
      return;
    }

    redirectBackToPrevious();
  }, [
    // showUnsavedModal,
    resetOmnisend,
    redirectBackToPrevious,
    omnisendConfigSaved,
  ]);

  const submit = () => {
    if (
      !apiKey ||
      isEmpty(trim(apiKey)) ||
      apiKey.search(/^[A-Z0-9]{24}-[A-Z0-9]{50}$/i)
    ) {
      setApiKeyError(t('omnisend.modal.public_api_key_error_text'));
      return;
    }
    const tempTriggers = omnisendConfig.triggers.map((trigger) => {
      if (selectedTrigger.includes(trigger.trigger_slug)) {
        return { ...trigger, is_active: true };
      } else {
        return { ...trigger, is_active: false };
      }
    });
    saveOmnisend({
      api_key: trim(apiKey),
      triggers: tempTriggers,
      language: selectedLanguage,
    });
    setIsUpdated(false);
  };

  if (omnisendError) {
    history.push(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
    return;
  }

  if (!localOmnisendObj.hasOwnProperty('config')) {
    return <LoadingLayoutPage />;
  }

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          { content: t('settings.breadcrumbs'), onAction: redirectBack },
        ]}
        title={t('omnisend.modal.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source:
                  'https://support.rush.app/integrating-omnisend-and-rush',
              },
            ]}
          />
        }
      >
        <Layout>
          <SaveBar
            isSaveBarEnabled={isUpdated}
            submitData={submit}
            discardAction={discardChanges}
          />
          {omnisendConfigSaved && <Loading />}
          {(loading || loadingFeatureId > 0) && <Loading />}

          <OmnisendPageCustomization
            omnisendConfig={omnisendConfig}
            localOmnisendObj={localOmnisendObj}
            setLocalOmnisendObj={setLocalOmnisendObj}
            resetOmnisend={resetOmnisend}
          />

          {localOmnisendObj &&
            localOmnisendObj.hasOwnProperty('config') &&
            localOmnisendObj.config.is_active && (
              <>
                <Layout.AnnotatedSection
                  title={t('settings.omnisend.section.title')}
                  description={t(
                    'settings.omnisend.section.configure.description'
                  )}
                >
                  <OmnisendDetails
                    omnisendConfig={omnisendConfig}
                    omnisendMeta={omnisendMeta}
                    localOmnisendObj={localOmnisendObj}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                    apiKeyError={apiKeyError}
                    setSelectedLanguage={setSelectedLanguage}
                    setSelectedTrigger={setSelectedTrigger}
                    selectedLanguage={selectedLanguage}
                    selectedTrigger={selectedTrigger}
                    updateOmnisendPayload={updateOmnisendPayload}
                  />
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title={t('settings.omnisend.actions.title')}
                >
                  <OmnisendActions
                    omnisendConfig={omnisendConfig}
                    // omnisendMeta={omnisendMeta}
                    apiKey={apiKey}
                    setApiKeyError={setApiKeyError}
                    selectedLanguage={selectedLanguage}
                    selectedTrigger={selectedTrigger}
                  />
                </Layout.AnnotatedSection>
              </>
            )}
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
        <UnsavedChangesModal />
      </Page>
      <SettingsToaster />
    </>
  );
}

import React, { useEffect, useMemo } from 'react';
import {
  Page,
  Layout,
  FooterHelp,
  Loading,
  SettingToggle,
  TextStyle,
} from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import { useTranslation } from 'react-i18next';
import AccountsSection from './AccountsSection';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import { useDispatch } from 'react-redux';
import { useGetShipbobQuery, useUpdateShipbobMutation } from '../shipbobApis';
import { useParams } from 'react-router-dom';
import { baseActions } from 'redux/store/baseSlice';
import ShipbobSkeleton from './ShipbobSkeleton';
import FeaturePageWrapper from 'components/FeaturePageWrapper';
import { FEATURES_SLUG } from 'Constants';
import UpgradePlanBanner from 'components/UpgradePlanBanner';
function ShipbobConfig({ isFeatureAvailable }) {
  const [t] = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    data: shipbobConfig = {},
    isLoading: configLoading,
    isFetching: configFetching,
  } = useGetShipbobQuery();

  const [
    updateConfig,
    { isLoading: configUpdating, isSuccess: configUpdated, data: updateData },
  ] = useUpdateShipbobMutation();

  useEffect(() => {
    if (updateData && configUpdated && !configUpdating) {
      dispatch(
        baseActions.setToastMessage({
          message: updateData.message,
          type: 'success',
        })
      );
    }
  }, [configUpdated, configUpdating, updateData]);

  const isActive = useMemo(() => {
    return shipbobConfig.is_active;
  }, [shipbobConfig]);

  return (
    <>
      {configLoading ? (
        <ShipbobSkeleton />
      ) : (
        <>
          {configFetching || configUpdating ? <Loading /> : ''}
          <SmallTopMessage />
          <Page
            breadcrumbs={[
              {
                content: t('settings.breadcrumbs'),
                url: `/s/${params.uuid}${STORE_URLS.ADDONS}`,
              },
            ]}
            title={t('settings.shipbob_page.title')}
            subtitle={
              <Subtitle
                text={t('settings.shipbob_pages.description')}
                actions={[
                  {
                    text: t('settings.shipbob_pages.subtitle.read_more'),
                    source:
                      'https://support.rush.app/integrating-shipbob-and-rush',
                  },
                ]}
              />
            }
          >
            {!isFeatureAvailable ? (
              <>
                <UpgradePlanBanner />
                <br />
              </>
            ) : (
              ''
            )}
            <div className={!isFeatureAvailable ? 'disabled-feature-page' : ''}>
              <Layout>
                <Layout.AnnotatedSection
                  title={t('shipbob.connect.title')}
                  description={t('shipbob.connect.caption')}
                >
                  <SettingToggle
                    action={{
                      content: !isActive
                        ? t('settings_page.addon_card.button.label_activate')
                        : t('settings_page.addon_card.button.label_deactivate'),
                      onAction: () => {
                        updateConfig({
                          is_active: !isActive,
                        });
                      },
                      loading: configUpdating || configFetching,
                      disabled: !isFeatureAvailable,
                    }}
                    enabled={isActive}
                  >
                    <TextStyle>{t('shipbob.connect.card_title')}</TextStyle>
                  </SettingToggle>
                </Layout.AnnotatedSection>
                <AccountsSection
                  accounts={shipbobConfig.accounts}
                  installLink={shipbobConfig.auth_link}
                  isFeatureAvailable={isFeatureAvailable}
                />
              </Layout>
              <Layout.Section>
                <FooterHelp>
                  <TranslateLink text={t('footer_help.dashboard')} />
                </FooterHelp>
              </Layout.Section>
            </div>
          </Page>
        </>
      )}
    </>
  );
}

export default FeaturePageWrapper(ShipbobConfig, FEATURES_SLUG.SHIPBOB);

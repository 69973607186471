const { call, put, takeEvery, select } = require('redux-saga/effects');
const reportsActions = require('./reports.actions');
const reportsWebServices = require('./reports.webServices');

const { Types, Creators } = reportsActions;
const { getPerformanceReport, getReport } = reportsWebServices;

export function* watchReportsRequests() {
  yield takeEvery(Types.GET_PERFORMANCE_REPORT, requestGetPerformanceReport);
  yield takeEvery(Types.GET_REPORT, requestGetReport);
}

function* requestGetPerformanceReport(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getPerformanceReport,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(
      Creators.getPerformanceReportSuccess(response.data.data.report[0])
    );
  } catch (error) {
    if (error) {
      yield put(Creators.getPerformanceReportFailure(error.data.errors));
    }
  }
}

function* requestGetReport(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);

    let params = action.params;
    let reportType = params.type;
    delete params['type'];
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getReport,
      accessToken,
      storeUuid,
      action.language,
      action.params
    );
    yield put(Creators.getReportSuccess(response.data, reportType));
  } catch (error) {
    if (error) {
      yield put(Creators.getReportFailure(error.data.errors));
    }
  }
}

import {
  Avatar,
  Button,
  Card,
  Layout,
  Link,
  ResourceList,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactCardsHOC from './ContactCardsHOC';
import './styles.scss';

function BookTeamTimeCard({
  showCloseButton = false,
  isConditionFulfilled = false,
}) {
  const [t] = useTranslation();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(!isConditionFulfilled);
  }, [isConditionFulfilled]);

  const handleRemove = () => {
    setHide(true);
    ClientStorage.set(LOCAL_STORAGE.SHOW_FOUNDER_CARD, false);
  };
  return hide ? (
    ''
  ) : (
    <Layout.Section>
      <div className="bookingCards">
        <Card
          sectioned
          title={
            <Stack>
              <Stack.Item fill>
                <h2 className="Polaris-Heading">
                  {t('billing.book_team_title')}
                </h2>
              </Stack.Item>
              {showCloseButton ? (
                <Stack.Item>
                  <Button
                    plain
                    icon={CancelSmallMinor}
                    onClick={handleRemove}
                  ></Button>
                </Stack.Item>
              ) : (
                ''
              )}
            </Stack>
          }
        >
          <Stack vertical>
            <TextStyle variation="subdued">
              {t('billing.book_team_description')}
            </TextStyle>
            <ResourceList
              items={[
                {
                  id: 342,
                  name: 'Slav, Product Lead and co-founder',
                  email: 'slav@rush.app',
                  meetingLink: 'https://lp.rush.app/meetings/stanislav-stankov',
                  img: 'https://assets.rush.app/app/billing/slav_stankov.jpeg',
                },
              ]}
              renderItem={(item) => {
                const { id, name, email, img, meetingLink } = item;
                const media = (
                  <Avatar customer size="medium" name={name} source={img} />
                );

                return (
                  <ResourceList.Item id={id} media={media}>
                    <Stack vertical spacing="extraTight">
                      <TextStyle variation="strong">{name}</TextStyle>
                      <Link external url={`mailto:${email}`}>
                        {email}
                      </Link>
                      <Link external url={meetingLink}>
                        {t('common.schedule_meeting')}
                      </Link>
                    </Stack>
                  </ResourceList.Item>
                );
              }}
            />
          </Stack>
        </Card>
      </div>
    </Layout.Section>
  );
}

export default ContactCardsHOC(BookTeamTimeCard);

import { Spinner, Stack, TextStyle } from '@shopify/polaris';
import React from 'react';
import './style.scss';

function ShipmentsLoader({ show }) {
  return (
    <div className={`loadingContainer ${show ? 'show' : ''}`}>
      <div className="bg-container">
        <Stack>
          <Spinner size="small" />
          <TextStyle variation="subdued">Loading shipments</TextStyle>
        </Stack>
      </div>
    </div>
  );
}

export default ShipmentsLoader;

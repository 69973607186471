import { connect } from 'react-redux';
import ShipmentTrackingBy from './ShipmentTrackingBy';

function mapStateToProps(state) {
  return {
    trackingDetail: state.trackingDetail,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentTrackingBy);

import { ClientStorage } from 'ClientStorage';

const { call, put, takeEvery, select } = require('redux-saga/effects');
const visitorActions = require('./visitor.actions');
const visitorWebServices = require('./visitor.webServices');
import publicIp from 'public-ip';
import { LOCAL_STORAGE } from 'Constants';

const { Types, Creators } = visitorActions;
const {
  getVisitorLanguages,
  getAffiliatesCampaign,
  login,
  register,
  verifyCode,
  resendVerificationCode,
  forgotPassword,
  resetPassword,
  updateEmail,
  getAccessToken,
  logout,
} = visitorWebServices;

export function* watchVisitorRequests() {
  yield takeEvery(Types.GET_IP_ADDRESS, requestGetIpAddress);
  yield takeEvery(Types.GET_VISITOR_LANGUAGES, requestGetVisitorLanguages);
  yield takeEvery(Types.GET_AFFILIATES_CAMPAIGN, requestGetAffiliatesCampaign);
  yield takeEvery(Types.LOGIN, requestLogin);
  yield takeEvery(Types.REGISTER, requestRegister);
  yield takeEvery(
    Types.RESEND_VERIFICATION_CODE,
    requestResendVerificationCode
  );
  yield takeEvery(Types.VERIFY_CODE, requestVerifyCode);
  yield takeEvery(Types.FORGOT_PASSWORD, requestForgotPassword);
  yield takeEvery(Types.RESET_PASSWORD, requestResetPassword);
  yield takeEvery(Types.UPDATE_EMAIL, requestUpdateEmail);
  yield takeEvery(Types.GET_ACCESS_TOKEN, requestGetAccessToken);
  yield takeEvery(Types.LOGOUT, requestLogout);
}

function* requestGetIpAddress() {
  try {
    const response = yield publicIp.v4();
    yield put(Creators.getIpAddressSuccess(response));
  } catch (error) {
    if (error) {
      yield put(Creators.getIpAddressFailure());
    }
  }
}

function* requestGetVisitorLanguages() {
  try {
    const response = yield call(getVisitorLanguages);
    yield put(Creators.getVisitorLanguagesSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.getVisitorLanguagesFailure(error?.data));
  }
}

function* requestGetAffiliatesCampaign(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);

    const response = yield call(getAffiliatesCampaign, action.params, language);
    yield put(Creators.getAffiliatesCampaignSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.getAffiliatesCampaignFailure(error?.data));
  }
}

function* requestLogin(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);
    const response = yield call(login, action.params, language);

    ClientStorage.set(
      LOCAL_STORAGE.ACCESS_TOKEN,
      response.data.data.access_token
    );
    yield put(Creators.loginSuccess(response.data.data));
  } catch (error) {
    yield put(Creators.loginFailure(error.data.errors));
  }
}

function* requestRegister(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);
    const response = yield call(register, action.params, language);
    yield put(
      Creators.registerSuccess({
        ...response.data.data,
        password: action.params.password,
      })
    );
  } catch (error) {
    yield put(Creators.registerFailure(error.data.errors));
  }
}

function* requestResendVerificationCode(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);

    const response = yield call(
      resendVerificationCode,
      action.params,
      language
    );

    yield put(Creators.resendVerificationCodeSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.resendVerificationCodeFailure(error.data.errors));
    }
  }
}

function* requestVerifyCode(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);
    const response = yield call(verifyCode, action.params, language);

    yield put(Creators.verifyCodeSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.verifyCodeFailure(error.data.errors));
    }
  }
}

function* requestForgotPassword(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);

    const response = yield call(forgotPassword, action.params, language);
    yield put(
      Creators.forgotPasswordSuccess({
        email: action.params,
        message: response.data.data.message,
      })
    );
  } catch (error) {
    if (error) {
      yield put(Creators.forgotPasswordFailure(error.data.errors));
    }
  }
}

function* requestResetPassword(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);

    const response = yield call(resetPassword, action.params, language);

    ClientStorage.set(
      LOCAL_STORAGE.ACCESS_TOKEN,
      response.data.data.access_token
    );
    yield put(Creators.resetPasswordSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.resetPasswordFailure(error.data.errors));
    }
  }
}

function* requestUpdateEmail(action) {
  try {
    const languageSelector = (state) => state.visitor.selectedLanguageCode;
    const language = yield select(languageSelector);

    const response = yield call(updateEmail, action.params, language);
    yield put(
      Creators.updateEmailSuccess({
        ...response.data.data,
        email: action.params.email,
      })
    );
  } catch (error) {
    if (error) {
      yield put(Creators.updateEmailFailure(error.data.errors));
    }
  }
}

function* requestGetAccessToken(action) {
  try {
    let accessToken;

    // if OTT is provided
    if (action.params.ott) {
      const response = yield call(getAccessToken, action.params.ott);
      accessToken = response.data.data.access_token;
      ClientStorage.set(LOCAL_STORAGE.ACCESS_TOKEN, accessToken);
    } else {
      accessToken = ClientStorage.get(LOCAL_STORAGE.ACCESS_TOKEN);
    }
    yield put(Creators.getAccessTokenSuccess(accessToken));
  } catch (error) {
    if (error) {
      yield put(Creators.getAccessTokenFailure(error.data.errors));
    }
  }
}

function* requestLogout() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);

    const response = yield call(logout, accessToken);
    yield put(Creators.logoutSuccess(response.data.data));
  } catch (error) {
    if (error) {
      yield put(Creators.logoutFailure(error.data.errors));
    }
  }
}

import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import PaypalTrackings from './PaypalTrackings';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    paypalLoading: state.settings.paypalLoading,
    paypalTrackingsData: state.settings.paypalTrackingsData,
    paypalTrackingsMeta: state.settings.paypalTrackingsMeta,
    paypalTrackingsSummary: state.settings.paypalTrackingsSummary,
    paypalTrackingsExportMessage: state.settings.paypalTrackingsExportMessage,
    paypalExportLoading: state.settings.paypalExportLoading,
    paypalToaster: state.settings.paypalToaster,
  };
}

const mapDispatchToProps = {
  getPaypalTrackings: settingsActions.Creators.getPaypalTrackings,
  exportPaypalTrackings: settingsActions.Creators.exportPaypalTrackings,
  resetPaypalToaster: settingsActions.Creators.resetPaypalToaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalTrackings);

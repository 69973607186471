import React from 'react';
import { Card, FooterHelp, Layout, Loading, Page } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetPreShipmentTimelinesQuery } from '../preShipmentTimelineApis';
import PreShipmentDataTable from './PreShipmentDataTable';
import SkeletonPreShipmentTimelines from './SkeletonPreShipmentTimelines';
import TranslateLink from 'components/TranslateLink';
import SmallTopMessage from 'components/SmallTopMessage';
import { useTranslation } from 'react-i18next';
import Toaster from 'pages/Settings/TrackingPage/Toaster';
import Subtitle from 'components/Subtitle';
import UpgradePlanBanner from '../../../components/UpgradePlanBanner';
import FeaturePageWrapper from 'components/FeaturePageWrapper';
import { FEATURES_SLUG } from 'Constants';
import './styles.scss';

function PreShipmentTimeline({ isFeatureAvailable }) {
  const [t] = useTranslation();
  const history = useHistory();

  const { data, isLoading, isFetching } = useGetPreShipmentTimelinesQuery();
  const [preShipmentTimelines, setPreShipmentTimelines] = useState([]);

  useEffect(() => {
    data && setPreShipmentTimelines(data);
  }, [data]);

  const handleAddPreShipmentTimeline = () => {
    history.push(history.location.pathname + '/new');
  };
  return (
    <>
      <SmallTopMessage />
      <Page
        fullWidth
        title={t('pre-shipment.page-title')}
        primaryAction={{
          content: t('pre-shipment.page-action'),
          onAction: handleAddPreShipmentTimeline,
          disabled: !isFeatureAvailable,
        }}
        subtitle={
          <Subtitle
            text={t('pre-shipment.sub-title')}
            actions={[
              {
                text: t('common.get-started'),
                source: t('pre-shipment.get-started-link'),
              },
              {
                text: t('common.watch-video'),
                source: t('pre-shipment.for-dropshippers-link'),
              },
            ]}
          />
        }
      >
        {isLoading ? (
          <SkeletonPreShipmentTimelines />
        ) : (
          <>
            {' '}
            {!isFeatureAvailable ? (
              <>
                <UpgradePlanBanner />
                <br />
              </>
            ) : (
              ''
            )}
            <Card>
              <div
                className={`preShipmentTimelines-main ${
                  !isFeatureAvailable ? 'disabled-table' : ''
                }`}
              >
                <PreShipmentDataTable
                  preShipmentTimelines={preShipmentTimelines}
                  setPreShipmentTimelines={setPreShipmentTimelines}
                  isDisabled={!isFeatureAvailable}
                />
              </div>
              {preShipmentTimelines?.length ? <br /> : ''}
            </Card>
          </>
        )}
        <Layout.Section>
          <FooterHelp>
            <TranslateLink text={t('footer_help.dashboard')} />
          </FooterHelp>
        </Layout.Section>
      </Page>
      <Toaster />
      {isFetching ? <Loading /> : ''}
    </>
  );
}

export default FeaturePageWrapper(
  PreShipmentTimeline,
  FEATURES_SLUG.PRE_SHIPMENT
);

import {
  Page,
  Layout,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { STORE_URLS } from 'config/urls';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function DetailsSkeleton() {
  const params = useParams();
  return (
    <Page
      title={<SkeletonDisplayText />}
      secondaryActions={[]}
      fullWidth={false}
      narrowWidth={false}
      breadcrumbs={[
        {
          url: `/s/${params.uuid}${STORE_URLS.SHIPMENTS}`,
        },
      ]}
      pagination={{
        hasPrevious: false,
        onPrevious: () => {},
        hasNext: false,
        onNext: () => {},
      }}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText lines={10} />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={3} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={7} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={4} />
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={4} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

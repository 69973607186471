import React, { useEffect, useState } from 'react';
import {
  Stack,
  Card,
  Link,
  Badge,
  TextStyle,
  SkeletonBodyText,
} from '@shopify/polaris';
import {
  DASHBOARD_ANALYTICS_TYPE_SHIPMENT_PER_STATUS,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { STORE_URLS } from 'config/urls';
import { useGetReportsMutation } from 'pages/Dashboard/DashboardApi';
import i18next from 'i18next';
import { useGetStatusesQuery } from 'redux/store/commonStoreApis';
export default function ShipmentPerStatus({ selectedDates = {} }) {
  const [t] = useTranslation();
  const [getReport, reportsResponse] = useGetReportsMutation();
  const {
    data: shipmentsPerStatus = {},
    isLoading,
    isSuccess,
  } = reportsResponse || {};
  const { data = {} } = useGetStatusesQuery();
  const shipmentStatuses = data.statuses || [];

  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    getReport({
      type: DASHBOARD_ANALYTICS_TYPE_SHIPMENT_PER_STATUS,
      kind: 'shipments',
      filter: {
        date_from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
        date_to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      },
      sql: {
        select: [
          'shipment_status_label',
          'shipment_status_slug',
          'shipments_total',
        ],
        group: ['shipment_status_label'],
        sort: ['-shipments_total'],
      },
    });
  }, [getReport, selectedDates, i18next.language]);

  useEffect(() => {
    if (shipmentsPerStatus?.table?.data?.length > 0) {
      const total = shipmentsPerStatus.table.data.reduce((total, current) => {
        if (Array.isArray(total)) {
          return { value: parseInt(total[2]) + parseInt(current[2]) };
        } else {
          return { value: parseInt(total.value) + parseInt(current[2]) };
        }
      });
      setTotalItems(total.value);
    }
  }, [shipmentsPerStatus]);

  return (
    <Card.Section title={t('dashboard.analytics_sheet.shipment_status_title')}>
      {isLoading || !isSuccess ? (
        <SkeletonBodyText />
      ) : (
        shipmentsPerStatus && (
          <Stack vertical={true} spacing="tight">
            {!_.isEmpty(shipmentStatuses) &&
              shipmentsPerStatus.table.data.length > 0 &&
              shipmentsPerStatus.table.data.map((dataItem, index) => {
                const status = shipmentStatuses.filter(
                  (mapStatus) => mapStatus.id === dataItem[1]
                )[0];
                return (
                  <Stack.Item key={index}>
                    <Stack>
                      <Stack.Item fill>
                        {status && (
                          <Badge
                            status={status.badge_prop.status}
                            progress={status.badge_prop.progress}
                          >
                            {dataItem[0]}
                          </Badge>
                        )}
                      </Stack.Item>
                      <Stack.Item>
                        <Link
                          url={`.${STORE_URLS.SHIPMENTS}?statuses_uuid=${
                            status.uuid
                          }&orders_created_in=custom&orders_created_from=${moment(
                            selectedDates.start
                          ).format(
                            DB_DATE_FORMAT_STRING
                          )}&orders_created_to=${moment(
                            selectedDates.end
                          ).format(DB_DATE_FORMAT_STRING)}`}
                        >
                          {dataItem[2]}
                        </Link>
                      </Stack.Item>
                      {totalItems && (
                        <Stack.Item>
                          <TextStyle variation="subdued">
                            {' '}
                            {((dataItem[2] / totalItems) * 100).toFixed(0)} %
                          </TextStyle>
                        </Stack.Item>
                      )}
                    </Stack>
                  </Stack.Item>
                );
              })}
            {shipmentsPerStatus.table.data.length === 0 && (
              <div className="CustomEmptyState">
                <TextStyle variation="subdued">
                  {t('dashboard.analytics_sheet.no_row_message')}
                </TextStyle>
              </div>
            )}
          </Stack>
        )
      )}
    </Card.Section>
  );
}

import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import StoreInformation from './StoreInformation';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    storeLogoURL: state.trackingDetail.storeLogoURL,
    logoUploaded: state.trackingDetail.uploaded,
  };
}

const mapDispatchToProps = {
  saveTrackingPageDetail: trackingActions.Creators.saveTrackingPageDetail,
  uploadLogoRequest: trackingActions.Creators.uploadLogoRequest,
  uploadLogoReset: trackingActions.Creators.uploadLogoReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreInformation);

import React, { useState, useCallback, useEffect } from 'react';
import {
  DatePicker,
  Form,
  FormLayout,
  Card,
  Button,
  Select,
  TextField,
  Stack,
  Modal,
} from '@shopify/polaris';
import moment from 'moment';
import './styles.scss';

import {
  DATE_RANGE_VALUE_TODAY,
  DATE_RANGE_VALUE_LAST_7_DAYS,
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DATE_RANGE_VALUE_LAST_90_DAYS,
  DATE_RANGE_VALUE_THIS_YEAR,
  DATE_FORMAT_STRING,
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_YESTERDAY,
  DATE_RANGE_VALUE_PREVIOUS_MONTH,
  DATE_RANGE_VALUE_LAST_120_DAYS,
  DATE_RANGE_VALUE_LAST_365_DAYS,
  DATE_RANGE_VALUE_CUSTOM,
} from 'Constants';
import { useTranslation } from 'react-i18next';

export default function DateRange({
  fnSelectDates,
  propsSelectedDates,
  disableDates,
  fullWidth,
  selectedDropDownItem,
  markDisable,
}) {
  const [t] = useTranslation();

  const [selectedStartTime, setSelectedStartTime] = useState(
    propsSelectedDates.start
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    propsSelectedDates.end
  );
  const [popoverActive, setPopoverActive] = useState(false);
  const [activatorText, setActivatorText] = useState('');
  const togglePopoverActive = useCallback(
    () => setPopoverActive((pActive) => !pActive),
    []
  );

  const [dropDownOption, setDropDownOptions] = useState([]);
  useEffect(() => {
    let values = [
      {
        label: t('shipments.filter.date_range.today'),
        value: DATE_RANGE_VALUE_TODAY,
      },
      {
        label: t('shipments.filter.date_range.yesterday'),
        value: DATE_RANGE_VALUE_YESTERDAY,
      },
      {
        label: t('shipments.filter.date_range.last_7_days'),
        value: DATE_RANGE_VALUE_LAST_7_DAYS,
      },
      {
        label: t('shipments.filter.date_range.last_30_days'),
        value: DATE_RANGE_VALUE_LAST_30_DAYS,
      },
      {
        label: t('shipments.filter.date_range.last_month'),
        value: DATE_RANGE_VALUE_PREVIOUS_MONTH,
      },
      {
        label: t('shipments.filter.date_range.last_90_days'),
        value: DATE_RANGE_VALUE_LAST_90_DAYS,
      },
      {
        label: t('shipments.filter.date_range.last_120_days'),
        value: DATE_RANGE_VALUE_LAST_120_DAYS,
      },
      {
        label: t('shipments.filter.date_range.last_365_days'),
        value: DATE_RANGE_VALUE_LAST_365_DAYS,
      },
      {
        label: t('shipments.filter.date_range.this_year'),
        value: DATE_RANGE_VALUE_THIS_YEAR,
      },
    ];

    if (selectedDropDownItem === DATE_RANGE_VALUE_CUSTOM) {
      values = [
        {
          label: t('shipments.filter.date_range.custom'),
          value: DATE_RANGE_VALUE_CUSTOM,
        },
        ...values,
      ];
      setActivatorText(
        `${moment(selectedStartTime).format(DATE_FORMAT_STRING)} - ${moment(
          selectedEndTime
        ).format(DATE_FORMAT_STRING)}`
      );
    } else {
      const tempText = values.filter(
        (item) => item.value === selectedDropDownItem
      );
      if (tempText[0]) {
        setActivatorText(tempText[0].label);
      }
    }
    setDropDownOptions(values);
    setSelectedDropDownTime(selectedDropDownItem);
  }, [selectedDropDownItem, popoverActive]);

  const handleCancel = () => {
    try {
      if (selectedDropDownItem === DATE_RANGE_VALUE_CUSTOM) {
        setActivatorText(
          `${moment(propsSelectedDates.start).format(
            DATE_FORMAT_STRING
          )} - ${moment(propsSelectedDates.end).format(DATE_FORMAT_STRING)}`
        );
      } else {
        const tempText = dropDownOption.find(
          (item) => item.value === selectedDropDownItem
        ).label;
        setActivatorText(tempText);
      }
    } catch {
      console.log('error in DateRange');
    }
    togglePopoverActive();
  };

  // DatePicker
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: selectedStartTime,
    end: selectedEndTime,
  });
  const handleMonthChange = useCallback(
    (mon, yr) => setDate({ month: mon, year: yr }),
    []
  );
  const activator = (
    <Button
      onClick={togglePopoverActive}
      disclosure
      fullWidth={fullWidth}
      disabled={markDisable}
    >
      {activatorText}
    </Button>
  );

  const handleSelectedDate = useCallback(
    (dateRange) => {
      setSelectedDates(dateRange);
      setSelectedStartTime(dateRange.start);
      setSelectedEndTime(dateRange.end);
      setTextFieldStartTime(
        moment(dateRange.start).format(DB_DATE_FORMAT_STRING)
      );
      setTextFieldEndTime(moment(dateRange.end).format(DB_DATE_FORMAT_STRING));
      setActivatorText(
        `${moment(dateRange.start).format(DATE_FORMAT_STRING)} - ${moment(
          dateRange.end
        ).format(DATE_FORMAT_STRING)}`
      );

      if (
        !dropDownOption.find((opt) => opt.value === DATE_RANGE_VALUE_CUSTOM)
      ) {
        setDropDownOptions((options) => [
          {
            label: t('shipments.filter.date_range.custom'),
            value: DATE_RANGE_VALUE_CUSTOM,
          },
          ...options,
        ]);
      }
      setSelectedDropDownTime(DATE_RANGE_VALUE_CUSTOM);
    },
    [dropDownOption]
  );

  // select
  const [selectedDropDownTime, setSelectedDropDownTime] = useState(
    selectedDropDownItem ? selectedDropDownItem : DATE_RANGE_VALUE_TODAY
  );
  const handleSelectedDropDownTimeChange = useCallback(
    (value) => {
      setSelectedDropDownTime(value);
      let startTime = moment()._d;
      let endTime = moment()._d;
      if (value === DATE_RANGE_VALUE_YESTERDAY) {
        startTime = moment().subtract(1, 'days')._d;
        endTime = moment().subtract(1, 'days')._d;
      } else if (value === DATE_RANGE_VALUE_LAST_7_DAYS) {
        startTime = moment().subtract(6, 'days')._d;
      } else if (value === DATE_RANGE_VALUE_LAST_30_DAYS) {
        startTime = moment().subtract(29, 'days')._d;
      } else if (value === DATE_RANGE_VALUE_LAST_90_DAYS) {
        startTime = moment().subtract(89, 'days')._d;
      } else if (value === DATE_RANGE_VALUE_LAST_120_DAYS) {
        startTime = moment().subtract(119, 'days')._d;
      } else if (value === DATE_RANGE_VALUE_PREVIOUS_MONTH) {
        startTime = moment().subtract(1, 'months').startOf('month')._d;
        endTime = moment().subtract(1, 'months').endOf('month')._d;
      } else if (value === DATE_RANGE_VALUE_THIS_YEAR) {
        startTime = moment().startOf('year')._d;
      } else if (value === DATE_RANGE_VALUE_LAST_365_DAYS) {
        startTime = moment().subtract(365, 'days')._d;
      }
      setSelectedStartTime(startTime);
      setSelectedEndTime(moment(endTime)._d);
      setSelectedDates({
        start: moment(startTime)._d,
        end: moment(endTime)._d,
      });
      setTextFieldStartTime(moment(startTime).format(DB_DATE_FORMAT_STRING));
      setTextFieldEndTime(moment(endTime).format(DB_DATE_FORMAT_STRING));
      const tempText = dropDownOption.filter((item) => item.value === value);
      setActivatorText(tempText[0].label);
      if (value !== DATE_RANGE_VALUE_CUSTOM) {
        setDropDownOptions((options) =>
          options.filter((opt) => opt.value !== DATE_RANGE_VALUE_CUSTOM)
        );
      }
    },
    [dropDownOption]
  );

  const [textFieldStartTime, setTextFieldStartTime] = useState(
    moment(selectedStartTime).format(DB_DATE_FORMAT_STRING)
  );
  const [textFieldStartTimeError, setTextFieldStartTimeError] = useState(false);
  const [textFieldEndTime, setTextFieldEndTime] = useState(
    moment(selectedEndTime).format(DB_DATE_FORMAT_STRING)
  );
  const [textFieldEndTimeError, setTextFieldEndTimeError] = useState(false);

  const handleTextFieldStartTimeChange = useCallback(
    (newValue) => {
      if (
        moment(newValue).isValid() &&
        moment(disableDates.end).isSameOrAfter(moment(newValue)) &&
        moment(textFieldEndTime).isSameOrAfter(moment(newValue)) &&
        (!disableDates.start ||
          (disableDates.start &&
            moment(disableDates.start).isSameOrBefore(moment(newValue))))
      ) {
        setTextFieldStartTime(newValue);
        setTextFieldStartTimeError(false);
        setSelectedDates({
          start: moment(newValue)._d,
          end: moment(selectedEndTime)._d,
        });
        setActivatorText(
          `${moment(newValue).format(DATE_FORMAT_STRING)} - ${moment(
            selectedEndTime
          ).format(DATE_FORMAT_STRING)}`
        );
      } else {
        setTextFieldStartTimeError(true);
      }
    },
    [selectedEndTime, disableDates, textFieldEndTime]
  );

  const handleTextFieldEndTimeChange = useCallback(
    (newValue) => {
      if (
        moment(newValue).isValid() &&
        moment(disableDates.end).isSameOrAfter(moment(newValue)) &&
        moment(textFieldStartTime).isSameOrBefore(moment(newValue)) &&
        (!disableDates.start ||
          (disableDates.start &&
            moment(disableDates.start).isSameOrBefore(moment(newValue))))
      ) {
        setTextFieldEndTime(newValue);
        setTextFieldEndTimeError(false);
        setSelectedDates({
          start: moment(selectedStartTime)._d,
          end: moment(newValue)._d,
        });
        setActivatorText(
          `${moment(selectedStartTime).format(DATE_FORMAT_STRING)} - ${moment(
            newValue
          ).format(DATE_FORMAT_STRING)}`
        );
      } else {
        setTextFieldEndTimeError(true);
      }
    },
    [selectedStartTime, disableDates, textFieldStartTime]
  );

  return (
    <Modal open={popoverActive} activator={activator}>
      <div>
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <Select
                  label={t('shipments.filter.date_range_title')}
                  options={dropDownOption}
                  onChange={handleSelectedDropDownTimeChange}
                  value={selectedDropDownTime}
                />
                <FormLayout.Group>
                  <TextField
                    label={t('shipments.filter.date_starting')}
                    value={textFieldStartTime}
                    onChange={handleTextFieldStartTimeChange}
                    error={textFieldStartTimeError}
                  />
                  <TextField
                    label={t('shipments.filter.date_ending')}
                    value={textFieldEndTime}
                    onChange={handleTextFieldEndTimeChange}
                    error={textFieldEndTimeError}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={handleSelectedDate}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    multiMonth={true}
                    allowRange={true}
                    disableDatesBefore={
                      disableDates && disableDates.start
                        ? moment(disableDates.start)._d
                        : null
                    }
                    disableDatesAfter={
                      disableDates && disableDates.end
                        ? moment(disableDates.end)._d
                        : null
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                primary
                onClick={() => {
                  fnSelectDates({
                    start: moment(selectedStartTime)._d,
                    end: moment(selectedEndTime)._d,
                    selectedOption: selectedDropDownTime,
                  });
                  togglePopoverActive();
                }}
              >
                Apply
              </Button>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    </Modal>
  );
}

import {
  DATE_RANGE_VALUE_TODAY,
  DATE_RANGE_VALUE_LAST_7_DAYS,
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DATE_RANGE_VALUE_LAST_90_DAYS,
  DATE_RANGE_VALUE_THIS_YEAR,
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_YESTERDAY,
  DATE_RANGE_VALUE_PREVIOUS_MONTH,
  DATE_RANGE_VALUE_LAST_120_DAYS,
  DATE_RANGE_VALUE_LAST_365_DAYS,
  DATE_RANGE_VALUE_CUSTOM,
} from 'Constants';
import moment from 'moment';

export const dropDownOptions = (t) => {
  return [
    {
      label: t('shipments.filter.date_range.today'),
      value: DATE_RANGE_VALUE_TODAY,
    },
    {
      label: t('shipments.filter.date_range.yesterday'),
      value: DATE_RANGE_VALUE_YESTERDAY,
    },
    {
      label: t('shipments.filter.date_range.last_7_days'),
      value: DATE_RANGE_VALUE_LAST_7_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_30_days'),
      value: DATE_RANGE_VALUE_LAST_30_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_month'),
      value: DATE_RANGE_VALUE_PREVIOUS_MONTH,
    },
    {
      label: t('shipments.filter.date_range.last_90_days'),
      value: DATE_RANGE_VALUE_LAST_90_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_120_days'),
      value: DATE_RANGE_VALUE_LAST_120_DAYS,
    },
    {
      label: t('shipments.filter.date_range.last_365_days'),
      value: DATE_RANGE_VALUE_LAST_365_DAYS,
    },
    {
      label: t('shipments.filter.date_range.this_year'),
      value: DATE_RANGE_VALUE_THIS_YEAR,
    },
  ];
};

export const getCustomOption = (t) => {
  return {
    label: t('shipments.filter.date_range.custom'),
    value: DATE_RANGE_VALUE_CUSTOM,
  };
};

export const dropDownOptionsWithCustom = (t) => {
  return [getCustomOption(t), ...dropDownOptions(t)];
};

export const getOptionLabel = (value, t) => {
  return (
    (dropDownOptionsWithCustom(t).find((opt) => opt.value === value) || {})
      .label || ''
  );
};

export const getDropDownOptions = (selected, t) => {
  if (selected === DATE_RANGE_VALUE_CUSTOM) {
    return dropDownOptionsWithCustom(t);
  } else {
    return dropDownOptions(t);
  }
};

export const getDisplayValues = (value, range) => {
  let startTime = moment();
  let endTime = moment();
  if (value === DATE_RANGE_VALUE_YESTERDAY) {
    startTime = moment().subtract(1, 'days');
    endTime = moment().subtract(1, 'days');
  } else if (value === DATE_RANGE_VALUE_LAST_7_DAYS) {
    startTime = moment().subtract(6, 'days');
  } else if (value === DATE_RANGE_VALUE_LAST_30_DAYS) {
    startTime = moment().subtract(29, 'days');
  } else if (value === DATE_RANGE_VALUE_LAST_90_DAYS) {
    startTime = moment().subtract(89, 'days');
  } else if (value === DATE_RANGE_VALUE_LAST_120_DAYS) {
    startTime = moment().subtract(119, 'days');
  } else if (value === DATE_RANGE_VALUE_PREVIOUS_MONTH) {
    startTime = moment().subtract(1, 'months').startOf('month');
    endTime = moment().subtract(1, 'months').endOf('month');
  } else if (value === DATE_RANGE_VALUE_THIS_YEAR) {
    startTime = moment().startOf('year');
  } else if (value === DATE_RANGE_VALUE_LAST_365_DAYS) {
    startTime = moment().subtract(365, 'days');
  } else if (value === DATE_RANGE_VALUE_CUSTOM) {
    startTime = moment(range.start) || moment(range.end) || moment();
    endTime = moment(range.end) || moment(range.start) || moment();
  }
  return {
    start: moment(startTime).format(DB_DATE_FORMAT_STRING),
    end: moment(endTime).format(DB_DATE_FORMAT_STRING),
  };
};

import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import BillingDetail from './BillingDetail';

function mapStateToProps(state) {
  return {
    billingTransactions: state.billing.billingTransactions,
    exportMessage: state.billing.billingTransactions.exportMessage,
    loading: state.billing.loading,
    exportLoading: state.billing.exportLoading,
  };
}

const mapDispatchToProps = {
  getBillingTransactions: billingActions.Creators.getBillingTransactions,
  exportBillingTransactions: billingActions.Creators.exportBillingTransactions,
  disableExportBillingTransactions:
    billingActions.Creators.disableExportBillingTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetail);

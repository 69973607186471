import React from 'react';
import { Card, ChoiceList } from '@shopify/polaris';
import CountriesAutoComplete from 'components/CountriesAutoComplete';
import { useTranslation } from 'react-i18next';

function GeoLocationTargetCard({
  geo_location_targeted,
  onChange,
  showError,
  setShowError,
}) {
  const [t] = useTranslation();

  return (
    <Card title={t('smart-edd.transit-time-page.card1-title1')} sectioned>
      <ChoiceList
        choices={[
          {
            label: t('smart-edd.transit-time-page.all-locations'),
            value: 'all',
          },
          {
            label: t('smart-edd.transit-time-page.specific-locations'),
            value: 'specific',
            renderChildren: (selected) =>
              selected ? (
                <CountriesAutoComplete
                  allowMultiple={true}
                  label={''}
                  placeholder={t('common.search-for-countries')}
                  onChange={(val) => {
                    setShowError(Boolean(!(val || []).length));
                    onChange('geo_location_targeted', val);
                  }}
                  value={
                    Array.isArray(geo_location_targeted)
                      ? geo_location_targeted
                      : []
                  }
                  error={showError}
                  errorMessage={
                    showError
                      ? t('common.field-required', { field_name: 'Country' })
                      : ''
                  }
                />
              ) : (
                ''
              ),
          },
        ]}
        selected={!geo_location_targeted ? ['all'] : ['specific']}
        onChange={([val]) => {
          if (val === 'all') {
            onChange('geo_location_targeted', null);
          } else {
            setShowError(true);
            onChange('geo_location_targeted', []);
          }
        }}
      />
    </Card>
  );
}

export default GeoLocationTargetCard;

import React from 'react';
import { Link } from '@shopify/polaris';

export default function ExternalLink({ url, children, ...rest }) {
  const params = new URLSearchParams(url.split('?')[1]);
  if (!params.has('utm_source')) {
    params.append('utm_source', 'app.rush.app');
  }
  if (!params.has('utm_medium')) {
    params.append('utm_medium', 'app.rush.app');
  }
  const newUrl = `${url.split('?')[0]}?${params.toString()}`;

  return (
    <Link url={newUrl} {...rest} external>
      {children}
    </Link>
  );
}

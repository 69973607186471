import { connect } from 'react-redux';
import BillingFeatureList from './BillingFeatureList';

function mapStateToProps(state) {
  return {
    billing: state.billing,
    currentPlan: state.billing.currentPlan,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BillingFeatureList);

import React, { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  Image,
  TextStyle,
  Stack,
  Select,
  SkeletonBodyText,
  Loading,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import SyncImage from 'images/sync.svg';
import _ from 'lodash';
import { useGetOrderSyncOptionsQuery } from 'pages/Shipments/shipmentsApi';
import { useSyncOrdersMutation } from 'pages/Shipments/shipmentsApi';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
export default function OrderImportModal({ onClose, openOrderImportModal }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [selectedSyncOption, setSelectedSyncOption] = useState('');
  const [syncOrders, syncResponse] = useSyncOrdersMutation();
  const {
    data: syncData,
    isLoading: isSyncing,
    isSuccess: isSynced,
  } = syncResponse;
  const {
    data: { options: orderSyncOptions = [], message: orderSyncText = '' } = {},
    isLoading: orderSyncLoading,
  } = useGetOrderSyncOptionsQuery('', { skip: !openOrderImportModal });

  useEffect(() => {
    if (!isSyncing && isSynced && syncData) {
      dispatch(
        baseActions.setToastMessage({
          message: syncData.message,
          type: 'success',
        })
      );
      onClose();
    }
  }, [isSyncing, isSynced, syncData]);

  useEffect(() => {
    openOrderImportModal && setSelectedSyncOption('');
  }, [openOrderImportModal]);

  useEffect(() => {
    if (!_.isEmpty(orderSyncOptions)) {
      setSelectedSyncOption('30');
    }
  }, [orderSyncOptions]);

  const handleSelectChange = useCallback((value) => {
    setSelectedSyncOption(value);
  }, []);

  const handlePostOrderSync = useCallback(() => {
    syncOrders({ total_days_sync: selectedSyncOption });
  }, [syncOrders, selectedSyncOption]);

  return (
    <>
      {isSyncing ? <Loading /> : ''}
      <Modal
        open={openOrderImportModal}
        onClose={onClose}
        title={t('shipments.import_order_modal.title')}
        primaryAction={{
          content: t('shipments.import_order_modal.button_label'),
          onAction: handlePostOrderSync,
          disabled: orderSyncLoading,
          loading: isSyncing,
        }}
      >
        <Modal.Section>
          <Stack distribution="center">
            <Image
              source={SyncImage}
              alt={t('shipments.import_order_modal.input_label')}
            />
          </Stack>

          {orderSyncLoading ? (
            <SkeletonBodyText />
          ) : (
            <>
              <Select
                label={t('shipments.import_order_modal.input_label')}
                options={orderSyncOptions.map((option) => ({
                  label: option.label,
                  value: option.slug,
                }))}
                onChange={handleSelectChange}
                value={selectedSyncOption}
                disabled={orderSyncLoading}
              />
              <TextStyle variation="subdued">
                <TranslateLink text={orderSyncText} />
              </TextStyle>
            </>
          )}
        </Modal.Section>
      </Modal>
    </>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  SettingToggle,
  Form,
  FormLayout,
  Select,
  Checkbox,
  ChoiceList,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { FEATURE_PAYPAL_NOTIFICATION } from 'Constants';
import TranslateLink from 'components/TranslateLink';
export default function TrackingConf({
  paypalMetaTrackingNumberType,
  paypalMetaUpdateMode,
  paypalSyncTrackingConfig,
  putPaypalConfig,
  activateFeature,
  deactivateFeature,
}) {
  const [t] = useTranslation();

  const [typeOfTrackingNumberOptions, setTypeOfTrackingNumberOptions] =
    useState([]);

  const [typeOfTrackingNumber, setTypeOfTrackingNumber] = useState('');
  const handleTypeOfTrackingNumberChange = useCallback(
    (value) => {
      setTypeOfTrackingNumber(value);
      putPaypalConfig({
        sync_tracking_config: { tracking_number_type: value },
      });
    },
    [putPaypalConfig]
  );

  const [isTrackingConfEnabled, setIsTrackingConfEnabled] = useState(null);
  const handleIsTrackingConfEnabledChange = useCallback(() => {
    setIsTrackingConfEnabled((active) => !active);
    if (!isTrackingConfEnabled) {
      activateFeature(FEATURE_PAYPAL_NOTIFICATION);
    } else {
      deactivateFeature(FEATURE_PAYPAL_NOTIFICATION);
    }
  }, [activateFeature, deactivateFeature, isTrackingConfEnabled]);

  const [trackingNumberPriorityChoices, setTrackingNumberPriorityChoices] =
    useState([]);
  const [trackingNumberPriority, setTrackingNumberPriority] = useState([]);
  const handleTrackingNumberPriorityChange = useCallback(
    (newValue) => {
      setTrackingNumberPriority(newValue);
      putPaypalConfig({ sync_tracking_config: { tracking_mode: newValue[0] } });
    },
    [putPaypalConfig]
  );

  const [sendPaypalNotifications, setSendPaypalNotifications] = useState(false);
  const handleSendPaypalNotificationsChange = useCallback(
    (newChecked) => {
      setSendPaypalNotifications(newChecked);
      putPaypalConfig({ sync_tracking_config: { notify_buyer: newChecked } });
    },
    [putPaypalConfig]
  );

  const contentIsTrackingConfEnabledStatus = isTrackingConfEnabled
    ? t('settings.paypal_page.config_card.enable.button_on')
    : t('settings.paypal_page.config_card.enable.button_off');

  useEffect(() => {
    if (paypalMetaTrackingNumberType) {
      const temp = paypalMetaTrackingNumberType.map((type) => ({
        label: type.label,
        value: type.slug.toLowerCase(),
      }));
      setTypeOfTrackingNumberOptions(temp);
    }
  }, [paypalMetaTrackingNumberType]);
  useEffect(() => {
    if (paypalMetaUpdateMode) {
      const temp = paypalMetaUpdateMode.map((type) => ({
        label: type.label,
        value: type.slug.toLowerCase(),
      }));
      setTrackingNumberPriorityChoices(temp);
    }
  }, [paypalMetaUpdateMode]);

  useEffect(() => {
    if (paypalSyncTrackingConfig) {
      setIsTrackingConfEnabled(paypalSyncTrackingConfig.is_active);
      setSendPaypalNotifications(paypalSyncTrackingConfig.notify_buyer);
      setTrackingNumberPriority(
        paypalSyncTrackingConfig.tracking_mode.toLowerCase()
      );
      setTypeOfTrackingNumber(
        paypalSyncTrackingConfig.tracking_number_type.toLowerCase()
      );
    }
  }, [paypalSyncTrackingConfig]);

  return (
    <Layout.AnnotatedSection
      title={t('settings.paypal_page.config_card.title')}
      description={t('settings.paypal_page.config_card.description')}
    >
      <SettingToggle
        action={{
          content: contentIsTrackingConfEnabledStatus,
          onAction: handleIsTrackingConfEnabledChange,
        }}
        enabled={isTrackingConfEnabled}
      >
        {t('settings.paypal_page.config_card.heading')}
      </SettingToggle>
      {isTrackingConfEnabled && (
        <Card sectioned>
          <Form>
            <FormLayout>
              <TranslateLink
                text={t('settings.paypal_page.config_card.text')}
              />
              <Select
                label={t(
                  'settings.paypal_page.config_card.type_tracking_number.label'
                )}
                options={typeOfTrackingNumberOptions}
                onChange={handleTypeOfTrackingNumberChange}
                value={typeOfTrackingNumber}
              />
              <Checkbox
                label={t(
                  'settings.paypal_page.config_card.notification_checkbox.label'
                )}
                checked={sendPaypalNotifications}
                onChange={handleSendPaypalNotificationsChange}
              />
              <ChoiceList
                title={t(
                  'settings.paypal_page.config_card.tracking_number.label'
                )}
                choices={trackingNumberPriorityChoices}
                selected={trackingNumberPriority}
                onChange={handleTrackingNumberPriorityChange}
              />
            </FormLayout>
          </Form>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

import React from 'react';
import { Spinner } from '@shopify/polaris';
import './style.scss';
import { useTranslation } from 'react-i18next';
export default function LoadingPage({ text }) {
  const [t] = useTranslation();
  return (
    <div className="LoadingPage-Wrapper">
      <div className="LoadingPage-Spinner">
        <Spinner accessibilityLabel="Loading" size="large" color="teal" />
      </div>
      <div className="LoadingPage-LoadingMessage">
        {text || <span>{t('app.loading')}</span>}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Stack, Card, TextStyle, SkeletonBodyText } from '@shopify/polaris';
import {
  DASHBOARD_ANALYTICS_TYPE_ORDERS_PER_DESTINATION,
  FREE_TRACKINGS_FORMAT,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import i18next from 'i18next';
import stringHelper from 'helpers/string';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetReportsMutation } from 'pages/Dashboard/DashboardApi';

export default function OrdersPerDestinations({ selectedDates }) {
  const [t] = useTranslation();
  const [getReport, reportsResponse] = useGetReportsMutation();
  const {
    data: ordersPerDestination = {},
    isLoading,
    isSuccess,
  } = reportsResponse || {};

  const [totalItems, setTotalItems] = useState(0);
  const [othersTotal, setOthersTotal] = useState(0);
  const TOTAL_ITEM = 10;
  useEffect(() => {
    getReport({
      type: DASHBOARD_ANALYTICS_TYPE_ORDERS_PER_DESTINATION,
      kind: 'shipments',
      filter: {
        date_from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
        date_to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      },
      sql: {
        select: ['shipments_destination_country', 'shipments_total'],
        group: ['shipments_destination_country'],
        sort: ['-shipments_total', 'shipments_destination_country'],
      },
    });
  }, [getReport, selectedDates, i18next.language]);

  useEffect(() => {
    if (ordersPerDestination?.table?.data?.length > 0) {
      const total = ordersPerDestination.table.data.reduce((total, current) => {
        if (Array.isArray(total)) {
          return { value: parseInt(total[1]) + parseInt(current[1]) };
        } else {
          return { value: parseInt(total.value) + parseInt(current[1]) };
        }
      });
      setTotalItems(total.value);
      if (ordersPerDestination.table.data.length > TOTAL_ITEM) {
        const tempTotal = ordersPerDestination.table.data
          .slice(TOTAL_ITEM)
          .reduce((total, current) => {
            if (Array.isArray(total)) {
              return { value: parseInt(total[1]) + parseInt(current[1]) };
            } else {
              return { value: parseInt(total.value) + parseInt(current[1]) };
            }
          });
        setOthersTotal(tempTotal.value);
      }
    }
  }, [ordersPerDestination]);

  return (
    <Card.Section
      title={t('dashboard.analytics_sheet.orders_destination_title')}
    >
      {isLoading || !isSuccess ? (
        <SkeletonBodyText />
      ) : (
        <>
          {ordersPerDestination &&
            ordersPerDestination.table.data.length > 0 &&
            ordersPerDestination.table.data
              .slice(0, TOTAL_ITEM)
              .map((destination, index) => (
                <Stack distribution="trailing" key={index} spacing="extraTight">
                  <Stack.Item fill>
                    <Stack vertical={true} spacing="extraTight" wrap={true}>
                      <Stack.Item>{destination[0]}</Stack.Item>
                    </Stack>
                  </Stack.Item>
                  <Stack>
                    <Stack.Item>
                      <Stack
                        vertical={true}
                        alignment="trailing"
                        spacing="extraTight"
                        wrap={true}
                      >
                        <Stack.Item>
                          {stringHelper.formatNumber(
                            destination[1],
                            FREE_TRACKINGS_FORMAT
                          )}
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    <Stack.Item>
                      <Stack
                        vertical={true}
                        alignment="trailing"
                        spacing="extraTight"
                        wrap={true}
                      >
                        {totalItems && (
                          <Stack.Item>
                            <TextStyle variation="subdued">
                              {' '}
                              {((destination[1] / totalItems) * 100).toFixed(
                                0
                              )}{' '}
                              %
                            </TextStyle>
                          </Stack.Item>
                        )}
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack>
              ))}
          {othersTotal > 0 && (
            <Stack distribution="trailing">
              <Stack.Item fill>
                <Stack vertical={true} spacing="extraTight" wrap={true}>
                  <Stack.Item>
                    {t('dashboard.analytics_sheet.orders_destination_others')}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <Stack
                  vertical={true}
                  alignment="trailing"
                  spacing="extraTight"
                  wrap={true}
                >
                  <Stack.Item>
                    {stringHelper.formatNumber(
                      othersTotal,
                      FREE_TRACKINGS_FORMAT
                    )}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <Stack
                  vertical={true}
                  alignment="trailing"
                  spacing="extraTight"
                  wrap={true}
                >
                  {totalItems && (
                    <Stack.Item>
                      <TextStyle variation="subdued">
                        {' '}
                        {((othersTotal / totalItems) * 100).toFixed(0)} %
                      </TextStyle>
                    </Stack.Item>
                  )}
                </Stack>
              </Stack.Item>
            </Stack>
          )}
          {ordersPerDestination &&
            ordersPerDestination.table.data.length === 0 && (
              <div className="CustomEmptyState">
                <TextStyle variation="subdued">
                  {t('dashboard.analytics_sheet.no_row_message')}
                </TextStyle>
              </div>
            )}
        </>
      )}
    </Card.Section>
  );
}

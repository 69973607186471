import React, { useCallback } from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function UnsavedChangesModal({
  resetModalActive,
  handleResetModalChange,
  dispatchForTrackingPage,
}) {
  const [t] = useTranslation();
  const resetHTML = useCallback(() => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: { page_source: null },
    });
    handleResetModalChange();
  }, [dispatchForTrackingPage, handleResetModalChange]);
  return (
    <Modal
      open={resetModalActive}
      onClose={handleResetModalChange}
      title={t('settings.tracking_page.page_source.reset_modal.title')}
      primaryAction={{
        content: t(
          'settings.tracking_page.page_source.reset_modal.confirm_btn'
        ),
        destructive: true,
        onAction: () => {
          resetHTML();
        },
      }}
      secondaryActions={[
        {
          content: t(
            'settings.tracking_page.page_source.reset_modal.cancel_btn'
          ),
          onAction: handleResetModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>
            {t('settings.tracking_page.page_source.reset_modal.description')}
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}

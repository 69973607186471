import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Card,
  SettingToggle,
  TextStyle,
  Button,
  Icon,
  ButtonGroup,
} from '@shopify/polaris';

import { useTranslation } from 'react-i18next';
import TopMenuLinkModal from './TopMenuLinkModal';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
import {
  DeleteMinor,
  EditMinor,
  ArrowDownMinor,
  ArrowUpMinor,
} from '@shopify/polaris-icons';
import _ from 'lodash';
import '../style.scss';
export default function HeaderLinks({
  trackingPageState,
  dispatchForTrackingPage,
  trackingDetailMeta,
}) {
  const [t] = useTranslation();

  const [topMenuLinkSection, setTopMenuLinkSection] = useState({
    is_enabled: null,
    links: [],
  });
  const [topMenuLinkIndex, setTopMenuLinkIndex] = useState(null);
  const [showTopMenuLinkModal, setShowTopMenuLinkModal] = useState(false);

  useEffect(() => {
    if (trackingPageState.top_menu_links) {
      if (_.isNull(trackingPageState.top_menu_links.links)) {
        setTopMenuLinkSection({
          ...trackingPageState.top_menu_links,
          links: [],
        });
      } else {
        setTopMenuLinkSection({
          ...trackingPageState.top_menu_links,
          links: trackingPageState.top_menu_links.links.filter(
            (item) => !_.isNull(item)
          ),
        });
      }
    }
  }, [trackingPageState]);

  const changeSort = (oldIndex, changeIndex) => {
    let arr = topMenuLinkSection.links;
    let newIndex = oldIndex + changeIndex;

    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }

    if (newIndex === arr.length) {
      newIndex = 0;
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    let tempTopMenuLinkSectionLinks = arr;
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        top_menu_links: {
          ...topMenuLinkSection,
          links: tempTopMenuLinkSectionLinks,
        },
      },
    });
  };

  const editTopMenuLink = (index) => {
    setTopMenuLinkIndex(index);
    handleShowTopMenuLinkModalChange();
  };

  const deleteTopMenuLink = (index) => {
    let tempTopMenuLink = [
      ...topMenuLinkSection.links.slice(0, index),
      ...topMenuLinkSection.links.slice(index + 1),
    ];
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        top_menu_links: { ...topMenuLinkSection, links: tempTopMenuLink },
      },
    });
  };

  const handleHeaderLinksEnabledChange = useCallback(() => {
    const tempTopMenuLinkSection = {
      ...topMenuLinkSection,
      is_enabled: !topMenuLinkSection.is_enabled,
    };
    setTopMenuLinkSection(tempTopMenuLinkSection);
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: {
        top_menu_links: tempTopMenuLinkSection,
      },
    });
  }, [setTopMenuLinkSection, dispatchForTrackingPage, topMenuLinkSection]);

  const handleShowTopMenuLinkModalChange = useCallback(() => {
    if (showTopMenuLinkModal) {
      setTopMenuLinkIndex(null);
    }
    setShowTopMenuLinkModal(!showTopMenuLinkModal);
  }, [showTopMenuLinkModal]);

  const contentHeaderLinksEnabledStatus = topMenuLinkSection.is_enabled
    ? t('settings.tracking_page.header_links.enable.button_on')
    : t('settings.tracking_page.header_links.enable.button_off');

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.header_links.title')}
      description={t('settings.tracking_page.header_links.description')}
    >
      <SettingToggle
        action={{
          content: contentHeaderLinksEnabledStatus,
          onAction: handleHeaderLinksEnabledChange,
        }}
        enabled={topMenuLinkSection.is_enabled}
      >
        <TextStyle>
          {t('settings.tracking_page.header_links.enable.title')}
        </TextStyle>
      </SettingToggle>
      {topMenuLinkSection.is_enabled && (
        <Card
          sectioned
          title={t('settings.tracking_page.header_links.card.title')}
          actions={[
            {
              content: t(
                'settings.tracking_page.header_links.content.add_btn_label'
              ),
              onAction: handleShowTopMenuLinkModalChange,
            },
          ]}
        >
          {topMenuLinkSection.links.length > 0 && (
            <>
              {topMenuLinkSection.links.map((item, index) => {
                let labelText = '';
                if (!_.isUndefined(item.icon)) {
                  labelText = trackingDetailMeta.top_menu_links.icons
                    .map((iconItem) => {
                      if (iconItem.link === item.icon) {
                        return iconItem.name;
                      } else {
                        return undefined;
                      }
                    })
                    .filter((item) => !_.isUndefined(item));
                } else {
                  labelText = item.label;
                }
                return (
                  <div className="SearchReplace-List" key={index}>
                    <div className="SearchReplace-List-Heading">
                      <TextStyle>{labelText}</TextStyle>
                    </div>
                    <div className="SearchReplace-List-Buttons">
                      <ButtonGroup segmented>
                        <Button
                          size="slim"
                          onClick={() => changeSort(index, -1)}
                          icon={<Icon source={ArrowUpMinor} />}
                        />
                        <Button
                          size="slim"
                          onClick={() => changeSort(index, 1)}
                          icon={<Icon source={ArrowDownMinor} />}
                        />
                        <Button
                          size="slim"
                          onClick={() => editTopMenuLink(index)}
                          icon={<Icon source={EditMinor} />}
                        />
                        <Button
                          size="slim"
                          onClick={() => deleteTopMenuLink(index)}
                          icon={<Icon source={DeleteMinor} />}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {topMenuLinkSection.links.length === 0 && (
            <div className="CustomEmptyState">
              <TextStyle variation="subdued">
                {t(
                  'settings.tracking_page.header_links.card.content.no_row_message'
                )}
              </TextStyle>
            </div>
          )}
        </Card>
      )}
      <TopMenuLinkModal
        showTopMenuLinkModal={showTopMenuLinkModal}
        handleShowTopMenuLinkModalChange={handleShowTopMenuLinkModalChange}
        topMenuLinkIndex={topMenuLinkIndex}
        topMenuLinkSection={topMenuLinkSection}
        dispatchForTrackingPage={dispatchForTrackingPage}
        trackingPageState={trackingPageState}
      />
    </Layout.AnnotatedSection>
  );
}

import {
  Button,
  ButtonGroup,
  Card,
  Layout,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../styles.scss';
import NonShippingModal from './NonShippingModal';
function DateRangesCard({ dateItems = [], onChange }) {
  const [t] = useTranslation();
  const [showNonShipping, setShowNonShipping] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const onAddExcludeRange = () => {
    setEditIndex(-1);
    setShowNonShipping(true);
  };

  const toggleNonInterval = () => {
    setShowNonShipping((v) => !v);
  };

  const onEditDate = (index) => {
    setEditIndex(index);
    toggleNonInterval();
  };

  const addRange = (item) => {
    onChange([...dateItems, item]);
  };
  const editRange = (item) => {
    let updated = [...dateItems];
    updated.splice(editIndex, 1, item);
    onChange(updated);
  };
  const deleteRange = (index) => {
    let updated = [...dateItems];
    updated.splice(index, 1);
    onChange(updated);
  };

  const handleSave = (values) => {
    if (editIndex === -1) {
      addRange(values);
    } else {
      editRange(values);
    }
    toggleNonInterval();
  };

  const dateItemsMarkup = useMemo(
    () =>
      dateItems.map((range, index) => {
        let variation = '';
        const now = moment();
        if (
          moment(range.to).isSame(now, 'day') &&
          moment(range.from).isSame(now, 'day')
        ) {
          variation = 'positive';
        } else if (now > moment(range.to)) {
          variation = 'subdued';
        } else if (now <= moment(range.to) && now >= moment(range.from)) {
          variation = 'positive';
        }
        return (
          <Card.Section key={index}>
            <Stack distribution="fill">
              <Stack>
                <Stack.Item>
                  <TextStyle variation={variation}>
                    {range.from
                      ? moment(range.from).format('MMM Do, YYYY') + ' - '
                      : ''}
                    {range.to ? moment(range.to).format('MMM Do, YYYY') : ''}
                    {range.to && range.note ? ' - ' : ''}
                    {range.note ? (
                      <TextStyle variation="subdued">{range.note} </TextStyle>
                    ) : (
                      ''
                    )}
                  </TextStyle>
                </Stack.Item>
              </Stack>

              <div className="action-buttons">
                <ButtonGroup segmented>
                  <Button
                    size="slim"
                    accessibilityLabel={t('common.duplicate')}
                    onClick={() => onEditDate(index)}
                    icon={EditMinor}
                  ></Button>{' '}
                  <Button
                    size="slim"
                    accessibilityLabel={t('common.delete')}
                    onClick={() => deleteRange(index)}
                    icon={DeleteMinor}
                  ></Button>
                </ButtonGroup>
              </div>
            </Stack>
          </Card.Section>
        );
      }),
    [dateItems]
  );

  const emptyStatemarkup = (
    <div className="empty_markup">
      <TextStyle variation="subdued">
        {t('smart-edd.no-date-excluded')}
      </TextStyle>
    </div>
  );

  return (
    <Layout.AnnotatedSection
      title={t('smart-edd.exclude-shipping-days.title')}
      description={t('smart-edd.exclude-shipping-days.description')}
    >
      <Card
        title={t('smart-edd.exclude-shipping-days.card.title')}
        actions={[{ content: t('common.add'), onAction: onAddExcludeRange }]}
      >
        <Card.Section>
          <Stack vertical>
            <Stack vertical>
              <TextStyle variation="subdued">
                {t('smart-edd.preference.card.description')}
              </TextStyle>
              <Card>
                <div className="date-ranges-container">
                  {(dateItems || []).length
                    ? dateItemsMarkup
                    : emptyStatemarkup}
                </div>
              </Card>
            </Stack>
          </Stack>
        </Card.Section>
      </Card>
      {showNonShipping ? (
        <NonShippingModal
          onClose={toggleNonInterval}
          onChange={handleSave}
          values={dateItems[editIndex]}
        />
      ) : (
        ''
      )}
    </Layout.AnnotatedSection>
  );
}

export default DateRangesCard;

import React, { useState, useCallback } from 'react';

import { Layout, Card, ChoiceList } from '@shopify/polaris';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
export default function MapLocation({
  trackingPageState,
  dispatchForTrackingPage,
  trackingDetailMeta,
}) {
  const [t] = useTranslation();
  const [selectFinalDestination, setSelectFinalDestination] = useState([
    trackingPageState.map_location_mode_enum,
  ]);
  const handleSelectFinalDestinationChange = useCallback(
    (value) => {
      setSelectFinalDestination(value);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: { map_location_mode_enum: value[0] },
      });
    },
    [dispatchForTrackingPage]
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.map_location.title')}
      description={t('settings.tracking_page.map_location.description')}
    >
      <Card
        title={t('settings.tracking_page.map_location.card.title')}
        sectioned
      >
        <TranslateLink
          text={t('settings.tracking_page.map_location.card.description')}
        />
        <ChoiceList
          title=""
          choices={trackingDetailMeta.map.location_modes.map((type) => ({
            label: type.label,
            value: type.slug,
          }))}
          selected={selectFinalDestination}
          onChange={handleSelectFinalDestinationChange}
        />
      </Card>
    </Layout.AnnotatedSection>
  );
}

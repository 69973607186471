export const featuresList = (plan) => {
  const freePlan = ['basic', 'growth', 'advanced', 'enterprise'].includes(plan);
  const growthPlan = ['growth', 'advanced', 'enterprise'].includes(plan);
  const advancedPlan = ['advanced', 'enterprise'].includes(plan);
  const enterprisePlan = ['enterprise'].includes(plan);
  return [
    {
      title: '1300+ carrier integrations',
      tooltip:
        'Integrate and receive updates from 1000+ express & LTL carriers postal and freight services worldwide',
      enabled: freePlan,
    },
    {
      title: 'Shopify shipping notifications',
      tooltip:
        'Trigger sending of Out for delivery and Delivered Shopify notifications',
      enabled: freePlan,
    },
    {
      title: 'Drag-and-drop tracking page (OS2)',
      tooltip: 'Self-Service branded order tracking, built to drive sales',
      enabled: freePlan,
    },
    {
      title: 'Product recommendation (OS2)',
      tooltip:
        'Recommend products on your tracking page and notifications to generate additional revenue',
      enabled: freePlan,
    },
    {
      title: 'Review and UDC app integrations',
      tooltip:
        'Recommend products on your tracking page and notifications to generate additional revenue',
      enabled: freePlan,
    },
    {
      title: 'Unlimited shipment lookup',
      tooltip:
        'See all your shipment statuses and details in one view. Ease to filter and search multiple criteria',
      enabled: freePlan,
    },
    {
      title: 'Basic analytics',
      tooltip:
        'Summary of your shipments, carrier, and delivery times performance',
      enabled: freePlan,
    },
    {
      title: 'Transactional emails',
      tooltip:
        'Send transactional delivery notifications and delay updates to customers via email. Start with 11 present templates',
      enabled: growthPlan,
    },
    {
      title: 'Klaviyo & Omnisend integrations',
      tooltip:
        'Trigger flows and automation with few clicks. Keep your communication with clients in your favorite EMS',
      enabled: growthPlan,
    },
    {
      title: 'Pre-shipment statuses',
      tooltip:
        'Show movement on your tracking page before the order is fulfilled',
      enabled: growthPlan,
    },
    {
      title: 'Predictive estimated delivery',
      tooltip:
        'Set clear delivery expectations for your customers by displaying 90%+ accurate delivery dates',
      enabled: advancedPlan,
    },
    {
      title: 'Personalized analytics',
      tooltip:
        'Personalized reports that show the extra information and filters you need to help you drive the business in the right direction',
      enabled: advancedPlan,
    },
    {
      title: 'Slack updates',
      tooltip:
        'Send specific updates directly in your team slack channel for proactive resolution',
      enabled: advancedPlan,
    },
    {
      title: 'API access',
      tooltip: 'Access to full Rush API capabilities',
      enabled: advancedPlan,
    },
    {
      title: 'Onboarding',
      tooltip:
        'Make sure you are set up for success right from the start. We will set you up with the best practices',
      enabled: advancedPlan,
    },
    {
      title: 'Dedicated CS manager',
      tooltip:
        'Customer Success manager helps you gain maximum from Rush and the partner ecosystem',
      enabled: enterprisePlan,
    },
    {
      title: 'User roles and permissions',
      tooltip: 'Add all your team with different roles and permissions',
      enabled: enterprisePlan,
    },
    {
      title: 'Custom carrier integration',
      tooltip: `We'll help you integrate with external systems or carriers`,
      enabled: enterprisePlan,
    },
  ];
};

export const transformToNumStr = (value) => {
  return Number(value).toLocaleString('en-US');
};

import React, { useEffect, useState } from 'react';
import useUnmountSignal from 'use-unmount-signal';
import {
  Card,
  ResourceList,
  ResourceItem,
  TextStyle,
  SkeletonBodyText,
  Stack,
  Spinner,
  Modal,
  Badge,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { storeApi } from 'redux/store/storeApi';
import { useDispatch } from 'react-redux';
import { useGetOS2ThemesQuery } from '../os2TrackingApi';
import './styles.scss';

const StatusType = {
  Init: undefined,
  Loading: 'Loading',
  Installed: 'Installed',
  Not_Installed: 'Not-Installed',
};

function ThemesStatusModal({ onClose, onAdd, onClone, onRemove, setThemes }) {
  const unmountSignal = useUnmountSignal();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState({});
  const themesResponse = useGetOS2ThemesQuery();

  const {
    data: themes = [],
    isFetching: isThemesFetching,
    refetch: refetchThemes,
    isSuccess: isThemesLoaded,
  } = themesResponse || {};

  useEffect(() => {
    refetchThemes();
  }, []);

  useEffect(() => {
    isThemesLoaded && setThemes(themes);
  }, [setThemes, isThemesLoaded, themes]);

  useEffect(() => {
    if (isThemesLoaded) {
      themes.reduce((last, { id }) => {
        return last
          .then(() => {
            return !unmountSignal.aborted
              ? dispatch(
                  storeApi.endpoints.preInstallTemplate.initiate(id, {
                    forceRefetch: true,
                  })
                )
              : Promise.reject('rejected');
          })
          .then((response) => {
            const { data } = response;
            const installStatus = data?.installed
              ? StatusType.Installed
              : StatusType.Not_Installed;
            setStatus((status) => ({ ...status, [id]: installStatus }));
          })
          .then(() => {
            return new Promise((resolve) => {
              setTimeout(resolve, 1000);
            });
          })
          .catch(() => {});
      }, Promise.resolve());
    }
  }, [isThemesLoaded, themes, unmountSignal]);

  const getItemActions = (item) => {
    const { id } = item;
    if (status[id] === StatusType.Installed) {
      let actions = [
        {
          content: t('settings.os2_tracking_page.wizard.Clone'),
          onAction: () => onClone(id),
        },
        {
          content: t('settings.os2_tracking_page.wizard.remove'),
          onAction: () => onRemove(id, item.is_published),
          destructive: true,
        },
      ];
      if (item.is_published) {
        actions.unshift({
          content: t('settings.os2_tracking_page.wizard.add_tracking_page'),
          onAction: () => onAdd(id),
          primary: true,
        });
      }
      return actions;
    } else if (status[id] === StatusType.Not_Installed && item.is_published) {
      return [
        {
          content: t('settings.os2_tracking_page.wizard.add_tracking_page'),
          onAction: () => onAdd(id),
          primary: true,
        },
      ];
    } else {
      return null;
    }
  };

  const getStatusString = (id) => {
    if (status[id] === StatusType.Installed) {
      return t('settings.os2_tracking_page.wizard.app_installed');
    } else if (status[id] === StatusType.Not_Installed) {
      return t('settings.os2_tracking_page.wizard.app_not_installed');
    } else {
      return '';
    }
  };
  const getLabel = (theme) => {
    return theme.is_published ? (
      <>
        {theme.name} <Badge status="success">Published</Badge>
      </>
    ) : (
      theme.name
    );
  };

  return (
    <Modal
      iFrameName="themes-status-container"
      title={t('settings.os2_tracking_page.wizard.status_modal_title')}
      open={true}
      onClose={onClose}
    >
      <Card>
        <Card.Section>
          <p>{t('settings.os2_tracking_page.wizard.status_card_title')}</p>
          <br />
          {isThemesFetching ? (
            <SkeletonBodyText size="small" />
          ) : (
            <Card>
              <ResourceList
                items={themes}
                renderItem={(item) => {
                  const { id } = item;

                  return (
                    <ResourceItem
                      id={id}
                      shortcutActions={getItemActions(item)}
                      persistActions
                    >
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <h3>
                            <TextStyle variation="strong">
                              {getLabel(item)}
                            </TextStyle>
                          </h3>
                          <TextStyle variation="subdued">
                            {getStatusString(id)}
                          </TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                          {status[id] === StatusType.Init && (
                            <Spinner size="small"></Spinner>
                          )}
                        </Stack.Item>
                      </Stack>
                    </ResourceItem>
                  );
                }}
              />
            </Card>
          )}
        </Card.Section>
      </Card>
    </Modal>
  );
}
export default ThemesStatusModal;

import React from 'react';
import {
  Card,
  ResourceList,
  ResourceItem,
  TextStyle,
  Link,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function PageItems({ pages = [] }) {
  const [t] = useTranslation();
  return (
    <Card>
      <ResourceList
        items={pages}
        renderItem={(item) => {
          const { id, url, title, edit_page_url, editor_url } = item;
          const shortcutActions = [
            {
              content: t('settings.os2_tracking_page.page_item.primary_action'),
              onAction: () => {
                window.open(edit_page_url);
              },
            },
            {
              content: t(
                'settings.os2_tracking_page.page_item.secondary_action'
              ),
              onAction: () => {
                window.open(editor_url);
              },
            },
          ];

          return (
            <ResourceItem id={id} shortcutActions={shortcutActions}>
              <h3>
                <Link url={url} monochrome external>
                  <TextStyle variation="strong">{title}</TextStyle>
                </Link>
              </h3>
              <TextStyle variation="subdued">{url}</TextStyle>
            </ResourceItem>
          );
        }}
      />
    </Card>
  );
}
export default PageItems;

import React from 'react';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

function PerksSkeleton() {
  const [t] = useTranslation();

  return (
    <SkeletonPage title={t('perks.title')} subtitle={t('perks.description')}>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
        <br />
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
        <br />
        <TextContainer>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText />
        </TextContainer>
      </Card>
    </SkeletonPage>
  );
}
export default PerksSkeleton;

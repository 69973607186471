import {
  Card,
  Layout,
  Modal,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetOS2PageMetaQuery } from '../../os2TrackingApi';
import { useTranslation } from 'react-i18next';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function AddPageWizard({ onClose, showToast, selectedThemeId }) {
  const [t] = useTranslation();
  const [wizardData, setWizardData] = useState({
    style: '',
    layout: '',
    pageUrl: '',
    editorUrl: '',
    themeId: '',
    pagesPrefix: '',
    pageTitle: '',
    pageHandle: '',
  });
  // next button enabled status for all steps in wizard
  const [nextButtonStatus, setNextButtonStatus] = useState({
    1: false,
    2: false,
    3: true,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const {
    data: { style = [], layout = [], pages_prefix = '' } = {},
    isLoading,
  } = useGetOS2PageMetaQuery() || {};

  useEffect(() => {
    selectedThemeId &&
      setWizardData({ ...wizardData, themeId: selectedThemeId });
  }, [selectedThemeId]);

  useEffect(() => {
    if (pages_prefix) {
      setWizardData({ ...wizardData, pagesPrefix: pages_prefix });
    }
  }, [pages_prefix]);
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleStyleChange = (style) => {
    setWizardData({ ...wizardData, style });
    setNextButtonStatus({ ...nextButtonStatus, 1: true });
    handleNext();
  };

  const handleLayoutChange = (layout) => {
    setWizardData({ ...wizardData, layout });
    setNextButtonStatus({ ...nextButtonStatus, 2: true });
    handleNext();
  };

  const primaryAction = useMemo(() => {
    return {
      content: t('settings.os2_tracking_page.wizard.primary_action'),
      onAction: handleNext,
      disabled: !nextButtonStatus[currentStep],
    };
  }, [currentStep, handleNext]);

  const secondaryAction = useMemo(() => {
    if (currentStep === 1) {
      return [];
    }
    return [
      {
        content: t('settings.os2_tracking_page.wizard.secondary_action'),
        onAction: handleBack,
      },
    ];
  }, [currentStep, handleBack]);

  const renderStepComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            onChange={handleStyleChange}
            selected={wizardData.style}
            styleList={style}
          />
        );
      case 2:
        return (
          <Step2
            onChange={handleLayoutChange}
            selected={wizardData.layout}
            layoutList={layout}
          />
        );
      case 3:
        return (
          <Step3
            wizardData={wizardData}
            onClose={onClose}
            showToast={showToast}
            handleBack={handleBack}
          />
        );

      default:
        break;
    }
  };
  return (
    <Modal
      title={t('settings.os2_tracking_page.wizard.title')}
      open={true}
      onClose={onClose}
      primaryAction={currentStep !== 3 && primaryAction} // step 3 has its own actions for next and back
      secondaryActions={currentStep !== 3 && secondaryAction}
      large
    >
      {isLoading ? (
        <SkeletonPage>
          <Layout>
            <Layout.Section oneThird>
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card sectioned>
                <TextContainer>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </TextContainer>
              </Card>
            </Layout.Section>
          </Layout>
        </SkeletonPage>
      ) : (
        renderStepComponent()
      )}
    </Modal>
  );
}

export default AddPageWizard;

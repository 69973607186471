import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppProvider, Frame, Toast } from '@shopify/polaris';
import { connect, useDispatch, useSelector } from 'react-redux';
import TopBarMarkup from './components/TopBarMarkup';
import LeftNavigation from './components/LeftNavigation';
import TrackingManager from './components/TrackingManager';
import { Slide, ToastContainer } from 'react-toastify';
import SegmentAnalytics from './components/SegmentAnalytics';
import { Link, useHistory, useLocation } from 'react-router-dom';
import LogoComplete from './images/logo-complete.svg';
import VisitorRoutes from 'routes/VisitorRoutes';
import LoadingPage from 'components/LoadingPage';
import * as Sentry from '@sentry/browser';
import { useGetQuestionnaireQuery } from 'pages/Questionnaire/questionnaireApi';
import { shopActions } from 'redux/store/shopSlice';

import {
  baseActions,
  IsErrorToast,
  ShowToast,
  ToastMessage,
} from 'redux/store/baseSlice';
import {
  LEFT_NAVIGATION_EXCLUDED_URLS,
  LOCAL_STORAGE,
  TOP_BAR_EXCLUDED_URLS,
} from './Constants';
import PrivateRoutes from 'routes/PrivateRoutes';
import { PERMISSION_URLS } from 'config/urls';
import { USER_URLS } from 'config/urls';
import i18next from 'i18next';
import { ClientStorage } from 'ClientStorage';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';
import {
  useGetStoreStatisticsQuery,
  useGetUserLanguagesQuery,
  useGetUserDetailsQuery,
} from 'redux/store/commonBaseApis';
function MyAppProvider({ accessToken, shop }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { storeUUID } = shop;
  const { data: userLanguages = [], isLoading: isLangsLoading } =
    useGetUserLanguagesQuery();

  const {
    isLoading: isUserLoading,
    data: userInfo = {},
    isSuccess: isUserLoaded,
  } = useGetUserDetailsQuery(undefined, { skip: !accessToken });

  useEffect(() => {
    if (!isUserLoading && isUserLoaded && userLanguages.length) {
      let langToSelect = 'en';
      const storageLang = ClientStorage.get(LOCAL_STORAGE.LANGUAGE);
      if (storageLang) {
        langToSelect = storageLang;
      } else {
        const userLang = userInfo.language;
        const userLangISO =
          userLanguages.find(({ localisation_codes }) =>
            localisation_codes.includes(userLang)
          )?.language_iso_639 || userLang;
        langToSelect = userLangISO;
      }
      ClientStorage.set(LOCAL_STORAGE.TRACK_ANALYTICS, !userInfo.sudo);
      ClientStorage.set(LOCAL_STORAGE.LANGUAGE, langToSelect);
      i18next.changeLanguage(langToSelect);
    }
  }, [isUserLoaded, isUserLoading, userLanguages]);

  const showToast = useSelector(ShowToast);
  const toastMessage = useSelector(ToastMessage);
  const isErrorToast = useSelector(IsErrorToast);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((mNavigationActive) => !mNavigationActive),
    []
  );

  const skipPermissions = useSelector((state) => state.base.skipPermissions);
  const {
    isSuccess: gotPermissionsResponse,
    isLoading: isPermissionsLoading,
    data: permissionsData = {},
    refetch: refetchStatistics,
  } = useGetStoreStatisticsQuery(storeUUID, {
    skip: !storeUUID || !accessToken,
  });

  useGetFeaturesQuery(undefined, {
    skip: !storeUUID || !accessToken,
  });

  useEffect(() => {
    if (storeUUID) {
      refetchStatistics();
    }
  }, [storeUUID]);

  /**
   * if we don't have language in localstorage then wait for
   * user to get loaded to check user's selected language
   * Also wait for accessToken
   */
  const { data: questionnaire, status: questionnaireStatus } =
    useGetQuestionnaireQuery('', {
      skip:
        (!isUserLoaded && !ClientStorage.get(LOCAL_STORAGE.LANGUAGE)) ||
        !accessToken,
    });

  const isQuestionnaireLoaded = useMemo(() => {
    return (
      questionnaireStatus === 'fulfilled' || questionnaireStatus === 'rejected'
    );
  }, [questionnaireStatus]);

  Sentry.addBreadcrumb({
    category: 'route',
    level: 'info',
    data: { location: location, shop: shop },
  });

  useEffect(() => {
    if (gotPermissionsResponse) {
      dispatch(shopActions.setStoreTitle(permissionsData?.name));
      if (!skipPermissions) {
        if (permissionsData?.status === 'uninstalled') {
          history.push(PERMISSION_URLS.RECONNECT);
        } else if (permissionsData?.is_permissions_pending_approval) {
          history.push(PERMISSION_URLS.APPROVE);
        }
      }
    }
  }, [permissionsData, gotPermissionsResponse, skipPermissions, dispatch]);

  useEffect(() => {
    if (isQuestionnaireLoaded) {
      if (
        !questionnaire ||
        history.location.pathname === USER_URLS.QUESTIONNAIRE
      ) {
        return;
      }

      history.push(
        `${USER_URLS.QUESTIONNAIRE}?redirect=${history.location.pathname}`
      );
    }
  }, [questionnaire, isQuestionnaireLoaded]);

  function AdapterLink({ url, external, ...rest }) {
    if (!external) {
      return <Link to={url} {...rest} />;
    }

    const { children, ...linkProps } = rest;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" {...linkProps}>
        {' '}
        {children}
      </a>
    );
  }

  const toastMarkup = useMemo(() => {
    if (showToast && toastMessage) {
      return (
        <Toast
          content={toastMessage}
          onDismiss={() => {
            dispatch(baseActions.dismissToast());
          }}
          error={isErrorToast}
        />
      );
    }
  }, [showToast, toastMessage, dispatch, isErrorToast]);

  const theme = {
    logo: {
      width: 60,
      topBarSource: LogoComplete,
      url: storeUUID ? `/s/${storeUUID}` : '/s',
      accessibilityLabel: 'Rush',
    },
  };

  const excludeLeftBar = LEFT_NAVIGATION_EXCLUDED_URLS.some((url) =>
    location.pathname.endsWith(url)
  );

  const excludeTopBar = TOP_BAR_EXCLUDED_URLS.some((url) =>
    location.pathname.endsWith(url)
  );

  const showLoadingPage = useMemo(() => {
    return isLangsLoading || !isQuestionnaireLoaded || isPermissionsLoading;
  }, [isLangsLoading, isQuestionnaireLoaded, isPermissionsLoading]);

  let translations = useMemo(() => {
    try {
      const lang =
        ClientStorage.get(LOCAL_STORAGE.LANGUAGE) || i18next.language;
      return require(`@shopify/polaris/locales/${lang}.json`);
    } catch (e) {
      return require('@shopify/polaris/locales/en.json');
    }
  }, [i18next.language]);

  return (
    <AppProvider i18n={translations} linkComponent={AdapterLink} theme={theme}>
      <Frame
        topBar={
          accessToken &&
          isQuestionnaireLoaded &&
          !isPermissionsLoading &&
          !excludeTopBar ? (
            <TopBarMarkup
              toggleMobileNavigationActive={toggleMobileNavigationActive}
            />
          ) : undefined
        }
        navigation={
          accessToken &&
          isQuestionnaireLoaded &&
          !isPermissionsLoading &&
          !excludeLeftBar ? (
            <LeftNavigation />
          ) : undefined
        }
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        <TrackingManager />

        <div className="main">
          {accessToken ? (
            showLoadingPage ? (
              <LoadingPage />
            ) : (
              <PrivateRoutes />
            )
          ) : (
            <VisitorRoutes />
          )}
        </div>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          limit={1}
          transition={Slide}
        />
        <SegmentAnalytics />
        {toastMarkup}
      </Frame>
    </AppProvider>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.visitor.accessToken,
  shop: state.shop,
});

export default connect(mapStateToProps, null)(MyAppProvider);

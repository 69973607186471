const { API } = require('../../helpers/api');
const { API_URL } = require('../../config');

function getSubscriptionPlans(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/subscription_plans_with_groups`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getCurrentBillingPlan(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/billing/plans/current`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function postSubscriptionPlan(accessToken, shopifyId, planId) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/charges/plan/${planId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getChargeLimit(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/charge_limit?include_forecasted_usage=1`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function updateChargeLimit(accessToken, shopifyId, amount) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/charges/charge_limit/${amount}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getBillingTransactions(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/billing/plans/transactions?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function exportBillingTransactions(accessToken, shopifyId, params) {
  const urlParams = new URLSearchParams({ ...params }).toString();
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/billing/plans/transactions/export?${urlParams}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getPlanSuggestion(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/subscription_plans/suggest`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getBillingSummary(accessToken, shopifyId) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/billing/plans/transactions/summary`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getChargeByToken(accessToken, shopifyId, token) {
  return API.request({
    method: 'GET',
    url: `${API_URL}/stores/${shopifyId}/charges/${token}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function createBillingPlan(accessToken, shopifyId, requestBody) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/billing/plans/`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function changeChargeLimit(accessToken, shopifyId, requestBody) {
  return API.request({
    method: 'PUT',
    url: `${API_URL}/stores/${shopifyId}/charge_limit`,
    data: requestBody,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function applyPromoCode(accessToken, shopifyId, promoCode) {
  const url = `${API_URL}/stores/${shopifyId}/promo/${promoCode}/applicable`;
  return API.request({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function postPromoCharges(accessToken, shopifyId, promoCode) {
  return API.request({
    method: 'POST',
    url: `${API_URL}/stores/${shopifyId}/charges/promo/${promoCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function getFeedbackModalCheck(accessToken, shopifyId) {
  const url = `${API_URL}/stores/${shopifyId}/checks/can_write_review`;
  return API.request({
    method: 'GET',
    url: url,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

module.exports = {
  getSubscriptionPlans,
  getCurrentBillingPlan,
  postSubscriptionPlan,
  getChargeLimit,
  updateChargeLimit,
  getBillingTransactions,
  exportBillingTransactions,
  getPlanSuggestion,
  getBillingSummary,
  getChargeByToken,
  createBillingPlan,
  changeChargeLimit,
  applyPromoCode,
  postPromoCharges,
  getFeedbackModalCheck,
};

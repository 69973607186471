import React, { useEffect } from 'react';
import { useGetHealthQuery } from 'redux/store/commonBaseApis';

export default function APIHealth() {
  const { isSuccess, inLoading, refetch } = useGetHealthQuery();
  useEffect(() => {
    refetch();
  }, [refetch]);

  if (inLoading) {
    return <div>Checking</div>;
  }
  return isSuccess ? (
    <div>Application is live.</div>
  ) : (
    <div>Error, Can&apos;t connect to API.</div>
  );
}

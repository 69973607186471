import React from 'react';
import { Card, SkeletonBodyText } from '@shopify/polaris';
import './style.scss';

function TableSkeleton() {
  return (
    <div className="shipmentsSkeleton">
      <Card>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
        <Card.Section>
          <div className="skeleton-tbl">
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
            <SkeletonBodyText lines={1} />
          </div>
        </Card.Section>
      </Card>
    </div>
  );
}

export default TableSkeleton;

import { connect } from 'react-redux';
import Configuration from './Configuration';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);

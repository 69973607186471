import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Card, TextField, TextContainer } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import ResetTemplateModal from './ResetTemplateModal';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function ShipmentTrackingBy({
  dispatchForTrackingPage,
  trackingPageState,
  getHTMLTemplate,
  shop,
  templateHTML,
}) {
  const [t] = useTranslation();
  const [pageSource, setPageSource] = useState(null);
  const handleChange = useCallback((value) => {
    setPageSource(value);
  }, []);
  const handleBlur = useCallback(() => {
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.UPDATE,
      payload: { page_source: pageSource },
    });
  }, [dispatchForTrackingPage, pageSource]);

  useEffect(() => {
    if (!trackingPageState.page_source && !templateHTML) {
      getHTMLTemplate({
        theme: trackingPageState.theme,
        style: trackingPageState.page_layout,
      });
    } else if (trackingPageState.page_source) {
      setPageSource(trackingPageState.page_source);
    } else if (!trackingPageState.page_source && templateHTML) {
      setPageSource(templateHTML);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: { page_source: templateHTML },
      });
    }
  }, [
    trackingPageState,
    templateHTML,
    getHTMLTemplate,
    dispatchForTrackingPage,
  ]);

  const [resetModalActive, setResetModalActive] = useState(false);

  const handleResetModalChange = useCallback(
    () => setResetModalActive((active) => !active),
    []
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.page_source.section_title')}
      description={t('settings.tracking_page.page_source.section_description')}
    >
      <Card
        title={t('settings.tracking_page.page_source.card_title')}
        actions={[
          {
            content: t('settings.tracking_page.page_source.reset_btn'),
            onAction: () => {
              handleResetModalChange();
            },
          },
        ]}
        sectioned
      >
        <TextContainer>
          <TranslateLink
            text={t('settings.tracking_page.page_source.render_label', {
              cypher: shop.storeUUID,
            })}
          />
          <TextField
            value={pageSource}
            onChange={handleChange}
            multiline={10}
            onBlur={handleBlur}
          />
        </TextContainer>
      </Card>
      <ResetTemplateModal
        resetModalActive={resetModalActive}
        handleResetModalChange={handleResetModalChange}
        dispatchForTrackingPage={dispatchForTrackingPage}
      />
    </Layout.AnnotatedSection>
  );
}

import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { withRouter, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/toaster.scss';
import { GOOGLE_TAG_ID, SENTRY_URL, STAGE } from 'config';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import './styles/custom.scss';
import MyAppProvider from './MyAppProvider';
import store from './redux/store';

function App() {
  useEffect(() => {
    if (SENTRY_URL) {
      Sentry.init({
        dsn: SENTRY_URL,
        environment: STAGE,
        ignoreErrors: ['y is not a function', /getElementsByTagName/],
      });
    }
  }, []);

  const gtmParams = { id: GOOGLE_TAG_ID, dataLayerName: 'customDataLayerName' };

  return (
    <GTMProvider state={gtmParams}>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <MyAppProvider />
        </ReduxProvider>
      </BrowserRouter>
    </GTMProvider>
  );
}

export default withRouter(App);

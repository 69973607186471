const { createReducer } = require('reduxsauce');
const reportsActions = require('./reports.actions');
const initialState = require('./reports.initialState');

const getPerformanceReport = (state) => ({
  ...state,
  reportsLoading: true
});
const getPerformanceReportSuccess = (state, action) => {
  return {
    ...state,
    performanceReport: action.data.cards,
    performanceReportTitle: action.data.title,
    performanceReportSubTitle: action.data.subtitle,
    performanceReportNote: action.data.update_note,
    performanceReportDateRange: action.data.possible_range,
    reportsLoading: false
  };
};
const getPerformanceReportFailure = (state) => ({
  ...state,
  reportsLoading: false
});

const getReport = (state) => ({
  ...state,
  reportsLoading: true
});
const getReportSuccess = (state, action) => {
  let response = {
    ...state,
    reportsLoading: false
  };
  response[`report_${action.reportType}`] = action.data;
  return response;
};
const getReportFailure = (state) => ({
  ...state,
  reportsLoading: false
});

const { Types } = reportsActions;

const HANDLERS = {
  [Types.GET_PERFORMANCE_REPORT]: getPerformanceReport,
  [Types.GET_PERFORMANCE_REPORT_SUCCESS]: getPerformanceReportSuccess,
  [Types.GET_PERFORMANCE_REPORT_FAILURE]: getPerformanceReportFailure,

  [Types.GET_REPORT]: getReport,
  [Types.GET_REPORT_SUCCESS]: getReportSuccess,
  [Types.GET_REPORT_FAILURE]: getReportFailure
};

module.exports = createReducer(initialState, HANDLERS);

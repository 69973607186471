import { storeApi } from 'redux/store/storeApi';

export const os2TrackingApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getOS2TrackingPages: builder.query({
      query: () => {
        return {
          url: '/os2/tracking-page/pages?published_status=published&template=app-rush-tracking-page',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['OS2Tracking'],
    }),
    getOS2PageMeta: builder.query({
      query: () => {
        return {
          url: '/os2/tracking-page/meta',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getOS2Themes: builder.query({
      query: () => {
        return {
          url: '/storefront/themes',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    preInstallTemplate: builder.query({
      query: (id) => {
        return {
          url: `/os2/tracking-page/install/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    addTrackingPage: builder.mutation({
      query: (data) => {
        return {
          url: `/os2/tracking-page/pages`,
          method: 'POST',
          body: { ...data },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['OS2Tracking'],
    }),
    cloneTemplate: builder.mutation({
      query: ({ fromID, toID }) => {
        return {
          url: `/os2/tracking-page/clone/${fromID}/to/${toID}`,
          method: 'POST',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    uninstallTemplate: builder.mutation({
      query: ({ id, with_pages }) => {
        return {
          url: `/os2/tracking-page/uninstall/${id}`,
          method: 'DELETE',
          body: { with_pages },
        };
      },
      invalidatesTags: (result, error, arg) => {
        if (arg.with_pages) {
          return ['OS2Tracking'];
        } else {
          return [];
        }
      },
    }),
  }),
});

export const {
  useGetOS2TrackingPagesQuery,
  useGetOS2PageMetaQuery,
  useGetOS2ThemesQuery,
  useLazyPreInstallTemplateQuery,
  useInstallTemplateMutation,
  useCloneTemplateMutation,
  useUninstallTemplateMutation,
  useAddTrackingPageMutation,
} = os2TrackingApi;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, TextStyle, Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { useHistory } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';

export default function RecoverSuccess({
  getVisitorLanguages,
  changeVisitorLanguage,
  visitorLanguages,
  forgotPassword,
  selectedVisitorLanguage,
  visitorToast,
  resetVisitorToast,
  loading,
  newEmail,
  resetNewEmail,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
    return () => {
      resetNewEmail();
    };
  }, []);

  const handleResendInstructions = () => {
    forgotPassword(newEmail);
  };

  const handleSubmit = () => {
    history.push(VISITOR_URLS.LOGIN);
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('visitor-app:Instructions sent')}
          subtitle={
            <TranslateLink
              text={t(
                'visitor-app:password_recovery_successs_screen.sub_title',
                {
                  email: newEmail,
                }
              )}
            />
          }
          button={t(
            'visitor-app:password_recovery_successs_screen.back_to_sign_in_btn'
          )}
          footer1={
            <TextStyle>
              <Link onClick={handleResendInstructions}>
                {t(
                  'visitor-app:password_recovery_successs_screen.resent_instructions_again_btn'
                )}
              </Link>
            </TextStyle>
          }
          onSubmit={handleSubmit}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

const { createActions } = require('reduxsauce');

module.exports = createActions({
  getSubscriptionPlans: ['params'],
  getSubscriptionPlansSuccess: ['subscription_plans'],
  getSubscriptionPlansFailure: ['error'],

  getCurrentBillingPlan: ['params'],
  getCurrentBillingPlanSuccess: ['currentPlan'],
  getCurrentBillingPlanFailure: ['error'],

  postSubscriptionPlan: ['planId'],
  resetSubscriptionPlan: null,
  postSubscriptionPlanSuccess: ['chargeDetail'],
  postSubscriptionPlanFailure: ['error'],

  getChargeLimit: null,
  getChargeLimitSuccess: ['chargeLimitDetail'],
  getChargeLimitFailure: ['error'],

  updateChargeLimit: ['amount'],
  updateChargeLimitSuccess: ['chargeLink'],
  updateChargeLimitFailure: ['error'],

  changeChargeLimit: ['params'],
  changeChargeLimitSuccess: ['chargeLimitDetail'],
  changeChargeLimitFailure: ['error'],

  getBillingTransactions: ['params'],
  getBillingTransactionsSuccess: ['billingTransactions'],
  getBillingTransactionsFailure: ['error'],

  exportBillingTransactions: ['params'],
  exportBillingTransactionsSuccess: ['billingTransactions'],
  exportBillingTransactionsFailure: ['error'],
  disableExportBillingTransactions: null,

  getPlanSuggestion: null,
  disablePlanSuggestion: null,
  getPlanSuggestionSuccess: ['suggestion'],
  getPlanSuggestionFailure: ['error'],

  getBillingSummary: null,
  getBillingSummarySuccess: ['summary'],
  getBillingSummaryFailure: ['error'],

  getChargeByToken: ['token'],
  getChargeByTokenSuccess: ['charge'],
  getChargeByTokenFailure: ['error'],

  createBillingPlan: ['params'],
  disableBillingPlan: null,
  createBillingPlanSuccess: ['billingPlan'],
  createBillingPlanFailure: ['error'],

  applyPromoCode: ['params'],
  applyPromoCodeSuccess: ['promo'],
  applyPromoCodeFailure: ['errors'],

  postPromoCharges: ['params'],
  postPromoChargesSuccess: ['chargeDetail'],
  postPromoChargesFailure: ['errors'],

  showPromoCodeModal: null,
  showPromoCodeConfirmationModal: null,

  showFeedbackModal: null,

  getFeedbackModalCheck: null,
  getFeedbackModalCheckSuccess: ['data'],
  getFeedbackModalCheckFailure: null,

  handleSubscriptionPlansModalChange: null,

  setBillingToasterMessage: ['message'],
  resetBillingToasterMessage: null,
});

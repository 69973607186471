import React from 'react';

export const LineWithBreak = () => {
  return <div style={style} />;
};

const style = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#A7A7A7',
  margin: 1,
  borderBottom: 0.3,
};

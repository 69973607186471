import {Card, SkeletonBodyText, SkeletonTabs} from '@shopify/polaris';
import React from 'react';

function TabsTableSkeleton() {
  return (
    <Card>
      <SkeletonTabs />
      <Card.Section>
      <SkeletonBodyText />
      <SkeletonBodyText />
      </Card.Section>
    </Card>
  );
}

export default TabsTableSkeleton
const { call, put, takeEvery, select } = require('redux-saga/effects');
const billingActions = require('./billing.actions');
const billingWebServices = require('./billing.webServices');

const { Types, Creators } = billingActions;
const {
  getSubscriptionPlans,
  getCurrentBillingPlan,
  postSubscriptionPlan,
  getChargeLimit,
  updateChargeLimit,
  getBillingTransactions,
  exportBillingTransactions,
  getPlanSuggestion,
  getBillingSummary,
  getChargeByToken,
  createBillingPlan,
  changeChargeLimit,
  applyPromoCode,
  postPromoCharges,
  getFeedbackModalCheck,
} = billingWebServices;

export function* watchBillingRequests() {
  yield takeEvery(Types.GET_SUBSCRIPTION_PLANS, requestGetSubscriptionPlans);
  yield takeEvery(Types.GET_CURRENT_BILLING_PLAN, requestGetCurrentBillingPlan);
  yield takeEvery(Types.POST_SUBSCRIPTION_PLAN, requestPostSubscriptionPlan);
  yield takeEvery(Types.GET_CHARGE_LIMIT, requestGetChargeLimit);
  yield takeEvery(Types.UPDATE_CHARGE_LIMIT, requestUpdateChargeLimit);
  yield takeEvery(Types.CHANGE_CHARGE_LIMIT, requestChangeChargeLimit);
  yield takeEvery(
    Types.GET_BILLING_TRANSACTIONS,
    requestGetBillingTransactions
  );
  yield takeEvery(
    Types.EXPORT_BILLING_TRANSACTIONS,
    requestExportBillingTransactions
  );
  yield takeEvery(Types.GET_PLAN_SUGGESTION, requestGetPlanSuggestion);
  yield takeEvery(Types.GET_BILLING_SUMMARY, requestGetBillingSummary);
  yield takeEvery(Types.GET_CHARGE_BY_TOKEN, requestGetChargeByToken);
  yield takeEvery(Types.CREATE_BILLING_PLAN, requestCreateBillingPlan);
  yield takeEvery(Types.APPLY_PROMO_CODE, requestApplyPromoCode);
  yield takeEvery(Types.POST_PROMO_CHARGES, requestPostPromoCharges);
  yield takeEvery(Types.GET_FEEDBACK_MODAL_CHECK, requestGetFeedbackModalCheck);
}

function* requestGetSubscriptionPlans(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getSubscriptionPlans,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getSubscriptionPlansSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getSubscriptionPlansFailure(error.data.errors));
    }
  }
}

function* requestGetCurrentBillingPlan(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    // LANGUAGE ENABLED THROUGH PARAMS.
    const response = yield call(
      getCurrentBillingPlan,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.getCurrentBillingPlanSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getCurrentBillingPlanFailure(error.data.errors));
    }
  }
}

function* requestPostSubscriptionPlan(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      postSubscriptionPlan,
      accessToken,
      storeUuid,

      action.planId
    );
    yield put(Creators.postSubscriptionPlanSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.postSubscriptionPlanFailure(error.data.errors));
    }
  }
}

function* requestGetChargeLimit() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getChargeLimit, accessToken, storeUuid);
    yield put(Creators.getChargeLimitSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getChargeLimitFailure(error.data.errors));
    }
  }
}

function* requestUpdateChargeLimit(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      updateChargeLimit,
      accessToken,
      storeUuid,

      action.amount
    );
    yield put(Creators.updateChargeLimitSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.updateChargeLimitFailure(error.data.errors));
    }
  }
}

function* requestGetBillingTransactions(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getBillingTransactions,
      accessToken,
      storeUuid,

      action.params
    );
    yield put(Creators.getBillingTransactionsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getBillingTransactionsFailure(error.data.errors));
    }
  }
}

function* requestExportBillingTransactions(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      exportBillingTransactions,
      accessToken,
      storeUuid,

      action.params
    );
    yield put(Creators.exportBillingTransactionsSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.exportBillingTransactionsFailure(error.data.errors));
    }
  }
}

function* requestGetPlanSuggestion() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getPlanSuggestion, accessToken, storeUuid);
    yield put(Creators.getPlanSuggestionSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getPlanSuggestionFailure(error.data.errors));
    }
  }
}

function* requestGetBillingSummary() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getBillingSummary, accessToken, storeUuid);
    yield put(Creators.getBillingSummarySuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getBillingSummaryFailure(error.data.errors));
    }
  }
}

function* requestGetChargeByToken(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      getChargeByToken,
      accessToken,
      storeUuid,
      action.token
    );
    yield put(Creators.getChargeByTokenSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.getChargeByTokenFailure(error.data.errors));
    }
  }
}

function* requestCreateBillingPlan(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      createBillingPlan,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.createBillingPlanSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.createBillingPlanFailure(error.data.errors));
    }
  }
}

function* requestChangeChargeLimit(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      changeChargeLimit,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.changeChargeLimitSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.changeChargeLimitFailure(error.data.errors));
    }
  }
}

function* requestApplyPromoCode(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      applyPromoCode,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.applyPromoCodeSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.applyPromoCodeFailure(error.data.errors));
    }
  }
}

function* requestPostPromoCharges(action) {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(
      postPromoCharges,
      accessToken,
      storeUuid,
      action.params
    );
    yield put(Creators.postPromoChargesSuccess(response.data));
  } catch (error) {
    if (error) {
      yield put(Creators.postPromoChargesFailure(error.data.errors));
    }
  }
}

function* requestGetFeedbackModalCheck() {
  try {
    const accessTokenSelector = (state) => state.visitor.accessToken;
    const accessToken = yield select(accessTokenSelector);
    const storeUuidSelector = (state) => state.shop.storeUUID;
    const storeUuid = yield select(storeUuidSelector);
    const response = yield call(getFeedbackModalCheck, accessToken, storeUuid);
    yield put(Creators.getFeedbackModalCheckSuccess(response.data.data.status));
  } catch (error) {
    if (error) {
      yield put(Creators.getFeedbackModalCheckFailure(error.data.errors));
    }
  }
}

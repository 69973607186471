import { all } from 'redux-saga/effects';
import { watchSettingsRequests } from '../settings';
import { watchTrackingRequests } from '../tracking';
import { watchBillingRequests } from '../billing';
import { watchReportsRequests } from '../reports';
import { watchVisitorRequests } from '../visitor';

export function* rootSaga() {
  yield all([
    watchSettingsRequests(),
    watchTrackingRequests(),
    watchBillingRequests(),
    watchReportsRequests(),
    watchVisitorRequests(),
  ]);
}

export const VISITOR_URLS = {
  LOGIN: '/v/login',
  REGISTER: '/v/register',
  REGISTER_BY_INVITE: '/v/register-by-invite',
  RECOVER: '/v/recover',
  RECOVER_SUCCESS: '/v/recover-success',
  RECOVER_PASSWORD: '/v/recover-password',
  EMAIL_VALIDATION: '/v/email-validation',
  CHANGE_EMAIL: '/v/change-email',
};
export const PERMISSION_URLS = {
  APPROVE: '/permissions/approve',
  RECONNECT: '/permissions/reconnect',
};
export const STORE_URLS = {
  SMART_EDD: '/addons/smart-estimated-delivery-date',
  ONBOARDING: '/onboarding',
  DASHBOARD: '/dashboard',
  SHIPMENTS: '/shipments',
  SHIPMENTS3: '/shipments3',
  SHIPMENTS_BLACKLISTING: '/shipments/blacklisting',
  SHIPMENTS_PRE_SHIPMENT_TIMELINE: '/shipments/pre-shipment-timeline',
  SHIPMENTS_CONFIGURATIONS: '/shipments/configuration',
  TRACKING_PAGES: '/tracking-pages',
  BILLING: '/billing',
  BILLING_DETAIL: '/billing/detail',
  REPORTS: '/reports',
  HELP: '/help',
  CARRIER_MASKING: '/shipments/carrier-masking',

  NOTIFICATIONS: '/notifications',
  EMAIL_NOTIFICATION: '/notifications/emailNotification',
  EMAIL_NOTIFICATION_RULE: '/notifications/emailNotificationRule',
  PAYPAL_CONFIG: '/notifications/paypal-config',
  PAYPAL_TRACKINGS: '/notifications/paypal-trackings',
  KLAVIYO: '/notifications/klaviyo',
  OMNISEND: '/notifications/omnisend',

  CONVERSIONS: '/conversions',
  ADDONS: '/addons',
  PRODUCT_REVIEWS: '/addons/product-reviews',
  THANK_YOU: '/addons/thankyou',
  SMS_NOTIFICATION: '/addons/smsNotification',
  SMS_NOTIFICATION_RULE: '/addons/SMSNotificationRule',
  UPSELLS: '/addons/upsells',
  MERCHANT_NOTE: '/addons/merchant-note',
  SHIP_BOB_CONFIG: '/addons/shipbob-config',
  PERKS: '/perks',
};

export const USER_URLS = {
  DETAILS: '/u/details',
  QUESTIONNAIRE: '/u/questionnaire',
  ADD_STORE: '/u/add-store',
};

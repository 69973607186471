import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import { settingsActions } from 'redux/settings';
import LiveTrackingPage from './LiveTrackingPage';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    trackingDetailMeta: state.trackingDetail.meta,
  };
}

const mapDispatchToProps = {
  getTrackingPageDetail: trackingActions.Creators.getTrackingPageDetail,
  showUnsavedModal: settingsActions.Creators.showUnsavedModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrackingPage);

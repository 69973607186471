import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Icon,
  Card,
  Toast,
  DataTable,
  SkeletonBodyText,
  Pagination,
} from '@shopify/polaris';
import DateRange from 'components/DateRange';
import { ExportMinor } from '@shopify/polaris-icons';
import stringHelper from 'helpers/string';
import {
  CURRENT_MONTH,
  PREVIOUS_MONTH,
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_LAST_30_DAYS,
} from 'Constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './style.scss';
import SmallTopMessage from 'components/SmallTopMessage';
import _ from 'lodash';
export default function BillingDetail({
  getBillingTransactions,
  exportBillingTransactions,
  billingTransactions,
  exportMessage,
  exportLoading,
  disableExportBillingTransactions,
  ...props
}) {
  const [t] = useTranslation();
  const queryString = stringHelper.parseQueryString(props.location.search);
  const [toasterActive, setToasterActive] = useState(false);
  const [toasterText, setToasterText] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedDates, setSelectedDates] = useState({
    start: moment().startOf('month')._d,
    end: moment()._d,
  });

  useEffect(() => {
    if (queryString.month === CURRENT_MONTH) {
      setSelectedDates({
        start: moment().startOf('month')._d,
        end: moment()._d,
      });
    } else if (queryString.month === PREVIOUS_MONTH) {
      setSelectedDates({
        start: moment().subtract(1, 'month').startOf('month')._d,
        end: moment().subtract(1, 'month').endOf('month')._d,
      });
    }
    setPage(1);
  }, [queryString.month, setPage]);

  let rows = [];
  if (!_.isNull(billingTransactions.billingPlanTransactions)) {
    rows = billingTransactions.billingPlanTransactions.map((transaction) => {
      return [
        transaction.descriptor,
        `$${transaction.price.toFixed(2)}`,
        transaction.feature_name,
        moment(transaction.created_at).format('D MMMM, YYYY'),
      ];
    });
  }
  useEffect(() => {
    // selectedDates
    getBillingTransactions({
      from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
      to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      page: page,
    });
  }, [getBillingTransactions, selectedDates, page]);

  const getExportFileLink = () => {
    exportBillingTransactions({
      from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
      to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
    });
  };

  useEffect(() => {
    if (exportMessage) {
      setToasterText(exportMessage);
      setToasterActive(true);
    }
  }, [exportMessage]);

  const closeToasterActive = useCallback(() => {
    setToasterActive(false);
    disableExportBillingTransactions();
  }, [disableExportBillingTransactions]);
  const ToastToasterActiveMarkup = toasterActive ? (
    <Toast content={toasterText} onDismiss={closeToasterActive} />
  ) : null;

  return (
    <>
      <SmallTopMessage />
      <Page
        title={t('billing_details.header.title')}
        breadcrumbs={[
          { content: t('navigation.item.billing'), url: '/billing' },
        ]}
        secondaryActions={[
          {
            content: t('billing_details.header.download_csv'),
            accessibilityLabel: t('billing_details.header.download_csv'),
            icon: () => {
              return !exportLoading ? (
                <Icon source={ExportMinor} color="inkLighter" />
              ) : (
                ''
              );
            },
            onAction: !exportLoading ? getExportFileLink : null,
            loading: exportLoading,
            disabled: exportLoading,
          },
        ]}
      >
        <div className="TimeSelection">
          <div>
            <DateRange
              fnSelectDates={setSelectedDates}
              propsSelectedDates={selectedDates}
              disableDates={{ end: moment()._d }}
              selectedDropDownItem={DATE_RANGE_VALUE_LAST_30_DAYS}
            />
          </div>
        </div>
        {(_.isNull(billingTransactions.billingPlanTransactions) ||
          props.loading) && (
          <Card sectioned>
            <SkeletonBodyText />
            <br />
            <SkeletonBodyText />
          </Card>
        )}
        {!_.isNull(billingTransactions.billingPlanTransactions) &&
          !props.loading && (
            <>
              <Card>
                <DataTable
                  columnContentTypes={['text', 'numeric', 'text', 'numeric']}
                  headings={[
                    t('billing_details.table.column_description'),
                    t('billing_details.table.column_cost'),
                    t('billing_details.table.column_category'),
                    t('billing_details.table.column_date'),
                  ]}
                  rows={rows}
                  footerContent={t('billing_details.table.summary', {
                    startResult: billingTransactions.meta.from,
                    endResult: billingTransactions.meta.to,
                    total_results: billingTransactions.meta.total,
                  })}
                />
              </Card>
              <div className="BillingDetail-Pagination">
                <Pagination
                  hasPrevious={!_.isNull(billingTransactions.links.prev)}
                  onPrevious={() => {
                    setPage(
                      parseInt(billingTransactions.meta.current_page) - 1
                    );
                  }}
                  hasNext={!_.isNull(billingTransactions.links.next)}
                  onNext={() => {
                    setPage(
                      parseInt(billingTransactions.meta.current_page) + 1
                    );
                  }}
                />
              </div>
            </>
          )}
        {ToastToasterActiveMarkup}
      </Page>
    </>
  );
}

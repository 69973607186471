import React, { useState, useEffect, useCallback } from 'react';
import { Page, Layout, Loading, FooterHelp } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import trim from 'lodash/trim';
import { privateKeyValidator, publicKeyValidator } from 'Utils/utils';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import SmallTopMessage from 'components/SmallTopMessage';
import KlaviyoDetails from './KlaviyoDetails/KlaviyoDetails';
import KlaviyoPageCustomization from './KlaviyoPageCustomization';
import KlaviyoActions from './KlaviyoActions';
import KlaviyoFlows from './KlaviyoFlows/KlaviyoFlows';
import KlaviyoFlowTable from './KlaviyoFlowTable/KlaviyoFlowTable';
import SaveBar from './SaveBar';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import SettingsToaster from '../SettingsToaster';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
import Subtitle from 'components/Subtitle';
import i18next from 'i18next';
export default function KlaviyoPage({
  showUnsavedModal,
  getKlaviyo,
  saveKlaviyo,
  loading = true,
  klaviyoConfig,
  klaviyoMeta,
  resetKlaviyo,
  klaviyoConfigSaved,
  getKlaviyoSummaryFlow,
  klaviyoFlowSummary,
  loadingFlow,
  loadingFlowError,
  loadingFeatureId,
  featureRequest,
  resetFeatureRequest,
  shop,
  // klaviyoError,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  const [localKlaviyoObj, setLocalKlaviyoObj] = useState({});

  const [publicApiKeyError, setPublicApiKeyError] = useState(null);
  const [privateApiKeyError, setPrivateApiKeyError] = useState(null);
  const [privateApiKey, setPrivateApiKey] = useState(null);

  const [publicApiKey, setPublicApiKey] = useState(null);
  const [selectedTrigger, setSelectedTrigger] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [refreshedAtDate, setRefreshedAtDate] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (klaviyoConfigSaved) {
      getKlaviyo();
      privateApiKey && getKlaviyoSummaryFlow(privateApiKey);
      resetKlaviyo();
    }
  }, [
    klaviyoConfigSaved,
    t,
    resetKlaviyo,
    getKlaviyo,
    getKlaviyoSummaryFlow,
    privateApiKey,
  ]);

  useEffect(() => {
    resetKlaviyo();
    getKlaviyo();
  }, [getKlaviyo, resetKlaviyo, i18next.language]);

  useEffect(() => {
    if (featureRequest) {
      setIsUpdated(false);
      getKlaviyo();
      resetFeatureRequest();
      setPrivateApiKeyError(false);
      setPublicApiKeyError(false);
    }
  }, [getKlaviyo, resetFeatureRequest, featureRequest]);

  useEffect(() => {
    if (klaviyoFlowSummary) {
      const { refreshed_at = '' } = klaviyoFlowSummary;
      setRefreshedAtDate(refreshed_at);
    }
  }, [klaviyoFlowSummary]);

  useEffect(() => {
    if (
      !loadingFlowError &&
      !loadingFlow &&
      klaviyoFlowSummary === undefined &&
      !isEmpty(privateApiKey)
    ) {
      privateApiKey !== null &&
        privateKeyValidator(trim(privateApiKey)) &&
        getKlaviyoSummaryFlow(privateApiKey);
    }
  }, [
    loadingFlow,
    getKlaviyoSummaryFlow,
    loadingFlowError,
    klaviyoFlowSummary,
    privateApiKey,
    klaviyoConfigSaved,
  ]);

  useEffect(() => {
    if (klaviyoConfig?.hasOwnProperty('is_active') && !loading && !isUpdated) {
      setLocalKlaviyoObj({
        config: klaviyoConfig,
        meta: klaviyoMeta,
        isUpdated: false,
      });
    }
  }, [klaviyoMeta, klaviyoConfig, loading, isUpdated]);

  const discardChanges = useCallback(() => {
    setIsUpdated(false);
    setPrivateApiKeyError(false);
    setPublicApiKeyError(false);

    setLocalKlaviyoObj({
      config: klaviyoConfig,
      meta: klaviyoMeta,
      isUpdated: false,
    });
  }, [klaviyoMeta, klaviyoConfig]);

  const redirectBackToPrevious = useCallback(() => {
    history.push(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
  }, [history, shop.storeUUID]);

  const callbackFunction = useCallback(() => {
    discardChanges();
    redirectBackToPrevious();
  }, [discardChanges, redirectBackToPrevious]);

  const redirectBack = useCallback(() => {
    resetKlaviyo();

    if (klaviyoConfigSaved) {
      showUnsavedModal(callbackFunction);
      return;
    }

    redirectBackToPrevious();
  }, [
    showUnsavedModal,
    resetKlaviyo,
    redirectBackToPrevious,
    callbackFunction,
    klaviyoConfigSaved,
  ]);

  useEffect(() => {
    if (!isEmpty(localKlaviyoObj.config) && localKlaviyoObj.config.triggers) {
      const { config } = localKlaviyoObj;
      const temp = config.triggers
        .map((trigger) => (trigger.is_active ? trigger.trigger_slug : null))
        .filter((item) => !isNull(item));
      setPublicApiKey(config.public_api_key);
      setPrivateApiKey(config.private_api_key);
      setSelectedLanguage(config.language);
      setSelectedTrigger(temp);
    }
  }, [klaviyoConfig, localKlaviyoObj]);

  const updateKlaviyoPayload = () => {
    setPublicApiKeyError(null);
    setPrivateApiKeyError(null);
    setIsUpdated(true);
  };

  const submit = () => {
    if (
      !publicApiKey ||
      isEmpty(trim(publicApiKey)) ||
      !publicKeyValidator(trim(publicApiKey))
    ) {
      setPublicApiKeyError(t('klaviyo.modal.public_api_key_error_text'));
      return;
    }
    if (
      !privateApiKey ||
      isEmpty(trim(privateApiKey)) ||
      !privateKeyValidator(trim(privateApiKey))
    ) {
      setPrivateApiKeyError(t('klaviyo.modal.private_api_key_error_text'));
      return;
    }
    const tempTriggers = klaviyoConfig.triggers.map((trigger) => {
      if (selectedTrigger.includes(trigger.trigger_slug)) {
        return { ...trigger, is_active: true };
      } else {
        return { ...trigger, is_active: false };
      }
    });
    saveKlaviyo({
      public_api_key: trim(publicApiKey),
      private_api_key: trim(privateApiKey),
      triggers: tempTriggers,
      language: selectedLanguage,
    });
    setIsUpdated(false);
  };

  // if (klaviyoError) {
  //   history.push(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
  //   return;
  // }

  if (!localKlaviyoObj.hasOwnProperty('config')) {
    return <LoadingLayoutPage />;
  }
  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          { content: t('settings.breadcrumbs'), onAction: redirectBack },
        ]}
        title={t('settings.klaviyo.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('settings.shipbob_pages.subtitle.read_more'),
                source: 'https://support.rush.app/integrating-klaviyo-and-rush',
              },
            ]}
          />
        }
      >
        <Layout>
          <SaveBar
            isSaveBarEnabled={isUpdated}
            submitData={submit}
            discardAction={discardChanges}
          />
          {klaviyoConfigSaved && <Loading />}
          {(loading || loadingFeatureId > 0) && <Loading />}

          <KlaviyoPageCustomization
            klaviyoConfig={klaviyoConfig}
            localKlaviyoObj={localKlaviyoObj}
            setLocalKlaviyoObj={setLocalKlaviyoObj}
            resetKlaviyo={resetKlaviyo}
          />
          {localKlaviyoObj &&
            localKlaviyoObj.hasOwnProperty('config') &&
            localKlaviyoObj.config.is_active && (
              <>
                <Layout.AnnotatedSection
                  title={t('settings.klaviyo.section.title')}
                  description={t(
                    'settings.klaviyo.section.configure.description'
                  )}
                >
                  <KlaviyoDetails
                    klaviyoConfig={klaviyoConfig}
                    klaviyoMeta={klaviyoMeta}
                    localKlaviyoObj={localKlaviyoObj}
                    setPublicApiKey={setPublicApiKey}
                    setSelectedLanguage={setSelectedLanguage}
                    setSelectedTrigger={setSelectedTrigger}
                    publicApiKeyError={publicApiKeyError}
                    publicApiKey={publicApiKey}
                    selectedLanguage={selectedLanguage}
                    selectedTrigger={selectedTrigger}
                    loading={loading}
                    klaviyoConfigSaved={klaviyoConfigSaved}
                    updateKlaviyoPayload={updateKlaviyoPayload}
                    privateApiKey={privateApiKey}
                    privateApiKeyError={privateApiKeyError}
                    setPrivateApiKey={setPrivateApiKey}
                    setPrivateApiKeyError={setPrivateApiKeyError}
                  />
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title={t('settings.klaviyo.actions.title')}
                >
                  <KlaviyoActions
                    klaviyoConfig={klaviyoConfig}
                    klaviyoMeta={klaviyoMeta}
                    publicApiKey={publicApiKey}
                    setPublicApiKeyError={setPublicApiKeyError}
                    selectedLanguage={selectedLanguage}
                  />
                  <KlaviyoFlows
                    klaviyoConfig={klaviyoConfig}
                    klaviyoMeta={klaviyoMeta}
                  />
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title={t('settings.klaviyo.flow_summary.title')}
                  description={t('settings.klaviyo.flow_summary.description')}
                >
                  <KlaviyoFlowTable
                    klaviyoConfig={klaviyoConfig}
                    klaviyoMeta={klaviyoMeta}
                    klaviyoFlowSummary={klaviyoFlowSummary}
                    getKlaviyoSummaryFlow={getKlaviyoSummaryFlow}
                    loadingFlow={loadingFlow}
                    privateApiKey={privateApiKey}
                    refreshedAtDate={refreshedAtDate}
                  />
                </Layout.AnnotatedSection>
              </>
            )}
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
        <UnsavedChangesModal />
      </Page>
      <SettingsToaster />
    </>
  );
}

import React, { useCallback, useMemo } from 'react';
import {
  ChoiceList,
  Card,
  Icon,
  Stack,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import AutoCompleteWithTags from 'components/AutoCompleteWithTags';

function OverrideCarriersCard({
  currCarrierRule = {},
  handleChoiceChange,
  formValidations = {},
}) {
  const [t] = useTranslation();
  const carriersResponse = useAllCarriersQuery();

  const { data: allCarriers = [], isLoading: isCarriersLoading } =
    carriersResponse;

  const carrierOptions = useMemo(() => {
    if (!isCarriersLoading && allCarriers) {
      return allCarriers.map((c) => ({
        value: c.uuid,
        label: c.title,
      }));
    }
  }, [isCarriersLoading, allCarriers]);

  const updateSelection = useCallback(
    (selected) => {
      if (selected.length) {
        handleChoiceChange('target_carriers_uuid', selected);
      } else {
        handleChoiceChange('target_carriers_uuid', selected);
      }
    },
    [handleChoiceChange]
  );

  const selectedOptions = useMemo(() => {
    return currCarrierRule.target_carriers_uuid || [];
  }, [currCarrierRule.target_carriers_uuid]);

  const renderChildren = (isSelected) => {
    if (isSelected) {
      return isCarriersLoading || !currCarrierRule ? (
        <SkeletonDisplayText />
      ) : (
        <Stack vertical>
          <AutoCompleteWithTags
            error={
              !formValidations.override_carrier && !selectedOptions?.length
            }
            errorMessage={t('carrier-maskings.override-carriers-required')}
            allowMultiple={true}
            optionList={carrierOptions || []}
            selected={selectedOptions}
            onChange={updateSelection}
            emptyState={t('shipments.settings_modal.location_default_text')}
            label={''}
            id="carrier-maskings"
            prefix={<Icon source={SearchMinor} color="inkLighter" />}
            placeholder="Search"
          />
        </Stack>
      );
    }
  };

  return (
    <Card title={t('carrier-maskings.override-carriers')} sectioned>
      <ChoiceList
        choices={[
          { label: t('carrier-maskings.all-carriers'), value: 'all' },
          {
            label: t('carrier-maskings.specific-carriers'),
            value: 'specific',
            renderChildren,
          },
        ]}
        selected={
          currCarrierRule.target_carriers_all === true ? ['all'] : ['specific']
        }
        onChange={([value]) => {
          handleChoiceChange('target_carriers_all', value === 'all');
        }}
      />
    </Card>
  );
}

OverrideCarriersCard.propTypes = {
  currCarrierRule: PropTypes.object,
  handleChoiceChange: PropTypes.func,
};

export default OverrideCarriersCard;

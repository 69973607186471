import { connect } from 'react-redux';
import Register from './Register';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
    loading: state.visitor.loading,
    visitorToast: state.visitor.toast,
    newEmail: state.visitor.newEmail,
    verificationToken: state.visitor.verificationToken,
    campaignMessage: state.visitor.campaignMessage,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  getAffiliatesCampaign: visitorActions.Creators.getAffiliatesCampaign,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
  register: visitorActions.Creators.register,
  resetVisitorToast: visitorActions.Creators.resetVisitorToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

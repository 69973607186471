import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import PromoCodeModal from './PromoCodeModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    promoCodeObject: state.billing.promoCode,
    promoError: state.billing.promoError,
    showPromoCode: state.billing.showPromoCode,
    promoLoading: state.billing.promoLoading,
    chargeDetail: state.billing.chargeDetail,
    billingPlan: state.billing.billingPlan,
    createBillingLoading: state.billing.createBillingLoading,
  };
}

const mapDispatchToProps = {
  applyPromoCode: billingActions.Creators.applyPromoCode,
  showPromoCodeModal: billingActions.Creators.showPromoCodeModal,
  createBillingPlan: billingActions.Creators.createBillingPlan,
  postPromoCharges: billingActions.Creators.postPromoCharges,
  resetSubscriptionPlan: billingActions.Creators.resetSubscriptionPlan,
  getCurrentBillingPlan: billingActions.Creators.getCurrentBillingPlan,
  disableBillingPlan: billingActions.Creators.disableBillingPlan,
  showPromoCodeConfirmationModal:
    billingActions.Creators.showPromoCodeConfirmationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeModal);

import React, { useEffect } from 'react';
import { ClientStorage } from 'ClientStorage';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GOOGLE_TAG_ID, FULL_STORY_ORG } from 'config';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';
import {
  useGetUserDetailsQuery,
  useGetChatIdentifierQuery,
} from 'redux/store/commonBaseApis';
import { useParams } from 'react-router-dom';
import { STAGE } from 'config';
import { ENV_PRODUCTION } from 'Constants';
import i18next from 'i18next';

export default function TrackingManager({ base, getIpAddress, accessToken }) {
  const { data: user = {}, isSuccess: isUserLoaded } = useGetUserDetailsQuery(
    undefined,
    { skip: !accessToken }
  );

  const params = useParams();
  const chatIdentifierResponse = useGetChatIdentifierQuery('', {
    skip: STAGE !== ENV_PRODUCTION || !accessToken,
  });

  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  useEffect(() => {
    getIpAddress();
  }, [getIpAddress]);

  useEffect(() => {
    if (
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true ||
      !window.HubSpotConversations ||
      !chatIdentifierResponse?.data?.hubspot_identification_token ||
      !user.email
    ) {
      return;
    }

    window.hsConversationsSettings = {
      identificationEmail: user.email,
      identificationToken:
        chatIdentifierResponse?.data?.hubspot_identification_token,
    };

    window.HubSpotConversations.widget.load();
  }, [chatIdentifierResponse, user.email]);

  useEffect(() => {
    if (!FULL_STORY_ORG || FULL_STORY_ORG === 'null' || !window.FS) {
      window.FS && window.FS.log({ FULL_STORY_ORG });
      return;
    }

    // if user is not loaded yet
    if (!isUserLoaded) {
      return;
    }

    if (ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true) {
      window.FS.log(
        'Shutting down fullstory because "trackAnalytics !== true"'
      );
      window.FS && window.FS.shutdown();
      return;
    }

    if (base.isFullstoryLoaded) {
      return;
    }

    const FSData = {
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      language: i18next.language,
    };

    window.FS.event('appLoaded', FSData);
    window.FS.identify(user.id, FSData);
    dispatch(baseActions.markFullstoryLoaded(true));
  }, [user, base.isFullstoryLoaded]);

  useEffect(() => {
    if (!GOOGLE_TAG_ID || GOOGLE_TAG_ID === 'null') {
      return;
    }

    if (base.gtmDataSent) {
      return;
    }

    if (ClientStorage.get('trackAnalytics') !== true) {
      return;
    }

    sendDataToGTM({
      event: 'appLoaded',
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      storeUUID: params.uuid,
    });

    dispatch(baseActions.markGtmDataSentTrue());
  }, [sendDataToGTM, dispatch, params.uuid]);
  return <></>;
}

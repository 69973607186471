import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Card, SettingToggle, TextStyle } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
export default function TrackingConf() {
  const [t] = useTranslation();

  const [isDisputeResolution, setIsDisputeResolution] = useState(false);
  const handleIsDisputeResolutionChange = useCallback(() => {
    setIsDisputeResolution((active) => !active);
  }, []);

  const contentIsDisputeResolutionStatus = isDisputeResolution
    ? t('settings.paypal_page.config_card.enable.button_on')
    : t('settings.paypal_page.config_card.enable.button_off');

  useEffect(() => {}, []);

  return (
    <Layout.AnnotatedSection
      title={t('settings.paypal_page.dispute_section.title')}
      description={t('settings.paypal_page.dispute_section.description')}
    >
      <SettingToggle
        action={{
          content: contentIsDisputeResolutionStatus,
          onAction: handleIsDisputeResolutionChange,
        }}
        enabled={isDisputeResolution}
      >
        {t('settings.paypal_page.dispute_card.description')}
      </SettingToggle>
      {isDisputeResolution && (
        <Card sectioned>
          <TextStyle variation="strong">
            {t('settings.upsell_page.product_action.coming_soon')}
          </TextStyle>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

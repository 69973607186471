import React from 'react';
import {
  MediaCard,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  VideoThumbnail,
} from '@shopify/polaris';

export default function TaskItemSkeleton() {
  return (
    <>
      <MediaCard
        size={'small'}
        description={
          <Stack vertical>
            <SkeletonBodyText lines={4} />
            <SkeletonDisplayText size={'small'} />
          </Stack>
        }
      >
        <VideoThumbnail onClick={() => {}} thumbnailUrl={null} />
      </MediaCard>
    </>
  );
}

import React, { useCallback, useState, useEffect } from 'react';
import {
  Layout,
  Card,
  Banner,
  Stack,
  TextStyle,
  TextContainer,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
// import { getPublishedTrackingPage } from '../tempUtils';
import '../style.scss';

export default function LiveTrackingPage({
  trackingPageState,
  dispatchForTrackingPage,
  trackingDetailMeta,
  showUnsavedModal,
  discardChanges,
}) {
  const [t] = useTranslation();

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedThemeError, setSelectedThemeError] = useState(false);

  const redirectToCustomize = useCallback(() => {
    if (!selectedTheme) {
      setSelectedThemeError(true);
    } else {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.RESET,
        payload: { customizeTheme: true },
      });
    }
  }, [dispatchForTrackingPage, selectedTheme]);
  const callbackCustomizeFunction = useCallback(() => {
    discardChanges();
    redirectToCustomize();
  }, [discardChanges, redirectToCustomize]);

  const handleCustomizeTheme = useCallback(() => {
    if (trackingPageState.isUpdated) {
      showUnsavedModal(callbackCustomizeFunction);
      return;
    } else {
      redirectToCustomize();
    }
  }, [
    callbackCustomizeFunction,
    showUnsavedModal,
    trackingPageState.isUpdated,
    redirectToCustomize,
  ]);

  let selectedThemeObj = null;
  let selectedStyleObj = null;
  if (trackingPageState.theme) {
    selectedThemeObj = trackingDetailMeta.themes.filter(
      (theme) => theme.slug === trackingPageState.theme
    )[0];
  }
  if (trackingPageState.page_layout && selectedThemeObj) {
    selectedStyleObj = selectedThemeObj?.styles?.filter(
      (theme) => theme.slug === trackingPageState.page_layout
    )[0];
  }

  useEffect(() => {
    setSelectedTheme(trackingPageState.theme);
  }, [trackingPageState.theme]);

  // const publishedTrackingPage = getPublishedTrackingPage();
  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.live_tracking_page.title')}
      description={t('settings.tracking_page.live_tracking_page.description')}
    >
      <Card
        sectioned
        title={t('settings.tracking_page.card_theme.active_tracking_page')}
        // title={publishedTrackingPage.name}
        primaryFooterAction={{
          content: t('settings.tracking_page.card_theme.btn_primary'),
          onAction: handleCustomizeTheme,
        }}
        secondaryFooterActionsDisclosureText={t(
          'settings.tracking_page.tracking_pages.btn_secondary'
        )}
        // secondaryFooterActions={[
        //   {
        //     content: t('settings.tracking_page.tracking_pages.view'),
        //     onAction: () => {},
        //   },
        //   {
        //     content: t('settings.tracking_page.tracking_pages.rename'),
        //     onAction: () => {},
        //   },
        //   {
        //     content: t('settings.tracking_page.tracking_pages.duplicate'),
        //     onAction: () => {},
        //   },
        // ]}
      >
        <TextStyle variation="subdued">
          <TextContainer spacing="tight">
            {/* <section>
              <span>
                {t('settings.tracking_page.tracking_pages.created')}{' '}
                {publishedTrackingPage.createdAt}
              </span>
              ,
              <span>
                {t('settings.tracking_page.tracking_pages.updated')}{' '}
                {publishedTrackingPage.updatedAt}
              </span>
            </section> */}
            <p>
              {t('settings.tracking_page.card_theme.style_title', {
                theme_style: selectedThemeObj?.name,
              })}
            </p>
          </TextContainer>
        </TextStyle>

        <Stack distribution="fill" vertical>
          {selectedThemeError && (
            <Stack.Item distribution="fill">
              <Banner
                title={t('settings.tracking_page.card_theme.select_theme_msg')}
                status="warning"
              ></Banner>
            </Stack.Item>
          )}
          <Stack.Item>
            <div className="PageLayout-MapConfig">
              {selectedStyleObj && (
                <>
                  <div className="Template-Preview">
                    <div className="Template-Desktop">
                      <div className="Template-DesktopView">
                        <div className="Template-Desktop-TaskBar">
                          <div className="Template-Desktop-TaskBarDot"></div>
                          <div className="Template-Desktop-TaskBarDot"></div>
                          <div className="Template-Desktop-TaskBarDot"></div>
                          <div className="Template-Desktop-TaskBarAddress"></div>
                        </div>
                        <div className="Template-Image-Wrapper">
                          <img
                            src={selectedStyleObj.image_desktop_link}
                            alt="Laptop screenshot"
                            className="Template-Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Template-Mobile">
                      <div className="Template-Mobile-Wrapper">
                        <div className="Template-Image-Wrapper">
                          <img
                            src={selectedStyleObj.image_mobile_link}
                            alt="Mobile screenshot"
                            className="Template-Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}

// import React, { useContext, useState, useEffect } from 'react';
import React from 'react';
import { Button } from '@shopify/polaris';
import PropTypes from 'prop-types';
import stringHelper from 'helpers/string';

export default function Oauth({ externalURL, callbackFns, imgURL, ...props }) {
  let popup;
  function createPopup(e) {
    e.preventDefault();
    popup = openPopup();
    try {
      popup.location = `${externalURL}`;
      polling(popup);
    } catch (error) {
      popup.close();
      // oauthResponse(400, "Something went wrong while fetching your profile. Please try again.");
    }
  }

  function polling(popup) {
    const polling = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
      }

      const closeDialog = () => {
        clearInterval(polling);
        popup.close();
      };

      try {
        if (popup.location.search) {
          const query = stringHelper.parseQueryString(popup.location.search);
          callbackFns(query);
          closeDialog();
          return query;
        }
        if (popup.location.host) {
          // oauthResponse(401, "Something went wrong. Please try again.");
          closeDialog();
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);
  }

  function openPopup() {
    const width = props.dialogWidth;
    const height = props.dialogHeight;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    return window.open(
      '',
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left
    );
  }
  return (
    <React.Fragment>
      {!imgURL ? (
        <Button primary onClick={createPopup}>
          Add Account
        </Button>
      ) : (
        <img
          src={imgURL}
          onClick={createPopup}
          alt="Connect"
          className="cursor-pointer"
        />
      )}
    </React.Fragment>
  );
}

Oauth.propTypes = {
  externalURL: PropTypes.string.isRequired,
};

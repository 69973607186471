import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import SubscriptionPlansModal from './SubscriptionPlansModal';

function mapStateToProps(state) {
  return {
    subscriptionPlanLoading: state.billing.subscriptionPlanLoading,
    loading: state.billing.loading,
    subscriptionPlanMeta: state.billing.subscriptionPlanMeta,
    subscriptionPlanGroups: state.billing.subscriptionPlanGroups,
    currentPlan: state.billing.currentPlan,
    chargeDetail: state.billing.chargeDetail,
    showSubscriptionPlansModal: state.billing.showSubscriptionPlansModal,
  };
}

const mapDispatchToProps = {
  getSubscriptionPlans: billingActions.Creators.getSubscriptionPlans,
  postSubscriptionPlan: billingActions.Creators.postSubscriptionPlan,
  resetSubscriptionPlan: billingActions.Creators.resetSubscriptionPlan,
  getCurrentBillingPlan: billingActions.Creators.getCurrentBillingPlan,
  handleSubscriptionPlansModalChange:
    billingActions.Creators.handleSubscriptionPlansModalChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlansModal);

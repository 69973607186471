import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AccountPage,
  ChangeEmailModal,
  ChangePhoneModal,
  VerifyModal,
} from '@rush-app-admin/storybooks';
import { useTranslation } from 'react-i18next';
import { ContextualSaveBar, Loading } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import i18next from 'i18next';
import {
  useChangeUserEmailMutation,
  useChangeUserPhoneMutation,
  useGetCountryCodesQuery,
  useResendCodeMutation,
  useResendVerificationNewMutation,
  useUpdateUserDetailsMutation,
  useVerifyCodeUserMutation,
} from '../accountApis';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';
import AccountSkeleton from './AccountSkeleton';

export default function Account() {
  const [t] = useTranslation();

  const { isLoading: isLoadingCodes, data: countryCodes = [] } =
    useGetCountryCodesQuery();
  const {
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    data: user = {},
    refetch: refetchUserInfo,
  } = useGetUserDetailsQuery();

  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePhoneModal, setShowChangePhoneModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [updatingEntry, setUpdatingEntry] = useState(''); // email | phone
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [tobeVerifiedResponse, setTobeVerifiedResponse] = useState({});

  const handleFirstNameChange = useCallback((text) => {
    setFirstName(text);
  }, []);

  const handleLastNameChange = useCallback((text) => {
    setLastName(text);
  }, []);

  useEffect(() => {
    handleFirstNameChange(user.first_name);
    handleLastNameChange(user.last_name);
  }, [user.first_name, user.last_name]);

  let translations;
  try {
    translations = require(`@shopify/polaris/locales/${i18next.language}.json`);
  } catch (e) {
    translations = require('@shopify/polaris/locales/en.json');
  }

  const [resendCode, { isLoading: isResendingCode }] = useResendCodeMutation();
  const [
    resendVerificationNew,
    { data: resentNewData, isSuccess: isResentNew, isLoading: inResendingNew },
  ] = useResendVerificationNewMutation();

  useEffect(() => {
    if (!inResendingNew && isResentNew) {
      setTobeVerifiedResponse(resentNewData);
      setShowVerifyModal(true);
    }
  }, [inResendingNew, isResentNew, resentNewData]);

  const [updateUserDetails, { isLoading: isUpdating }] =
    useUpdateUserDetailsMutation();

  const [
    changeUserEmail,
    {
      data: changeEmailData,
      isLoading: isChangingEmail,
      isSuccess: isEmailChanged,
    },
  ] = useChangeUserEmailMutation();

  useEffect(() => {
    if (!isChangingEmail && isEmailChanged) {
      setShowChangeEmailModal(false);
      setTobeVerifiedResponse(changeEmailData);
      setShowVerifyModal(true);
    }
  }, [isEmailChanged, isChangingEmail, changeEmailData]);

  const [
    changeUserPhone,
    {
      data: changePhoneData,
      isLoading: isChangingPhone,
      isSuccess: isPhoneChanged,
    },
  ] = useChangeUserPhoneMutation();

  useEffect(() => {
    if (!isChangingPhone && isPhoneChanged) {
      setShowChangePhoneModal(false);
      setTobeVerifiedResponse(changePhoneData);
      setShowVerifyModal(true);
    }
  }, [isPhoneChanged, isChangingPhone, changePhoneData]);

  const [
    verifyCodeUser,
    {
      data: verifyCodeData,
      isLoading: isCodeVerifying,
      isSuccess: isCodeVerified,
    },
  ] = useVerifyCodeUserMutation();

  useEffect(() => {
    if (!isCodeVerifying && isCodeVerified) {
      refetchUserInfo();
      setShowVerifyModal(false);
      setTobeVerifiedResponse({});
      setUpdatingEntry('');
    }
  }, [isCodeVerifying, isCodeVerified, verifyCodeData]);

  const currentCountry = countryCodes?.find(
    ({ calling_code }) => calling_code === user.phone_country_code
  );

  const getCallingCodeFromId = (id) =>
    countryCodes.find((country) => country.id === id)?.calling_code;

  const saveBarMarkup =
    (firstName !== user.first_name || lastName !== user.last_name) &&
    !isFetchingUser ? (
      <ContextualSaveBar
        alignContentFlush
        message={t('unsaved_changes_modal.title')}
        saveAction={{
          onAction: () =>
            updateUserDetails({ first_name: firstName, last_name: lastName }),
          loading: isUpdating,
          disabled: isUpdating || firstName === '' || lastName === '',
          content: t('common.save'),
        }}
        discardAction={{
          onAction: () => {
            handleFirstNameChange(user.first_name);
            handleLastNameChange(user.last_name);
          },
          loading: isUpdating,
          disabled: isUpdating,
          content: t('common.discard'),
        }}
      />
    ) : undefined;
  const showLoadingBar = useMemo(() => {
    return isLoadingCodes || isFetchingUser || inResendingNew;
  }, [isLoadingCodes, isFetchingUser, inResendingNew]);

  return isLoadingUser ? (
    <AccountSkeleton />
  ) : (
    <>
      {saveBarMarkup}
      {showLoadingBar && <Loading />}
      <AccountPage
        translationFile={translations}
        title={t('account.title')}
        firstNameLabel={t('account.card.first_name')}
        firstNameError={
          firstName === '' ? t('account.card.first_name_required') : undefined
        }
        lastNameLabel={t('account.card.last_name')}
        lastNameError={
          lastName === '' ? t('account.card.last_name_required') : undefined
        }
        emailLabel={t('account.card.email')}
        phoneNumberLabel={t('account.card.phone_number')}
        changeEmailText={t('account.card.change_email')}
        changePhoneText={t('account.card.change_phone')}
        verifiedMessage={t('account.card.message.verified')}
        notVerifiedMessage={t('account.card.message.not_verified')}
        onChangeEmail={() => {
          setShowChangeEmailModal(true);
          setUpdatingEntry('email');
        }}
        onChangePhone={() => {
          setShowChangePhoneModal(true);
          setUpdatingEntry('phone');
        }}
        user={{
          firstName,
          lastName,
          emailVerified: user.email_verified,
          phoneVerified: user.phone_verified,
          countries: currentCountry
            ? [currentCountry.country]
            : ['United States'],
          prefix: user.phone_country_code || '+1',
          email: user.email,
          phone: user.phone_number || undefined,
        }}
        cardTitle={t('account.card.title')}
        onFirstNameChange={handleFirstNameChange}
        onLastNameChange={handleLastNameChange}
        footer={<TranslateLink text={t('footer_help.dashboard')} />}
        onResendEmail={() => {
          resendVerificationNew({ email: true });
          setUpdatingEntry('email');
        }}
        onResendPhone={() => {
          resendVerificationNew({ phone: true });
          setUpdatingEntry('phone');
        }}
        resendPhoneLabel={t('account.resend_verification_sms')}
        resendEmailLabel={t('account.resend_verification_email')}
      />
      {showChangeEmailModal ? (
        <ChangeEmailModal
          translationFile={{}}
          open={true}
          onClose={() => {
            setShowChangeEmailModal(false);
          }}
          email={user.email}
          title={t('account.change_email.modal.title')}
          label={t('account.card.email')}
          actionText={t('account.card.change_email')}
          onAction={(email) => {
            changeUserEmail({ email });
          }}
          bannerText={t('account.change_email.modal.banner')}
          error={user.error}
          invalidEmailError={t('account.change_email.modal.invalid_email')}
          loading={isChangingEmail}
        />
      ) : (
        ''
      )}
      {showChangePhoneModal ? (
        <ChangePhoneModal
          translationFile={{}}
          open={true}
          onClose={() => {
            setShowChangePhoneModal(false);
          }}
          title={t('account.change_phone.modal.title')}
          label={t('account.card.phone_number')}
          actionText={t('account.card.change_phone')}
          onAction={(phone, countryId) =>
            changeUserPhone({
              phone_number: phone,
              phone_country_code: getCallingCodeFromId(countryId),
            })
          }
          bannerText={t('account.change_phone.modal.banner')}
          error={user.error}
          loading={isChangingPhone}
          countries={countryCodes || []}
          countryId={
            currentCountry
              ? currentCountry.id
              : countryCodes.length
              ? countryCodes[0].id
              : null
          }
          phone={user.phone_number}
          invalidPhoneError={t('account.change_email.modal.invalid_phone')}
          startWithZeroError={t(
            'account.change_phone.modal.start_with_zeror_error'
          )}
        />
      ) : (
        ''
      )}
      <VerifyModal
        translationFile={{}}
        loading={isCodeVerifying || isResendingCode}
        open={showVerifyModal}
        onClose={() => setShowVerifyModal(false)}
        title={
          updatingEntry === 'email'
            ? t('account.verify_email.modal.title')
            : t('account.verify_phone.modal.title')
        }
        actionText={t('account.verify_modal.button_success')}
        onAction={(code) =>
          verifyCodeUser({
            token: tobeVerifiedResponse.token,
            code,
          })
        }
        bannerText={
          updatingEntry === 'email'
            ? t('account.verify_email.modal.message', {
                email: tobeVerifiedResponse?.new_contact?.email,
              })
            : t('account.verify_phone.modal.message', {
                phone: `${tobeVerifiedResponse?.new_contact?.phone_country_code} ${tobeVerifiedResponse?.new_contact?.phone_number}`,
              })
        }
        error={user.error}
        placeholder={t('account.verify_modal.code_placeholder')}
        secondaryActionText={t('account.verify_modal.send_again')}
        onSecondaryAction={() => resendCode(tobeVerifiedResponse.token)}
      />
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SmallTopMessage from 'components/SmallTopMessage';
import {
  Button,
  ButtonGroup,
  Card,
  ContextualSaveBar,
  FooterHelp,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  Stack,
  TextStyle,
  Toast,
} from '@shopify/polaris';
import Toaster from 'pages/Settings/TrackingPage/Toaster';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownMinor,
  ArrowUpMinor,
  DeleteMinor,
  EditMinor,
} from '@shopify/polaris-icons';
import ShipmentSettingRuleModal from './ShipmentSettingRuleModal';
import TranslateLink from 'components/TranslateLink';
import FulfillmentSettings from './FulfillmentSettings';
import {
  useGetConfigQuery,
  useGetRulesQuery,
  useUpdateConfigMutation,
  useUpdateRulesMutation,
} from '../ShipmentSettingsApi';
import isEqual from 'lodash/isEqual';
import { useGetCountriesQuery } from 'redux/store/commonStoreApis';
import './styles.scss';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';

export default function ShipmentSettings() {
  const [t] = useTranslation();

  const [saveConfig, configMutationResponse] = useUpdateConfigMutation();
  const { isLoading: isConfigSaving, isSuccess: isConfigSaved } =
    configMutationResponse;

  const [saveRules, rulesMutationResponse] = useUpdateRulesMutation();
  const { isLoading: isRulesSaving, isSuccess: isRulesSaved } =
    rulesMutationResponse;

  const carriersResponse = useAllCarriersQuery();
  const { data: carriers = [], isLoading: isCarriersLoading } =
    carriersResponse;

  const locationsResponse = useGetCountriesQuery();
  const { data = [], isLoading: isLocationsLoading } = locationsResponse;
  const locationsData = data.filter((geo = {}) => geo.type === 'country') || [];
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    (isConfigSaved || isRulesSaved) && setShowSuccessToast(true);
  }, [isConfigSaved, isRulesSaved]);

  const toastMarkup = useMemo(() => {
    return (
      showSuccessToast && (
        <Toast
          content={t('shipments.settings_page.save_message')}
          onDismiss={() => {
            setShowSuccessToast(false);
          }}
          duration={2000}
        />
      )
    );
  }, [showSuccessToast]);

  const countriesList = useMemo(() => {
    return locationsData.map((item) => {
      return {
        label: item.label,
        value: item.loc_id,
      };
    });
  }, [locationsData]);

  const carriersList = useMemo(() => {
    return carriers.map((item) => {
      return {
        label: item.title,
        value: item.uuid,
      };
    });
  }, [carriers]);

  const [rules, setRules] = useState([]);
  const [sendUpdates, setSendUpdates] = useState(false);
  const [selectedFullFillSetting, setSelectedFullFillSetting] = useState('');

  const [editRuleIndex, setEditRuleIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const configResponse = useGetConfigQuery();
  const {
    data: configResData,
    isLoading: isConfigLoading,
    isFetching: isConfigFetching,
    isSuccess: isConfigLoaded,
    refetch,
  } = configResponse;

  useEffect(() => {
    refetch();
  }, [t]);

  useEffect(() => {
    if (isConfigLoaded && configResData) {
      setSelectedFullFillSetting(configResData?.config?.update_mode);
      setSendUpdates(configResData?.config?.is_customer_updates_sent);
    }
  }, [isConfigLoaded, configResData]);

  const updateModes = useMemo(() => {
    if (isConfigLoaded && configResData) {
      return configResData?.meta?.update_mode || [];
    }
    return [];
  }, [isConfigLoaded, configResData]);

  const rulesResponse = useGetRulesQuery();
  const {
    data: rulesResData,
    isLoading: isRulesLoading,
    isFetching: isRulesFetching,
    isSuccess: isRulesLoaded,
  } = rulesResponse;

  useEffect(() => {
    if (isRulesLoaded && rulesResData) {
      setRules(rulesResData?.rules);
    }
  }, [isRulesLoaded, rulesResData]);

  const toggleModal = () => {
    setShowModal((show) => !show);
  };

  const editRule = (index) => {
    setEditRuleIndex(index);
    toggleModal();
  };
  const deleteRule = (index) => {
    setRules((rules) => {
      let updatedRules = [...rules];
      updatedRules.splice(index, 1);
      return updatedRules;
    });
  };

  const moveRule = (index, direction) => {
    try {
      setRules((rules) => {
        let updatedRules = [...rules];
        const rule = updatedRules.splice(index, 1)[0];
        if (direction === 'down') {
          updatedRules.splice(index + 1, 0, rule);
        } else if (direction === 'up') {
          updatedRules.splice(index - 1, 0, rule);
        }
        return updatedRules;
      });
    } catch {
      console.log('error in moveRule');
    }
  };

  const handleFulFillSettingChange = useCallback(([value]) => {
    setSelectedFullFillSetting(value);
  }, []);

  const toggleShipingUpdate = useCallback(() => {
    setSendUpdates((sendUpdates) => !sendUpdates);
  }, []);

  const onAddNew = () => {
    setEditRuleIndex(-1);
    toggleModal();
  };

  const handleAddUpdateRule = (rule) => {
    if (editRuleIndex !== -1) {
      setRules((rules) => {
        let updatedRules = [...rules];
        updatedRules.splice(editRuleIndex, 1, { ...rule });
        return updatedRules;
      });
      setEditRuleIndex(null);
    } else {
      setRules((rules = []) => {
        return [...rules].concat(rule);
      });
    }
    toggleModal();
  };

  const isRulesDirty = useMemo(() => {
    if (isRulesLoaded) {
      return !isEqual(rules, rulesResData?.rules);
    } else {
      return false;
    }
  }, [isRulesLoaded, rulesResData, rules]);

  const isConfigDirty = useMemo(() => {
    if (isConfigLoaded) {
      const current = {
        selectedFullFillSetting,
        sendUpdates,
      };
      const prev = {
        selectedFullFillSetting: configResData?.config?.update_mode,
        sendUpdates: configResData?.config?.is_customer_updates_sent,
      };
      return !isEqual(current, prev);
    } else {
      return false;
    }
  }, [isConfigLoaded, configResData, selectedFullFillSetting, sendUpdates]);

  const isDirtyState = useMemo(() => {
    try {
      return isConfigDirty || isRulesDirty;
    } catch {
      return false;
    }
  }, [isConfigDirty, isRulesDirty]);

  const handleSave = useCallback(() => {
    if (isRulesDirty) {
      saveRules(rules);
    }

    if (isConfigDirty) {
      saveConfig({
        update_mode: selectedFullFillSetting,
        is_customer_updates_sent: sendUpdates,
      });
    }
  }, [
    rules,
    selectedFullFillSetting,
    sendUpdates,
    isRulesDirty,
    isConfigDirty,
  ]);

  const handleDiscard = useCallback(() => {
    try {
      setSelectedFullFillSetting(configResData?.config?.update_mode);
      setSendUpdates(configResData?.config?.is_customer_updates_sent);
      setRules(rulesResData?.rules);
    } catch {
      return false;
    }
  }, [configResData, rulesResData]);

  const saveBarMarkup = useMemo(
    () =>
      isDirtyState && !isConfigFetching && !isRulesFetching ? (
        <ContextualSaveBar
          message={t('carrier-maskings.unsaved-changes')}
          saveAction={{
            content: t('carrier-maskings.save'),
            onAction: handleSave,
            loading: isRulesSaving || isConfigSaving,
          }}
          discardAction={{
            onAction: handleDiscard,
            loading: isRulesSaving || isConfigSaving,
            content: t('common.discard'),
          }}
        />
      ) : (
        ''
      ),
    [
      isDirtyState,
      isRulesSaving,
      isConfigSaving,
      isConfigFetching,
      isRulesFetching,
      handleSave,
      handleDiscard,
    ]
  );

  const rulesListMarkup = useMemo(() => {
    return rules?.map((item, index) => {
      const selectedCarrier =
        carriersList.find((c) => c.value === item.carrier_uuid) || {};
      const countryNames = countriesList
        .filter((c) => item?.countries?.includes(c.value))
        .map((c) => c.label);
      return (
        <div className="SearchReplace-List" key={index}>
          <div className="SearchReplace-List-Heading">
            <Stack vertical spacing="extraTight">
              <TextStyle>
                {t('shipments.settings_page.tracking_number_is')}{' '}
                <TextStyle variation="code">{item.regex}</TextStyle>
                {countryNames.length > 0 ? (
                  <p>
                    {countryNames.length === 1
                      ? t('shipments.settings_page.singular_destination')
                      : t('shipments.settings_page.plural_destination')}{' '}
                    <TextStyle variation="strong">{`${countryNames.join(
                      ', '
                    )}`}</TextStyle>
                  </p>
                ) : (
                  ''
                )}
                <p>
                  {t('shipments.settings_page.assigning_to_carrier')}{' '}
                  <TextStyle variation="strong">{`${selectedCarrier.label}`}</TextStyle>
                </p>
              </TextStyle>
            </Stack>
          </div>
          <div className="SearchReplace-List-Buttons">
            <ButtonGroup segmented>
              <Button
                size="slim"
                onClick={() => moveRule(index, 'up')}
                icon={<Icon source={ArrowUpMinor} />}
                disabled={index === 0}
              />
              <Button
                size="slim"
                onClick={() => moveRule(index, 'down')}
                icon={<Icon source={ArrowDownMinor} />}
                disabled={index === rules?.length - 1}
              />
              <Button
                size="slim"
                onClick={() => editRule(index)}
                icon={<Icon source={EditMinor} />}
              />
              <Button
                size="slim"
                onClick={() => deleteRule(index)}
                icon={<Icon source={DeleteMinor} />}
              />
            </ButtonGroup>
          </div>
        </div>
      );
    });
  }, [rules, carriersList, countriesList]);

  return (
    <>
      <SmallTopMessage />

      <Page title={t('shipments.settings_page.title')}>
        <Layout>
          <Layout.AnnotatedSection
            title={t('shipments.settings_page.carrier_assigning.layout_title')}
            description={t(
              'shipments.settings_page.carrier_assigning.layout_description'
            )}
          >
            <Card
              title={t('shipments.settings_page.carrier_assigning.card_title')}
            >
              <Card.Section>
                <TextStyle variation="subdued">
                  <TranslateLink
                    text={t(
                      'shipments.settings_page.carrier_assigning.card_description'
                    )}
                  />
                </TextStyle>
              </Card.Section>
              {isCarriersLoading || isLocationsLoading || isRulesLoading ? (
                <Card.Section>
                  <SkeletonBodyText />
                </Card.Section>
              ) : (
                <Card.Section
                  title={t(
                    'shipments.settings_page.carrier_assigning.card_rules_title'
                  )}
                  actions={[
                    {
                      content: t(
                        'shipments.settings_page.carrier_assigning.card_rules_link'
                      ),
                      onClick: onAddNew,
                    },
                  ]}
                >
                  {rules && rules.length > 0 && rulesListMarkup}
                  {rules && rules.length === 0 && (
                    <div className="CustomEmptyState">
                      <TextStyle variation="subdued">
                        {t(
                          'shipments.settings_page.carrier_assigning.no_rules'
                        )}
                      </TextStyle>
                    </div>
                  )}
                  {!rules && (
                    <div className="CustomEmptyState">
                      <TextStyle variation="subdued">
                        {t('shipments.settings_page.carrier_assigning.loading')}
                      </TextStyle>
                    </div>
                  )}
                </Card.Section>
              )}
            </Card>
          </Layout.AnnotatedSection>

          <FulfillmentSettings
            fulfillmentSettings={updateModes}
            selectedSetting={selectedFullFillSetting}
            onChangeSetting={handleFulFillSettingChange}
            sendUpdates={sendUpdates}
            toggleShipingUpdate={toggleShipingUpdate}
            isLoading={isConfigLoading}
          />

          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
      {showModal && (
        <ShipmentSettingRuleModal
          rule={rules[editRuleIndex]}
          onSave={handleAddUpdateRule}
          onClose={toggleModal}
          countriesList={countriesList}
          carriersList={carriersList}
        />
      )}
      {saveBarMarkup}
      {toastMarkup}
      <Toaster />
    </>
  );
}

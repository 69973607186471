import React, { useState } from 'react';
import { Banner } from '@shopify/polaris';
import {
  RiskMajor,
  AlertMinor,
  RefreshMajor,
  GiftCardMajor,
} from '@shopify/polaris-icons';
import TranslateLink from 'components/TranslateLink';

import './style.scss';
import { openHubSpotChat } from '../../Utils/utils';
import { SETTINGS_CUSTOMER_CHAT_OPEN } from '../../Constants';
import IncreaseLimitModal from 'pages/Billing1/components/IncreaseLimitModal';
import UpdatePlanModal from 'pages/Billing1/components/UpdatePlanModal';
import { useDispatch } from 'react-redux';
import { billingActions } from 'pages/Billing1/billingSlice';

export default function ShowBanner({ bannerObject }) {
  const dispatch = useDispatch();

  const toggleUpdatePlanModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };

  const toggleChargeLimitModal = () => {
    dispatch(billingActions.toggleChargeLimitModal());
  };

  function getAction(bannerObject, key) {
    const actionObject = bannerObject[key];
    if (!actionObject) {
      return;
    }

    let action = { content: actionObject.content };

    if (actionObject.external) {
      action.external = true;
      action.url = actionObject.url;
      return action;
    }

    if (actionObject.id === 'update_usage_charges_cap_limit') {
      action.onAction = toggleChargeLimitModal;
    } else if (actionObject.id === 'show_subscription_plans') {
      action.onAction = toggleUpdatePlanModal;
    } else if (actionObject.id === SETTINGS_CUSTOMER_CHAT_OPEN) {
      action.onAction = openHubSpotChat;
    }

    return action;
  }

  const [isVisible, setIsVisible] = useState(true);
  let icon = null;
  const action = getAction(bannerObject, 'action');
  const secondaryAction = getAction(bannerObject, 'secondary_action');

  if (bannerObject.icon === 'RiskMajor') {
    icon = RiskMajor;
  } else if (bannerObject.icon === 'AlertMinor') {
    icon = AlertMinor;
  } else if (bannerObject.icon === 'RefreshMajor') {
    icon = RefreshMajor;
  } else if (bannerObject.icon === 'GiftCardMajor') {
    icon = GiftCardMajor;
  }

  if (!isVisible) {
    return <div />;
  }

  let onDismissCallback = false;
  if (bannerObject.close.visible) {
    onDismissCallback = () => {
      setIsVisible(false);
    };
  }

  return (
    <React.Fragment>
      <Banner
        title={bannerObject.title}
        status={bannerObject.status}
        action={action}
        onDismiss={onDismissCallback}
        icon={icon}
        secondaryAction={secondaryAction}
      >
        <TranslateLink text={bannerObject.message} />
      </Banner>
      <br />
      <IncreaseLimitModal />
      <UpdatePlanModal />
    </React.Fragment>
  );
}

import React, { useEffect } from 'react';
import { Stack, Card, TextStyle, SkeletonBodyText } from '@shopify/polaris';
import {
  DASHBOARD_ANALYTICS_TYPE_DELIVERY_TIMES,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useGetReportsMutation } from 'pages/Dashboard/DashboardApi';
export default function DeliveryTimes({ selectedDates = {} }) {
  const [t] = useTranslation();

  const [getReport, reportsResponse] = useGetReportsMutation();
  const {
    data: deliveryTimes = {},
    isLoading,
    isSuccess,
  } = reportsResponse || {};

  useEffect(() => {
    getReport({
      type: DASHBOARD_ANALYTICS_TYPE_DELIVERY_TIMES,
      kind: 'shipments',
      filter: {
        date_from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
        date_to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      },
      sql: {
        select: [
          'days_order_created_to_fulfillment_created',
          'days_order_created_to_first_checkpoint',
          'days_order_created_to_in_transit',
          'days_order_created_to_delivery',
        ],
      },
    });
  }, [getReport, selectedDates, i18next.language]);

  return (
    <Card.Section title={t('dashboard.analytics_sheet.delivery_times_title')}>
      {isLoading || !isSuccess ? (
        <SkeletonBodyText />
      ) : (
        deliveryTimes && (
          <Stack vertical={true} spacing="extraTight">
            {deliveryTimes.table.columns.length > 0 &&
              deliveryTimes.table.columns.map((column, index) => (
                <Stack.Item key={index}>
                  <Stack spacing="extraTight" distribution="trailing">
                    <Stack.Item fill>{column.label}</Stack.Item>
                    <Stack.Item>
                      {deliveryTimes.table.data[0][index]
                        ? deliveryTimes.table.data[0][index]
                        : 0}{' '}
                      days
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              ))}
            {deliveryTimes.table.columns.length === 0 && (
              <div className="CustomEmptyState">
                <TextStyle variation="subdued">
                  {t('dashboard.analytics_sheet.no_row_message')}
                </TextStyle>
              </div>
            )}
          </Stack>
        )
      )}
    </Card.Section>
  );
}

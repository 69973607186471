import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import HTMLText from './HTMLText';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    templateHTML: state.trackingDetail.templateHTML,
  };
}

const mapDispatchToProps = {
  getHTMLTemplate: trackingActions.Creators.getHTMLTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(HTMLText);

import { TextStyle } from '@shopify/polaris';
import React from 'react';
import ReactMarkdown from 'react-markdown';

// Rewrite `em`s (`*like so*`) to `i` with Code text style

function CodeString({ text, showBraces = false }) {
  return (
    <ReactMarkdown
      children={text}
      components={{
        em: ({ children }) => {
          if (showBraces) {
            return (
              <TextStyle variation="code">
                &#123;&#123;{children}&#125;&#125;
              </TextStyle>
            );
          } else {
            return <TextStyle variation="code">{children}</TextStyle>;
          }
        },
      }}
    />
  );
}

export default CodeString;

import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Card, ChoiceList, TextStyle } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import {
  TRACKING_PAGE_SEARCH_FROM_EMAIL,
  TRACKING_PAGE_SEARCH_FROM_PHONE,
  TRACKING_PAGE_SEARCH_FROM_ORDER,
  TRACKING_PAGE_ACTIONS,
} from 'Constants';
import _ from 'lodash';
export default function ShipmentTrackingBy({
  trackingPageState,
  dispatchForTrackingPage,
}) {
  const [t] = useTranslation();
  const [selectInsertShippingDetail, setSelectInsertShippingDetail] = useState(
    []
  );
  const TRACKING_PAGE_SHIPMENT_DETAIL = [
    {
      label: t('settings.tracking_page.section_form_inputs.inputs.opt_email'),
      value: TRACKING_PAGE_SEARCH_FROM_EMAIL,
    },
    {
      label: t('settings.tracking_page.section_form_inputs.inputs.opt_order'),
      value: TRACKING_PAGE_SEARCH_FROM_ORDER,
    },
    {
      label: t('settings.tracking_page.section_form_inputs.inputs.opt_phone'),
      value: TRACKING_PAGE_SEARCH_FROM_PHONE,
    },
  ];

  const handleSelectInsertShippingDetailChange = useCallback(
    (value) => {
      setSelectInsertShippingDetail(value);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: {
          tracking_by: {
            check_email: value.includes(TRACKING_PAGE_SEARCH_FROM_EMAIL),
            check_order: value.includes(TRACKING_PAGE_SEARCH_FROM_ORDER),
            check_phone: value.includes(TRACKING_PAGE_SEARCH_FROM_PHONE),
          },
        },
        validation: {
          trackingBy: !_.isEmpty(value),
        },
      });
    },
    [dispatchForTrackingPage]
  );

  useEffect(() => {
    let tempSelected = [];
    if (trackingPageState.tracking_by.check_email) {
      tempSelected.push(TRACKING_PAGE_SEARCH_FROM_EMAIL);
    }
    if (trackingPageState.tracking_by.check_order) {
      tempSelected.push(TRACKING_PAGE_SEARCH_FROM_ORDER);
    }
    if (trackingPageState.tracking_by.check_phone) {
      tempSelected.push(TRACKING_PAGE_SEARCH_FROM_PHONE);
    }
    setSelectInsertShippingDetail(tempSelected);
  }, [trackingPageState]);

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.section_form_inputs.title')}
      description={t('settings.tracking_page.section_form_inputs.description')}
    >
      <Card
        title={t('settings.tracking_page.section_form_inputs.inputs.title')}
        sectioned
      >
        <ChoiceList
          title=""
          choices={TRACKING_PAGE_SHIPMENT_DETAIL}
          selected={selectInsertShippingDetail}
          onChange={handleSelectInsertShippingDetailChange}
          allowMultiple
          error={
            _.isEmpty(selectInsertShippingDetail)
              ? t(
                  'settings.tracking_page.section_form_inputs.inputs.error_message'
                )
              : null
          }
        />
        <br/>
        <TextStyle variation="subdued">{t('settings.tracking_page.section_form_inputs.search_by_tracking_number_hint')}</TextStyle>
      </Card>
    </Layout.AnnotatedSection>
  );
}

import React from 'react';
import {
  Layout,
  Card,
  ChoiceList,
  TextStyle,
  Form,
  FormLayout,
  Checkbox,
  SkeletonBodyText,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
export default function FulfillmentSettings(props) {
  const [t] = useTranslation();
  const {
    fulfillmentSettings = [],
    selectedSetting = '',
    onChangeSetting,
    sendUpdates = false,
    toggleShipingUpdate,
    isLoading,
  } = props;

  return (
    <Layout.AnnotatedSection
      title={t('shipments.settings_page.fulfillment.layout_title')}
      description={t('shipments.settings_page.fulfillment.layout_description')}
    >
      <Card
        title={t('shipments.settings_page.fulfillment.card_title')}
        sectioned
      >
        {isLoading ? (
          <SkeletonBodyText />
        ) : (
          <Form>
            <FormLayout>
              <TranslateLink
                text={t('shipments.settings_page.fulfillment.card_description')}
              />
              <ChoiceList
                title=""
                choices={fulfillmentSettings.map((type) => ({
                  label: type.label,
                  value: type.slug,
                }))}
                selected={[selectedSetting]}
                onChange={onChangeSetting}
              />
              <TextStyle variation="strong">
                {t(
                  'shipments.settings_page.fulfillment.shopify_shipping_notification_title'
                )}
              </TextStyle>
              <Checkbox
                label={t(
                  'shipments.settings_page.fulfillment.shopify_shipping_notification_label'
                )}
                checked={sendUpdates}
                onChange={toggleShipingUpdate}
              />
            </FormLayout>
          </Form>
        )}
      </Card>
    </Layout.AnnotatedSection>
  );
}

import { sortBy } from 'lodash';
import { storeApi } from '../../redux/store/storeApi';

export const shipmentsAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getShipmentStatuses: builder.query({
      query: () => {
        return {
          url: '/shipments/statuses',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getShipmentCarriers: builder.query({
      query: () => {
        return {
          url: '/shipments/carriers',
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => {
        if (response?.data) {
          return {
            carriers: sortBy(response?.data?.carriers || [], 'label'),
          };
        } else {
          return response;
        }
      },
      providesTags: ['shipments-carriers'],
    }),
    getShipments3: builder.query({
      query: (queryParams) => {
        return {
          url: `/shipments3?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    getShipments: builder.query({
      query: (queryParams) => {
        return {
          url: `/shipments?${queryParams}`,
          method: 'GET',
        };
      },
      providesTags: ['shipments'],
    }),
    getShipmentDetails: builder.query({
      query: (shipmentId) => {
        return {
          url: `/shipments/${shipmentId}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipment-details'],
    }),
    exportShipments: builder.query({
      query: (urlParams) => {
        return {
          url: `/shipments/export?${urlParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getOrderSyncOptions: builder.query({
      query: () => {
        return {
          url: `/orders/sync-options`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    syncOrders: builder.mutation({
      query: (requestBody) => {
        const urlParams = new URLSearchParams({ ...requestBody }).toString();
        return {
          url: `/orders/sync?${urlParams}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    markAsDelivered: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipments/mark-as-delivered`,
          method: 'PUT',
          body: { shipment_uuids: shipmentUUIds },
        };
      },
      invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateCarrier: builder.mutation({
      query: (data) => {
        return {
          url: `/shipments/carrier`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateTrackingDetails: builder.mutation({
      query: ({ shipmentId, ...data }) => {
        return {
          url: `/shipments/${shipmentId}/tracking-details`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['shipment-details'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    reTrackShipments: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipments/retrack`,
          method: 'PUT',
          body: { shipment_uuids: shipmentUUIds },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    deleteShipments: builder.mutation({
      query: (shipmentUUIds) => {
        return {
          url: `/shipments`,
          method: 'DELETE',
          body: { shipment_uuids: shipmentUUIds },
        };
      },
      invalidatesTags: ['shipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useGetShipmentStatusesQuery,
  useGetShipmentsQuery,
  useGetShipments3Query,
  useGetShipmentCarriersQuery,
  useLazyExportShipmentsQuery,
  useMarkAsDeliveredMutation,
  useUpdateCarrierMutation,
  useReTrackShipmentsMutation,
  useDeleteShipmentsMutation,
  useUpdateTrackingDetailsMutation,
  useGetShipmentDetailsQuery,
  useGetOrderSyncOptionsQuery,
  useSyncOrdersMutation,
} = shipmentsAPIs;

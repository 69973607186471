import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  DatePicker,
  Form,
  FormLayout,
  Modal,
  Select,
  Stack,
  TextField,
} from '@shopify/polaris';
import moment from 'moment';
import {
  DATE_RANGE_VALUE_CUSTOM,
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DATE_FORMAT_STRING,
} from 'Constants';
import {
  getDisplayValues,
  getDropDownOptions,
  getOptionLabel,
} from './helpers';
import { useTranslation } from 'react-i18next';

const DateRangeNew = ({
  onChange,
  selectedOption,
  selectedRange,
  isDisabled,
  fullWidth,
  disabledRange = {},
}) => {
  const [t] = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(
    selectedOption || DATE_RANGE_VALUE_LAST_30_DAYS
  );

  const [range, setRange] = useState({
    start: selectedRange.start,
    end: selectedRange.end,
  });

  const [{ month, year }, setDate] = useState({
    month: new Date(selectedRange.end).getMonth(),
    year: new Date(selectedRange.end).getFullYear(),
  });

  useEffect(() => {
    setSelected(selectedOption || DATE_RANGE_VALUE_LAST_30_DAYS);
  }, [selectedOption]);

  const handleMonthChange = useCallback(
    (mon, yr) => setDate({ month: mon, year: yr }),
    []
  );

  const activatorText = useMemo(() => {
    if (selected) {
      let { start, end } = range;
      if (selected === DATE_RANGE_VALUE_CUSTOM) {
        return `${moment(start).format(DATE_FORMAT_STRING)} - ${moment(
          end
        ).format(DATE_FORMAT_STRING)}`;
      } else {
        return getOptionLabel(selected, t);
      }
    } else {
      return '';
    }
  }, [selected, range, t]);

  const handleApply = () => {
    onChange({
      start: moment(range.start)._d,
      end: moment(range.end)._d,
      selectedOption: selected,
    });
    toggleModal();
  };

  const handleCancel = () => {
    setSelected(selectedOption || DATE_RANGE_VALUE_LAST_30_DAYS);
    setRange({
      start: selectedRange.start,
      end: selectedRange.end,
    });

    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOptionChange = (option) => {
    setSelected(option);
  };

  useEffect(() => {
    if (selected !== DATE_RANGE_VALUE_CUSTOM) {
      const newRange = getDisplayValues(selected, range);
      const startDate = moment(newRange.start)._d;
      const endDate = moment(newRange.end)._d;
      setRange({ start: startDate, end: endDate });
      setDate({
        month: new Date(endDate).getMonth(),
        year: new Date(endDate).getFullYear(),
      });
    }
  }, [selected]);

  const handleRangeChange = (range) => {
    setSelected(DATE_RANGE_VALUE_CUSTOM);
    setRange(range);
  };

  const displayRange = useMemo(() => {
    return getDisplayValues(selected, range);
  }, [selected, range]);

  const activator = (
    <Button
      onClick={toggleModal}
      disclosure
      fullWidth={fullWidth}
      disabled={isDisabled}
    >
      {activatorText}
    </Button>
  );

  const dropDownOptions = useMemo(() => {
    if (t) {
      return getDropDownOptions(selected, t);
    } else {
      return [];
    }
  }, [selected, t]);

  return (
    <Modal open={showModal} activator={activator}>
      <div>
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                <Select
                  label={t('shipments.filter.date_range_title')}
                  options={dropDownOptions}
                  onChange={handleOptionChange}
                  value={selected}
                />
                <FormLayout.Group>
                  <TextField
                    label={t('shipments.filter.date_starting')}
                    value={displayRange.start}
                  />
                  <TextField
                    label={t('shipments.filter.date_ending')}
                    value={displayRange.end}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <DatePicker
                    month={month}
                    year={year}
                    onMonthChange={handleMonthChange}
                    onChange={handleRangeChange}
                    selected={range}
                    multiMonth={true}
                    allowRange={true}
                    disableDatesBefore={disabledRange.start}
                    disableDatesAfter={disabledRange.end}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card.Section>
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={handleCancel}>Cancel</Button>
              <Button primary onClick={handleApply}>
                Apply
              </Button>
            </Stack>
          </Card.Section>
        </Card>
      </div>
    </Modal>
  );
};

DateRangeNew.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default DateRangeNew;

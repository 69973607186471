import React from 'react';
import { EmptyState } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
export default function NotFound() {
  const [t] = useTranslation();
  return (
    <EmptyState
      heading={t('error_page.heading')}
      action={{ content: t('error_page.action.label'), url: `/s` }}
      // footerContent={}
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/bb00f3e8d1c7d411d93a0e0a92dd1375.svg"
    >
      <TranslateLink text={t('error_page.content')} />
    </EmptyState>
  );
}

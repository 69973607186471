import React, { useEffect } from 'react';
import { ClientStorage } from 'ClientStorage';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch } from 'react-redux';
import { baseActions } from 'redux/store/baseSlice';

export default function SegmentAnalytics({
  shop,
  base,
  visitor,
  getIpAddress,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    getIpAddress();
  }, [getIpAddress]);

  useEffect(() => {
    if (
      shop.storeUUID &&
      !base.segmentIdentified &&
      window.analytics &&
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true
    ) {
      window.analytics.identify(shop.storeUUID, {
        name: shop.user.fullName,
        email: shop.user.email,
        ip: visitor.ipAddress,
      });
      window.analytics.track('Shopify App login');
      dispatch(baseActions.markSegmentIdentified());
    }
  }, [base.segmentIdentified, shop, dispatch]);
  return <></>;
}

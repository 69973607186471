import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Stack, Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { useHistory, useLocation } from 'react-router-dom';
import TranslateLink from 'components/TranslateLink';
import { VISITOR_URLS } from 'config/urls';

export default function EmailValidation({
  getVisitorLanguages,
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  verifyCode,
  visitorToast,
  resetVisitorToast,
  loading,
  resendVerificationCode,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let email = params.get('email');
  const token = params.get('token');

  const [code, setCode] = useState(params.get('code') || '');
  const [showCodeError, setShowCodeError] = useState(false);

  if (!email || !token) {
    history.push(VISITOR_URLS.REGISTER);
  } else {
    email = email.replaceAll(' ', '+');
  }

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
  }, []);

  const handleCodeChange = useCallback((value) => {
    setCode(value.slice(0, 6));
  }, []);

  useEffect(() => {
    if (code.length === 6) {
      verifyCode({ code, token });
    }
  }, [code]);

  const handleSubmit = () => {
    setShowCodeError(false);

    let hasErrors = false;

    if (code.length < 6) {
      setShowCodeError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }
    verifyCode({ code, token });
  };

  const handleChangeEmail = () => {
    history.push(`${VISITOR_URLS.CHANGE_EMAIL}?token=${token}&email=${email}`);
  };

  const handleSendCodeAgain = () => {
    resendVerificationCode(token);
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  if (visitorToast.redirect) {
    history.push(visitorToast.redirect);
  }

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('visitor-app:email_validation_screen.title')}
          subtitle={
            <TranslateLink
              text={t('visitor-app:email_validation_screen.sub_title', {
                email,
              })}
            />
          }
          inputFields={[
            {
              placeholder: t(
                'visitor-app:email_validation_screen.code_placeholder'
              ),
              error: showCodeError ? t('visitor-app:errors.code_length') : null,
              value: code,
              onChange: handleCodeChange,
              type: 'text',
            },
          ]}
          button={t('visitor-app:password_recovery_screen.continue_btn')}
          footer1={
            <Stack spacing={'extraTight'}>
              <Link onClick={handleSendCodeAgain}>
                {t('visitor-app:email_validation_screen.send_code_again_link')}
              </Link>
              <p>
                {t('visitor-app:email_validation_screen.options_message_or')}
              </p>
              <Link onClick={handleChangeEmail}>
                {t('visitor-app:email_validation_screen.change_email_link')}
              </Link>
            </Stack>
          }
          footer2={
            <TranslateLink
              text={t('visitor-app:email_validation_screen.footer_message')}
            />
          }
          onSubmit={handleSubmit}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

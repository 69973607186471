import { connect } from 'react-redux';
import SmallTopMessage from './SmallTopMessage';

function mapStateToProps(state) {
  return {
    chargeDetail: state.billing.chargeDetail,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmallTopMessage);

import { connect } from 'react-redux';
import { reportsActions } from 'redux/reports';
import ShipmentPerStatus from './ShipmentPerStatus';
import { DASHBOARD_ANALYTICS_TYPE_SHIPMENT_PER_STATUS } from 'Constants';

function mapStateToProps(state) {
  return {
    shipmentsPerStatus:
      state.reports[`report_${DASHBOARD_ANALYTICS_TYPE_SHIPMENT_PER_STATUS}`],
    shipmentStatuses: state.shipments.statuses,
  };
}

const mapDispatchToProps = {
  getReport: reportsActions.Creators.getReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPerStatus);

import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import {
  Login,
  Register,
  RegisterByInvite,
  Recover,
  EmailValidation,
  RecoverSuccess,
  RecoverPassword,
  ChangeEmail,
} from 'pages';
import LoadingPage from 'components/LoadingPage';
import { ClientStorage } from 'ClientStorage';
import { isUUID } from 'Utils/utils';
import { LOCAL_STORAGE } from 'Constants';
import { getJobStatus } from 'redux/visitor/visitor.webServices';
import { useTranslation } from 'react-i18next';

function VisitorRoutes({ accessTokenLoading, getAccessToken, setStoreUUID }) {
  const [t] = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const [isJobRunning, setIsJobRunning] = useState(false);

  useEffect(() => {
    const storeUUIDParam = location.pathname.split('/')[2];
    if (isUUID(storeUUIDParam)) {
      setStoreUUID(storeUUIDParam);
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeUUIDParam);
    } else {
      const storeUUID = ClientStorage.get(LOCAL_STORAGE.LAST_STORE_UUID);
      setStoreUUID(storeUUID);
    }

    const jobId = params.get('job_id');
    const ott = params.get('ott');

    if (jobId) {
      runJobInterval(jobId, ott);
    } else {
      getAccessToken({ ott });
    }

    removeAuthParams();
  }, []);

  const runJobInterval = (jobId, ott) => {
    const interval = setInterval(async function () {
      setIsJobRunning(true);
      const response = await getJobStatus(jobId);
      if (response.data.data.process !== 'finished') {
        return;
      }

      const storeUUID = response.data.data.output.store_uuid;
      setStoreUUID(storeUUID);
      ClientStorage.set(LOCAL_STORAGE.LAST_STORE_UUID, storeUUID);
      history.replace(`/s/${storeUUID}`);

      clearInterval(interval);
      setIsJobRunning(false);
      getAccessToken({ ott });
    }, 3000);
  };

  const removeAuthParams = () => {
    params.delete('job_id');
    params.delete('ott');
    params.delete('skip_tracking');
    params.delete('domain');
    history.replace({
      search: params.toString(),
    });
  };

  return (
    <React.Fragment>
      {accessTokenLoading ? (
        <LoadingPage
          text={isJobRunning ? t('app.connecting_to_store') : undefined}
        />
      ) : (
        <Switch>
          <Route path={VISITOR_URLS.LOGIN} component={Login} />
          <Route path={VISITOR_URLS.REGISTER} component={Register} />
          <Route
            path={VISITOR_URLS.REGISTER_BY_INVITE}
            component={RegisterByInvite}
          />
          <Route path={VISITOR_URLS.RECOVER} component={Recover} />
          <Route
            path={VISITOR_URLS.RECOVER_SUCCESS}
            component={RecoverSuccess}
          />
          <Route
            path={VISITOR_URLS.RECOVER_PASSWORD}
            component={RecoverPassword}
          />
          <Route
            path={VISITOR_URLS.EMAIL_VALIDATION}
            component={EmailValidation}
          />
          <Route path={VISITOR_URLS.CHANGE_EMAIL} component={ChangeEmail} />

          <Redirect to={VISITOR_URLS.LOGIN} />
        </Switch>
      )}
    </React.Fragment>
  );
}

export default withRouter(VisitorRoutes);

module.exports = {
  loading: false,
  error: null,
  toast: {
    message: null,
    error: null,
  },

  languages: null,
  selectedLanguage: '',
  selectedLanguageCode: null,

  newEmail: null,
  registeredUser: null,
  verificationToken: null,

  accessTokenLoading: true,
  accessToken: null,
  refreshToken: null,

  ipAddress: null,

  campaignMessage: null,
};

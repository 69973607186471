import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import CustomRedirect from 'components/CustomRedirect';
import StoreRoute from 'components/StoreRoute';
import {
  OnBoarding as OnBoardingPage,
  Dashboard as DashboardPage,
  Reports as ReportsPage,
  Shipments as ShipmentsPage,
  ShipmentDetail as ShipmentDetailPage,
  ShipmentSettings as ShipmentSettingsPage,
  Billing1 as BillingPage,
  Billing as BillingPage1,
  Language as LanguagePage,
  Settings as SettingsPage,
  Conversions as ConversionsPage,
  TrackingPage as TrackingPagePage,
  ThankyouPage as ThankyouPagePage,
  KlaviyoPage as KlaviyoPagePage,
  OmnisendPage,
  SmsNotification as SmsNotificationPage,
  SMSNotificationRule as SMSNotificationRulePage,
  EmailNotification as EmailNotificationPage,
  EmailNotificationRule as EmailNotificationRulePage,
  SmartEDD,
  MerchantNote as MerchantNotePage,
  Upsells as UpsellsPage,
  PaypalConfig as PaypalConfigPage,
  PaypalTrackings as PaypalTrackingsPage,
  ShipbobConfig as shipbobConfigPage,
  BillingDetail as BillingDetailPage,
  NotFound as NotFoundPage,
  Help as HelpPage,
  Health as HealthPage,
  APIHealth as APIHealthPage,
  DefaultPage,
  Blacklisting,
  PreShipmentTimeline,
  MessageTimelinePage,
  CarrierMasking,
  CarrierMaskingPage,
  Stores as StoresPage,
  Account as AccountPage,
  Questionnaire as QuestionnairePage,
  ProductReviews as ProductReviewsPage,
  AddStore as AddStorePage,
  Perks as PerksPage,
  ApprovePage,
  ReconnectPage,
} from 'pages';
import ScrollToTop from 'components/ScrollToTop';
import { STORE_URLS, USER_URLS } from 'config/urls';
import { Banner, TextStyle } from '@shopify/polaris';
import { PERMISSION_URLS } from 'config/urls';
import Shipments3 from 'pages/Shipments/components/Shipments3';
import { useGetUserDetailsQuery } from 'redux/store/commonBaseApis';

function PrivateRoutes({ shop }) {
  const { data: user = {} } = useGetUserDetailsQuery();

  const storeBaseUrl = '/s/:uuid';
  return (
    <React.Fragment>
      {user.sudo && (
        <div className="Banners-Wrapper">
          <Banner title={`Sudo session!`} status={'warning'}>
            <TextStyle>{`${user.sudo.name} with ${user.sudo.email} logged as ${user.first_name} ${user.last_name} with ${user.email}`}</TextStyle>
          </Banner>
        </div>
      )}
      <ScrollToTop />
      <Switch>
        <Route
          path={USER_URLS.QUESTIONNAIRE}
          component={QuestionnairePage}
          exact
        />
        <Route path={`${PERMISSION_URLS.APPROVE}`} component={ApprovePage} />
        <Route
          path={`${PERMISSION_URLS.RECONNECT}`}
          component={ReconnectPage}
        />
        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.SMART_EDD}`}
          component={SmartEDD}
        />

        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}/:notificationId`}
          component={EmailNotificationRulePage}
        />
        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION_RULE}`}
          component={EmailNotificationRulePage}
        />
        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.EMAIL_NOTIFICATION}`}
          component={EmailNotificationPage}
        />
        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.SMS_NOTIFICATION_RULE}/:notificationId`}
          component={SMSNotificationRulePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SMS_NOTIFICATION_RULE}
          component={SMSNotificationRulePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SMS_NOTIFICATION}
          component={SmsNotificationPage}
        />
        <StoreRoute
          path={`${storeBaseUrl}${STORE_URLS.TRACKING_PAGES}/:languageId/:mode`}
          component={LanguagePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.TRACKING_PAGES}
          component={TrackingPagePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.PRODUCT_REVIEWS}
          component={ProductReviewsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.THANK_YOU}
          component={ThankyouPagePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.KLAVIYO}
          component={KlaviyoPagePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.OMNISEND}
          component={OmnisendPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.MERCHANT_NOTE}
          component={MerchantNotePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.UPSELLS}
          component={UpsellsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.PAYPAL_CONFIG}
          component={PaypalConfigPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.PAYPAL_TRACKINGS}
          component={PaypalTrackingsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIP_BOB_CONFIG}
          component={shipbobConfigPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.DASHBOARD}
          component={DashboardPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.REPORTS}
          component={ReportsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_CONFIGURATIONS}
          component={ShipmentSettingsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_BLACKLISTING}
          component={Blacklisting}
        />
        <StoreRoute
          path={`${
            storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE
          }/:timelineId`}
          component={MessageTimelinePage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIPMENTS_PRE_SHIPMENT_TIMELINE}
          component={PreShipmentTimeline}
        />
        <StoreRoute
          path={[
            `${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId`,
            `${storeBaseUrl}${STORE_URLS.CARRIER_MASKING}/:maskingId/duplicate`,
          ]}
          component={CarrierMaskingPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.CARRIER_MASKING}
          component={CarrierMasking}
        />
        <StoreRoute
          path={[
            `${storeBaseUrl}${STORE_URLS.SHIPMENTS}/:shipmentId`,
            `${storeBaseUrl}${STORE_URLS.SHIPMENTS}3/:shipmentId`,
          ]}
          component={ShipmentDetailPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIPMENTS}
          component={ShipmentsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.SHIPMENTS + '3'}
          component={Shipments3}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.BILLING_DETAIL}
          component={BillingDetailPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.BILLING}
          component={BillingPage}
        />

        <StoreRoute
          path={storeBaseUrl + STORE_URLS.BILLING + '1'}
          component={BillingPage1}
        />

        <StoreRoute
          path={storeBaseUrl + STORE_URLS.BILLING + '1'}
          component={BillingPage1}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.NOTIFICATIONS}
          component={SettingsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.ADDONS}
          component={SettingsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.CONVERSIONS}
          component={ConversionsPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.HELP}
          component={HelpPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.ONBOARDING}
          component={OnBoardingPage}
        />
        <StoreRoute
          path={storeBaseUrl + STORE_URLS.PERKS}
          component={PerksPage}
        />

        <Route path={USER_URLS.ADD_STORE} component={AddStorePage} exact />
        <Route path={USER_URLS.DETAILS} component={AccountPage} exact />

        <Route path="/status/health-connect-api" component={APIHealthPage} />
        <Route path="/status/health" component={HealthPage} />
        <Route path={'/s/:uuid'} component={DefaultPage} />

        <Route path={'/s'} component={StoresPage} />
        <CustomRedirect from="/" to={`/s/${shop.storeUUID}`} />
        <Route path="*" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(PrivateRoutes);

module.exports = {
  subscriptionPlans: [],
  subscriptionPlanGroups: [],
  subscriptionPlanMeta: [],
  currentPlan: {
    billing_plan: {
      id: null
    }
  },
  chargeDetail: {
    charge_id: null,
    charge_url: null,
    type: null
  },
  chargeLimitError: null,
  chargeLimit: {
    charge_limit: null
  },
  chargeLink: {
    charge_url: null
  },
  billingTransactions: {
    billingPlanTransactions: null,
    meta: null,
    links: null,
    exportMessage: null
  },
  planSuggestions: null,
  billingSummary: null,
  billingPlan: null,
  billingPlanError: null,
  charge: null,
  showPromoCodeConfirmation: false,
  showPromoCode: false,
  promoCode: {},
  promoCodeConfirmation: {},
  promoError: {},
  promoLoading: {},
  isShowingFeedBackModal: false,
  canWriteFeedbackReview: false,
  canWriteFeedbackReviewLoading: false,
  showSubscriptionPlansModal: false,
  billingToasterMessage: null
};

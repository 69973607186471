import React from 'react';
import { TextStyle, Stack, Icon } from '@shopify/polaris';
import { ExternalSmallMinor, NoteMajor } from '@shopify/polaris-icons';
import ExternalLink from '../ExternalLink';

export default function Subtitle({ text, actions }) {
  return (
    <Stack vertical={true} spacing={'baseTight'}>
      <TextStyle>{text}</TextStyle>
      <Stack>
        {actions.map((action) => (
          <ExternalLink url={action.source} key={action.text}>
            <Stack spacing={'extraTight'}>
              <Icon source={action.icon || NoteMajor} color={'interactive'} />
              <TextStyle>{action.text}</TextStyle>
              <Icon source={ExternalSmallMinor} color={'interactive'} />
            </Stack>
          </ExternalLink>
        ))}
      </Stack>
    </Stack>
  );
}

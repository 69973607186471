import React, { useEffect, useState, useCallback } from 'react';
import {
  Layout,
  Card,
  Button,
  Stack,
  TextStyle,
  ButtonGroup,
  SkeletonBodyText,
} from '@shopify/polaris';
import ExternalModal from 'components/ExternalModal';
import {
  ENV_DEV,
  SETTINGS_BUTTON_PRIMARY,
  SETTINGS_PAYPAL_ACTION_DELETE,
  SETTINGS_PAYPAL_ACTION_DEACTIVATE,
  SETTINGS_PAYPAL_ACTION_ACTIVATE,
} from 'Constants';
import { useTranslation } from 'react-i18next';
import { FRONTEND_URL, PAYPAL_REDIRECT_LINK, STAGE } from 'config';
export default function AccountsSection({
  getPaypalConfig,
  paypalButtonConfiguration,
  paypalSyncTrackingConfig,
  postPaypalAccount,
  putPaypalAccount,
  paypalLoading,
  deletePaypalAccount,
  paypalAccountsUpdated,
  shop,
}) {
  const [t] = useTranslation();
  const [paypalButton, setPaypalButton] = useState(null);

  useEffect(() => {
    if (paypalAccountsUpdated) {
      getPaypalConfig();
    }
  }, [getPaypalConfig, paypalAccountsUpdated]);

  const postPaypalAccountCallback = (params) => {
    postPaypalAccount(params);
  };

  // button conf
  useEffect(() => {
    if (paypalButtonConfiguration) {
      let returnURL = paypalButtonConfiguration.return_url;
      if (STAGE === ENV_DEV) {
        returnURL = `${FRONTEND_URL}redirects/paypal`;
      }
      const paypalLink = `${PAYPAL_REDIRECT_LINK}connect?flowEntry=static&client_id=${paypalButtonConfiguration.app_id}&scope=${paypalButtonConfiguration.scopes}&redirect_uri=${returnURL}`;
      setPaypalButton(paypalLink);
    }
  }, [paypalButtonConfiguration, shop]);

  const handleAccountClick = useCallback(
    (btnId, accountId) => {
      if (btnId === SETTINGS_PAYPAL_ACTION_DEACTIVATE) {
        putPaypalAccount(accountId, { activate: false });
      } else if (btnId === SETTINGS_PAYPAL_ACTION_ACTIVATE) {
        putPaypalAccount(accountId, { activate: true });
      } else if (btnId === SETTINGS_PAYPAL_ACTION_DELETE) {
        deletePaypalAccount(accountId);
      }
    },
    [putPaypalAccount, deletePaypalAccount]
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.paypal_page.connected_accounts_layout.title')}
      description={t(
        'settings.paypal_page.connected_accounts_layout.description'
      )}
    >
      <Card title={t('settings.paypal_page.connected_accounts_card.title')}>
        <Card.Section>
          {paypalLoading && (
            <div>
              <div>
                <SkeletonBodyText lines={2} />
              </div>
            </div>
          )}
          {!paypalLoading &&
            paypalSyncTrackingConfig &&
            paypalSyncTrackingConfig.accounts.length > 0 &&
            paypalSyncTrackingConfig.accounts.map((account, index) => (
              <div className="Paypal-ListItem" key={index}>
                <Stack alignment="center">
                  <TextStyle>{account.name}</TextStyle>
                </Stack>
                <div>
                  <ButtonGroup>
                    {account.buttons &&
                      account.buttons.map((btn) => (
                        <Button
                          primary={btn.style === SETTINGS_BUTTON_PRIMARY}
                          external={btn.external}
                          key={btn.id}
                          onClick={() => {
                            handleAccountClick(btn.id, account.id);
                          }}
                          disabled={paypalLoading}
                        >
                          {btn.content.text}
                        </Button>
                      ))}
                  </ButtonGroup>
                </div>
              </div>
            ))}
          {!paypalLoading &&
            paypalSyncTrackingConfig &&
            paypalSyncTrackingConfig.accounts.length === 0 && (
              <div className="CustomEmptyState">
                <TextStyle variation="subdued">
                  {t(
                    'settings.paypal_page.connected_accounts_card.no_row_message'
                  )}
                </TextStyle>
              </div>
            )}
        </Card.Section>
        <Card.Section>
          <Stack distribution="trailing">
            {paypalButton && (
              <ExternalModal
                externalURL={paypalButton}
                callbackFns={postPaypalAccountCallback}
                imgURL="https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png"
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

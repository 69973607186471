import React, { useState, useCallback, useEffect } from 'react';
import TranslateLink from 'components/TranslateLink';
import {
  Layout,
  Card,
  Form,
  FormLayout,
  Select,
  TextStyle,
  Banner,
  TextField,
  SkeletonBodyText,
} from '@shopify/polaris';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
import ExternalLink from '../../../../components/ExternalLink';
export default function PageURL({
  trackingPageState,
  pageLink,
  trackingDetailMeta,
  dispatchForTrackingPage,
  ...props
}) {
  const [t] = useTranslation();
  const [extension, setExtension] = useState(null);
  const [extensionError, setExtensionError] = useState(null);
  const [selectedPageURL, setSelectedPageURL] = useState(null);
  const handlePageURLChange = useCallback(
    (value) => {
      setSelectedPageURL(value);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.UPDATE,
        payload: {
          page_link: {
            proxy: value,
            extension: extension,
            domain: pageLink.domain,
            append_test_link: pageLink.append_test_link,
          },
        },
      });
    },
    [extension, pageLink, dispatchForTrackingPage]
  );

  const handleExtensionChange = useCallback(
    (newValue) => setExtension(newValue),
    []
  );
  const handleExtensionBlur = useCallback(
    (newValue) => {
      if (_.isNull(extension) || _.isEmpty(_.trim(extension))) {
        setExtensionError(t('settings.tracking_page.section_permlink.error'));
        dispatchForTrackingPage({
          type: TRACKING_PAGE_ACTIONS.VALIDATION,
          payload: {
            pageExtension: false,
          },
        });
      } else if (/\/|\s/g.test(newValue.target.value)) {
        setExtensionError(
          t('settings.tracking_page.section_permlink.validation_error')
        );
        dispatchForTrackingPage({
          type: TRACKING_PAGE_ACTIONS.VALIDATION,
          payload: {
            pageExtension: false,
          },
        });
      } else if (extension !== trackingPageState.page_link.extension) {
        setExtensionError(null);
        dispatchForTrackingPage({
          type: TRACKING_PAGE_ACTIONS.UPDATE,
          payload: {
            page_link: {
              extension: _.trim(extension),
              proxy: selectedPageURL,
              domain: pageLink.domain,
              append_test_link: pageLink.append_test_link,
            },
          },
          validation: {
            pageExtension: true,
          },
        });
      } else {
        setExtensionError(null);
      }
    },
    [
      setExtensionError,
      selectedPageURL,
      extension,
      pageLink,
      t,
      dispatchForTrackingPage,
      trackingPageState.page_link,
    ]
  );

  useEffect(() => {
    setSelectedPageURL(trackingPageState.page_link.proxy);
    setExtension(trackingPageState.page_link.extension);
  }, [trackingPageState.page_link, trackingPageState.trackingPageToaster]);

  let tempPageLink = null;
  if (pageLink && pageLink.proxy) {
    tempPageLink = `${pageLink.domain}${pageLink.proxy}${pageLink.extension}`;
  }

  if (!pageLink) {
    return (
      <Layout.AnnotatedSection>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
      </Layout.AnnotatedSection>
    );
  }
  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.section_permlink.title')}
      description={t('settings.tracking_page.section_permlink.description')}
    >
      {pageLink && !_.isNull(selectedPageURL) && (
        <Card
          title={t('settings.tracking_page.section_permlink.card_title')}
          sectioned
        >
          <Banner status="warning">
            <TranslateLink
              text={t(
                'settings.tracking_page.section_permlink.warning_message'
              )}
            />
          </Banner>

          {trackingDetailMeta && (
            <div>
              <div className="PageURL-Form">
                <TextStyle variation="strong">
                  {t('settings.tracking_page.section_permlink.select_label')}
                </TextStyle>
              </div>
              <div className="PageURL-Form">
                <Form>
                  <FormLayout>
                    <FormLayout.Group>
                      <Select
                        options={trackingDetailMeta.page_link.proxy.map(
                          (proxy) => ({
                            value: proxy,
                            label: `${pageLink.domain}${proxy}`,
                          })
                        )}
                        onChange={handlePageURLChange}
                        value={selectedPageURL}
                        id={`select-permlink-select-label`}
                        disabled={trackingPageState.loading}
                      />
                      <TextField
                        value={extension}
                        onChange={handleExtensionChange}
                        onBlur={handleExtensionBlur}
                        error={extensionError}
                        id={`input-extension`}
                        disabled={trackingPageState.loading}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              </div>
              {props.publicLink && (
                <div className="PageURL-Form">
                  <TextStyle variation="subdued">
                    {t('settings.tracking_page.section_permlink.helper_label')}
                    <ExternalLink url={tempPageLink}>
                      {tempPageLink}
                    </ExternalLink>
                  </TextStyle>
                </div>
              )}
            </div>
          )}
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import IncreaseLimitModal from './IncreaseLimitModal';

function mapStateToProps(state) {
  return {
    billing: state.billing,
    currentPlan: state.billing.currentPlan,
    chargeLimit: state.billing.chargeLimit,
    chargeLimitError: state.billing.chargeLimitError,
    chargeLink: state.billing.chargeLink,
    loading: state.billing.loading,
  };
}

const mapDispatchToProps = {
  getChargeLimit: billingActions.Creators.getChargeLimit,
  updateChargeLimit: billingActions.Creators.updateChargeLimit,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncreaseLimitModal);

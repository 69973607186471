import React from 'react';
import {
  IndexTable,
  Stack,
  Button,
  Loading,
  TextStyle,
  Badge,
} from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { useReorderPreShipmentTimelineMutation } from '../preShipmentTimelineApis';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PreShipmentEmptyState from './PreShipmentEmptyState';

function PreShipmentDataTable({
  preShipmentTimelines = [],
  setPreShipmentTimelines,
  isDisabled = false,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  const [updatePreShipmentTimelines, updatePreShipmentTimelinesResponse] =
    useReorderPreShipmentTimelineMutation();

  const { isLoading: isUpdatingPreShipmentTimelines } =
    updatePreShipmentTimelinesResponse;

  const resourceName = {
    singular: 'pre-sipment timeline',
    plural: 'pre-shipment timelines',
  };

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...preShipmentTimelines], index, index - 1);
    setPreShipmentTimelines(updated);
    syncUpdates(updated);
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...preShipmentTimelines], index, index + 1);
    setPreShipmentTimelines(updated);
    syncUpdates(updated);
  };

  const syncUpdates = (updated) => {
    !isDisabled &&
      updatePreShipmentTimelines({
        order: updated.map((p, i) => {
          return {
            uuid: p.uuid,
            order: i + 1,
          };
        }),
      });
  };

  const ClickAbleCell = (values) => {
    return (
      <div
        className="clickable-container-div"
        onClick={() => {
          history.push(window.location.pathname + '/' + values.uuid);
        }}
        title={values.showTitle ? values.title || values.children : ''}
      >
        {values.children}
      </div>
    );
  };

  const renderItemsWithMore = (items = [], type) => {
    if (items.length > 2) {
      const itms = items.slice(0, 2);
      return [...itms, `... and ${items.length - 2} more ${type}`];
    } else {
      return items;
    }
  };

  const renderProducts = (filter_by_products = []) => {
    const length = (filter_by_products || []).length;
    if (!length) {
      return (
        <TextStyle variation="subdued">
          <i>Any</i>
        </TextStyle>
      );
    } else {
      return renderItemsWithMore(
        filter_by_products.map((p) => <div key={p}> {p.name} </div>),
        'products'
      );
    }
  };

  const renderTags = (tags = []) => {
    const length = (tags || []).length;
    if (!length) {
      return (
        <TextStyle variation="subdued">
          <i>Any</i>
        </TextStyle>
      );
    } else {
      return renderItemsWithMore(
        tags.map((t) => (
          <div key={t}>
            <Badge> {t} </Badge>
          </div>
        )),
        'tags'
      );
    }
  };

  const renderMessages = (messages = []) => {
    return renderItemsWithMore(
      messages.map((m) => (
        <div key={m.message}> {m.time_offset_label + ' | ' + m.message} </div>
      )),
      'statuses'
    );
  };

  const rowMarkup = preShipmentTimelines.map((preShipmentTimeline, index) => {
    const {
      filter_by_products = [],
      filter_by_tags = [],
      messages = [],
      last_message_time_offset_hours,
      uuid,
    } = preShipmentTimeline;
    return (
      <IndexTable.Row id={uuid} key={uuid} position={index}>
        <IndexTable.Cell className="products-cell">
          <ClickAbleCell
            uuid={uuid}
            showTitle={(filter_by_products || []).length}
            title={(filter_by_products || []).map((p) => p.name).join('\r\n')}
          >
            {renderProducts(filter_by_products)}
          </ClickAbleCell>
        </IndexTable.Cell>
        <IndexTable.Cell className="products-cell">
          <ClickAbleCell
            uuid={uuid}
            showTitle={(filter_by_tags || []).length}
            title={(filter_by_tags || []).map((p) => p).join('\r\n')}
          >
            {renderTags(filter_by_tags)}
          </ClickAbleCell>
        </IndexTable.Cell>
        <IndexTable.Cell className="products-cell">
          <ClickAbleCell
            uuid={uuid}
            showTitle
            title={messages
              .map((m) => m.time_offset_label + ' | ' + m.message)
              .join('\r\n')}
          >
            {renderMessages(messages)}
          </ClickAbleCell>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ClickAbleCell uuid={uuid}>
            {`${moment
              .duration(last_message_time_offset_hours, 'hours')
              .humanize()}`}
          </ClickAbleCell>
        </IndexTable.Cell>
        <IndexTable.Cell className="navigate-buttons">
          <Stack distribution="trailing">
            <Button
              disabled={index === 0}
              icon={ArrowUpMinor}
              plain
              onClick={() => handleMoveUp(index)}
            ></Button>
            <Button
              disabled={index === preShipmentTimelines.length - 1}
              icon={ArrowDownMinor}
              plain
              onClick={() => handleMoveDown(index)}
            ></Button>
          </Stack>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });
  return (
    <>
      <IndexTable
        emptyState={<PreShipmentEmptyState />}
        resourceName={resourceName}
        itemCount={preShipmentTimelines.length}
        headings={[
          { title: t('pre-shipment.target-products') },
          { title: t('pre-shipment.tags') },
          { title: t('pre-shipment.timeline-summary') },
          { title: t('pre-shipment.duration') },
          { title: '' },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
      {isUpdatingPreShipmentTimelines ? <Loading /> : ''}
    </>
  );
}
export default PreShipmentDataTable;

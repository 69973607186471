import React, { useEffect, useState, useCallback } from 'react';
import {
  Layout,
  Card,
  RadioButton,
  TextStyle,
  TextField,
  Banner,
  Stack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';

export default function RealTimeStatusSection({
  orderPageConfigObj,
  setOrderPageConfigObj,
}) {
  const [t] = useTranslation();
  const [showWarning, setShowWarning] = useState(true);
  const dissmissBanner = useCallback(() => {
    setShowWarning(!showWarning);
  }, [showWarning]);

  const [sectionVisibilityData, setSectionVisibilityData] = useState(false);
  const [showEstimatedDelivery, setShowEstimatedDelivery] = useState(false);
  const [sectionOrder, setSectionOrder] = useState(1);

  useEffect(() => {
    setSectionVisibilityData(
      orderPageConfigObj.configuration.realtime_section.visible
    );
    setShowEstimatedDelivery(
      orderPageConfigObj.configuration.realtime_section.estimated_delivery_dates
    );
    setSectionOrder(orderPageConfigObj.configuration.realtime_section.order);
  }, [orderPageConfigObj]);

  const handleSelectSectionVisibilityChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        realtime_section: {
          ...orderPageConfigObj.configuration.realtime_section,
          visible: !sectionVisibilityData,
        },
      },
      isUpdated: true,
    });
    setSectionVisibilityData((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, sectionVisibilityData]);
  const handleShowEstimatedDeliveryChange = useCallback(() => {
    setOrderPageConfigObj({
      configuration: {
        ...orderPageConfigObj.configuration,
        realtime_section: {
          ...orderPageConfigObj.configuration.realtime_section,
          estimated_delivery_dates: !showEstimatedDelivery,
        },
      },
      isUpdated: true,
    });
    setShowEstimatedDelivery((visible) => !visible);
  }, [orderPageConfigObj, setOrderPageConfigObj, showEstimatedDelivery]);

  const handleChange = useCallback(
    (value) => {
      setOrderPageConfigObj({
        configuration: {
          ...orderPageConfigObj.configuration,
          realtime_section: {
            ...orderPageConfigObj.configuration.realtime_section,
            order: parseInt(value),
          },
        },
        isUpdated: true,
      });
      setSectionOrder(value);
    },
    [orderPageConfigObj, setOrderPageConfigObj]
  );

  return (
    <Layout.AnnotatedSection
      title={t('settings.thankyou_page.realtime.section.title')}
      description={t('settings.thankyou_page.realtime.section.description')}
    >
      <Card title={t('settings.thankyou_page.realtime.card.title')} sectioned>
        <Stack vertical>
          <TextStyle>
            {t('settings.thankyou_page.realtime.card.description')}
          </TextStyle>
          <img
            style={{ width: '100%', height: 'auto' }}
            src="https://assets.rush.app/shopifyapp/shopify-order-status-page-feature/realtime_section.png"
            alt=""
          />

          <Stack vertical spacing="extraTight">
            <TextStyle>
              {t('settings.thankyou_page.realtime.section_visibility.title')}
            </TextStyle>
            <RadioButton
              label={t('settings.thankyou_page.realtime.shown_option')}
              checked={sectionVisibilityData}
              name="realtime_section_visible"
              onChange={handleSelectSectionVisibilityChange}
              value={true}
            />
            <RadioButton
              label={t('settings.thankyou_page.realtime.hidden_option')}
              name="realtime_section_visible"
              checked={!sectionVisibilityData}
              onChange={handleSelectSectionVisibilityChange}
              value={false}
            />
          </Stack>

          {showWarning &&
            orderPageConfigObj.configuration
              .estimated_delivery_dates_feature_warning_message && (
              <Banner title="" status="warning" onDismiss={dissmissBanner}>
                <TextStyle>
                  <TranslateLink
                    text={
                      orderPageConfigObj.configuration
                        .estimated_delivery_dates_feature_warning_message
                    }
                  />
                </TextStyle>
              </Banner>
            )}
          <Stack vertical spacing="extraTight">
            <TextStyle>
              {t('settings.thankyou_page.realtime.estimated_days.title')}
            </TextStyle>
            <RadioButton
              label={t('settings.thankyou_page.realtime.shown_option')}
              checked={showEstimatedDelivery}
              name="estimated_delivery_dates_visible"
              onChange={handleShowEstimatedDeliveryChange}
              value={true}
            />
            <RadioButton
              label={t('settings.thankyou_page.realtime.hidden_option')}
              name="estimated_delivery_dates_visible"
              checked={!showEstimatedDelivery}
              onChange={handleShowEstimatedDeliveryChange}
              value={false}
            />
          </Stack>

          <div className="ContactCard-TextField">
            <TextField
              type="number"
              label={t('settings.thankyou_page.realtime.order.title')}
              value={`${sectionOrder !== 0 ? sectionOrder : 1}`}
              onChange={handleChange}
              min={1}
            />
          </div>
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}

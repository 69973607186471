import { connect } from 'react-redux';
import RecoverSuccess from './RecoverSuccess';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
    loading: state.visitor.loading,
    visitorToast: state.visitor.toast,
    newEmail: state.visitor.newEmail,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
  forgotPassword: visitorActions.Creators.forgotPassword,
  resetVisitorToast: visitorActions.Creators.resetVisitorToast,
  resetNewEmail: visitorActions.Creators.resetNewEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverSuccess);

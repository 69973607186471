import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle, Stack, Heading } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { isEmailValid } from 'Utils/utils';
import TranslateLink from 'components/TranslateLink';

export default function RegisterByInvite({
  getVisitorLanguages,
  visitorLanguages,
  changeVisitorLanguage,
  selectedVisitorLanguage,
}) {
  const [t] = useTranslation();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [firstnameError, setFirstnameError] = useState(null);
  const [lastnameError, setLastnameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
  }, []);

  const handleFirstnameChange = useCallback((value) => {
    setFirstname(value);
  }, []);

  const handleLastnameChange = useCallback((value) => {
    setLastname(value);
  }, []);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setFirstnameError(null);
    setLastnameError(null);
    setEmailError(null);
    setPasswordError(null);

    let hasErrors = false;

    if (firstname.length === 0) {
      setFirstnameError('First name is required');
      hasErrors = true;
    }

    if (lastname.length === 0) {
      setLastnameError('Last name is required');
      hasErrors = true;
    }

    if (!isEmailValid(email)) {
      setEmailError(t('visitor-app:change_email_screen.modal.invalid_email'));
      hasErrors = true;
    }

    if (password.length < 6) {
      setPasswordError('Password cannot be less than 6 characters');
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }
  };

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={false}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || []
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={"You've been invited to join"}
          message={
            <Stack distribution={'center'} alignment={'center'}>
              <img
                src={'https://assets.rush.app/languages/svg/034-china.svg'}
                alt={'logo'}
                width={50}
              />
              <Stack vertical spacing={'extraTight'} alignment={'leading'}>
                <Heading element="h1">{'Primal Harvest'}</Heading>
                <TextStyle variation={'subdued'}>
                  {'primalharvest.com'}
                </TextStyle>
              </Stack>
            </Stack>
          }
          inputFields={[
            {
              placeholder: '* Your work email@gmail.com',
              error: emailError,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.firstname_placeholder'
              ),
              error: firstnameError,
              value: firstname,
              onChange: handleFirstnameChange,
              type: 'text',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.lastname_placeholder'
              ),
              error: lastnameError,
              value: lastname,
              onChange: handleLastnameChange,
              type: 'text',
            },
            {
              placeholder: t(
                'visitor-app:registration_screen.password_placeholder'
              ),
              error: passwordError,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          button={'Join Primal Harvest'}
          footer1={
            <TranslateLink
              text={t('visitor-app:registration_screen.footer_message1')}
            />
          }
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export const API_URL = process.env.REACT_APP_API_URL;
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const FALLBACK_LANGUAGE = process.env.REACT_APP_FALLBACK_LANGUAGE;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const GOOGLE_TAG_ID = process.env.REACT_APP_GOOGLE_TAG_ID;
export const FULL_STORY_ORG = process.env.REACT_APP_FULL_STORY_ORG;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const TINY_MCE_KEY = process.env.REACT_APP_TINY_MCE_KEY;
export const RUSH_FEEDBACK_LINK = process.env.REACT_APP_RUSH_FEEDBACK_LINK;
export const PAYPAL_REDIRECT_LINK = process.env.REACT_APP_PAYPAL_REDIRECT_LINK;
export const STAGE = process.env.REACT_APP_STAGE;

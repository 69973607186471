import React, { useState, useEffect, useCallback } from 'react';
import { Page, Layout, Toast, FooterHelp } from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import { useTranslation } from 'react-i18next';
import './style.scss';
import AccountsSection from './AccountsSection';
import TrackingConf from './TrackingConf';
import DisputeResolution from './DisputeResolution';
import { useHistory } from 'react-router-dom';
import { STORE_URLS } from 'config/urls';
import TranslateLink from 'components/TranslateLink';
export default function PaypalConfig({
  paypalLoading,
  paypalToaster,
  resetPaypalToaster,
  getPaypalConfig,
  shop,
  paypalError,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getPaypalConfig();
  }, [getPaypalConfig]);

  const [showToast, setShowToast] = useState(false);
  const [isShowToastError, setIsShowToastError] = useState(false);
  const [showToastText, setShowToastText] = useState(null);

  const dismissShowToast = useCallback(() => {
    setShowToast((active) => !active);
    resetPaypalToaster();
  }, [resetPaypalToaster]);

  const ShowToastMarkup = showToast ? (
    <Toast
      error={isShowToastError}
      content={showToastText}
      duration={3000}
      onDismiss={dismissShowToast}
    />
  ) : null;

  useEffect(() => {
    setShowToast(paypalToaster.showToast);
    setIsShowToastError(paypalToaster.isErrorToast);
    setShowToastText(paypalToaster.toastText);
  }, [paypalToaster]);

  if (paypalError) {
    history.push(`/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`);
    return;
  }

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          {
            content: t('settings.breadcrumbs'),
            url: `/s/${shop.storeUUID}${STORE_URLS.NOTIFICATIONS}`,
          },
        ]}
        title={t('settings.paypal_page.title')}
        primaryAction={{
          content: t('settings.paypal_page.trackings_btn'),
          url: `/s/${shop.storeUUID}${STORE_URLS.PAYPAL_TRACKINGS}`,
          disabled: paypalLoading,
        }}
      >
        <Layout>
          <AccountsSection />
          <TrackingConf />
          <DisputeResolution />
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
      {ShowToastMarkup}
    </>
  );
}

import React from 'react';
import { Layout, Card, SkeletonPage, SkeletonBodyText } from '@shopify/polaris';

export default function LoadingLayoutPage() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.AnnotatedSection>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  );
}

import React from 'react';
import {
  Badge,
  Card,
  Loading,
  Page,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useGetPerksQuery } from '../perksApi';
import PerksSkeleton from './PerksSkeleton';
import TranslateLink from 'components/TranslateLink';
import './styles.scss';

function Perks() {
  const [t] = useTranslation();
  const perksResponse = useGetPerksQuery();
  const {
    data: perksList = [],
    isLoading: isPerksLoading,
    isFetching: isPerksFetching,
  } = perksResponse || {};

  return (
    <>
      {isPerksLoading ? (
        <PerksSkeleton />
      ) : (
        <Page title={t('perks.title')} subtitle={t('perks.description')}>
          {perksList.map((perk, i) => {
            return (
              <Card
                key={perk.title + i}
                sectioned
                title={
                  <Stack>
                    <img
                      src={perk.logo}
                      width="40px"
                      height="40px"
                      style={{ borderRadius: '7px' }}
                    />
                    <Stack vertical spacing="extraTight">
                      <Stack spacing="tight">
                        {perk.is_new_badge ? (
                          <Badge status="success">New</Badge>
                        ) : (
                          ''
                        )}
                        <TextStyle variation="strong">{perk.title}</TextStyle>
                      </Stack>
                      <TextStyle variation="subdued">{perk.category}</TextStyle>
                    </Stack>
                  </Stack>
                }
                primaryFooterAction={{
                  content: t('perks.card.action'),
                  url: perk.link,
                  external,
                }}
              >
                <TextContainer>
                  <TranslateLink text={perk.description}></TranslateLink>
                </TextContainer>
                <br />
                {perk.how_to ? (
                  <TextContainer>
                    <TextStyle variation="strong">
                      {t('perks.card.how_to_use')}
                    </TextStyle>
                    <TranslateLink text={perk.how_to}></TranslateLink>
                  </TextContainer>
                ) : (
                  ''
                )}
              </Card>
            );
          })}
          <br />
        </Page>
      )}
      {isPerksFetching ? <Loading /> : ''}
    </>
  );
}

export default Perks;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Card,
  TextStyle,
  Form,
  FormLayout,
  ChoiceList,
  TextField,
  SettingToggle,
  Checkbox,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { FEATURE_TRACKING_PAGE } from 'Constants';
import TranslateLink from 'components/TranslateLink';
import _ from 'lodash';
export default function FeatureSection({
  translationField,
  putMerchantNote,
  sectionInfo,
  merchantNoteListMeta,
}) {
  const [t] = useTranslation();
  const [sectionId, setSectionId] = useState(null);
  const [sectionActive, setSectionActive] = useState(null);
  const [selectedType, setSelectedType] = useState([null]);
  const [noteTitle, setNoteTitle] = useState(null);
  const [noteTitleError, setNoteTitleError] = useState(null);
  const [noteMessage, setNoteMessage] = useState(null);
  const [noteMessageError, setNoteMessageError] = useState(null);
  const [featureId, setFeatureId] = useState(null);

  const saveSection = useCallback(
    (
      sectionActive,
      selectedType,
      noteTitle,
      noteMessage,
      dismissibleChecked,
      toasterMessage
    ) => {
      if (_.isNull(noteTitle) || _.isEmpty(_.trim(noteTitle))) {
        return;
      }
      if (_.isNull(noteMessage) || _.isEmpty(_.trim(noteMessage))) {
        return;
      }
      putMerchantNote(sectionId, {
        is_active: sectionActive,
        type: selectedType[0],
        feature_id: featureId[0],
        title: _.trim(noteTitle),
        message: _.trim(noteMessage),
        toasterMessage: toasterMessage,
        is_dismissible: dismissibleChecked,
      });
    },
    [putMerchantNote, sectionId, featureId]
  );

  const [dismissibleChecked, setDismissibleChecked] = useState(false);
  const handleDismissibleChange = useCallback(
    (newChecked) => {
      setDismissibleChecked(newChecked);
      saveSection(
        sectionActive,
        selectedType,
        noteTitle,
        noteMessage,
        newChecked,
        t(`settings.${translationField}.selected_type.toast_message`)
      );
    },
    [
      saveSection,
      sectionActive,
      noteTitle,
      translationField,
      selectedType,
      t,
      noteMessage,
    ]
  );

  const handleSectionActiveToggle = useCallback(() => {
    setSectionActive((active) => !active);
    const msg = !sectionActive
      ? t(`settings.${translationField}.enable.toast_message`)
      : t(`settings.${translationField}.disable.toast_message`);
    saveSection(
      !sectionActive,
      selectedType,
      noteTitle,
      noteMessage,
      dismissibleChecked,
      msg
    );
  }, [
    saveSection,
    sectionActive,
    selectedType,
    noteTitle,
    t,
    translationField,
    noteMessage,
    dismissibleChecked,
  ]);
  const contentSectionActiveStatus = sectionActive
    ? t(`settings.${translationField}.enable.button_on`)
    : t(`settings.${translationField}.enable.button_off`);

  const handleSelectedTypeChange = useCallback(
    (newValue) => {
      setSelectedType(newValue);
      saveSection(
        sectionActive,
        newValue,
        noteTitle,
        noteMessage,
        dismissibleChecked,
        t(`settings.${translationField}.selected_type.toast_message`)
      );
    },
    [
      saveSection,
      sectionActive,
      noteTitle,
      translationField,
      dismissibleChecked,
      t,
      noteMessage,
    ]
  );

  const handleNoteTitleChange = useCallback(
    (newValue) => setNoteTitle(newValue),
    []
  );
  const handleNoteTitleBlur = useCallback(() => {
    if (_.isNull(noteTitle) || _.isEmpty(_.trim(noteTitle))) {
      setNoteTitleError(t(`settings.${translationField}.card.title_error`));
      return;
    }
    setNoteTitleError(null);
    saveSection(
      sectionActive,
      selectedType,
      noteTitle,
      noteMessage,
      dismissibleChecked,
      t(`settings.${translationField}.card_title.toast_message`)
    );
  }, [
    saveSection,
    sectionActive,
    selectedType,
    noteTitle,
    translationField,
    dismissibleChecked,
    t,
    noteMessage,
  ]);

  const handleNoteMessageChange = useCallback(
    (newValue) => setNoteMessage(newValue),
    []
  );
  const handleNoteMessageBlur = useCallback(() => {
    if (_.isNull(noteMessage) || _.isEmpty(_.trim(noteMessage))) {
      setNoteMessageError(t(`settings.${translationField}.card.message_error`));
      return;
    }
    setNoteMessageError(null);
    saveSection(
      sectionActive,
      selectedType,
      noteTitle,
      noteMessage,
      dismissibleChecked,
      t(`settings.${translationField}.card_message.toast_message`)
    );
  }, [
    saveSection,
    sectionActive,
    selectedType,
    noteTitle,
    translationField,
    dismissibleChecked,
    t,
    noteMessage,
  ]);

  useEffect(() => {
    setSectionId(sectionInfo.id);
    setSectionActive(sectionInfo.is_active);
    setNoteTitle(sectionInfo.title);
    setNoteMessage(sectionInfo.message);
    if (
      sectionInfo.feature_id === FEATURE_TRACKING_PAGE &&
      _.isUndefined(sectionInfo.type)
    ) {
      setSelectedType(merchantNoteListMeta.type[0].slug);
    } else {
      setSelectedType([sectionInfo.type]);
    }
    setFeatureId([sectionInfo.feature_id]);
    if (
      sectionInfo.feature_id === FEATURE_TRACKING_PAGE &&
      _.isUndefined(sectionInfo.is_dismissible)
    ) {
      setDismissibleChecked(false);
    } else {
      setDismissibleChecked(sectionInfo.is_dismissible);
    }
  }, [sectionInfo, merchantNoteListMeta]);

  return (
    <Layout.AnnotatedSection
      title={t(`settings.${translationField}.title`)}
      description={t(`settings.${translationField}.description`)}
    >
      <SettingToggle
        action={{
          content: contentSectionActiveStatus,
          onAction: handleSectionActiveToggle,
        }}
        enabled={sectionActive}
      >
        <TextStyle>{t(`settings.${translationField}.enable.title`)}</TextStyle>
      </SettingToggle>
      {sectionActive && (
        <Card>
          {featureId[0] === FEATURE_TRACKING_PAGE && (
            <Card.Section>
              <Form>
                <FormLayout>
                  {merchantNoteListMeta && (
                    <ChoiceList
                      disabled={!sectionActive}
                      title={t(`settings.${translationField}.card.type_title`)}
                      choices={merchantNoteListMeta.type.map((type) => ({
                        value: type.slug,
                        label: type.label,
                      }))}
                      selected={selectedType}
                      onChange={handleSelectedTypeChange}
                    />
                  )}
                  <Checkbox
                    label={t(`settings.${translationField}.card.dismissible`)}
                    checked={dismissibleChecked}
                    onChange={handleDismissibleChange}
                    id={`merchant-note-${translationField}-dismissible`}
                  />
                  <TextStyle variation="subdued">
                    <TranslateLink
                      text={t(
                        `settings.${translationField}.card.dismissible_help_text`
                      )}
                    />
                  </TextStyle>
                </FormLayout>
              </Form>
            </Card.Section>
          )}
          <Card.Section>
            <Form>
              <FormLayout>
                <TextField
                  value={noteTitle}
                  label={t(`settings.${translationField}.card.title_label`)}
                  onChange={handleNoteTitleChange}
                  onBlur={handleNoteTitleBlur}
                  disabled={!sectionActive}
                  id={`input-tracking-${translationField}-title`}
                  error={noteTitleError}
                />
                <TextField
                  value={noteMessage}
                  label={t(`settings.${translationField}.card.message_label`)}
                  onChange={handleNoteMessageChange}
                  onBlur={handleNoteMessageBlur}
                  disabled={!sectionActive}
                  id={`input-tracking-${translationField}-message`}
                  multiline={4}
                  error={noteMessageError}
                />
                <TextStyle variation="subdued">
                  <TranslateLink
                    text={t(
                      `settings.${translationField}.card.message_help_text`
                    )}
                  />
                </TextStyle>
              </FormLayout>
            </Form>
          </Card.Section>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

import {
  Card,
  ChoiceList,
  FormLayout,
  Stack,
  TextField,
} from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function TransitTimeCard({ date_range, single_day, onChange }) {
  const [t] = useTranslation();
  const [fromError, setFromError] = useState(false);
  const [toError, setToError] = useState(false);

  useEffect(() => {
    if (toError) {
      setTimeout(() => {
        setToError(false);
      }, 4000);
    }
  }, [toError]);

  useEffect(() => {
    if (fromError) {
      setTimeout(() => {
        setFromError(false);
      }, 4000);
    }
  }, [fromError]);

  return (
    <Card title={t('smart-edd.transit-time-page.card1-title2')} sectioned>
      <ChoiceList
        choices={[
          {
            label: t('smart-edd.transit-time-page.single-date'),
            value: 'single',
            renderChildren: (selected) =>
              selected ? (
                <FormLayout>
                  <Stack>
                    <TextField
                      min={0}
                      max={90}
                      prefix={t('smart-edd.transit-time-rules.business_days')}
                      label={''}
                      value={single_day}
                      onChange={(val) => {
                        if (val >= 0 && val <= 90) {
                          onChange('single_day', Number(val));
                        }
                      }}
                      type="number"
                    />
                  </Stack>
                </FormLayout>
              ) : (
                ''
              ),
          },
          {
            label: t('smart-edd.transit-time-page.date-range'),
            value: 'range',
            renderChildren: (selected) =>
              selected ? (
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      min={0}
                      max={90}
                      prefix={t('smart-edd.transit-time-rules.business_days')}
                      label={t('common.from')}
                      value={date_range?.from}
                      onChange={(val) => {
                        if (val >= 0 && val <= 90) {
                          if (val > date_range.to) {
                            setFromError(true);
                          } else {
                            onChange('date_range', {
                              ...date_range,
                              from: Number(val),
                            });
                            setFromError(false);
                          }
                        }
                      }}
                      type="number"
                      error={
                        fromError
                          ? t('smart-edd.transit-time-rules.range-from-error')
                          : ''
                      }
                    />

                    <TextField
                      min={0}
                      max={90}
                      prefix={t('smart-edd.transit-time-rules.business_days')}
                      label={t('common.to')}
                      value={date_range?.to}
                      onChange={(val) => {
                        if (val >= 0 && val <= 90) {
                          if (val < date_range.from) {
                            setToError(true);
                          } else {
                            setToError(false);
                            onChange('date_range', {
                              ...date_range,
                              to: Number(val),
                            });
                          }
                        }
                      }}
                      type="number"
                      error={
                        toError
                          ? t('smart-edd.transit-time-rules.range-to-error')
                          : ''
                      }
                    />
                  </FormLayout.Group>
                </FormLayout>
              ) : (
                ''
              ),
          },
        ]}
        selected={date_range ? ['range'] : ['single']}
        onChange={([val]) => {
          if (val === 'range') {
            onChange('date_range', { from: 1, to: 2 });
          } else {
            onChange('single_day', 1);
          }
        }}
      />
    </Card>
  );
}

export default TransitTimeCard;

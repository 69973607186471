import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Card,
  TextStyle,
  Form,
  FormLayout,
  ChoiceList,
  TextField,
  SettingToggle,
  Autocomplete,
  Icon,
  TextContainer,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { FEATURE_EMAIL, UP_SELL_TYPE_COLLECTION } from 'Constants';
import _ from 'lodash';

export default function FeatureSection({
  translationField,
  putUpsells,
  sectionInfo,
  upSellListMeta,
  getUpsellsCollections,
  upSellCollections,
  getUpsellsCollectionById,
  upSellCollectionDetail,
}) {
  const [t] = useTranslation();
  const [sectionId, setSectionId] = useState(null);
  const [sectionActive, setSectionActive] = useState(null);
  const [selectedProductsSource, setSelectedProductsSource] = useState([null]);
  const [selectedProductButtonAction, setSelectedProductButtonAction] =
    useState([null]);
  const [selectedProductImageAction, setSelectedProductImageAction] = useState([
    null,
  ]);
  const [cardTitle, setCardTitle] = useState(null);
  const [featureId, setFeatureId] = useState(null);

  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState(
    []
  );
  const [collectionInputValue, setCollectionInputValue] = useState('');
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [collectionError, setCollectionError] = useState(null);

  const saveSection = useCallback(
    (
      sectionActive,
      selectedProductsSource,
      selectedProductButtonAction,
      selectedProductImageAction,
      cardTitle,
      toasterMessage,
      selectedCollectionOptions
    ) => {
      if (
        selectedProductsSource[0] === UP_SELL_TYPE_COLLECTION &&
        (_.isNull(selectedCollectionOptions[0]) ||
          _.isUndefined(selectedCollectionOptions[0]))
      ) {
        setCollectionError(
          t(`settings.${translationField}.product_source.required_message`)
        );
        return;
      } else {
        setCollectionError(null);
      }

      putUpsells(sectionId, {
        is_active: sectionActive,
        type: selectedProductsSource[0],
        collection_id: selectedCollectionOptions[0],
        feature_id: featureId[0],
        title: cardTitle,
        toasterMessage: toasterMessage,
        product_image_action: selectedProductImageAction[0],
        product_button_action: selectedProductButtonAction[0],
      });
    },
    [putUpsells, sectionId, featureId, t, translationField]
  );

  const handleSectionActiveToggle = useCallback(() => {
    // setSectionActive((active) => !active)
    const msg = !sectionActive
      ? t(`settings.${translationField}.enable.toast_message`)
      : t(`settings.${translationField}.disable.toast_message`);
    saveSection(
      !sectionActive,
      selectedProductsSource,
      selectedProductButtonAction,
      selectedProductImageAction,
      cardTitle,
      msg,
      selectedCollectionOptions
    );
  }, [
    saveSection,
    sectionActive,
    selectedProductsSource,
    selectedProductButtonAction,
    selectedProductImageAction,
    cardTitle,
    t,
    translationField,
    selectedCollectionOptions,
  ]);
  const contentSectionActiveStatus = sectionActive
    ? t(`settings.${translationField}.enable.button_on`)
    : t(`settings.${translationField}.enable.button_off`);

  const handleSelectedProductsSourceChange = useCallback(
    (newValue) => {
      setSelectedProductsSource(newValue);
      if (
        newValue[0] !== UP_SELL_TYPE_COLLECTION ||
        (newValue[0] === UP_SELL_TYPE_COLLECTION &&
          !_.isNull(selectedCollectionOptions[0]))
      ) {
        saveSection(
          sectionActive,
          newValue,
          selectedProductButtonAction,
          selectedProductImageAction,
          cardTitle,
          t(`settings.${translationField}.product_source.toast_message`),
          selectedCollectionOptions
        );
      }
    },

    [
      saveSection,
      sectionActive,
      cardTitle,
      translationField,
      t,
      selectedCollectionOptions,
    ]
  );

  const handleSelectedProductButtonActionChange = (newValue) => {
    setSelectedProductButtonAction(newValue);
    saveSection(
      sectionActive,
      selectedProductsSource,
      newValue,
      selectedProductImageAction,
      cardTitle,
      t('settings.upsell_page.product_button_action.toast_message'),
      selectedCollectionOptions
    );
  };

  const handleSelectedProductImageActionChange = (newValue) => {
    setSelectedProductImageAction(newValue);
    saveSection(
      sectionActive,
      selectedProductsSource,
      selectedProductButtonAction,
      newValue,
      cardTitle,
      t('settings.upsell_page.product_image_action.toast_message'),
      selectedCollectionOptions
    );
  };

  const handleCardTitleChange = useCallback(
    (newValue) => setCardTitle(newValue),
    []
  );
  const handleCardTitleBlur = useCallback(() => {
    saveSection(
      sectionActive,
      selectedProductsSource,
      selectedProductButtonAction,
      selectedProductImageAction,
      cardTitle,
      t(`settings.${translationField}.card_title.toast_message`),
      selectedCollectionOptions
    );
  }, [
    saveSection,
    sectionActive,
    selectedProductButtonAction,
    selectedProductImageAction,
    selectedProductsSource,
    cardTitle,
    translationField,
    t,
    selectedCollectionOptions,
  ]);

  useEffect(() => {
    setSectionId(sectionInfo.id);
    setSectionActive(sectionInfo.is_active);
    setCardTitle(sectionInfo.title);
    setSelectedProductsSource([sectionInfo.type]);
    setSelectedProductButtonAction([sectionInfo.product_button_action]);
    setSelectedProductImageAction([sectionInfo.product_image_action]);
    setFeatureId([sectionInfo.feature_id]);
    setSelectedCollectionOptions([sectionInfo.collection_id]);
    if (
      sectionInfo.type === UP_SELL_TYPE_COLLECTION &&
      sectionInfo.collection_id
    ) {
      getUpsellsCollectionById(sectionInfo.collection_id);
    }
  }, [sectionInfo, getUpsellsCollectionById]);

  useEffect(() => {
    if (
      upSellCollectionDetail &&
      upSellCollectionDetail.id === sectionInfo.collection_id
    ) {
      setCollectionInputValue(upSellCollectionDetail.label);
    }
  }, [upSellCollectionDetail, sectionInfo]);

  useEffect(() => {
    if (!_.isEmpty(collectionInputValue)) {
      getUpsellsCollections({ search: collectionInputValue });
    }
  }, [getUpsellsCollections, collectionInputValue]);

  useEffect(() => {
    setCollectionOptions(upSellCollections);
  }, [upSellCollections]);

  const updateCollectionText = useCallback(
    (value) => {
      setCollectionInputValue(value);
      if (!_.isEmpty(selectedCollectionOptions)) {
        setSelectedCollectionOptions([]);
      }
      if (value === '') {
        setCollectionOptions(upSellCollections);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = upSellCollections.filter((option) =>
        option.label.match(filterRegex)
      );
      setCollectionOptions(resultOptions);
    },
    [upSellCollections, selectedCollectionOptions]
  );

  const updateCollectionSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = collectionOptions.find((option) => {
          return option.value === selectedItem;
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedCollectionOptions(selected);
      setCollectionInputValue(selectedValue[0]);
      saveSection(
        sectionActive,
        selectedProductsSource,
        selectedProductButtonAction,
        selectedProductImageAction,
        cardTitle,
        t(`settings.${translationField}.product_source.toast_message`),
        selected
      );
    },
    [
      collectionOptions,
      saveSection,
      sectionActive,
      selectedProductsSource,
      selectedProductButtonAction,
      selectedProductImageAction,
      cardTitle,
      t,
      translationField,
    ]
  );

  const collectionTextField = (
    <Autocomplete.TextField
      onChange={updateCollectionText}
      label={t(`settings.${translationField}.card.search_collection_label`)}
      value={collectionInputValue}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder={t(
        `settings.${translationField}.card.search_collection_label`
      )}
      type="search"
      id={`input-upsell-search-collection-${translationField}`}
      error={collectionError}
    />
  );

  const emptyState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>
          {t(`settings.${translationField}.card.input_default_text`)}
        </TextContainer>
      </div>
    </React.Fragment>
  );

  return (
    <Layout.AnnotatedSection
      title={t(`settings.${translationField}.title`)}
      description={t(`settings.${translationField}.description`)}
    >
      <SettingToggle
        action={{
          content: contentSectionActiveStatus,
          onAction: handleSectionActiveToggle,
        }}
        enabled={sectionActive}
      >
        <TextStyle>{t(`settings.${translationField}.enable.title`)}</TextStyle>
      </SettingToggle>
      {sectionActive && (
        <Card>
          <Card.Section>
            <Form>
              <FormLayout>
                {upSellListMeta && (
                  <ChoiceList
                    disabled={!sectionActive}
                    title={t(`settings.${translationField}.card.title`)}
                    choices={upSellListMeta.type.map((type) => ({
                      value: type.slug,
                      label: type.label,
                    }))}
                    selected={selectedProductsSource}
                    onChange={handleSelectedProductsSourceChange}
                  />
                )}
              </FormLayout>
              {selectedProductsSource[0] === UP_SELL_TYPE_COLLECTION && (
                <FormLayout>
                  <div className="Upsells-SearchCollection">
                    <Autocomplete
                      options={collectionOptions}
                      selected={selectedCollectionOptions}
                      onSelect={updateCollectionSelection}
                      textField={collectionTextField}
                      listTitle={t(
                        `settings.${translationField}.card.search_collection_label`
                      )}
                      emptyState={emptyState}
                    />
                  </div>
                </FormLayout>
              )}
            </Form>
          </Card.Section>
          {translationField !== 'upsell_page' && (
            <Card.Section>
              <Form>
                <FormLayout>
                  <TextField
                    value={cardTitle}
                    label={t(`settings.${translationField}.card.box_label`)}
                    onChange={handleCardTitleChange}
                    onBlur={handleCardTitleBlur}
                    disabled={!sectionActive}
                    id={`input-tracking-${translationField}-title`}
                    autoComplete={'off'}
                  />
                </FormLayout>
              </Form>
            </Card.Section>
          )}
          <Card.Section>
            <ChoiceList
              disabled={
                sectionInfo.feature_id === FEATURE_EMAIL || !sectionActive
              }
              title={
                t('settings.upsell_page.product_button_action.title') +
                (sectionInfo.feature_id === FEATURE_EMAIL
                  ? ` (${t('settings.upsell_page.product_action.coming_soon')})`
                  : '')
              }
              choices={upSellListMeta.product_button_action.map((type) => ({
                value: type.slug,
                label: type.label,
              }))}
              selected={selectedProductButtonAction}
              onChange={handleSelectedProductButtonActionChange}
            />
          </Card.Section>
          <Card.Section>
            <ChoiceList
              disabled={
                sectionInfo.feature_id === FEATURE_EMAIL || !sectionActive
              }
              title={
                t('settings.upsell_page.product_image_action.title') +
                (sectionInfo.feature_id === FEATURE_EMAIL
                  ? ` (${t('settings.upsell_page.product_action.coming_soon')})`
                  : '')
              }
              choices={upSellListMeta.product_image_action.map((type) => ({
                value: type.slug,
                label: type.label,
              }))}
              selected={selectedProductImageAction}
              onChange={handleSelectedProductImageActionChange}
            />
          </Card.Section>
        </Card>
      )}
    </Layout.AnnotatedSection>
  );
}

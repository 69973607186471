import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Layout,
  Stack,
  Select,
  Card,
  DisplayText,
  SkeletonBodyText,
  Toast,
} from '@shopify/polaris';
import moment from 'moment';
import SmallTopMessage from 'components/SmallTopMessage';
import { useTranslation } from 'react-i18next';
import { ExportMinor } from '@shopify/polaris-icons';
import {
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_LAST_30_DAYS,
} from 'Constants';
import TrackingDataTable from './TrackingDataTable';
import _ from 'lodash';
import DateRange from 'components/DateRange';
import { STORE_URLS } from 'config/urls';
export default function PaypalTrackings({
  shop,
  getPaypalTrackings,
  exportPaypalTrackings,
  paypalLoading,
  paypalTrackingsData,
  paypalTrackingsMeta,
  paypalTrackingsSummary,
  paypalExportLoading,
  paypalToaster,
  resetPaypalToaster,
}) {
  const [t] = useTranslation();
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(29, 'days')._d,
    end: new moment()._d,
  });
  const [noOfRecords, setNoOfRecords] = useState('100');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(false);
  const [isShowToastError, setIsShowToastError] = useState(false);
  const [showToastText, setShowToastText] = useState(null);
  const [queryValue, setQueryValue] = useState(null);

  const dismissShowToast = useCallback(() => {
    setShowToast((active) => !active);
    resetPaypalToaster();
  }, [resetPaypalToaster]);

  const ShowToastMarkup = showToast ? (
    <Toast
      error={isShowToastError}
      content={showToastText}
      duration={3000}
      onDismiss={dismissShowToast}
    />
  ) : null;

  useEffect(() => {
    setShowToast(paypalToaster.showToast);
    setIsShowToastError(paypalToaster.isErrorToast);
    setShowToastText(paypalToaster.toastText);
  }, [paypalToaster]);

  const handleNoOfRecordsChange = useCallback((value) => {
    setCurrentPage(1);
    setNoOfRecords(value);
  }, []);

  const options = [
    {
      label: t('settings.paypal_tracking_page.pagination_drop_down.20'),
      value: '20',
    },
    {
      label: t('settings.paypal_tracking_page.pagination_drop_down.50'),
      value: '50',
    },
    {
      label: t('settings.paypal_tracking_page.pagination_drop_down.100'),
      value: '100',
    },
  ];

  useEffect(() => {
    getPaypalTrackings({
      search: queryValue,
      from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
      to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      per_page: noOfRecords,
      current_page: currentPage,
      with_meta: 1,
    });
  }, [getPaypalTrackings, selectedDates, noOfRecords, currentPage, queryValue]);

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          {
            content: t('settings.breadcrumbs'),
            url: `/s/${shop.storeUUID}${STORE_URLS.PAYPAL_CONFIG}`,
          },
        ]}
        title={t('settings.paypal_tracking_page.title')}
        primaryAction={{
          content: t('settings.paypal_page.configuration_btn'),
          url: '/settings/paypal-config',
        }}
        secondaryActions={[
          {
            content: t('settings.paypal_tracking_page.btn_csv'),
            icon: ExportMinor,
            onAction: () => exportPaypalTrackings(),
            disabled: paypalLoading,
            loading: paypalExportLoading,
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <div className="TimeSelection">
              <div>
                <Stack distribution="trailing">
                  <Select
                    label={''}
                    options={options}
                    onChange={handleNoOfRecordsChange}
                    value={noOfRecords}
                    disabled={paypalLoading}
                  />
                  <DateRange
                    fnSelectDates={setSelectedDates}
                    propsSelectedDates={selectedDates}
                    disableDates={{ end: moment()._d }}
                    selectedDropDownItem={DATE_RANGE_VALUE_LAST_30_DAYS}
                  />
                </Stack>
              </div>
            </div>
          </Layout.Section>
          {!_.isNull(paypalTrackingsSummary) && !paypalLoading && (
            <Layout.Section>
              <div className="LayoutWithTwoColumns TwoCardRow">
                <Card
                  sectioned
                  title={t(
                    'settings.paypal_tracking_page.total_orders_card.title'
                  )}
                  key={1}
                >
                  <DisplayText size="large">
                    {paypalTrackingsSummary.orders}
                  </DisplayText>
                </Card>
                <Card
                  sectioned
                  title={t(
                    'settings.paypal_tracking_page.paid_orders_card.title'
                  )}
                  key={2}
                >
                  <DisplayText size="large">
                    {paypalTrackingsSummary.value}
                  </DisplayText>
                </Card>
              </div>
            </Layout.Section>
          )}
          <Layout.Section>
            {(_.isNull(paypalTrackingsData) || paypalLoading) && (
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
            )}
            {!_.isNull(paypalTrackingsData) && !paypalLoading && (
              <TrackingDataTable
                paypalTrackingsData={paypalTrackingsData}
                paypalTrackingsMeta={paypalTrackingsMeta}
                noOfRecords={noOfRecords}
                setPage={setCurrentPage}
                queryValue={queryValue}
                setQueryValue={setQueryValue}
              />
            )}
          </Layout.Section>
        </Layout>
      </Page>
      {ShowToastMarkup}
    </>
  );
}

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Modal,
  Autocomplete,
  Form,
  FormLayout,
  Icon,
  TextField,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TranslateLink from 'components/TranslateLink';
import { SearchMinor } from '@shopify/polaris-icons';
import { isUUID } from 'Utils/utils';
import { useAllCarriersQuery } from 'redux/store/commonBaseApis';
import {
  useUpdateCarrierMutation,
  useUpdateTrackingDetailsMutation,
} from '../shipmentsApi';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';

export default function UpdateCarrierModal({
  onClose,
  initializer,
  shipmentIds,
  shipmentId,
  shipmentTrackNumber,
  shipmentCarrier,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selectedCarrierOptions, setSelectedCarrierOptions] = useState([]);
  const [inputCarrierValue, setInputCarrierValue] = useState('');
  const [inputCarrierError, setInputCarrierError] = useState(null);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [newTrackingNumberText, setNewTrackingNumberText] = useState('');
  const [newTrackingNumberError, setNewTrackingNumberError] = useState(null);

  const [updateCarrier, updateCarrierResponse] = useUpdateCarrierMutation();
  const [updateTrackingDetails, updateDetailsResponse] =
    useUpdateTrackingDetailsMutation();
  const {
    data: updateCarrierData,
    isLoading: isUpdatingCarrier,
    isSuccess: isCarrierUpdated,
  } = updateCarrierResponse;

  const {
    data: updateDetailsData,
    isLoading: isUpdatingDetails,
    isSuccess: isDetailsUpdated,
  } = updateDetailsResponse;

  const showToast = useCallback((message) => {
    dispatch(
      baseActions.setToastMessage({
        message: message,
        type: 'success',
      })
    );
    onClose(true);
  }, []);
  useEffect(() => {
    if (!isUpdatingCarrier && isCarrierUpdated) {
      showToast(updateCarrierData.message);
    }
  }, [updateCarrierData, isUpdatingCarrier, isCarrierUpdated]);

  useEffect(() => {
    if (!isUpdatingDetails && isDetailsUpdated) {
      showToast(updateDetailsData.message);
    }
  }, [updateDetailsData, isUpdatingDetails, isDetailsUpdated]);

  const {
    data: carriers = [],
    isFetching: carrierListLoading,
    isSuccess: carrierListLoaded,
  } = useAllCarriersQuery();

  const carriersList = useMemo(() => {
    if (carriers && !carrierListLoading && carrierListLoaded) {
      return carriers.map((carrier) => ({
        value: carrier.uuid,
        label: carrier.title,
        slug: carrier.slug,
      }));
    }
    return [];
  }, [carriers, carrierListLoading, carrierListLoaded]);

  useEffect(() => {
    if (carrierListLoaded && shipmentCarrier) {
      const selectedCarrier = carriersList.find(
        ({ value }) => value === shipmentCarrier
      )?.label;

      setSelectedCarrierOptions([shipmentCarrier]);
      setInputCarrierValue(selectedCarrier);

      setInputCarrierError(null);
      setNewTrackingNumberText(shipmentTrackNumber);
      setNewTrackingNumberError(null);
    }
  }, [shipmentTrackNumber, carriersList, carrierListLoaded, shipmentCarrier]);

  useEffect(() => {
    if (carrierListLoaded && !carrierListLoading) {
      setCarrierOptions(carriersList);
    }
  }, [carriersList, carrierListLoaded, carrierListLoading]);

  const saveSelectedCarrierOptions = useCallback(
    (selected) => {
      const selectedCarrierLabel = (
        carriersList.find((carrier) => carrier.value === selected[0]) || {}
      ).label;
      setSelectedCarrierOptions(selected);
      setInputCarrierValue(selectedCarrierLabel);
    },
    [carriersList]
  );

  const updateCarrierText = useCallback(
    (value) => {
      setInputCarrierValue(value);
      if (value === '') {
        setCarrierOptions(carriersList);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = carriersList.filter((option) =>
        option.label.match(filterRegex)
      );
      setCarrierOptions(resultOptions);
    },
    [carriersList]
  );

  const carrierTextField = (
    <Autocomplete.TextField
      onChange={updateCarrierText}
      label={t(`${initializer}.change_carrier.carrier_search_input_label`)}
      value={inputCarrierValue}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      type="search"
      placeholder={t(
        `${initializer}.change_carrier.carrier_search_input_label`
      )}
      error={inputCarrierError}
      id="input-chang-carrier-modal-carrier"
    />
  );

  const handleNewTrackingNumberTextChange = useCallback((value) => {
    setNewTrackingNumberText(value);
    setNewTrackingNumberError(null);
  }, []);
  const handleNewTrackingNumberTextBlur = useCallback(() => {
    if (!newTrackingNumberText) {
      setNewTrackingNumberError(
        t(`${initializer}.change_carrier.tracking_number_error`)
      );
    } else {
      setNewTrackingNumberError(null);
    }
  }, [newTrackingNumberText, t, initializer]);

  const updateTrackingInformation = (e) => {
    e.preventDefault();

    if (
      _.isUndefined(inputCarrierValue) ||
      _.isUndefined(selectedCarrierOptions[0]) ||
      !isUUID(selectedCarrierOptions[0])
    ) {
      setInputCarrierError(
        t(`${initializer}.change_carrier.selected_carrier_error`)
      );
      return;
    }
    if (!newTrackingNumberText && initializer === 'shipment_detail') {
      setNewTrackingNumberError(
        t(`${initializer}.change_carrier.tracking_number_error`)
      );
      return;
    }
    if (initializer === 'shipment_detail') {
      const carrierSlug = carriersList.find(
        ({ value }) => value === selectedCarrierOptions[0]
      ).slug;
      updateTrackingDetails({
        shipmentId,
        carrier_slug: carrierSlug,
        tracking_number: newTrackingNumberText,
      });
    } else {
      let updateObject = {
        new_firstmile_carrier_uuid: selectedCarrierOptions[0],
      };
      updateObject.filter = {
        shipments_uuid: shipmentIds,
      };

      updateCarrier(updateObject);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t(`${initializer}.change_carrier.modal_title`)}
      primaryAction={{
        content: t(`${initializer}.change_carrier.modal_primary_action_label`),
        onClick: updateTrackingInformation,
        disabled: carrierListLoading,
        loading: carrierListLoading || isUpdatingCarrier || isUpdatingDetails,
      }}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <div>
              <TranslateLink
                text={t(`${initializer}.change_carrier.description`)}
              />
            </div>
            <Autocomplete
              // allowMultiple
              options={carrierOptions}
              selected={selectedCarrierOptions}
              textField={carrierTextField}
              onSelect={saveSelectedCarrierOptions}
              listTitle={t(
                `${initializer}.change_carrier.carrier_search_input_label`
              )}
              emptyState={t(
                `${initializer}.change_carrier.carrier_search_default_text`
              )}
              disabled={carrierListLoading}
            />
            {initializer === 'shipment_detail' && (
              <TextField
                type="text"
                value={newTrackingNumberText}
                placeholder={t(
                  `${initializer}.change_carrier.tracking_number_placeholder`
                )}
                label={t(`${initializer}.change_carrier.tracking_number_label`)}
                onChange={handleNewTrackingNumberTextChange}
                onBlur={handleNewTrackingNumberTextBlur}
                error={newTrackingNumberError}
                disabled={carrierListLoading}
                id={`input-change-carrier-modal-tracking-number`}
              />
            )}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
}

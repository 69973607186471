import {
  Card,
  Layout,
  Page,
  SkeletonBodyText,
  Stack,
  TextField,
} from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AccountSkeleton() {
  const [t] = useTranslation();
  return (
    <Page title={t('account.title')}>
      <Layout>
        <Layout.AnnotatedSection title={t('account.card.title')}>
          <Card sectioned>
            <Stack distribution="fillEvenly">
              <Stack vertical>
                <SkeletonBodyText lines={1} />
                <TextField disabled autoComplete="off" />
              </Stack>
              <Stack vertical>
                <SkeletonBodyText lines={1} />
                <TextField disabled autoComplete="off" />
              </Stack>
            </Stack>
            <br />
            <Stack vertical>
              <SkeletonBodyText lines={1} />
              <TextField disabled autoComplete="off" />
            </Stack>
            <br />
            <Stack vertical>
              <SkeletonBodyText lines={1} />
              <TextField disabled autoComplete="off" />
            </Stack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}

export default AccountSkeleton;

import React from 'react';

import { Card, Stack, Button } from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import './style.scss';

export default function KlaviyoFlows() {
  const [t] = useTranslation();

  const text = t('settings.klaviyo.actions.section.part2.description');
  return (
    <div className="klaviyoFlows">
      <Card title={t('settings.klaviyo.actions.section.part2.title')} sectioned>
        <Stack vertical>
          <TranslateLink text={text} />
          <Stack vertical spacing="extraTight">
            <Button class="alignRight" outline disabled>
              {t('settings.klaviyo.actions.section.part2.button')}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
}

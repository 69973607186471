import React from 'react';
import Banner from 'components/Banner';
import ReviewModal from 'components/ReviewModal';
import { useGetSmallTopMessagesQuery } from 'redux/store/commonStoreApis';

export default function SmallTopMessage({ chargeDetail }) {
  const { data: smallTopMessage = [] } = useGetSmallTopMessagesQuery('', {
    pollingInterval: 3600000,
  });

  if (chargeDetail.charge_url) {
    window.top.location.replace(chargeDetail.charge_url);
  }

  return (
    <>
      {smallTopMessage?.langth && (
        <div className="Banners-Wrapper">
          {smallTopMessage.map((msg, index) => {
            return <Banner bannerObject={msg} key={index} />;
          })}
        </div>
      )}
      <ReviewModal />
    </>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  Modal,
  Banner,
  Stack,
  TextStyle,
  Form,
  FormLayout,
  TextField,
} from '@shopify/polaris';
import './style.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import stringHelper from 'helpers/string';
export default function IncreaseLimitModal({
  chargeLimit,
  getChargeLimit,
  showIncreaseLimitModal,
  updateChargeLimit,
  chargeLimitError,
  chargeLink,
  ...props
}) {
  const [t] = useTranslation();
  const [newLimit, setNewLimitText] = useState(null);
  const [newLimitError, setNewLimitTextError] = useState(null);
  const handleNewLimitChange = useCallback((value) => {
    if (value > 9999) {
      return;
    }
    setNewLimitText(value);
  }, []);
  useEffect(() => {
    if (showIncreaseLimitModal) {
      getChargeLimit();
    }
  }, [getChargeLimit, showIncreaseLimitModal]);

  useEffect(() => {
    setNewLimitText(null);
  }, [showIncreaseLimitModal]);

  useEffect(() => {
    if (!_.isNull(chargeLimit.charge_limit) && showIncreaseLimitModal) {
      let forecastedUsage = chargeLimit.charge_limit.forecasted_usage;
      let chargeLimitValue = chargeLimit.charge_limit.charge_limit;

      const value =
        Math.ceil(Math.max(forecastedUsage * 1.5, chargeLimitValue) * 0.1) * 10;
      if (value > 0) {
        setNewLimitText(`${value}`);
      }
    }
  }, [chargeLimit, showIncreaseLimitModal, handleNewLimitChange]);

  useEffect(() => {
    setNewLimitTextError(chargeLimitError);
  }, [chargeLimitError]);

  const closeButton = () => {
    if (stringHelper.isNumeric(newLimit)) {
      updateChargeLimit(newLimit);
    } else {
      setNewLimitTextError(t('billing.charge_limit_modal.charge_limit_error'));
    }
    // props.handleIncreaseLimitModalChange()
  };

  if (!_.isNull(chargeLink.charge_url)) {
    window.top.location.replace(chargeLink.charge_url);
  }
  return (
    <Modal
      open={showIncreaseLimitModal}
      onClose={() => {
        setNewLimitTextError(null);
        props.handleIncreaseLimitModalChange();
      }}
      title={t('billing.charge_limit_modal.title')}
      primaryAction={{
        content: t('billing.charge_limit_modal.apply_button_label'),
        onClick: closeButton,
        disabled: props.loading,
        loading: props.loading,
      }}
    >
      <Modal.Section>
        {!_.isNull(chargeLimit.charge_limit) && (
          <>
            {chargeLimit.charge_status && (
              <>
                <Banner
                  title=""
                  status={chargeLimit.charge_status.badge_prop.status}
                >
                  <p>{chargeLimit.charge_status.label}</p>
                </Banner>
                <br />
              </>
            )}
            <Stack>
              <div className="ChangeLimit-Description">
                {t('billing.charge_limit_modal.description')}
              </div>
            </Stack>
            <div className="BillingLimit">
              <div className="BillingRow">
                <div>
                  <TextStyle variation="strong">
                    {t('billing.charge_limit_modal.charge_limit_field')}
                  </TextStyle>
                </div>
                <div>${chargeLimit.charge_limit.charge_limit.toFixed(2)}</div>
              </div>
              <div className="BillingRow">
                <div>
                  <TextStyle variation="strong">
                    {t(
                      'billing.charge_limit_modal.forcasted_charge_limit_field'
                    )}
                  </TextStyle>
                </div>
                <div>
                  ${chargeLimit.charge_limit.forecasted_usage.toFixed(2)}
                </div>
              </div>
            </div>
            <div className="NewLimit-TextField">
              <Form onSubmit={closeButton}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label={t('billing.charge_limit_modal.new_charge_limit')}
                      type="number"
                      value={newLimit}
                      onChange={handleNewLimitChange}
                      autoFocus={true}
                      disabled={props.loading}
                      error={newLimitError}
                      id="input-new-limit"
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </div>
          </>
        )}
      </Modal.Section>
    </Modal>
  );
}

import React, { useCallback, useState } from 'react';
import {
  Modal,
  Thumbnail,
  Stack,
  Icon,
  TextStyle,
  TextField,
  Toast,
} from '@shopify/polaris';
import { StarFilledMinor, StarOutlineMinor } from '@shopify/polaris-icons';
import { RUSH_FEEDBACK_LINK } from 'config';
import { useTranslation } from 'react-i18next';
export default function ModalWithoutTitleExample({
  isShowingFeedBackModal,
  showFeedbackModal,
}) {
  const [t] = useTranslation();
  const [toasterActive, setToasterActive] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [selectedStar, setSelectedStar] = useState(0);
  const [highlightedStar, setHighlightedStar] = useState(0);
  const iconMouseEnter = useCallback((itemNumber) => {
    setHighlightedStar(itemNumber);
  }, []);
  const sectionMouseLeave = useCallback(() => {
    if (selectedStar === 0) {
      setHighlightedStar(0);
    }
  }, [selectedStar]);
  const selectStars = useCallback(
    (itemNumber) => {
      setSelectedStar(itemNumber);
      if (itemNumber === 5) {
        window.open(RUSH_FEEDBACK_LINK, '_blank');
        showFeedbackModal();
      }
    },
    [showFeedbackModal]
  );

  const closeToasterActive = useCallback(() => {
    setToasterActive(false);
  }, []);

  const ToastToasterActiveMarkup = toasterActive ? (
    <Toast
      error={true}
      content={t('feedback_modal.stars_validation_text')}
      onDismiss={closeToasterActive}
    />
  ) : null;

  const submitModal = useCallback(() => {
    if (selectedStar === 0) {
      setToasterActive(true);
    } else {
      if (selectedStar === 5) {
        window.open(RUSH_FEEDBACK_LINK, '_blank');
      }
      showFeedbackModal();
    }
  }, [selectedStar, showFeedbackModal]);

  return (
    <Modal
      titleHidden
      open={isShowingFeedBackModal}
      onClose={showFeedbackModal}
      primaryAction={{
        onAction: submitModal,
        content: t('feedback_modal.primary_button_text'),
        disabled: selectedStar === 0,
      }}
      secondaryActions={[
        {
          onAction: showFeedbackModal,
          content: t('feedback_modal.secondary_button_text'),
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical={true} spacing="tight" distribution="fill">
          <Stack.Item>
            <Stack distribution="center">
              <Stack.Item>
                <Thumbnail
                  source="https://assets.rush.app/shopifyapp/logos/rush-app-logo_98x98.png"
                  size="large"
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <Stack distribution="center">
              <Stack.Item>
                <TextStyle variation="strong">
                  {t('feedback_modal.heading')}
                </TextStyle>
              </Stack.Item>
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <Stack distribution="center">
              <Stack.Item>{t('feedback_modal.sub_heading')}</Stack.Item>
            </Stack>
          </Stack.Item>

          <Stack.Item>
            <Stack distribution="center">
              <div
                onMouseLeave={sectionMouseLeave}
                className="display-flex margin-bottom-2"
              >
                {[1, 2, 3, 4, 5].map((itemNumber) => (
                  <div
                    onClick={() => selectStars(itemNumber)}
                    onMouseOver={() => iconMouseEnter(itemNumber)}
                    className="margin-right-1 cursor-pointer"
                    key={itemNumber}
                  >
                    <Icon
                      source={
                        selectedStar > 0 && selectedStar >= itemNumber
                          ? StarFilledMinor
                          : StarOutlineMinor
                      }
                      color={
                        highlightedStar >= itemNumber ||
                        selectedStar >= itemNumber
                          ? 'warning'
                          : 'base'
                      }
                    />
                  </div>
                ))}
              </div>
            </Stack>
          </Stack.Item>
          {selectedStar > 0 && selectedStar < 5 && (
            <Stack.Item>
              <Stack wrap={false} distribution="fill">
                <Stack.Item>
                  <TextField
                    multiline={4}
                    labelHidden={true}
                    placeholder={t('feedback_modal.textarea_placeholder')}
                    onChange={setUserMessage}
                    value={userMessage}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          )}
        </Stack>
      </Modal.Section>
      {ToastToasterActiveMarkup}
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Card,
  Checkbox,
  Modal,
  Select,
  Spinner,
  Stack,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useUninstallTemplateMutation } from '../os2TrackingApi';

function RemoveModal({
  onClose,
  selectedThemeId,
  themes,
  showToast,
  isPublishedTheme,
}) {
  const [t] = useTranslation();
  const [withPages, setWithPages] = useState(false);
  const [uninstallTemplate, uninstallResponse] = useUninstallTemplateMutation();
  const { isSuccess: uninstallSuccess, isLoading: isRemoving } =
    uninstallResponse;

  useEffect(() => {
    if (uninstallSuccess) {
      showToast({
        message: t('settings.os2_tracking_page.wizard.removed_toast'),
      });
      onClose();
    }
  }, [uninstallSuccess]);

  const handleRemove = () => {
    uninstallTemplate({ id: selectedThemeId, with_pages: Number(withPages) });
  };

  const getLabel = (theme) => {
    return theme.is_published ? `${theme.name} (Published)` : theme.name;
  };

  const renderDropdown = () => {
    let themeOptions = themes.filter(({ id }) => id === selectedThemeId);
    const options = themeOptions.map((theme) => ({
      label: getLabel(theme),
      value: theme.id,
    }));
    return (
      <Stack>
        <Select
          label={t('settings.os2_tracking_page.wizard.selected_theme')}
          options={options}
          disabled
          value={selectedThemeId}
        />
      </Stack>
    );
  };

  return (
    <Modal
      title={
        isRemoving
          ? t(
              'settings.os2_tracking_page.wizard.step3.uninstallation_inprogress'
            )
          : t('settings.os2_tracking_page.wizard.step3.uninstallation_title')
      }
      open={true}
      onClose={onClose}
    >
      <Card
        sectioned
        secondaryFooterActions={
          isRemoving ? [] : [{ content: 'Close', onAction: onClose }]
        }
        primaryFooterAction={
          isRemoving
            ? null
            : {
                content: 'Remove',
                onAction: handleRemove,
                destructive: true,
                outline: true,
              }
        }
      >
        {isRemoving ? (
          <Stack distribution="center">
            <Spinner size="large" />
          </Stack>
        ) : (
          <Stack vertical>
            {renderDropdown()}
            {isPublishedTheme && (
              <Checkbox
                label={t('settings.os2_tracking_page.wizard.remove_pages')}
                checked={withPages}
                onChange={setWithPages}
              />
            )}
          </Stack>
        )}
      </Card>
    </Modal>
  );
}
export default RemoveModal;

import { connect } from 'react-redux';
import { visitorActions } from 'redux/visitor';
import SegmentAnalytics from './SegmentAnalytics';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    base: state.base,
    billing: state.billing,
    currentPlan: state.billing.currentPlan,
    visitor: state.visitor,
  };
}

const mapDispatchToProps = {
  getIpAddress: visitorActions.Creators.getIpAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentAnalytics);

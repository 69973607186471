import React from 'react';
import {
  Modal,
  TextStyle,
  Heading,
  Icon,
  Spinner,
  Scrollable,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import _ from 'lodash';
import './style.scss';
import { TickSmallMinor } from '@shopify/polaris-icons';
import moment from 'moment';
import stringHelper from 'helpers/string';
import { FREE_TRACKINGS_FORMAT } from 'Constants';

export default function PromoCode({
  showPromoCodeConfirmation,
  showPromoCodeConfirmationModal,
  currentPlan,
  handleModalChange,
}) {
  const [t] = useTranslation();
  const affiliateMessage =
    currentPlan.promo && currentPlan.promo.affiliate_store_message
      ? currentPlan.promo.affiliate_store_message
      : '';

  const redirectToDashboard = () => {
    if (_.isFunction(handleModalChange)) {
      handleModalChange();
    }
    showPromoCodeConfirmationModal();
  };

  return (
    <Modal
      open={showPromoCodeConfirmation}
      onClose={redirectToDashboard}
      title={t(`billing.promo_congrats_modal.title`)}
    >
      <Modal.Section>
        {!_.isEmpty(currentPlan) && currentPlan.promo && (
          <>
            <div className="PromoModel-Header">
              <img
                src={
                  'https://assets.rush.app/shopifyapp/illustrations-svg/promo.svg'
                }
                alt={affiliateMessage}
              />
              <div className="title">
                <Heading>{affiliateMessage}</Heading>
              </div>
            </div>
            <div className="PromoModelFeature-Div">
              <div>
                <TextStyle>
                  {t('billing.promo_congrats_modal.benefits_message')}
                </TextStyle>
              </div>
              <Scrollable shadow className="PromoModelFeature-FeatureDiv">
                {currentPlan.billing_plan.features.map((planFeature) => {
                  const feature = currentPlan.features
                    .map((feat) => {
                      if (feat.id === planFeature.feature_id) {
                        return feat;
                      } else {
                        return undefined;
                      }
                    })
                    .filter((item) => !_.isUndefined(item))[0];
                  return (
                    <div className="Row" key={feature.id}>
                      <div>{feature.name} </div>
                      <div>
                        {(planFeature.quota && planFeature.quota > 0) !== 0 &&
                          stringHelper.formatNumber(
                            planFeature.quota,
                            FREE_TRACKINGS_FORMAT
                          )}
                        {(!planFeature.quota || planFeature.quota === 0) &&
                          !_.isNull(planFeature.price) &&
                          !_.isUndefined(planFeature.price) && (
                            <>
                              {currentPlan.billing_plan.subscription_plan
                                .currency === 'USD' && '$'}
                              {parseFloat(planFeature.price).toFixed(2)}
                            </>
                          )}
                        {!planFeature.quota &&
                          !planFeature.price &&
                          planFeature.price !== 0 && (
                            <Icon source={TickSmallMinor} color="green" />
                          )}
                      </div>
                    </div>
                  );
                })}
              </Scrollable>
              <div>
                <TextStyle variation="subdued">
                  <TranslateLink
                    text={t('billing.promo_congrats_modal.note', {
                      promo_expires_at_date: moment(
                        currentPlan.promo.expire_at
                      ).format('MMM DD, YYYY'),
                    })}
                  />
                </TextStyle>
              </div>
            </div>
          </>
        )}
        {(_.isEmpty(currentPlan) || !currentPlan.promo) && (
          <div className="LoadingPage-Wrapper">
            <div className="LoadingPage-Spinner">
              <Spinner accessibilityLabel="Loading" size="large" color="teal" />
            </div>
          </div>
        )}
      </Modal.Section>
    </Modal>
  );
}

import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import Toaster from './Toaster';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingSaved: state.trackingDetail.trackingSaved,
    trackingPageToaster: state.trackingDetail.trackingPageToaster,
    upSellLoading: state.trackingDetail.upSellLoading,
  };
}

const mapDispatchToProps = {
  resetTrackingPageToaster: trackingActions.Creators.resetTrackingPageToaster,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);

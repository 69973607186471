import React, { useMemo } from 'react';
import { useGetFeaturesQuery } from 'redux/store/commonStoreApis';

const FeaturePageWrapper = (Component, featureSlug) => (props) => {
  const { data: features = [], isSuccess, isLoading } = useGetFeaturesQuery();
  const isAvailable = useMemo(() => {
    if (isSuccess && !isLoading && isSuccess) {
      const feature = features.find(({ slug }) => slug === featureSlug);
      return feature?.is_available;
    } else {
      return true;
    }
  }, [featureSlug, isSuccess, isLoading, features]);
  return <Component {...props} isFeatureAvailable={isAvailable} />;
};

export default FeaturePageWrapper;

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  ChoiceList,
  TextStyle,
  TextField,
  SettingToggle,
  Button,
  Icon,
  ButtonGroup,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import FAQModal from './FAQModal';
import stringHelper from 'helpers/string';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function FaqSection({
  trackingPageState,
  dispatchForTrackingPage,
  trackingDetailMeta,
}) {
  const OPTION_LINK_TO_ANOTHER_PAGE = 'link';
  const OPTION_CUSTOM_CONTENT = 'structure';
  const [t] = useTranslation();

  const [fAQIndex, setFAQIndex] = useState(null);
  const [showFAQModal, setShowFAQModal] = useState(false);
  const [linkToFAQPage, setLinkToFAQPage] = useState('');
  const [linkToFAQPageError, setLinkToFAQPageError] = useState(null);
  const [faqSection, setFaqSection] = useState({
    is_enabled: null,
    type: null,
    link: null,
    structure: [],
  }); // set the faq section object

  // helping section enable functions to
  const handleFaqSectionEnabledToggle = useCallback(() => {
    const tempFAQSection = {
      ...faqSection,
      is_enabled: !faqSection.is_enabled,
    };
    setFaqSection(tempFAQSection);
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.FAQ_UPDATE,
      payload: tempFAQSection,
    });
  }, [faqSection, setFaqSection, dispatchForTrackingPage]);
  const contentFaqSectionEnabledStatus = faqSection.is_enabled
    ? t('settings.tracking_page.faq.enable.button_on')
    : t('settings.tracking_page.faq.enable.button_off');

  const handleLinkToFAQPageChange = useCallback(
    (newValue) => setLinkToFAQPage(newValue),
    []
  );
  const handleLinkToFAQPageBlur = useCallback(() => {
    if (!stringHelper.validateUrl(linkToFAQPage)) {
      setLinkToFAQPageError(
        t('settings.tracking_page.faq.page_link_saved.error')
      );
    } else {
      setLinkToFAQPageError(null);
      const tempFAQSection = { ...faqSection, link: linkToFAQPage };
      setFaqSection(tempFAQSection);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.FAQ_UPDATE,
        payload: tempFAQSection,
      });
    }
  }, [linkToFAQPage, dispatchForTrackingPage, faqSection, setFaqSection, t]);

  const handleSelectedFaqSourceChange = useCallback(
    (value) => {
      const tempFAQSection = { ...faqSection, type: value[0] };
      setFaqSection(tempFAQSection);
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.FAQ_UPDATE,
        payload: tempFAQSection,
      });
    },
    [dispatchForTrackingPage, faqSection, setFaqSection]
  );

  const handleShowFAQModalChange = useCallback(() => {
    if (showFAQModal) {
      setFAQIndex(null);
    }
    setShowFAQModal(!showFAQModal);
  }, [showFAQModal]);

  const editFAQ = (index) => {
    setFAQIndex(index);
    handleShowFAQModalChange();
  };

  const deleteFAQ = (index) => {
    let tempFAQ = [
      ...(faqSection.structure || []).slice(0, index),
      ...(faqSection.structure || []).slice(index + 1),
    ] || [];
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.FAQ_UPDATE,
      payload: { structure: tempFAQ },
    });
  };

  useEffect(() => {
    if (trackingPageState.faq) {
      if (!trackingPageState.faq.type) {
        setFaqSection({
          ...trackingPageState.faq,
          type: OPTION_LINK_TO_ANOTHER_PAGE,
        });
      } else {
        setFaqSection(trackingPageState.faq);
      }
      setLinkToFAQPage(trackingPageState.faq.link);
    }
  }, [trackingPageState]);

  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.faq.title')}
      description={t('settings.tracking_page.faq.description')}
    >
      <SettingToggle
        action={{
          content: contentFaqSectionEnabledStatus,
          onAction: handleFaqSectionEnabledToggle,
        }}
        enabled={faqSection.is_enabled}
      >
        <TextStyle>{t('settings.tracking_page.faq.enable.title')}</TextStyle>
      </SettingToggle>
      {faqSection.is_enabled && (
        <Card title={t('settings.tracking_page.faq.card.title')}>
          <Card.Section>
            <ChoiceList
              title={t('settings.tracking_page.faq.card.options_title')}
              choices={trackingDetailMeta.faq.type.map((type) => ({
                label: type.label,
                value: type.slug,
              }))}
              selected={faqSection.type}
              onChange={handleSelectedFaqSourceChange}
            />
          </Card.Section>
          {faqSection.type === OPTION_LINK_TO_ANOTHER_PAGE && (
            <Card.Section>
              <TextField
                value={linkToFAQPage}
                label={t(
                  'settings.tracking_page.faq.card.option_link_to_another_page'
                )}
                onChange={handleLinkToFAQPageChange}
                onBlur={handleLinkToFAQPageBlur}
                error={linkToFAQPageError}
                disabled={!faqSection.is_enabled}
                id={`input-faq-link`}
              />
            </Card.Section>
          )}
          {faqSection.type === OPTION_CUSTOM_CONTENT && (
            <Card.Section
              title={t('settings.tracking_page.faq.card.content.title')}
              actions={[
                {
                  content: t(
                    'settings.tracking_page.faq.card.content.add_btn_label'
                  ),
                  onAction: handleShowFAQModalChange,
                },
              ]}
            >
              {(faqSection.structure || []).length > 0 && (
                <>
                  {faqSection.structure.map((faqItem, index) => (
                    <div className="SearchReplace-List" key={index}>
                      <div className="SearchReplace-List-Heading">
                        <TextStyle>{faqItem.question}</TextStyle>
                      </div>
                      <div className="SearchReplace-List-Buttons">
                        <ButtonGroup segmented>
                          <Button
                            size="slim"
                            onClick={() => editFAQ(index)}
                            icon={<Icon source={EditMinor} />}
                          />
                          <Button
                            size="slim"
                            onClick={() => deleteFAQ(index)}
                            icon={<Icon source={DeleteMinor} />}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {(faqSection.structure || []).length === 0 && (
                <div className="CustomEmptyState">
                  <TextStyle variation="subdued">
                    {t(
                      'settings.tracking_page.faq.card.content.no_row_message'
                    )}
                  </TextStyle>
                </div>
              )}
            </Card.Section>
          )}
        </Card>
      )}
      <FAQModal
        showFAQModal={showFAQModal}
        handleShowFAQModalChange={handleShowFAQModalChange}
        fAQIndex={fAQIndex}
        faqSection={faqSection}
        dispatchForTrackingPage={dispatchForTrackingPage}
      />
    </Layout.AnnotatedSection>
  );
}

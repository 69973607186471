import React, { useState, useCallback } from "react";
import {
  Page,
  Layout,
  FooterHelp,
  Card,
  TextStyle,
  Checkbox,
  Select,
  TextField,
  Link,
  Icon,
  ButtonGroup,
  Button,
  Stack,
} from "@shopify/polaris";
import SmallTopMessage from "components/SmallTopMessage";
import TranslateLink from "components/TranslateLink";
import { useTranslation } from "react-i18next";
import {
  DuplicateMinor,
  ViewMinor,
  MobileCancelMajor,
} from "@shopify/polaris-icons";

import "./style.scss";

export default function SMSNotificationRule() {
  const [t] = useTranslation();

  const [selected, setSelected] = useState("today");
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];

  const [subStatusCheckBoxes, setSubStatusCheckBoxes] = useState({});
  const [statusCheckBoxes, setStatusCheckBoxes] = useState({});
  const statusCheckBoxesList = [
    {
      key: "any",
      label: "Any",
    },
    {
      key: "delivered",
      label: "Delivered",
    },
    {
      key: "pickup_ready",
      label: "Pickup ready",
    },
  ];
  const subStatusCheckBoxesList = [
    {
      key: "any",
      label: "Any",
    },
    {
      key: "at_front_door",
      label: "At Front Door",
    },
    {
      key: "At Mailbox",
      label: "atmailBox",
    },
  ];
  const handleStatusCheckBoxes = (key, value) => {
    let tempStatusCheckBoxes = { ...statusCheckBoxes };
    tempStatusCheckBoxes[key] = value;
    setStatusCheckBoxes(tempStatusCheckBoxes);
  };
  const handleSubStatusCheckBoxes = (key, value) => {
    let tempSubStatusCheckBoxes = { ...subStatusCheckBoxes };
    tempSubStatusCheckBoxes[key] = value;
    setSubStatusCheckBoxes(tempSubStatusCheckBoxes);
  };

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[
          { content: "SMS Notifications", url: "/settings/smsNotification" },
        ]}
        title="SMS Rule"
        secondaryActions={[
          {
            content: "Duplicate",
            icon: () => {
              return <Icon source={DuplicateMinor} color="inkLighter" />;
            },
          },
          {
            content: "Send test",
            icon: () => {
              return <Icon source={ViewMinor} color="inkLighter" />;
            },
          },
          {
            content: "Delete",
            icon: () => {
              return <Icon source={MobileCancelMajor} color="inkLighter" />;
            },
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card title="Triggers" sectioned>
              <div className="EmailNotification-Form">
                <div className="EmailNotification-FormColumn">
                  <div>
                    <Select
                      label="When"
                      options={options}
                      onChange={handleSelectChange}
                      value={selected}
                    />
                  </div>
                </div>
                <div className="EmailNotification-FormColumn">
                  <TextField
                    type="text"
                    label="than (duration)"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="EmailNotification-Form Space">
                <div className="EmailNotification-FormColumn">
                  <TextStyle>
                    And Shipment <strong>Status</strong> is{" "}
                  </TextStyle>
                  <div>
                    {statusCheckBoxesList.map((statusBox) => (
                      <div key={statusBox.key}>
                        <Checkbox
                          label={statusBox.label}
                          checked={statusCheckBoxes[statusBox.key]}
                          onChange={(e) => {
                            handleStatusCheckBoxes(statusBox.key, e);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="EmailNotification-FormColumn">
                  <TextStyle>
                    And Shipment <strong>Sub Status</strong> is{" "}
                  </TextStyle>
                  <div>
                    {subStatusCheckBoxesList.map((subStatus) => (
                      <div key={subStatus.key}>
                        <Checkbox
                          label={subStatus.label}
                          checked={subStatusCheckBoxes[subStatus.key]}
                          onChange={(e) => {
                            handleSubStatusCheckBoxes(subStatus.key, e);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="EmailNotification-Form">
                <div className="EmailNotification-FormColumn">
                  <div>
                    <Select
                      label="Send SMS"
                      options={options}
                      onChange={handleSelectChange}
                      value={selected}
                    />
                  </div>
                </div>
                <div className="EmailNotification-FormColumn">
                  <TextField type="text" label="Duration" onChange={() => {}} />
                </div>
              </div>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Summary">
              <Card.Section>
                <TextStyle variation="strong">TRIGGER</TextStyle>
                <br />
                <TextStyle>
                  When <strong>New status</strong> is <strong>Delivered</strong>
                </TextStyle>
              </Card.Section>
              <Card.Section>
                <Stack vertical spacing="tight">
                  <TextStyle variation="strong">STATUS</TextStyle>
                  <ButtonGroup segmented fullWidth connectedTop>
                    <Button primary>Active</Button>
                    <Button>Inactive</Button>
                  </ButtonGroup>
                </Stack>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <br />
        <Layout>
          <Layout.Section>
            <Card>
              <Card.Header
                actions={[
                  {
                    content: "Reset to another template",
                  },
                ]}
                title="SMS Content"
              />
              <Card.Section>
                <TextField
                  type="text"
                  label="Message"
                  onChange={() => {}}
                  multiline
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Short Codes" sectioned>
              <TextStyle>
                You can use variables in message.{" "}
                <Link external>See in detail Explanation</Link>
              </TextStyle>
              <br />
              <br />
              <Link external>{`{short_name}`}</Link>
              <br />
              <Link external>{`{short_url}`}</Link>
              <br />
              <Link external>{`{customer_firstname}`}</Link>
              <br />
              <Link external>{`{customer_lastname}`}</Link>
              <br />
              <Link external>{`{customer_email}`}</Link>
              <br />
              <Link external>{`{customer_phone}`}</Link>
              <br />
              <Link external>{`{customer_country}`}</Link>
              <br />
              <Link external>{`{customer_locate}`}</Link>
              <br />
              <Link external>{`{customer_address}`}</Link>
              <br />
            </Card>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t("footer_help.dashboard")} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}

import React, { useMemo } from 'react';
import { Stack, Heading, TextStyle, Button, Tooltip } from '@shopify/polaris';
import './style.css';
import { useTranslation } from 'react-i18next';
import SubscriptionBoxSkeleton from './SubscriptionBoxSkeleton';
import { billingActions } from 'pages/Billing1/billingSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdatePlanModal from 'pages/Billing1/components/UpdatePlanModal';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';

export default function SubscriptionBox() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const storeUUID = useSelector((state) => state.shop?.storeUUID);

  const {
    isFetching: isLoading,
    data: { billing_plan: billingPlan = {} } = {},
  } = useGetCurrentBillingPlanQuery(undefined, { skip: !storeUUID });

  const planInfo = useMemo(() => {
    if (!isLoading && billingPlan.features) {
      const feature2 =
        billingPlan?.features?.find((feature) => feature.feature_id === 2) ||
        {};
      const used = feature2.quota - feature2.remaining_quota;
      return {
        feature2,
        used,
        isEightyPercentUsed: used / feature2.quota >= 0.8,
      };
    } else {
      return {};
    }
  }, [billingPlan, isLoading]);

  return isLoading ? (
    <SubscriptionBoxSkeleton />
  ) : (
    <React.Fragment>
      <div className={'left-bar-header'}>
        <Stack distribution={'equalSpacing'} alignment={'center'}>
          <Stack vertical={true} spacing={'extraTight'}>
            <Heading>{billingPlan.subscription_plan?.name}</Heading>
            <Tooltip
              content={t('dashboard.analytics_sheet.shipment_quota_title')}
            >
              <TextStyle
                variation={planInfo.isEightyPercentUsed ? 'negative' : ''}
              >
                <div className={'usage'}>{`${Number(
                  planInfo.used
                )?.toLocaleString('en-US')} / ${Number(
                  planInfo.feature2?.quota
                )?.toLocaleString('en-US')}`}</div>
              </TextStyle>
            </Tooltip>
          </Stack>
          <Button
            onClick={() => {
              dispatch(billingActions.toggleUpdateModal());
            }}
            primary
          >
            {t('dashboard.analytics_sheet.shipment_quota_upgrade_btn')}
          </Button>
        </Stack>
      </div>
      <UpdatePlanModal />
    </React.Fragment>
  );
}

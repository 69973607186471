import React, { useState, useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  TextStyle,
  SkeletonBodyText,
  FooterHelp,
} from '@shopify/polaris';
import SmallTopMessage from 'components/SmallTopMessage';
import moment from 'moment';
import TranslateLink from 'components/TranslateLink';
import {
  DB_DATE_FORMAT_STRING,
  DATE_RANGE_VALUE_LAST_30_DAYS,
} from 'Constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRange from 'components/DateRange';
export default function Reports({
  getPerformanceReport,
  performanceReport,
  reportsLoading,
  performanceReportNote,
  performanceReportTitle,
  performanceReportSubTitle,
  performanceReportDateRange,
}) {
  const [t] = useTranslation();

  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(29, 'days')._d,
    end: moment()._d,
  });

  useEffect(() => {
    getPerformanceReport({
      from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
      to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      include_tracking_page: 1,
      include_emails: 1,
    });
  }, [getPerformanceReport, selectedDates]);

  useEffect(() => {
    if (_.isNull(selectedDates.start) && performanceReportDateRange) {
      setSelectedDates({
        start: moment(performanceReportDateRange.from)._d,
        end: moment(performanceReportDateRange.to)._d,
      });
    }
  }, [performanceReportDateRange, selectedDates]);

  return (
    <>
      <SmallTopMessage />
      <Page
        title={performanceReportTitle ? performanceReportTitle : ''}
        subtitle={performanceReportSubTitle ? performanceReportSubTitle : ''}
        fullWidth={false}
        narrowWidth={false}
      >
        <Layout>
          <Layout.Section>
            <div className="TimeSelection">
              <div>
                {performanceReportDateRange && (
                  <DateRange
                    fnSelectDates={setSelectedDates}
                    propsSelectedDates={selectedDates}
                    disableDates={{
                      start: performanceReportDateRange.from,
                      end: performanceReportDateRange.to,
                    }}
                    selectedDropDownItem={DATE_RANGE_VALUE_LAST_30_DAYS}
                    markDisable={reportsLoading}
                  />
                )}
              </div>
            </div>
          </Layout.Section>
          {reportsLoading && (
            <Layout.Section>
              <div className="HalfCard">
                <Card sectioned>
                  <div className="SettingCard ">
                    <div className="SettingCard-Heading">
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div className="SettingCard-Status" />
                  </div>
                  <div>
                    <br />
                    <TextStyle>
                      <SkeletonBodyText />
                    </TextStyle>
                    <br />
                  </div>
                  <div>
                    <br />
                    <TextStyle>
                      <SkeletonBodyText />
                    </TextStyle>
                    <br />
                  </div>
                  <div>
                    <br />
                    <TextStyle>
                      <SkeletonBodyText />
                    </TextStyle>
                    <br />
                  </div>
                </Card>
              </div>
            </Layout.Section>
          )}
          {!reportsLoading && performance && (
            <>
              {performanceReport && performanceReport[0] && (
                <Layout.Section>
                  <div className="LayoutWithTwoColumns FullWidthCard">
                    {performanceReport[0].metrics &&
                      performanceReport[0].metrics.map((metric, index) => (
                        <Card sectioned title={metric.title} key={index}>
                          <div className="LargeCardText">{metric.value}</div>
                        </Card>
                      ))}
                  </div>
                </Layout.Section>
              )}
              <Layout.Section>
                <div className="LayoutWithTwoColumns">
                  {[1, 2].map((cardIndex) => (
                    <React.Fragment key={cardIndex}>
                      {performanceReport && performanceReport[cardIndex] && (
                        <div className="HalfCard">
                          <Card>
                            <Card.Header
                              title={performanceReport[cardIndex].title}
                            />
                            <div className="CardWithDetails">
                              {performanceReport[cardIndex].description && (
                                <Card.Section>
                                  <TranslateLink
                                    text={
                                      performanceReport[cardIndex].description
                                    }
                                  />
                                </Card.Section>
                              )}
                              {performanceReport[cardIndex].sections &&
                                performanceReport[cardIndex].sections.map(
                                  (section, sectionIndex) => (
                                    <Card.Section
                                      title={section.title}
                                      key={sectionIndex}
                                    >
                                      <p>
                                        <TranslateLink
                                          text={section.description}
                                        />
                                      </p>
                                      {section.metrics &&
                                        section.metrics.map(
                                          (metric, metricIndex) => (
                                            <div
                                              className="Row BorderedRow"
                                              key={metricIndex}
                                            >
                                              <div>
                                                <TextStyle>
                                                  <TranslateLink
                                                    text={metric.title}
                                                  />
                                                </TextStyle>
                                              </div>
                                              <div>
                                                <TextStyle>
                                                  {metric.value}
                                                </TextStyle>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </Card.Section>
                                  )
                                )}
                            </div>
                          </Card>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Layout.Section>
            </>
          )}
          <Layout.Section>
            <TextStyle variation="subdued">
              <TranslateLink text={performanceReportNote} />
            </TextStyle>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('report.footer_help')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}

import React from 'react';

import { Card, Stack, Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import _ from 'lodash';

export default function OmnisendActions({
  apiKey,
  setApiKeyError,
  omnisendConfig,
  selectedTrigger,
  selectedLanguage,
  testOmnisend,
}) {
  const [t] = useTranslation();

  const sendTestOmnisendNotification = () => {
    if (!apiKey || isEmpty(trim(apiKey))) {
      setApiKeyError(t('omnisend.modal.public_api_key_error_text'));
      return;
    }
    setApiKeyError(null);
    const tempTriggers = omnisendConfig.triggers.map((trigger) => {
      if (selectedTrigger.includes(trigger.trigger_slug)) {
        return { ...trigger, is_active: true };
      } else {
        return { ...trigger, is_active: false };
      }
    });
    testOmnisend({
      api_key: _.trim(apiKey),
      triggers: tempTriggers,
      language: selectedLanguage,
    });
  };
  const text = t('settings.omnisend.actions.section.description');

  return (
    <Card
      className="omnisendAction"
      title={t('settings.omnisend.actions.section.title')}
      sectioned
    >
      <Stack vertical>
        <TranslateLink text={text} />
        <Stack vertical spacing="extraTight">
          <Button
            onClick={sendTestOmnisendNotification}
            class="alignRight"
            outline
          >
            {t('settings.omnisend.actions.section.button')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}

import React, { useCallback, useState } from 'react';
import { Card } from '@shopify/polaris';
import moment from 'moment';
import DateRangeNew from 'components/DateRangeNew';
import {
  DATE_RANGE_VALUE_LAST_30_DAYS,
  DATE_RANGE_VALUE_CUSTOM,
  DB_DATE_FORMAT_STRING,
} from 'Constants';

import RevenueStatistics from './RevenueStatistics';
import DeliveryTimes from './DeliveryTimes';
import ShipmentPerStatus from './ShipmentPerStatus';
import OrdersPerDestinations from './OrdersPerDestinations';

export default function RightPanel() {
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(29, 'days')._d,
    end: moment()._d,
    selectedOption: DATE_RANGE_VALUE_LAST_30_DAYS,
  });

  const handleSelectedDate = useCallback((dateRange) => {
    const { start, end, selectedOption = DATE_RANGE_VALUE_CUSTOM } = dateRange;
    setSelectedDates({
      start: moment(start).format(DB_DATE_FORMAT_STRING),
      end: moment(end).format(DB_DATE_FORMAT_STRING),
      selectedOption,
    });
  }, []);

  return (
    <Card>
      <Card.Section>
        <DateRangeNew
          onChange={handleSelectedDate}
          selectedRange={{
            start: moment(selectedDates.start)._d,
            end: moment(selectedDates.end)._d,
          }}
          fullWidth={false}
          selectedOption={selectedDates.selectedOption}
          disableDates={{ end: moment()._d }}
        />
      </Card.Section>
      <RevenueStatistics selectedDates={selectedDates} />
      <DeliveryTimes selectedDates={selectedDates} />
      <ShipmentPerStatus selectedDates={selectedDates} />
      <OrdersPerDestinations selectedDates={selectedDates} />
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Stack,
  Card,
  DisplayText,
  TextStyle,
  SkeletonBodyText,
} from '@shopify/polaris';
import {
  DASHBOARD_ANALYTICS_TYPE_REVENUE_STATISTICS,
  DB_DATE_FORMAT_STRING,
} from 'Constants';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ExternalLink from 'components/ExternalLink';
import { useGetReportsMutation } from 'pages/Dashboard/DashboardApi';
import { useSelector } from 'react-redux';
import { useGetOrganizationsQuery } from 'redux/store/commonBaseApis';

export default function RevenueStatistics({ selectedDates = {} }) {
  const shop = useSelector((state) => state.shop);
  const { data: organizations = [] } = useGetOrganizationsQuery();
  const [getReport, reportsResponse] = useGetReportsMutation();
  const {
    data: revenueStats = {},
    isLoading,
    isSuccess,
  } = reportsResponse || {};

  const [totalOrdersValue, setTotalOrdersValue] = useState(null);
  const [averageOrdersValue, setAverageOrdersValue] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    getReport({
      type: DASHBOARD_ANALYTICS_TYPE_REVENUE_STATISTICS,
      kind: 'revenue',
      filter: {
        date_from: moment(selectedDates.start).format(DB_DATE_FORMAT_STRING),
        date_to: moment(selectedDates.end).format(DB_DATE_FORMAT_STRING),
      },
    });
  }, [getReport, selectedDates, i18next.language]);

  useEffect(() => {
    if (revenueStats?.table?.columns?.length) {
      revenueStats.table.columns.forEach((item, index) => {
        switch (item.id) {
          case 'total_orders_value':
            setTotalOrdersValue(revenueStats.table.data[index]);
            break;
          case 'average_orders_value':
            setAverageOrdersValue({
              label: item.label,
              value: revenueStats.table.data[index],
            });
            break;
          default:
          // code block
        }
      });
    }
  }, [revenueStats]);

  let store;
  organizations &&
    organizations.find((org) => {
      store = org.stores.find((store) => store.uuid === shop.storeUUID);
      return !!store;
    });

  return (
    <Card.Section
      title={t('dashboard.analytics_sheet.revenue_statistics_title')}
    >
      {isLoading || !isSuccess ? (
        <SkeletonBodyText />
      ) : (
        revenueStats && (
          <Stack vertical={true} spacing="extraTight">
            <Stack.Item>
              <Stack alignment="baseline">
                {totalOrdersValue && (
                  <Stack.Item fill>
                    <DisplayText size="large">
                      {store?.myshopify_domain ? (
                        <ExternalLink
                          url={`https://${store?.myshopify_domain}/admin/orders?tag=RUSH_ORDER`}
                        >
                          {totalOrdersValue}
                        </ExternalLink>
                      ) : (
                        <TextStyle variation={'subdued'}>
                          {totalOrdersValue}
                        </TextStyle>
                      )}
                    </DisplayText>
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            {averageOrdersValue && (
              <Stack.Item>
                <Stack>
                  <Stack.Item fill>{averageOrdersValue.label}</Stack.Item>
                  <Stack.Item>{averageOrdersValue.value}</Stack.Item>
                </Stack>
              </Stack.Item>
            )}
          </Stack>
        )
      )}
    </Card.Section>
  );
}

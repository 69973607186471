import { connect } from 'react-redux';
import PrivateRoutes from './PrivateRoutes';

function mapStateToProps(state) {
  return {
    shop: state.shop,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);

import React, { useState, useCallback, useEffect } from 'react';
import {
  FormLayout,
  TextField,
  Form,
  Stack,
  ButtonGroup,
  Button,
  Modal,
} from '@shopify/polaris';
import { ClientStorage } from 'ClientStorage';
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
  LOCAL_STORAGE,
  PROMO_TYPE_PROMO,
  PROMO_TYPE_SUBSCRIPTION,
} from 'Constants';
import i18next from 'i18next';
function PromoCode({
  showPromoCode,
  showPromoCodeModal,
  textPrefix,
  promoError,
  handleModalChange,
  promoCodeObject,
  promoLoading,
  createBillingPlan,
  postPromoCharges,
  chargeDetail,
  resetSubscriptionPlan,
  getCurrentBillingPlan,
  showPromoCodeConfirmationModal,
  billingPlan,
  disableBillingPlan,
  ...props
}) {
  const [t] = useTranslation();
  const [promoCodeText, setPromoCodeText] = useState('');
  const [promoCodeError, setPromoCodeError] = useState(null);
  const handleChange = useCallback((value) => {
    setPromoCodeError(null);
    setPromoCodeText(value);
  }, []);

  // this sets promo code error.
  useEffect(() => {
    if (promoError) {
      if (promoError.display && promoError.display.title) {
        setPromoCodeError(promoError.display.title);
      } else {
        setPromoCodeError(promoError.message);
      }
    }
  }, [promoError]);

  // this works when promo code is applicable.
  useEffect(() => {
    if (
      !_.isEmpty(promoCodeObject) &&
      _.isNull(billingPlan) &&
      promoCodeObject.promo_type === PROMO_TYPE_PROMO &&
      promoCodeText
    ) {
      createBillingPlan({ promo_code: promoCodeText });
    }
    if (
      !_.isEmpty(promoCodeObject) &&
      promoCodeObject.promo_type === PROMO_TYPE_SUBSCRIPTION &&
      _.isNull(billingPlan)
    ) {
      postPromoCharges(promoCodeText);
    }
  }, [
    promoCodeObject,
    createBillingPlan,
    promoCodeText,
    postPromoCharges,
    billingPlan,
  ]);

  useEffect(() => {
    if (
      !_.isEmpty(promoCodeObject) &&
      promoCodeText &&
      window.analytics &&
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true
    ) {
      window.analytics.track('Shopify App Apply Promo', {
        promo: promoCodeText,
      });
    }
  }, [promoCodeObject, promoCodeText]);

  // this one works for when promo code has a subscription plan but the price is 0 and cap amount is also 0 so charge url is not generated.
  useEffect(() => {
    if (
      _.isNull(chargeDetail.charge_url) &&
      !_.isNull(chargeDetail.type) &&
      showPromoCode
    ) {
      resetSubscriptionPlan();
      getCurrentBillingPlan({});
      showPromoCodeConfirmationModal();
      showPromoCodeModal();
    }
  }, [
    chargeDetail,
    getCurrentBillingPlan,
    showPromoCode,
    resetSubscriptionPlan,
    showPromoCodeConfirmationModal,
    showPromoCodeModal,
    i18next.language,
  ]);

  // on showing the promo code reset the modal.
  useEffect(() => {
    setPromoCodeText('');
    setPromoCodeError(null);
  }, [showPromoCode]);

  // apply promo code.
  const handlePromoService = (e) => {
    e.preventDefault();
    if (!promoCodeText.trim()) {
      setPromoCodeError(t(`${textPrefix}.error_message`));
    } else {
      props.applyPromoCode(promoCodeText.trim());
    }
  };

  // this basically closes the promo code modal.
  // handleModalChange is used on onboarding as we also need to redirect to dashboard.
  const redirectToDashboard = () => {
    if (_.isFunction(handleModalChange)) {
      handleModalChange();
    }
    showPromoCodeModal();
  };

  // if the promo code type is promo get the current plan and show confirmation modal.
  useEffect(() => {
    if (billingPlan && showPromoCode) {
      getCurrentBillingPlan({});
      showPromoCodeConfirmationModal();
      disableBillingPlan();
    }
  }, [
    billingPlan,
    disableBillingPlan,
    handleModalChange,
    showPromoCodeConfirmationModal,
    getCurrentBillingPlan,
    showPromoCode,
  ]);

  return (
    <Modal
      open={showPromoCode}
      onClose={redirectToDashboard}
      title={t(`${textPrefix}.title`)}
    >
      <Modal.Section>
        <Stack spacing="loose" vertical>
          <p>{t(`${textPrefix}.message`)}</p>

          <Form onSubmit={handlePromoService}>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  placeholder={t(`${textPrefix}.input.default`)}
                  value={promoCodeText}
                  onChange={handleChange}
                  error={promoCodeError}
                  disabled={promoLoading}
                  id="input-promo-code-modal"
                />
              </FormLayout.Group>
            </FormLayout>
          </Form>

          <Stack distribution="leading">
            <ButtonGroup>
              <Button
                onClick={handlePromoService}
                primary
                loading={promoLoading}
              >
                {t(`${textPrefix}.redeem_button`)}
              </Button>
              <Button
                plain
                onClick={redirectToDashboard}
                loading={promoLoading}
              >
                {t(`${textPrefix}.skip_link`)}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}

export default withRouter(PromoCode);

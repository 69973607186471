import React from 'react';
import {
  SkeletonPage,
  Layout,
  Card,
  SkeletonBodyText,
  Heading,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import './style.scss';

function SkeletonDashboardPage() {
  const [t] = useTranslation();
  return (
    <SkeletonPage titleHidden fullWidth={false} narrowWidth={false}>
      <Layout>
        <Layout.Section>
          <Layout.Section>
            <Heading>{t('dashboard.need_action.card.title')}</Heading>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
              <Card.Section>
                <SkeletonBodyText lines={2} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout.Section>
        <Layout.Section secondary>
          <Layout.Section>
            <Heading>{t('dashboard.analytics_sheet.title')}</Heading>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Section>
                <SkeletonDisplayText />
              </Card.Section>

              <Card.Section
                title={t('dashboard.analytics_sheet.revenue_statistics_title')}
              >
                <SkeletonBodyText />
              </Card.Section>
              <Card.Section
                title={t('dashboard.analytics_sheet.delivery_times_title')}
              >
                <SkeletonBodyText />
              </Card.Section>
              <Card.Section
                title={t('dashboard.analytics_sheet.shipment_status_title')}
              >
                <SkeletonBodyText />
              </Card.Section>
              <Card.Section
                title={t('dashboard.analytics_sheet.orders_destination_title')}
              >
                <SkeletonBodyText />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
export default SkeletonDashboardPage;

import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import PageURL from './PageURL';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    trackingDetailMeta: state.trackingDetail.meta,
    pageLink: state.trackingDetail.page_link,
  };
}

const mapDispatchToProps = {
  saveTrackingPageLink: trackingActions.Creators.saveTrackingPageLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageURL);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CalloutCard } from '@shopify/polaris';
import ApproveImg from '../../images/approve.svg';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import LoadingPage from 'components/LoadingPage';
import { STORE_URLS } from 'config/urls';
import { useLazyConnectStoreQuery } from 'pages/Permissions/permissionsApis';
import { baseActions } from 'redux/store/baseSlice';

function Approve() {
  const [t] = useTranslation();
  const shop = useSelector((state) => state.shop);
  const [visible, setVisible] = useState(false);
  const [connectStore, { data = {}, isLoading, isSuccess }] =
    useLazyConnectStoreQuery();

  const dispatch = useDispatch();
  const history = useHistory();
  const handleSkip = () => {
    dispatch(baseActions.setSkipPermissions(true));
    try {
      history.push(`/s/${shop.storeUUID}${STORE_URLS.DASHBOARD}`);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1200);
  });

  const handleApprove = () => {
    connectStore({ uuid: shop.storeUUID, action: 'approve' });
  };

  useEffect(() => {
    if (isSuccess) {
      window.open(data.redirect_uri, '_self');
    }
  }, [data, isSuccess]);

  return (
    <>
      {!visible ? <LoadingPage /> : ''}

      <div className={`permissions-card ${!visible ? 'hidden' : ''}`}>
        <CalloutCard
          title={t('approve.title')}
          illustration={ApproveImg}
          primaryAction={{
            content: t('approve.primary.action'),
            onAction: handleApprove,
            loading: isLoading,
          }}
          secondaryAction={{
            content: t('approve.secondary.action'),
            onAction: handleSkip,
          }}
        >
          <p>{t('approve.description')}</p>
        </CalloutCard>
      </div>
    </>
  );
}

export default Approve;

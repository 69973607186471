import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, TextStyle, Toast } from '@shopify/polaris';
import { UserPage } from '@rush-app-admin/storybooks';
import { isEmailValid } from 'Utils/utils';
import { useHistory } from 'react-router-dom';
import { VISITOR_URLS } from 'config/urls';
import { API_URL } from 'config';

export default function Login({
  getVisitorLanguages,
  changeVisitorLanguage,
  visitorLanguages,
  selectedVisitorLanguage,
  selectedVisitorLanguageCode,
  login,
  visitorToast,
  resetVisitorToast,
  loading,
  setStoreUUID,
  registeredUser,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  const [email, setEmail] = useState(
    registeredUser?.verified ? registeredUser.email : ''
  );
  const [password, setPassword] = useState(
    registeredUser?.verified ? registeredUser.password : ''
  );
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  useEffect(() => {
    if (!visitorLanguages) {
      getVisitorLanguages();
    }
    registeredUser?.verified && handleSubmit();
  }, []);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handlePasswordChange = useCallback((value) => {
    setPassword(value);
  }, []);

  const handleSubmit = () => {
    setShowEmailError(false);
    setShowPasswordError(false);

    let hasErrors = false;

    if (!isEmailValid(email)) {
      setShowEmailError(true);
      hasErrors = true;
    }

    if (password.length < 6) {
      setShowPasswordError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setStoreUUID(null);
    login({ email, password });
  };

  const handleSignupClick = () => {
    history.push(`${VISITOR_URLS.REGISTER}`);
  };

  const handleForgotPasswordClick = () => {
    resetVisitorToast();
    history.push(`${VISITOR_URLS.RECOVER}`);
  };

  const handleGoogleClick = () => {
    window.open(
      `${API_URL}/social/google/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };
  const handleFacebookClick = () => {
    window.open(
      `${API_URL}/social/facebook/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };
  const handleLinkedIdClick = () => {
    window.open(
      `${API_URL}/social/linkedin/redirect?language=${selectedVisitorLanguageCode}&force_language_parameter=1`,
      '_self'
    );
  };

  const ToastMarkup =
    visitorToast.error || visitorToast.message ? (
      <Toast
        content={visitorToast.message || visitorToast.error}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : visitorToast.message_id ? (
      <Toast
        content={t(visitorToast.message_id)}
        onDismiss={resetVisitorToast}
        error={!!visitorToast.error}
      />
    ) : null;

  return (
    <div className={'visitorPagesContainer'}>
      <div style={{ width: '100%' }}>
        <UserPage
          loading={loading}
          languages={
            visitorLanguages?.map((lang) => ({
              uuid: lang.uuid,
              label: lang.label_local,
              flagUrl: lang.svg_flag_url,
            })) || null
          }
          selectedLanguageUuid={selectedVisitorLanguage}
          onChangeLanguage={changeVisitorLanguage}
          title={t('visitor-app:signin_screen.title')}
          inputFields={[
            {
              placeholder: t('visitor-app:signin_screen.email_placeholder'),
              error: showEmailError
                ? t('visitor-app:errors.invalid_email')
                : null,
              value: email,
              onChange: handleEmailChange,
              type: 'email',
            },
            {
              placeholder: t('visitor-app:signin_screen.password_placeholder'),
              error: showPasswordError
                ? t('visitor-app:errors.password_minimum_length')
                : null,
              value: password,
              onChange: handlePasswordChange,
              type: 'password',
            },
          ]}
          forgotElement={
            <Link onClick={handleForgotPasswordClick}>
              {t('visitor-app:signin_screen.forgot_password_link')}
            </Link>
          }
          button={t('visitor-app:signin_screen.sign_in_btn')}
          footer1={
            <TextStyle>
              {t('visitor-app:signin_screen.footer_message')}{' '}
              <Link onClick={handleSignupClick}>
                {t('visitor-app:signin_screen.footer_action_message_link')}
              </Link>
            </TextStyle>
          }
          onSubmit={handleSubmit}
          brandButtons={{
            googleLabel: t('visitor-app:signin_screen.sign_in_with_google'),
            onGoogleClick: handleGoogleClick,
            onFacebookClick: handleFacebookClick,
            onLinkedInClick: handleLinkedIdClick,
          }}
        />
      </div>
      {ToastMarkup}
    </div>
  );
}

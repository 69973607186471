import { connect } from 'react-redux';
import Login from './Login';
import { visitorActions } from 'redux/visitor';
import { shopActions } from 'redux/store/shopSlice';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
    selectedVisitorLanguageCode: state.visitor.selectedLanguageCode,
    loading: state.visitor.loading,
    visitorToast: state.visitor.toast,
    registeredUser: state.visitor.registeredUser,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
  login: visitorActions.Creators.login,
  resetVisitorToast: visitorActions.Creators.resetVisitorToast,
  setStoreUUID: shopActions.setStoreUUID,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

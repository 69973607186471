import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import PromoConfirmationModal from './PromoConfirmationModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    promoCodeObject: state.billing.promoCodeConfirmation,
    promoError: state.billing.promoError,
    showPromoCode: state.billing.showPromoCode,
    promoLoading: state.billing.promoLoading,
    chargeDetail: state.billing.chargeDetail,
    billingPlan: state.billing.billingPlan,
    showPromoCodeConfirmation: state.billing.showPromoCodeConfirmation,
    currentPlan: state.billing.currentPlan,
  };
}

const mapDispatchToProps = {
  showPromoCodeConfirmationModal:
    billingActions.Creators.showPromoCodeConfirmationModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoConfirmationModal);

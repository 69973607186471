import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Stack, TextStyle } from '@shopify/polaris';

function SpinnerWithMessage({
  spinMessage,
  variation = 'positive',
  spinnerSize = 'small',
}) {
  return (
    <TextStyle variation={variation}>
      <Stack>
        <Spinner accessibilityLabel={spinMessage} size={spinnerSize}></Spinner>
        <TextStyle variation={variation}>{spinMessage}</TextStyle>;
      </Stack>
    </TextStyle>
  );
}
SpinnerWithMessage.propTypes = {
  variation:
    'positive' | 'negative' | 'warning' | 'strong' | 'subdued' | 'code',
  spinMessage: PropTypes.string.isRequired,
  spinnerSize: PropTypes.string,
};
export default SpinnerWithMessage;

import React, { Fragment, useState } from 'react';
import {
  Card,
  Stack,
  Icon,
  Heading,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import { ThemesMajor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import TemplateModal from '../../TemplateModal';
import './styles.scss';
const TrackingPageCard = ({ dispatchForTrackingPage }) => {
  const [t] = useTranslation();

  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const toggleTemplateModal = () => {
    setTemplateModalOpen(!templateModalOpen);
  };
  return (
    <Fragment>
      <Card
        sectioned
        secondaryFooterActions={[
          {
            content: t(
              'settings.tracking_page.tracking_pages.card_themes.btn_explore_themes'
            ),
            onAction: toggleTemplateModal,
          },
        ]}
      >
        <Stack wrap={false} alignment="leading">
          <div className="theme_icon_container">
            <Icon source={ThemesMajor} />
          </div>
          <Stack.Item fill>
            <TextContainer spacing="tight">
              <Heading>
                {t('settings.tracking_page.tracking_pages.card_themes.title')}
              </Heading>
              <TextStyle>
                {t(
                  'settings.tracking_page.tracking_pages.card_themes.description'
                )}
              </TextStyle>
            </TextContainer>
          </Stack.Item>
        </Stack>
      </Card>
      <TemplateModal
        templateModalOpen={templateModalOpen}
        handleTemplateModalOpenChange={toggleTemplateModal}
        dispatchForTrackingPage={dispatchForTrackingPage}
      />
    </Fragment>
  );
};

export default TrackingPageCard;

import React, { useEffect, useMemo } from 'react';
import SmallTopMessage from 'components/SmallTopMessage';
import {
  Layout,
  Card,
  Page,
  FooterHelp,
  Badge,
  Stack,
  TextStyle,
  Link,
} from '@shopify/polaris';
import stringHelper from 'helpers/string';
import { useTranslation } from 'react-i18next';
import Subtitle from 'components/Subtitle';
import TranslateLink from 'components/TranslateLink';
import { useGetChargeLimitQuery } from '../billingApis';
import SkeletonBilling, { LoadingCard } from './SkeletonBilling';
import MerchentSuccessManagerCard from './MerchentSuccessManagerCard';
import BookTeamTimeCard from './BookTeamTimeCard';
import moment from 'moment';
import { FEATURE_SUBSCRIPTION_PRICE } from 'Constants';
import { FEATURE_FREE_TRACKINGS } from 'Constants';
import { FEATURE_SHIPMENTS } from 'Constants';
import { useDispatch } from 'react-redux';
import { billingActions } from '../billingSlice';
import UpdatePlanModal from './UpdatePlanModal';
import RedeemPromoModal from './RedeemPromoModal';
import IncreaseLimitModal from './IncreaseLimitModal';
import { useHistory, useLocation } from 'react-router-dom';
import { REDIRECT_ACTION_BILLING } from 'Constants';
import { baseActions } from 'redux/store/baseSlice';
import { useGetCurrentBillingPlanQuery } from 'redux/store/commonStoreApis';
import { transformToNumStr } from './utils';

function Billing() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { isLoading: isPlanLoading, data: currentPlan = {} } =
    useGetCurrentBillingPlanQuery();

  const isMonthlyPlan = useMemo(() => {
    return currentPlan?.billing_plan?.subscription_plan
      ?.is_charge_limit_supported;
  }, [currentPlan]);

  const { isLoading: isChargeLoading, data: chargeLimit = {} } =
    useGetChargeLimitQuery(undefined, {
      skip: !isMonthlyPlan || isPlanLoading,
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('action') === REDIRECT_ACTION_BILLING) {
      dispatch(
        baseActions.setToastMessage({
          message: t(searchParams.get('message_id')),
          type: 'success',
        })
      );
      history.replace({ search: '' });
    }
  }, [location]);

  /**
   * Actions
   */
  const toggleUpdatePlanModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };

  const toggleRedeemModal = () => {
    dispatch(billingActions.toggleRedeemModal());
  };

  const toggleChargeLimitModal = () => {
    dispatch(billingActions.toggleChargeLimitModal());
  };

  let dateFormat = useMemo(() => {
    let format = 'MMMM D';
    if (
      !currentPlan?.billing_plan?.subscription_plan?.is_charge_limit_supported
    ) {
      format = 'MMMM D, YYYY';
    }
    return format;
  }, [currentPlan]);

  const perPlanPeriodLabel = useMemo(() => {
    return isMonthlyPlan
      ? 'billing.subscription_plans_modal.billing_intervals.30_days'
      : 'billing.subscription_plans_modal.billing_intervals.annual';
  }, [currentPlan]);

  const billingDetailsCard = () => {
    const pricePlanFeature = currentPlan?.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === FEATURE_SUBSCRIPTION_PRICE
    );
    return (
      <Layout.Section>
        <Card
          title={
            <Stack>
              <Stack.Item fill>
                <Stack>
                  <TextStyle variation="strong">
                    {currentPlan?.billing_plan?.subscription_plan.name}
                  </TextStyle>
                  {currentPlan?.billing_plan?.trial_expired_at ? (
                    <Badge>{t('billing.trail')}</Badge>
                  ) : (
                    ''
                  )}
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <TextStyle variation="strong">
                  {t(perPlanPeriodLabel, {
                    price: stringHelper.transformToCurrency(
                      parseFloat(pricePlanFeature?.price).toFixed(2)
                    ),
                  })}
                </TextStyle>
              </Stack.Item>
            </Stack>
          }
          secondaryFooterActions={[
            {
              content: t(
                'billing.current_plan_section.card.pick_a_plab_btn_label'
              ),
              onAction: toggleUpdatePlanModal,
            },
          ]}
        >
          <Card.Section>
            {currentPlan?.billing_plan?.trial_expired_at && (
              <div className="Row">
                {t('billing.trail_expire_at', {
                  date: moment(
                    currentPlan?.billing_plan?.trial_expired_at
                  ).format(dateFormat),
                })}
              </div>
            )}
            <div className="Row">
              {t('billing.plan_renew_at', {
                date: moment(
                  currentPlan?.billing_plan?.next_billing_cycle_date
                ).format(dateFormat),
              })}
            </div>
          </Card.Section>
          <Card.Section>
            <Stack vertical>
              <TextStyle variation="strong">
                {t('billing.payment_method')}
              </TextStyle>
              <div>{t('billing.shopify_billing_api')}</div>
            </Stack>
          </Card.Section>
        </Card>
      </Layout.Section>
    );
  };
  const usageChargesCard = () => {
    return (
      <Layout.Section>
        {isChargeLoading ? (
          <LoadingCard />
        ) : (
          <Card
            secondaryFooterActions={[
              {
                content: chargeLimit?.charge_limit?.charge_limit
                  ? t('billing.update_charge_limit')
                  : t('billing.set_charge_limit'),
                onAction: toggleChargeLimitModal,
              },
            ]}
            sectioned
            title={
              <Stack>
                <Stack.Item fill>
                  <Stack>
                    <TextStyle variation="strong">
                      {t('billing.charge_limit_section.info.title')}
                    </TextStyle>

                    {chargeLimit?.charge_status ? (
                      <Badge
                        status={chargeLimit?.charge_status?.badge_prop.status}
                      >
                        <p>{chargeLimit?.charge_status?.label}</p>
                      </Badge>
                    ) : (
                      ''
                    )}
                  </Stack>
                </Stack.Item>
                {chargeLimit?.charge_limit?.charge_limit ? (
                  <Stack.Item>
                    up to{' '}
                    <TextStyle variation="strong">
                      {t(perPlanPeriodLabel, {
                        price: stringHelper.transformToCurrency(
                          chargeLimit?.charge_limit?.charge_limit.toFixed(2)
                        ),
                      })}
                    </TextStyle>
                  </Stack.Item>
                ) : (
                  ''
                )}
              </Stack>
            }
          >
            {chargeLimit?.charge_limit?.charge_limit ? (
              <Stack vertical spacing="extraTight">
                <div>
                  {t('billing.usage_reset_at', {
                    date: moment(
                      currentPlan?.billing_plan?.next_billing_cycle_date
                    ).format(dateFormat),
                  })}
                </div>

                <div>
                  {t('billing.charges_used', {
                    used: stringHelper.transformToCurrency(
                      chargeLimit?.charge_limit?.current_usage.toFixed(2)
                    ),
                    total: stringHelper.transformToCurrency(
                      chargeLimit?.charge_limit?.charge_limit.toFixed(2)
                    ),
                  })}
                </div>
              </Stack>
            ) : (
              <Stack spacing="extraTight">
                <TextStyle variation="subdued">
                  {t('billing.no_charge_limit_set')}
                </TextStyle>
                <TranslateLink text={t('billing.learn_charge_limit')} />
              </Stack>
            )}
          </Card>
        )}
      </Layout.Section>
    );
  };
  const currentUsageCard = () => {
    const featureShipmentsFeature =
      currentPlan?.billing_plan?.features?.find(
        ({ feature_id }) => feature_id === FEATURE_SHIPMENTS
      ) || {};
    const freeTrackingFeature = currentPlan?.billing_plan?.features?.find(
      ({ feature_id }) => feature_id === FEATURE_FREE_TRACKINGS
    );

    let totalQuota = featureShipmentsFeature.quota;
    let remainingQuota = featureShipmentsFeature.remaining_quota;

    if (freeTrackingFeature) {
      remainingQuota = remainingQuota + freeTrackingFeature?.remaining_quota;
      totalQuota = totalQuota + freeTrackingFeature?.quota;
    }
    const used = totalQuota - remainingQuota;
    return (
      <Layout.Section>
        <Card sectioned title={t('billing.current_usage')}>
          <Stack>
            <Stack.Item fill>{t('billing.shipments_in_quota')}</Stack.Item>
            <Stack.Item>
              {t('billing.shipments_used', {
                used: transformToNumStr(used),
                total: transformToNumStr(totalQuota),
              })}
            </Stack.Item>
          </Stack>
        </Card>
      </Layout.Section>
    );
  };
  const supportCard = () => {
    return (
      <Layout.Section>
        <Card sectioned title={t('billing.support')}>
          <Stack vertical spacing="tight">
            <TextStyle variation="strong">
              {t('billing.email_or_chat')}
            </TextStyle>
            <Link external url={`mailto:${t('billing.support_address')}`}>
              {t('billing.support_address')}
            </Link>
          </Stack>
        </Card>
      </Layout.Section>
    );
  };
  return (
    <>
      <SmallTopMessage />
      <Page
        title={t('billing.page.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('billing.faq'),
                source: t('billing.faq_link'),
              },
              {
                text: t('billing.terms'),
                source: t('billing.terms_link'),
              },
              {
                text: t('billing.see_price'),
                source: t('billing.see_price_link'),
              },
            ]}
          />
        }
        primaryAction={{
          content: t('billing.redeem'),
          onAction: toggleRedeemModal,
          plain: true,
          ellipsis: false,
        }}
      >
        {isPlanLoading ? (
          <SkeletonBilling />
        ) : (
          <Layout>
            {billingDetailsCard()}
            {isMonthlyPlan ? usageChargesCard() : ''}
            {currentUsageCard()}
            <MerchentSuccessManagerCard />
            <BookTeamTimeCard />
            {supportCard()}
            <Layout.Section>
              <FooterHelp>
                <TranslateLink text={t('footer_help.dashboard')} />
              </FooterHelp>
            </Layout.Section>
          </Layout>
        )}
      </Page>
      <UpdatePlanModal />
      <RedeemPromoModal />
      <IncreaseLimitModal />
    </>
  );
}

export default Billing;

import React from 'react';
import { Layout } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TrackingPageCard from './TrackingPageCard';
import ThemesCard from './ThemesCard';
import { getOtherTrackingPages } from '../tempUtils';
export default function TrackingPages({ dispatchForTrackingPage }) {
  const [t] = useTranslation();

  const trackingPages = getOtherTrackingPages();
  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.tracking_pages.title')}
      description={t('settings.tracking_page.tracking_pages.description')}
    >
      {trackingPages.map((trackingPage) => {
        return (
          <TrackingPageCard trackingPage={trackingPage} key={trackingPage.id} />
        );
      })}
      <ThemesCard dispatchForTrackingPage={dispatchForTrackingPage} />
    </Layout.AnnotatedSection>
  );
}

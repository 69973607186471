import React, { useState, useEffect, useCallback } from 'react';
import { Toast } from '@shopify/polaris';

export default function Toaster({
  trackingPageToaster,
  resetTrackingPageToaster,
  upSellLoading,
}) {
  const [toasterActive, setToasterActive] = useState(false);
  const [toasterText, setToasterText] = useState(false);
  const [isToasterError, setIsToasterError] = useState(null);

  useEffect(() => {
    if (toasterActive && trackingPageToaster.showToast) {
      setToasterActive(false);
      setIsToasterError(false);
      setToasterText(null);
    }

    setToasterActive(trackingPageToaster.showToast);
    setIsToasterError(trackingPageToaster.isErrorToast);
    setToasterText(trackingPageToaster.toastText);
  }, [trackingPageToaster, toasterActive]);

  const closeToasterActive = useCallback(() => {
    resetTrackingPageToaster();
  }, [resetTrackingPageToaster]);

  const toastMarkup =
    !upSellLoading && toasterActive ? (
      <Toast
        error={isToasterError}
        content={toasterText}
        onDismiss={closeToasterActive}
        duration={3000}
      />
    ) : null;

  return <>{toastMarkup}</>;
}

import React, { useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  TextStyle,
  ResourceList,
  ResourceItem,
  Loading,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { ClientStorage } from 'ClientStorage';
import { useParams } from 'react-router-dom';
import { useDeleteShipbobMutation } from '../shipbobApis';
import { baseActions } from 'redux/store/baseSlice';
import { useDispatch } from 'react-redux';
export default function AccountsSection({
  accounts,
  installLink,
  isFeatureAvailable,
}) {
  const [t] = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const [
    deleteAccount,
    { isLoading: isDeleting, isSuccess: isDeleted, data: deleteData },
  ] = useDeleteShipbobMutation();

  useEffect(() => {
    if (isDeleted && deleteData) {
      dispatch(
        baseActions.setToastMessage({
          message: deleteData.message,
          type: 'success',
        })
      );
    }
  }, [deleteData, isDeleted]);
  const installShipbobAccount = () => {
    const token = ClientStorage.get('access_token');

    window.open(`${installLink}?token=${token}&store=${params.uuid}`, '_blank');
  };

  const handleAccountClick = useCallback(
    (accountId) => {
      deleteAccount(accountId);
    },
    [deleteAccount]
  );

  return (
    <>
      {isDeleting ? <Loading /> : ''}
      <Layout.AnnotatedSection
        title={t('settings.shipbob_page.connected_accounts_layout.title')}
        description={t(
          'settings.shipbob_page.connected_accounts_layout.description'
        )}
      >
        <Card
          title={t('settings.shipbob_page.connected_accounts_card.title')}
          primaryFooterAction={{
            content: t('settings.shipbob_page.connected_accounts_button.label'),
            onAction: installShipbobAccount,
            disabled: !isFeatureAvailable,
          }}
        >
          <Card.Section>
            {accounts && (
              <ResourceList
                resourceName={{ singular: 'customer', plural: 'customers' }}
                items={accounts.map((account) => ({
                  id: account.id,
                  name: account.channel_name,
                  url: '/wer',
                }))}
                renderItem={(item) => {
                  const { id, name } = item;
                  const shortcutActions = [
                    {
                      content: t('common.delete'),
                      destructive: true,
                      accessibilityLabel: `Delete`,
                      onClick: () => handleAccountClick(id),
                      loading: isDeleting,
                    },
                  ];

                  return (
                    <ResourceItem
                      id={id}
                      accessibilityLabel={`${name}`}
                      shortcutActions={shortcutActions}
                    >
                      <h3>
                        <TextStyle variation="strong">{name}</TextStyle>
                      </h3>
                    </ResourceItem>
                  );
                }}
              />
            )}

            {!accounts && (
              <div className="CustomEmptyState">
                <TextStyle variation="subdued">
                  {t(
                    'settings.shipbob_page.connected_accounts_card.no_row_message'
                  )}
                </TextStyle>
              </div>
            )}
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </>
  );
}

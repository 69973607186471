import React, { useCallback, useState, useEffect } from 'react';
import {
  Card,
  Filters,
  DataTable,
  Badge,
  Pagination,
  Link,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import ExternalLink from 'components/ExternalLink';
import { STORE_URLS } from 'config/urls';

export default function TrackingDataTable({
  paypalTrackingsData,
  paypalTrackingsMeta,
  noOfRecords,
  queryValue,
  setQueryValue,
  setPage,
}) {
  const [t] = useTranslation();
  const [searchQueryValue, setSearchQueryValue] = useState(queryValue);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (queryValue !== searchQueryValue) {
        setQueryValue(searchQueryValue);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchQueryValue, queryValue, setQueryValue]);

  useEffect(() => {
    const tempRows = paypalTrackingsData.map((trackData) => {
      return [
        <ExternalLink
          url={trackData.order.link}
        >{`#${trackData.order.number}`}</ExternalLink>,
        <ExternalLink url={trackData.customer.link}>
          {trackData.customer.name}
        </ExternalLink>,
        trackData.order.formated_value,
        trackData.paypal.transaction_link ? (
          <ExternalLink url={trackData.paypal.transaction_link}>
            {trackData.paypal.transaction_id}
          </ExternalLink>
        ) : (
          trackData.paypal.transaction_id
        ),
        trackData.paypal.shipment_id ? (
          <Link url={`${STORE_URLS.SHIPMENTS}/${trackData.paypal.shipment_id}`}>
            {trackData.paypal.tracking_number}
          </Link>
        ) : (
          trackData.paypal.tracking_number
        ),
        <Badge status={trackData.paypal.status_badge_prop.status}>
          {trackData.paypal.status_label}
        </Badge>,
      ];
    });
    setRows(tempRows);
  }, [paypalTrackingsData]);

  const handleFiltersQueryChange = useCallback((value) => {
    setSearchQueryValue(value);
  }, []);
  const handleQueryValueRemove = useCallback(
    () => setSearchQueryValue(null),
    []
  );
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryValue={searchQueryValue}
            filters={[]}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'numeric',
            'text',
            'text',
            'numeric',
          ]}
          headings={[
            t('settings.paypal_tracking_page.data_table_heading.order'),
            t('settings.paypal_tracking_page.data_table_heading.customer'),
            t('settings.paypal_tracking_page.data_table_heading.value'),
            t(
              'settings.paypal_tracking_page.data_table_heading.paypal_transaction'
            ),
            t(
              'settings.paypal_tracking_page.data_table_heading.tracking_number'
            ),
            t(
              'settings.paypal_tracking_page.data_table_heading.shipment_status'
            ),
          ]}
          rows={rows}
          footerContent={t('billing_details.table.summary', {
            startResult:
              (paypalTrackingsMeta.current_page - 1) * noOfRecords + 1,
            endResult:
              paypalTrackingsMeta.current_page * noOfRecords <
              paypalTrackingsMeta.total
                ? paypalTrackingsMeta.current_page * noOfRecords
                : paypalTrackingsMeta.total,
            total_results: paypalTrackingsMeta.total,
          })}
        />
      </Card>
      <div className="BillingDetail-Pagination">
        <Pagination
          hasPrevious={paypalTrackingsMeta.current_page !== 1}
          onPrevious={() => {
            setPage(parseInt(paypalTrackingsMeta.current_page) - 1);
          }}
          hasNext={
            paypalTrackingsMeta.current_page * noOfRecords <
            paypalTrackingsMeta.total
          }
          onNext={() => {
            setPage(parseInt(paypalTrackingsMeta.current_page) + 1);
          }}
        />
      </div>
    </div>
  );
}

import React from 'react';
import stringHelper from 'helpers/string';
import {
  FEATURE_SUBSCRIPTION_PRICE,
  FEATURE_SHIPMENTS,
  FREE_TRACKINGS_FORMAT,
  FEATURE_FREE_TRACKINGS,
} from 'Constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
export default function BillingFeatureList({ currentPlan }) {
  const [t] = useTranslation();
  return (
    <>
      {currentPlan.billing_plan.features.map((planFeature) => {
        const feature = currentPlan.features
          .map((feat) => {
            if (feat.id === planFeature.feature_id) {
              return feat;
            } else {
              return undefined;
            }
          })
          .filter((item) => !_.isUndefined(item))[0];
        if (feature.id === FEATURE_SUBSCRIPTION_PRICE) {
          return (
            <div className="Row" key={feature.id}>
              <div>{t('billing.current_plan_section.card.plan_price')}</div>
              <div>
                {currentPlan.billing_plan.subscription_plan.currency ===
                  'USD' && '$'}
                {currentPlan.billing_plan.subscription_plan
                  .is_charge_limit_supported &&
                  t(
                    'billing.subscription_plans_modal.billing_intervals.30_days',
                    {
                      price: stringHelper.formatAmount(
                        parseFloat(planFeature.price).toFixed(2)
                      ),
                    }
                  )}
                {!currentPlan.billing_plan.subscription_plan
                  .is_charge_limit_supported &&
                  t(
                    'billing.subscription_plans_modal.billing_intervals.annual',
                    {
                      price: stringHelper.formatAmount(
                        parseFloat(planFeature.price).toFixed(2)
                      ),
                    }
                  )}
              </div>
            </div>
          );
        } else if (feature.id === FEATURE_FREE_TRACKINGS) {
          return <React.Fragment key={feature.id}></React.Fragment>;
        } else if (
          feature.id === FEATURE_SHIPMENTS &&
          currentPlan.billing_plan.subscription_plan.is_charge_limit_supported
        ) {
          let totalQuota = planFeature.quota;
          let remainingQuota = planFeature.remaining_quota;
          const freeTrackingFeature = currentPlan.billing_plan.features
            .map((subPlanFeature) => {
              if (subPlanFeature.feature_id === FEATURE_FREE_TRACKINGS) {
                return subPlanFeature;
              } else {
                return undefined;
              }
            })
            .filter((item) => !_.isUndefined(item));
          if (!_.isEmpty(freeTrackingFeature)) {
            remainingQuota =
              remainingQuota + freeTrackingFeature[0].remaining_quota;
            totalQuota = totalQuota + freeTrackingFeature[0].quota;
          }
          return (
            <React.Fragment key={feature.id}>
              <div className="Row">
                <div>
                  {t('billing.current_plan_section.card.cost_per_shipment')}
                </div>
                <div>
                  {currentPlan.billing_plan.subscription_plan.currency ===
                    'USD' && '$'}
                  {planFeature.price}
                </div>
              </div>
              <div className="Row">
                <div>
                  {t('billing.current_plan_section.card.shipments_quota_left')}
                </div>
                <div>
                  {stringHelper.formatNumber(
                    remainingQuota,
                    FREE_TRACKINGS_FORMAT
                  )}{' '}
                  /{' '}
                  {stringHelper.formatNumber(totalQuota, FREE_TRACKINGS_FORMAT)}
                </div>
              </div>
            </React.Fragment>
          );
        } else if (!_.isUndefined(feature.billing_plan)) {
          return (
            <div className="Row" key={feature.id}>
              <div>{feature.name}</div>
              <div>
                {currentPlan.billing_plan.subscription_plan.currency ===
                  'USD' && '$'}
                {planFeature.price}
              </div>
            </div>
          );
        } else {
          return <React.Fragment key={feature.id}></React.Fragment>;
        }
      })}
    </>
  );
}

import React from 'react';
import { Banner } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { billingActions } from 'pages/Billing1/billingSlice';
import UpdatePlanModal from 'pages/Billing1/components/UpdatePlanModal';

function UpgradePlanBanner() {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const showModal = () => {
    dispatch(billingActions.toggleUpdateModal());
  };
  return (
    <>
      <Banner
        title={t('common.upgrade-to-unlock')}
        action={{
          content: t('common.upgrade-to-growth.title'),
          onAction: showModal,
        }}
        status="critical"
      >
        <p>{t('common.upgrade-to-growth.description')}</p>
      </Banner>
      <UpdatePlanModal />
    </>
  );
}

export default UpgradePlanBanner;

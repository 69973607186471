import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import { settingsActions } from 'redux/settings';
import TrackingPage from './TrackingPage';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    trackingDetailMeta: state.trackingDetail.meta,
    trackingSaved: state.trackingDetail.trackingSaved,
  };
}

const mapDispatchToProps = {
  getTrackingPageDetail: trackingActions.Creators.getTrackingPageDetail,
  saveTrackingPageDetail: trackingActions.Creators.saveTrackingPageDetail,
  showUnsavedModal: settingsActions.Creators.showUnsavedModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingPage);

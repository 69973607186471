import { connect } from 'react-redux';
import EmailValidation from './EmailValidation';
import { visitorActions } from 'redux/visitor';

function mapStateToProps(state) {
  return {
    visitorLanguages: state.visitor.languages,
    selectedVisitorLanguage: state.visitor.selectedLanguage,
    visitorToast: state.visitor.toast,
    loading: state.visitor.loading,
  };
}

const mapDispatchToProps = {
  getVisitorLanguages: visitorActions.Creators.getVisitorLanguages,
  changeVisitorLanguage: visitorActions.Creators.changeVisitorLanguage,
  verifyCode: visitorActions.Creators.verifyCode,
  resetVisitorToast: visitorActions.Creators.resetVisitorToast,
  resendVerificationCode: visitorActions.Creators.resendVerificationCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidation);

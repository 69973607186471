import React, { useState, useCallback, useEffect } from 'react';
import { Modal, TextField, TextStyle, FormLayout } from '@shopify/polaris';
import './style.scss';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function FAQModal({
  showFAQModal,
  fAQIndex,
  faqSection,
  dispatchForTrackingPage,
  ...props
}) {
  const [t] = useTranslation();

  const [questionTextError, setQuestionTextError] = useState(null);
  const [questionText, setQuestionText] = useState(null);
  const handleQuestionTextChange = useCallback(
    (value) => setQuestionText(value),
    []
  );

  const [answerTextError, setAnswerTextError] = useState(null);
  const [answerText, setAnswerText] = useState(null);
  const handleAnswerTextChange = useCallback(
    (value) => setAnswerText(value),
    []
  );

  const submit = () => {
    if (_.isNull(questionText) || _.isEmpty(_.trim(questionText))) {
      setQuestionTextError(
        t('settings.tracking_page.faq_modal.question_invalid_text')
      );
      return;
    }
    if (_.isNull(answerText) || _.isEmpty(_.trim(answerText))) {
      setAnswerTextError(
        t('settings.tracking_page.faq_modal.answer_invalid_text')
      );
      return;
    }
    let tempFAQ = JSON.parse(JSON.stringify(faqSection.structure || []));
    if (!_.isNull(fAQIndex)) {
      tempFAQ[fAQIndex] = {
        question: _.trim(questionText),
        answer: _.trim(answerText),
      };
    } else {
      const tempQuestion = {
        question: _.trim(questionText),
        answer: _.trim(answerText),
      };
      tempFAQ = [...faqSection.structure || [], tempQuestion];
    }
    const tempFAQSection = { ...faqSection, structure: [...tempFAQ] };
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.FAQ_UPDATE,
      payload: tempFAQSection,
    });
    props.handleShowFAQModalChange();
  };

  useEffect(() => {
    setQuestionText(null);
    setAnswerText(null);
    setQuestionTextError(null);
    setAnswerTextError(null);
  }, [showFAQModal]);

  useEffect(() => {
    if (!_.isNull(fAQIndex) && faqSection.structure || [].length > 0) {
      const tempFAQ = faqSection.structure[fAQIndex];
      if (tempFAQ) {
        setQuestionText(tempFAQ.question);
        setAnswerText(tempFAQ.answer);
      }
    }
  }, [fAQIndex, faqSection]);

  return (
    <Modal
      open={showFAQModal}
      onClose={props.handleShowFAQModalChange}
      title={t('settings.tracking_page.faq_modal.title')}
      primaryAction={{
        content: t('settings.tracking_page.faq_modal.save_btn_label'),
        onAction: submit,
      }}
      secondaryActions={[
        {
          content: t('settings.tracking_page.faq_modal.cancel_btn_label'),
          onAction: props.handleShowFAQModalChange,
        },
      ]}
    >
      <Modal.Section>
        <div className="FAQ-Row">
          <FormLayout>
            <TextField
              value={questionText}
              label={t(
                'settings.tracking_page.faq_modal.content.question_label'
              )}
              onChange={handleQuestionTextChange}
              error={questionTextError}
              placeholder={t(
                'settings.tracking_page.faq_modal.content.question_input_text'
              )}
              id={`input-faq-modal-question`}
            />

            <TextField
              label={t('settings.tracking_page.faq_modal.content.answer_label')}
              onChange={handleAnswerTextChange}
              value={answerText}
              error={answerTextError}
              placeholder={t(
                'settings.tracking_page.faq_modal.content.answer_input_text'
              )}
              multiline={4}
              id={`input-faq-modal-answer`}
            />
          </FormLayout>
        </div>
        <div className="FAQ-Row">
          <TextStyle variation="subdued">
            <TranslateLink
              text={t('settings.tracking_page.faq_modal.content.helper_text')}
            />
          </TextStyle>
        </div>
      </Modal.Section>
    </Modal>
  );
}

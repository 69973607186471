import { Page } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CommingSoonPage() {
  const [t] = useTranslation();
  return <Page title={t('common.comming-soon')}></Page>;
}

export default CommingSoonPage;

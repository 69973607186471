import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Card,
  Form,
  FormLayout,
  TextStyle,
  Checkbox,
  ChoiceList,
  Icon,
  Badge,
  Stack,
  Subheading,
  Spinner,
} from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';

import {
  TRACKING_PAGE_ACTIONS,
  CUSTOM_LANGUAGE_CREATE,
  CUSTOM_LANGUAGE_EDIT,
} from 'Constants';
import LanguageField from './LanguageField';
import './style.scss';
import TranslateLink from 'components/TranslateLink';
import { STORE_URLS } from 'config/urls';

export default function Localization({
  loading,
  trackingPageState,
  dispatchForTrackingPage,
  deleteCustomLanguage,
  shop,
  trackingPageLanguages,
  privateLanguages,
  getTrackingPageLanguages,
  getPrivateLanguages,
}) {
  const [t] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getTrackingPageLanguages();
    getPrivateLanguages();
  }, [getTrackingPageLanguages, getPrivateLanguages]);

  const allLanguages = privateLanguages
    ? privateLanguages.concat(trackingPageLanguages || [])
    : [];

  // Checkboxes
  // ------------------------------------------------------------
  const [isAllowMultipleLanguages, setIsAllowMultipleLanguages] =
    useState(null);
  const handleIsAllowMultipleLanguagesChange = useCallback(
    (value) => {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.LOCALIZATION_UPDATE,
        payload: {
          use_multiple_languages: value[0] === 'multiple',
        },
      });
      setIsAllowMultipleLanguages(value[0] === 'multiple');
    },
    [dispatchForTrackingPage]
  );

  // -----------------------------------------------------------------
  const [multipleLanguageSelection, setMultipleLanguageSelection] =
    useState(null);
  const handleMultipleSelectedOptionsInStore = useCallback(
    (value) => {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.LOCALIZATION_UPDATE,
        payload: {
          localisation_languages_selected_uuids: value,
        },
      });
      setMultipleLanguageSelection(value);
    },
    [dispatchForTrackingPage]
  );

  // ------------------------------------------------------------
  const [languageSection, setLanguageSection] = useState(['every']);
  const handleLanguageSectionChange = useCallback(
    (value) => {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.LOCALIZATION_UPDATE,
        payload: {
          language_selection_type: value[0],
        },
      });
      setLanguageSection(value);

      // if shortlist is selected and none selected, select first 3 by default
      console.log(value[0] === 'shortlist', !multipleLanguageSelection?.length);
      if (value[0] === 'shortlist' && !multipleLanguageSelection?.length) {
        handleMultipleSelectedOptionsInStore(
          (trackingPageLanguages || [])
            .slice(0, 3)
            .map((language) => language.uuid)
        );
      }
    },

    [dispatchForTrackingPage, multipleLanguageSelection]
  );

  // ------------------------------------------------------------

  useEffect(() => {
    setIsAllowMultipleLanguages(
      trackingPageState.localisation[`use_multiple_languages`]
    );
    setLanguageSection([
      trackingPageState.localisation[`language_selection_type`],
    ]); //every // shortlist
    setDefaultLanguageSelection(
      trackingPageState.localisation[`localisation_default_language_uuid`]
    );
    setMultipleLanguageSelection(
      trackingPageState.localisation[`localisation_languages_selected_uuids`]
    );
  }, [trackingPageState.localisation]);

  // -----------------------------------------------------------------
  const [defaultLanguageSelection, setDefaultLanguageSelection] =
    useState(null);
  const handleSelectedOptionsInStore = useCallback(
    (value) => {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.LOCALIZATION_UPDATE,
        payload: {
          localisation_default_language_uuid: value[0],
        },
      });
      setDefaultLanguageSelection(value);
    },
    [dispatchForTrackingPage]
  );

  const handleShowAddLanguageForm = () => {
    history.push(
      `/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGES}/${defaultLanguageSelection}/${CUSTOM_LANGUAGE_CREATE}`
    );
  };

  const handleDeleteCustomLanguage = useCallback(
    (languageUuid) => {
      deleteCustomLanguage(languageUuid);
    },
    [
      dispatchForTrackingPage,
      trackingPageState,
      defaultLanguageSelection,
      deleteCustomLanguage,
    ]
  );

  // -----------------------------------------------------------------
  return (
    <Layout.AnnotatedSection
      title={t('settings.tracking_page.localisation.title')}
      description={t('settings.tracking_page.localisation.description')}
    >
      <Card>
        <Card.Section
          title={t('settings.tracking_page.localisation.card.primary_language')}
        >
          <Form>
            <FormLayout>
              <LanguageField
                languages={allLanguages}
                allowMultiple={false}
                showSelection={false}
                setSelectedOptionsInStore={handleSelectedOptionsInStore}
                selectedLanguages={defaultLanguageSelection}
                helpText={
                  <TranslateLink
                    text={t(
                      'settings.tracking_page.localization.card.help_text'
                    )}
                  />
                }
              />
            </FormLayout>
          </Form>
        </Card.Section>

        <Card.Section
          title={t(
            'settings.tracking_page.localization.card.multilanguage_support'
          )}
        >
          <Form>
            <FormLayout>
              <ChoiceList
                choices={[
                  {
                    label: t(
                      `settings.tracking_page.localization.card.radio.single_language`
                    ),
                    value: 'single',
                  },
                  {
                    label: t(
                      `settings.tracking_page.localization.card.radio.language_picker`
                    ),
                    value: 'multiple',
                  },
                ]}
                selected={isAllowMultipleLanguages ? ['multiple'] : ['single']}
                onChange={handleIsAllowMultipleLanguagesChange}
                title=""
              />
            </FormLayout>
          </Form>
        </Card.Section>
        {isAllowMultipleLanguages && (
          <Card.Section
            title={
              <Stack vertical spacing={'extraTight'}>
                <Subheading>
                  {t(`settings.tracking_page.localization.language_list.title`)}
                </Subheading>
                <TextStyle variation="subdued">
                  {t(
                    'settings.tracking_page.localization.language_list.description'
                  )}
                </TextStyle>
              </Stack>
            }
          >
            <ChoiceList
              title=""
              choices={[
                {
                  label: t(`settings.tracking_page.localization.card.every`),
                  value: 'every',
                },
                {
                  label: t(
                    `settings.tracking_page.localization.card.shortlist`
                  ),
                  value: 'shortlist',
                },
              ]}
              selected={languageSection}
              onChange={handleLanguageSectionChange}
            />
            <div className="rush-custom-form-container">
              {languageSection[0] === 'shortlist' && (
                <LanguageField
                  languages={allLanguages}
                  allowMultiple={true}
                  showSelection={true}
                  setMultipleSelectedOptionsInStore={
                    handleMultipleSelectedOptionsInStore
                  }
                  selectedLanguages={multipleLanguageSelection}
                />
              )}
            </div>
          </Card.Section>
        )}
        <Card.Section
          title={
            <Stack distribution={'equalSpacing'}>
              <Subheading>
                {t(
                  'settings.tracking_page.localization.card.localize_shipment.title'
                )}
              </Subheading>
              <Badge status="warning">
                {t(
                  'settings.tracking_page.localization.card.localize_shipment.beta'
                )}
              </Badge>
            </Stack>
          }
        >
          <Checkbox
            label={t(
              'settings.tracking_page.localization.card.localize_shipment.checkbox.translate'
            )}
            checked={
              trackingPageState.localisation?.localisation_shipment_messages
            }
            disabled
          />
          <Stack>
            <TextStyle variation={'subdued'}>
              {t(
                'settings.tracking_page.localization.card.localize_shipment.description'
              )}
            </TextStyle>
          </Stack>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section
          title={t(
            `settings.tracking_page.localisation.custom_language.your_languages`
          )}
          actions={[
            {
              content: t(
                `settings.tracking_page.localisation.custom_language.add_language`
              ),
              onAction: handleShowAddLanguageForm,
            },
          ]}
        >
          {privateLanguages?.length ? (
            <div className="custom-language-container">
              {privateLanguages.map((object, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="language-main-container" key={index}>
                      <div className="language-container">
                        {loading ? (
                          <div className="delete-icon">
                            <Spinner size={'small'} />
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              handleDeleteCustomLanguage(object.uuid)
                            }
                            className="delete-icon"
                          >
                            <Icon source={MobileCancelMajor} color="base" />
                          </div>
                        )}

                        <img
                          height="20px"
                          width="20px"
                          alt=""
                          src={object.svg_flag_url}
                        />
                        <h2 className="language-links">
                          {object.label_english}
                        </h2>
                      </div>
                      <div>
                        <Link
                          className="add-language-link"
                          to={`/s/${shop.storeUUID}${STORE_URLS.TRACKING_PAGES}/${object.uuid}/${CUSTOM_LANGUAGE_EDIT}`}
                        >
                          <div className="edit-text">edit</div>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <React.Fragment>
              <Stack distribution={'center'}>
                <TextStyle variation="subdued">
                  {t(
                    `settings.tracking_page.localisation.custom_language.no_languages_added_text1`,
                    {
                      number: allLanguages.length,
                    }
                  )}
                </TextStyle>
              </Stack>
              <Stack distribution={'center'}>
                <TextStyle variation={'subdued'}>
                  {t(
                    'settings.tracking_page.localisation.custom_language.no_languages_added_text2'
                  )}
                </TextStyle>
              </Stack>
            </React.Fragment>
          )}
        </Card.Section>
      </Card>
    </Layout.AnnotatedSection>
  );
}

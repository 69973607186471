import { storeApi } from '../../redux/store/storeApi';

export const preShipmentTimelineAPIs = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreShipmentTimelines: builder.query({
      query: () => {
        return {
          url: `/features/pre-shipment-timelines`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['preShipments'],
    }),
    getPreShipmentTimeline: builder.query({
      query: (uuid) => {
        return {
          url: `/features/pre-shipment-timelines/${uuid}`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    addPreShipmentTimeline: builder.mutation({
      query: (data) => {
        return {
          url: `/features/pre-shipment-timelines`,
          method: 'POST',
          body: { ...data },
        };
      },
      invalidatesTags: ['preShipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    reorderPreShipmentTimeline: builder.mutation({
      query: (data) => {
        return {
          url: `/features/pre-shipment-timelines/order`,
          method: 'PUT',
          body: { ...data },
        };
      },
      invalidatesTags: ['preShipments'],
    }),
    updatePreShipmentTimeline: builder.mutation({
      query: ({ uuid, ...data }) => {
        return {
          url: `/features/pre-shipment-timelines/${uuid}`,
          method: 'PATCH',
          body: { ...data },
        };
      },
      invalidatesTags: ['preShipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),

    deletePreShipmentTimeline: builder.mutation({
      query: (uuid) => {
        return {
          url: `/features/pre-shipment-timelines/${uuid}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['preShipments'],
      transformResponse: (response = {}) => response?.data || response,
    }),
    clearResourceCache: builder.query({
      query: (name) => {
        return {
          url: `/${name}/cache`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    getPreviewLink: builder.query({
      query: (uuid) => {
        return {
          url: `/features/pre-shipment-timelines/${uuid}/preview-link`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
  }),
});

export const {
  useAddPreShipmentTimelineMutation,
  useDeletePreShipmentTimelineMutation,
  useGetPreShipmentTimelineQuery,
  useGetPreShipmentTimelinesQuery,
  useReorderPreShipmentTimelineMutation,
  useUpdatePreShipmentTimelineMutation,
  useLazyClearResourceCacheQuery,
  useLazyGetPreviewLinkQuery,
} = preShipmentTimelineAPIs;

import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import TrackingConf from './TrackingConf';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    paypalLoading: state.settings.paypalLoading,
    paypalSyncTrackingConfig: state.settings.paypalSyncTrackingConfig,
    paypalButtonConfiguration: state.settings.paypalButtonConfiguration,
    paypalDisputeResolutionConfig: state.settings.paypalDisputeResolutionConfig,
    paypalMetaTrackingNumberType: state.settings.paypalMetaTrackingNumberType,
    paypalMetaUpdateMode: state.settings.paypalMetaUpdateMode,
    paypalToaster: state.settings.paypalToaster,
  };
}

const mapDispatchToProps = {
  getPaypalConfig: settingsActions.Creators.getPaypalConfig,
  putPaypalConfig: settingsActions.Creators.putPaypalConfig,
  activateFeature: settingsActions.Creators.activateFeature,
  deactivateFeature: settingsActions.Creators.deactivateFeature,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingConf);

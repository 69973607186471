import { connect } from 'react-redux';
import { reportsActions } from 'redux/reports';
import Reports from './Reports';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    performanceReport: state.reports.performanceReport,
    performanceReportTitle: state.reports.performanceReportTitle,
    performanceReportSubTitle: state.reports.performanceReportSubTitle,
    performanceReportNote: state.reports.performanceReportNote,
    performanceReportDateRange: state.reports.performanceReportDateRange,
    reportsLoading: state.reports.reportsLoading,
  };
}

const mapDispatchToProps = {
  getPerformanceReport: reportsActions.Creators.getPerformanceReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

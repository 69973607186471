import React from 'react';
import PropTypes from 'prop-types';
import { Card, TextStyle } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import './styles.scss';

function Step1({ selected, styleList, onChange }) {
  const [t] = useTranslation();
  return (
    <Card title={t('settings.os2_tracking_page.wizard.step1.title')} sectioned>
      <TextStyle variation="subdued">
        {t('settings.os2_tracking_page.wizard.step1.description')}
      </TextStyle>
      <ul className="style-list">
        {styleList.map((style) => (
          <li
            key={style.slug}
            className={selected === style.slug && 'is-selected'}
          >
            <button
              className="style-list-button"
              type="button"
              onClick={() => onChange(style.slug)}
            >
              <img className="style-list-image" src={style.image} alt="" />
              <div className="style-list-title-wrapper">
                <span className="style-list-title">{style.title}</span>
              </div>
              <div className="style-list-title-summary">
                <span className="style-list-summary">{style.summary}</span>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </Card>
  );
}

Step1.propTypes = {
  selected: PropTypes.string.isRequired,
  styleList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Step1;

import { connect } from 'react-redux';
import { trackingActions } from 'redux/tracking';
import MapLocation from './MapLocation';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    trackingDetail: state.trackingDetail,
    trackingDetailMeta: state.trackingDetail.meta,
  };
}

const mapDispatchToProps = {
  saveTrackingPageDetail: trackingActions.Creators.saveTrackingPageDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapLocation);

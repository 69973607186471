import { connect } from 'react-redux';
import { settingsActions } from 'redux/settings';
import AccountsSection from './AccountsSection';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    paypalLoading: state.settings.paypalLoading,
    paypalSyncTrackingConfig: state.settings.paypalSyncTrackingConfig,
    paypalButtonConfiguration: state.settings.paypalButtonConfiguration,
    paypalDisputeResolutionConfig: state.settings.paypalDisputeResolutionConfig,
    paypalMetaTrackingNumberType: state.settings.paypalMetaTrackingNumberType,
    paypalMetaUpdateMode: state.settings.paypalMetaUpdateMode,
    paypalAccountsUpdated: state.settings.paypalAccountsUpdated,
  };
}

const mapDispatchToProps = {
  getPaypalConfig: settingsActions.Creators.getPaypalConfig,
  postPaypalAccount: settingsActions.Creators.postPaypalAccount,
  putPaypalAccount: settingsActions.Creators.putPaypalAccount,
  deletePaypalAccount: settingsActions.Creators.deletePaypalAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsSection);

import { TRACKING_PAGE_ACTIONS } from 'Constants';
export default function reducer(state, action) {
  switch (action.type) {
    case TRACKING_PAGE_ACTIONS.INIT:
      return {
        ...state,
        customizeTheme: false,
        ...action.payload,
        isUpdated: false,
        validationFields: {
          themeSelection: true,
          pageLayout: true,
          pageExtension: true,
          geoLocSelected: true,
          geoLocReplaceSelected: true,
          facebook_pixel: true,
          google_analytics: true,
          google_conversion_id: true,
          google_tag_manager: true,
          storeName: true,
          productReviewOkendoUuid: true,
          trackingBy: true,
        },
      };
    case TRACKING_PAGE_ACTIONS.VALIDATION:
      return {
        ...state,
        validationFields: { ...state.validationFields, ...action.payload },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.UPDATE:
      return {
        ...state,
        ...action.payload,
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.LOCALIZATION_UPDATE:
      return {
        ...state,
        localisation: { ...state.localisation, ...action.payload },
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.FAQ_UPDATE:
      return {
        ...state,
        faq: { ...state.faq, ...action.payload },
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.TRACKING_INS_UPDATE:
      return {
        ...state,
        tracking_instrumentation: {
          ...state.tracking_instrumentation,
          ...action.payload,
        },
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.STORE_INFO_UPDATE:
      return {
        ...state,
        store_information: { ...state.store_information, ...action.payload },
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.BLACKLISTING_UPDATE:
      return {
        ...state,
        blacklist: { ...state.blacklist, ...action.payload },
        validationFields: { ...state.validationFields, ...action.validation },
        isUpdated: true,
      };
    case TRACKING_PAGE_ACTIONS.RESET:
      return {
        ...state,
        ...action.payload,
        isUpdated: false,
        validationFields: { ...state.validationFields, ...action.validation },
        trackingSaved: false,
      };
    default:
      return state;
  }
}

import React, { useState, useCallback, useEffect } from 'react';
import {
  Layout,
  Card,
  Page,
  FooterHelp,
  Badge,
  Heading,
  Toast,
  SkeletonBodyText,
} from '@shopify/polaris';
import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import IncreaseLimitModal from 'components/IncreaseLimitModal';

import './style.scss';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import SmallTopMessage from 'components/SmallTopMessage';
import PromoCodeModal from 'components/PromoCodeModal';
import PromoConfirmationModal from 'components/PromoConfirmationModal';
import SubscriptionPlansModal from 'components//SubscriptionPlansModal';
import {
  BILLING_PLAN_REASON_DOWNGRADE_ID,
  BILLING_PLAN_REASON_PROMO_CODE_APPLIED,
  BILLING_PLAN_REASON_UPGRADE_ID,
  LOCAL_STORAGE,
} from 'Constants';
import _ from 'lodash';
import moment from 'moment';

import BillingFeatureList from 'components/BillingFeatureList';
import Subtitle from '../../components/Subtitle';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientStorage } from 'ClientStorage';
import { FULL_STORY_ORG, GOOGLE_TAG_ID } from '../../config';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export default function Billing({
  getChargeLimit,
  loadingCurrentBillingPlan,
  currentPlan,
  chargeLimit,
  billingPlan,
  billingPlanError,
  disableBillingPlan,
  chargeLimitLoading,
  showPromoCodeModal,
  showPromoCodeConfirmationModal,
  handleSubscriptionPlansModalChange,
  billingToasterMessage,
  resetBillingToasterMessage,
  getCurrentBillingPlan,
}) {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const sendDataToGTM = useGTMDispatch();

  const [showIncreaseLimitModal, setShowIncreaseLimitModal] = useState(false);
  const handleIncreaseLimitModalChange = useCallback(
    () => setShowIncreaseLimitModal(!showIncreaseLimitModal),
    [showIncreaseLimitModal]
  );

  useEffect(() => {
    getCurrentBillingPlan();
  }, []);

  useEffect(() => {
    if (!currentPlan.billing_plan.id) {
      return;
    }

    if (searchParams.get('message_id')) {
      setToasterBillingPlanText(t(searchParams.get('message_id')));
      toggleToasterBillingPlan();
      searchParams.delete('message_id');
    }

    if (
      ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) === true &&
      searchParams.get('action') === 'billing_change' &&
      searchParams.get('is_success') === '1'
    ) {
      let eventName = null;
      switch (currentPlan.billing_plan.billing_plan_reason.id) {
        case BILLING_PLAN_REASON_UPGRADE_ID:
          eventName = 'subscriptionToUpgrade';
          break;
        case BILLING_PLAN_REASON_DOWNGRADE_ID:
          eventName = 'subscriptionToDowngrade';
          break;
      }

      if (FULL_STORY_ORG && FULL_STORY_ORG !== 'null' && window.FS) {
        window.FS.event('subscriptionChangeAny');
        eventName && window.FS.event(eventName);
      }

      if (GOOGLE_TAG_ID && GOOGLE_TAG_ID !== 'null') {
        sendDataToGTM({
          category: 'AppEvent',
          event: eventName || 'subscriptionChangeAny',
          action: eventName || 'subscriptionChangeAny',
          value: currentPlan.billing_plan.features.find(
            ({ feature_id }) => feature_id === 1
          ).price,
        });
      }
      searchParams.delete('action');
      searchParams.delete('is_success');
    }
    history.replace({
      search: searchParams.toString(),
    });
  }, [currentPlan]);

  useEffect(() => {
    if (
      currentPlan &&
      currentPlan.billing_plan &&
      currentPlan.billing_plan.subscription_plan &&
      currentPlan.billing_plan.subscription_plan.is_charge_limit_supported
    ) {
      getChargeLimit();
    }
  }, [getChargeLimit, currentPlan]);

  const [toasterBillingPlan, setToasterBillingPlan] = useState(false);
  const [toasterBillingPlanError, setToasterBillingPlanError] = useState(false);
  const [toasterBillingPlanText, setToasterBillingPlanText] = useState('');
  const toggleToasterBillingPlan = useCallback(
    () => setToasterBillingPlan((active) => !active),
    []
  );
  const ToastToasterBillingPlanMarkup = toasterBillingPlan ? (
    <Toast
      content={toasterBillingPlanText}
      error={toasterBillingPlanError}
      duration={5000}
      onDismiss={toggleToasterBillingPlan}
    />
  ) : null;

  useEffect(() => {
    const reason = billingPlan?.billing_plan_reason;

    if (!chargeLimit.charge_limit || !reason || !billingToasterMessage) {
      return;
    }

    switch (reason.id) {
      case BILLING_PLAN_REASON_PROMO_CODE_APPLIED:
        showPromoCodeConfirmationModal();
        break;
      default:
        break;
    }

    setToasterBillingPlanText(t(billingToasterMessage));
    toggleToasterBillingPlan();
    resetBillingToasterMessage();
  }, [
    resetBillingToasterMessage,
    showPromoCodeConfirmationModal,
    t,
    toggleToasterBillingPlan,
    chargeLimit,
    billingPlan,
    billingToasterMessage,
  ]);

  useEffect(() => {
    if (billingPlanError) {
      setToasterBillingPlanText(billingPlanError);
      setToasterBillingPlanError(true);
      toggleToasterBillingPlan();
      disableBillingPlan();
    }
  }, [billingPlanError, disableBillingPlan, toggleToasterBillingPlan]);

  if (!currentPlan.billing_plan.id) {
    return <LoadingLayoutPage />;
  }
  let dateFormat = 'MMMM D';
  if (
    currentPlan &&
    currentPlan.billing_plan &&
    currentPlan.billing_plan.subscription_plan &&
    !currentPlan.billing_plan.subscription_plan.is_charge_limit_supported
  ) {
    dateFormat = 'MMMM D, YYYY';
  }

  return (
    <>
      <SmallTopMessage />
      <Page
        title={t('billing.page.title')}
        subtitle={
          <Subtitle
            actions={[
              {
                text: t('billing.page.subtitle.introduction'),
                source:
                  'https://support.rush.app/getting-started-with-billing?utm_source=app.rush.app&utm_medium=app.rush.app',
              },
            ]}
          />
        }
      >
        <Layout>
          {loadingCurrentBillingPlan && (
            <Layout.AnnotatedSection>
              <Card sectioned>
                <SkeletonBodyText />
                <br />
                <SkeletonBodyText />
              </Card>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          )}
          {!loadingCurrentBillingPlan && (
            <>
              <Layout.AnnotatedSection
                title={t('billing.current_plan_section.info.title')}
                description={t('billing.current_plan_section.info.description')}
              >
                <Card
                  title={t('billing.current_plan_section.card.title')}
                  secondaryFooterActions={[
                    {
                      content: t(
                        'billing.current_plan_section.card.pick_a_plab_btn_label'
                      ),
                      onAction: handleSubscriptionPlansModalChange,
                    },
                  ]}
                  sectioned
                >
                  <div className="CardWithDetails">
                    <div className="Row">
                      <div>
                        {t('billing.current_plan_section.card.plan_name')}
                      </div>
                      <div>
                        <strong>
                          {currentPlan.billing_plan.subscription_plan.name}
                        </strong>
                      </div>
                    </div>
                    <BillingFeatureList />
                    {currentPlan.billing_plan.trial_expired_at && (
                      <div className="Row">
                        <div>
                          {t(
                            'billing.current_plan_section.card.trial_expired_at'
                          )}
                        </div>
                        <div>
                          {moment(
                            currentPlan.billing_plan.trial_expired_at
                          ).format(dateFormat)}
                        </div>
                      </div>
                    )}
                    <div className="Row">
                      <div>
                        {t(
                          'billing.current_plan_section.card.next_billing_cycle_date'
                        )}
                      </div>
                      {moment(
                        currentPlan.billing_plan.next_billing_cycle_date
                      ) <= moment() &&
                        t('billing.current_plan_section.card.renewing_today')}
                      {moment(
                        currentPlan.billing_plan.next_billing_cycle_date
                      ) > moment() && (
                        <div>
                          {moment(
                            currentPlan.billing_plan.next_billing_cycle_date
                          ).format(dateFormat)}
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title={t('billing.promo_section.info.title')}
                description={t('billing.promo_section.info.description')}
              >
                <Card
                  title={t('billing.promo_section.card.title')}
                  secondaryFooterActions={[
                    {
                      content: t(
                        'billing.promo_section.card.redeem_promo_btn_label'
                      ),
                      onAction: showPromoCodeModal,
                    },
                  ]}
                  sectioned
                >
                  {currentPlan.promo && (
                    <div className="CardWithDetails">
                      <div className="Row">
                        <div>{t('billing.promo_section.card.provider')}</div>
                        <div>{currentPlan.promo.affiliate_public_name}</div>
                      </div>
                      <div className="Row">
                        <div>{t('billing.promo_section.card.promo_code')}</div>
                        <div>{currentPlan.promo.code}</div>
                      </div>
                      <div className="Row">
                        <div>{t('billing.promo_section.card.expires_on')}</div>
                        {moment(currentPlan.promo.expire_at).format('YYYY') ===
                          moment().format('YYYY') && (
                          <div>
                            {moment(currentPlan.promo.expire_at).format(
                              'MMMM DD'
                            )}
                          </div>
                        )}
                        {moment(currentPlan.promo.expire_at).format('YYYY') !==
                          moment().format('YYYY') && (
                          <div>
                            {moment(currentPlan.promo.expire_at).format(
                              'MMMM DD, YYYY'
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!currentPlan.promo && (
                    <div className="CardWithDetails">
                      <i>{t('billing.promo_section.card.no_promo')}</i>
                    </div>
                  )}
                </Card>
              </Layout.AnnotatedSection>
            </>
          )}
          {chargeLimitLoading && (
            <Layout.AnnotatedSection>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          )}
          {!chargeLimitLoading && !_.isNull(chargeLimit.charge_limit) && (
            <Layout.AnnotatedSection
              title={t('billing.charge_limit_section.info.title')}
              description={t('billing.charge_limit_section.info.description')}
            >
              <Card
                secondaryFooterActions={[
                  {
                    content: t(
                      'billing.charge_limit_section.card.charge_limit_button_label'
                    ),
                    onAction: handleIncreaseLimitModalChange,
                  },
                ]}
                sectioned
              >
                <div className="SettingCard">
                  <div className="SettingCard-Heading">
                    <Heading>
                      {t('billing.charge_limit_section.card.title')}
                    </Heading>
                  </div>
                  <div className="SettingCard-Status">
                    <Badge
                      status={chargeLimit.charge_status.badge_prop.status}
                      progress={chargeLimit.charge_status.badge_prop.progress}
                    >
                      {chargeLimit.charge_status.label}
                    </Badge>
                  </div>
                </div>
                <div className="CardWithDetails">
                  <div className="Row">
                    <div>
                      {t('billing.charge_limit_section.card.charge_limit')}
                    </div>
                    <div>
                      ${chargeLimit.charge_limit.charge_limit.toFixed(2)}
                    </div>
                  </div>
                  <div className="Row">
                    <div>
                      {t('billing.charge_limit_section.card.current_usage')}
                    </div>
                    <div>
                      ${chargeLimit.charge_limit.current_usage.toFixed(2)}
                    </div>
                  </div>
                </div>
              </Card>
            </Layout.AnnotatedSection>
          )}

          {/* Hiding because billing is not showing real value, we want to keep it for future. */}

          {/* {billingSummaryLoading && (
            <Layout.AnnotatedSection>
              <Card sectioned>
                <SkeletonBodyText />
              </Card>
            </Layout.AnnotatedSection>
          )} */}

          {/* {billingSummary && (
            <Layout.AnnotatedSection
              title={t('billing.cost_section.info.title')}
              description={t('billing.cost_section.info.description')}
            >
              <Card
                title={t('billing.cost_section.current_month.title')}
                actions={[
                  {
                    content: t('billing.cost_section.current_month.more_link_label'),
                    url: `/billing/detail?month=${CURRENT_MONTH}`,
                  },
                ]}
                sectioned
              >
                {t('billing.cost_section.current_month.description')}
                <div className='CardWithDetails'>
                  {billingSummary[0] &&
                    billingSummary[0].breakdown.map((breakdownItem) => (
                      <div className='Row BorderedRow' key={breakdownItem.feature_id}>
                        <div>{breakdownItem.feature_billing_name}</div>
                        <div>
                          <TextStyle variation='subdued'>
                            {currentPlan.billing_plan.subscription_plan.currency === 'USD' && '$'}
                            {breakdownItem.amount.toFixed(2)}
                          </TextStyle>
                        </div>
                      </div>
                    ))}
                  <div className='Row BorderedRow'>
                    <div>
                      <strong>{t('billing.cost_section.current_month.total_cost')}</strong>
                    </div>
                    <div>
                      <strong>
                        {currentPlan.billing_plan.subscription_plan.currency === 'USD' && '$'}
                        {billingSummary[0].amount.toFixed(2)}
                      </strong>
                    </div>
                  </div>
                  {billingSummary[0].forecasted && (
                    <div className='Row BorderedRow'>
                      <div>
                        <strong>{t('billing.cost_section.current_month.forecasted_cost')}</strong>
                      </div>
                      <div>
                        <strong>
                          {currentPlan.billing_plan.subscription_plan.currency === 'USD' && '$'}
                          {billingSummary[0].forecasted.toFixed(2)}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
              {billingSummary && billingSummary.length > 1 && (
                <Card
                  title={t('billing.cost_section.history.title')}
                  actions={[
                    {
                      content: t('billing.cost_section.history.more_link_label'),
                      url: `/billing/detail?month=${PREVIOUS_MONTH}`,
                    },
                  ]}
                  sectioned
                >
                  {t('billing.cost_section.history.description')}
                  <div className='CardWithDetails'>
                    {billingSummary.map((summary, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && (
                          <div className='Row BorderedRow'>
                            <div>{summary.name}</div>
                            <div>
                              <TextStyle variation='subdued'>
                                {currentPlan.billing_plan.subscription_plan.currency === 'USD' &&
                                  '$'}
                                {summary.amount.toFixed(2)}
                              </TextStyle>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </Card>
              )}
            </Layout.AnnotatedSection>
          )} */}
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
      <IncreaseLimitModal
        showIncreaseLimitModal={showIncreaseLimitModal}
        handleIncreaseLimitModalChange={handleIncreaseLimitModalChange}
      />
      {ToastToasterBillingPlanMarkup}
      <PromoCodeModal textPrefix="billing.promo_modal" />
      <PromoConfirmationModal />
      <SubscriptionPlansModal />
    </>
  );
}

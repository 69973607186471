const { createActions } = require('reduxsauce');

module.exports = createActions({
  getIpAddress: null,
  getIpAddressSuccess: ['ip'],
  getIpAddressFailure: null,

  getVisitorLanguages: null,
  getVisitorLanguagesSuccess: ['data'],
  getVisitorLanguagesFailure: ['error'],

  getAffiliatesCampaign: ['params'],
  getAffiliatesCampaignSuccess: ['data'],
  getAffiliatesCampaignFailure: ['error'],

  changeVisitorLanguage: ['params'],

  resetVisitorToast: null,

  login: ['params'],
  loginSuccess: ['data'],
  loginFailure: ['error'],

  register: ['params'],
  registerSuccess: ['data'],
  registerFailure: ['error'],

  resendVerificationCode: ['params'],
  resendVerificationCodeSuccess: ['data'],
  resendVerificationCodeFailure: ['error'],

  verifyCode: ['params'],
  verifyCodeSuccess: ['data'],
  verifyCodeFailure: ['error'],

  forgotPassword: ['params'],
  forgotPasswordSuccess: ['data'],
  forgotPasswordFailure: ['error'],

  resetNewEmail: null,

  resetPassword: ['params'],
  resetPasswordSuccess: ['data'],
  resetPasswordFailure: ['error'],

  updateEmail: ['params'],
  updateEmailSuccess: ['data'],
  updateEmailFailure: ['error'],

  getAccessToken: ['params'],
  getAccessTokenSuccess: ['data'],
  getAccessTokenFailure: ['error'],

  logout: ['params'],
  logoutSuccess: ['data'],
  logoutFailure: ['error'],
});

import React, { useState, useCallback, useEffect } from 'react';
import {
  TextStyle,
  Combobox,
  Listbox,
  Icon,
  TextContainer,
  ResourceList,
  ResourceItem,
} from '@shopify/polaris';
import { SearchMinor, MobileCancelMajor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export default function LanguageField({
  languages,
  allowMultiple,
  showSelection,
  setSelectedOptionsInStore,
  setMultipleSelectedOptionsInStore,
  selectedLanguages,
  helpText,
}) {
  const [t] = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsObj, setSelectedOptionsObj] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [singleLanguageFieldFocued, setSingleLanguageFieldFocued] =
    useState(false);

  useEffect(() => {
    if (selectedLanguages?.length) {
      const newOptions = languages.filter(
        (option) => !selectedLanguages.includes(option.uuid)
      );
      setOptions(newOptions);
    } else {
      setOptions(languages);
    }
  }, [languages, selectedLanguages]);

  useEffect(() => {
    if (selectedLanguages) {
      const newSelectedLanguages = Array.isArray(selectedLanguages)
        ? selectedLanguages
        : [selectedLanguages];
      const selectedLanguagesArray = languages.filter((option) =>
        newSelectedLanguages.includes(option.uuid)
      );
      setSelectedOptions(newSelectedLanguages);
      setSelectedOptionsObj(selectedLanguagesArray);
      if (!allowMultiple && !_.isEmpty(selectedLanguagesArray)) {
        setInputValue(selectedLanguagesArray[0].label_english);
      }
    }
  }, [selectedLanguages, allowMultiple, languages]);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      const updatedSelectedLanguages = Array.isArray(selectedLanguages)
        ? selectedLanguages
        : [selectedLanguages];

      const updatedLanguages = languages.filter(
        (option) => !updatedSelectedLanguages.includes(option.uuid)
      );

      if (value === '') {
        setOptions(updatedLanguages);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = updatedLanguages.filter((option) =>
        option.label_english.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [languages, selectedLanguages]
  );

  const updateSelection = useCallback(
    (option) => {
      const selected = option.uuid;
      if (!allowMultiple) {
        setSelectedOptions([selected]);
        setSelectedOptionsObj([option]);
        setSelectedOptionsInStore([selected]);
        setInputValue(option.label_english || '');
        setSingleLanguageFieldFocued(false);
      } else {
        if (selectedOptions.includes(selected)) {
          const selection = selectedOptions.filter(
            (option) => option !== selected
          );
          const objSelection = selectedOptionsObj.filter(
            (option) => option.uuid !== selected
          );
          setSelectedOptions(selection);
          setSelectedOptionsObj(objSelection);
          setMultipleSelectedOptionsInStore(selection);
        } else {
          const selection = [...selectedOptions, selected];
          setSelectedOptions(selection);
          setSelectedOptionsObj([...selectedOptionsObj, option]);
          setMultipleSelectedOptionsInStore(selection);
        }
        setInputValue('');
        const newOptions = options.filter((option) => option.uuid !== selected);
        setOptions(newOptions);
      }
    },
    [
      selectedOptions,
      selectedOptionsObj,
      allowMultiple,
      options,
      setSelectedOptionsInStore,
      setMultipleSelectedOptionsInStore,
    ]
  );

  const removeSelectedLanguage = useCallback(
    (value) => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(value), 1);
      const updatedSelectionObj = selectedOptionsObj.filter(
        (obj) => obj.uuid !== value
      );
      setSelectedOptions(options);
      setSelectedOptionsObj(updatedSelectionObj);
      setMultipleSelectedOptionsInStore(options);
    },
    [selectedOptions, selectedOptionsObj, setMultipleSelectedOptionsInStore]
  );

  const optionsMarkup =
    options.length > 0
      ? options
          .sort((a, b) => a.label_english.localeCompare(b.label_english))
          .map((option) => {
            const { svg_flag_url, label_english, uuid } = option;

            return (
              <Listbox.Option
                key={uuid}
                value={option}
                selected={selectedOptions.includes(uuid)}
                accessibilityLabel={label_english}
              >
                <div
                  style={{
                    padding: '5px 0',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    style={{
                      height: '1.40625rem',
                      width: '1.40625rem',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                    src={svg_flag_url}
                    alt=""
                  />
                  {label_english}
                </div>
              </Listbox.Option>
            );
          })
      : null;

  const onLanguageBlur = () => {
    if (!allowMultiple) {
      const typedOption = languages.filter(
        (option) => option.label_english === inputValue
      );
      if (!typedOption.length) {
        setSelectedOptions(selectedOptions);
        setSelectedOptionsObj(selectedOptionsObj);
        setSelectedOptionsInStore(selectedOptions);
        setInputValue(selectedOptionsObj.label_english);
      }
      setSingleLanguageFieldFocued(false);
    }
  };

  const onLanguageFocus = () => {
    if (!allowMultiple) {
      setSingleLanguageFieldFocued(true);
    }
  };
  let inputIcon = <Icon source={SearchMinor} color="inkLighter" />;
  if (!allowMultiple && !singleLanguageFieldFocued) {
    if (selectedOptions?.length) {
      const selectedOptionForDefault = languages.filter(
        (option) => option.uuid === selectedOptions[0]
      );
      if (selectedOptionForDefault.length) {
        inputIcon = (
          <img
            style={{
              height: '1.40625rem',
              width: '1.40625rem',
              marginTop: '7px',
            }}
            src={selectedOptionForDefault[0].svg_flag_url}
            alt=""
          />
        );
      }
    }
  }
  return (
    <div
      className={`rush-custom-resource-list-container ${
        !allowMultiple ? 'single-item-resource-list' : ''
      }`}
    >
      <Combobox
        allowMultiple={allowMultiple}
        activator={
          <Combobox.TextField
            prefix={inputIcon}
            onChange={updateText}
            label={t(
              'settings.tracking_page.localization.card.search_bar_placeholder'
            )}
            labelHidden
            value={inputValue}
            placeholder={t(
              'settings.tracking_page.localization.card.search_bar_placeholder'
            )}
            onBlur={() => onLanguageBlur()}
            onFocus={() => onLanguageFocus()}
            helpText={helpText || undefined}
          />
        }
      >
        {optionsMarkup ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
      {showSelection ? (
        <TextContainer>
          <ResourceList
            resourceName={{ singular: 'language', plural: 'languages' }}
            items={selectedOptionsObj}
            renderItem={(item) => {
              const { svg_flag_url, uuid, label_english } = item;
              return (
                <ResourceItem
                  id={uuid}
                  media={
                    <>
                      <div onClick={() => removeSelectedLanguage(item.uuid)}>
                        <Icon source={MobileCancelMajor} color="base" />
                      </div>
                      <img src={svg_flag_url} alt={'logo'} width={'25'} />
                    </>
                  }
                  accessibilityLabel={`View details for ${label_english}`}
                  name={label_english}
                >
                  <h3>
                    <TextStyle variation="strong">{label_english}</TextStyle>
                  </h3>
                </ResourceItem>
              );
            }}
          />
        </TextContainer>
      ) : null}
    </div>
  );
}

import { storeApi } from '../../redux/store/storeApi';

export const shipmentSettingsApi = storeApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: () => {
        return {
          url: `/fulfillments/configuration`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipmentSettings'],
    }),
    getRules: builder.query({
      query: () => {
        return {
          url: `/tracking-number-rules`,
          method: 'GET',
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      providesTags: ['shipmentSettings'],
    }),
    updateConfig: builder.mutation({
      query: (config) => {
        return {
          url: `/fulfillments/configuration`,
          method: 'PUT',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),
    applyToPendingShipments: builder.mutation({
      query: (config) => {
        return {
          url: `/shipments/carrier`,
          method: 'PUT',
          body: { ...config },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
    }),
    updateRules: builder.mutation({
      query: (rulesData) => {
        return {
          url: `/tracking-number-rules`,
          method: 'PUT',
          body: { rules: [...rulesData] },
        };
      },
      transformResponse: (response = {}) => response?.data || response,
      invalidatesTags: ['shipmentSettings'],
    }),
  }),
});

export const {
  useGetConfigQuery,
  useGetRulesQuery,
  useUpdateConfigMutation,
  useUpdateRulesMutation,
  useApplyToPendingShipmentsMutation,
} = shipmentSettingsApi;

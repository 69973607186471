import React, { useState, useCallback } from 'react';
import { Autocomplete, Stack, Tag } from '@shopify/polaris';

function AutoCompleteWithTags({
  optionList = [],
  onChange,
  error = false,
  errorMessage = '',
  selected: selectedOptions = [],
  placeholder,
  listTitle,
  label,
  emptyState,
  prefix,
  allowMultiple = true,
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(optionList);

  const updateText = useCallback(
    (value) => {
      setInputValue(value);

      if (value === '') {
        setOptions(optionList);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = optionList.filter((option) =>
        option.label?.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [optionList]
  );

  const updateSelection = useCallback(
    (selected) => {
      if (selected.length) {
        onChange(selected);
      } else {
        onChange(selected);
        setInputValue('');
      }
    },
    [options, onChange]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      value={inputValue}
      placeholder={placeholder}
      error={error ? errorMessage : ''}
      label={label}
      prefix={prefix}
    />
  );

  const removeTag = useCallback(
    (tag) => () => {
      updateSelection(
        selectedOptions.filter((previousTag) => previousTag !== tag)
      );
    },
    [updateSelection, selectedOptions]
  );

  const tagMarkup = selectedOptions.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {(optionList.find((c) => c.value === option) || {}).label || option}
    </Tag>
  ));

  return (
    <Stack vertical spacing="tight">
      <Autocomplete
        options={(options || []).slice(0, 100)}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
        allowMultiple={allowMultiple}
        listTitle={listTitle}
        emptyState={emptyState}
      />
      <Stack spacing="extraTight">{tagMarkup}</Stack>
    </Stack>
  );
}

AutoCompleteWithTags.propTypes = {};

export default AutoCompleteWithTags;

import React, { useEffect } from 'react';
import {
  Page,
  Layout,
  Card,
  FooterHelp,
  Icon,
  ResourceList,
  ResourceItem,
  Heading,
  Loading,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import TranslateLink from 'components/TranslateLink';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GOOGLE_TAG_ID, FULL_STORY_ORG } from 'config';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import SmallTopMessage from 'components/SmallTopMessage';
import { ClientStorage } from 'ClientStorage';
import _ from 'lodash';
import { STORE_URLS } from 'config/urls';
import { LOCAL_STORAGE } from 'Constants';
import { useDispatch, useSelector } from 'react-redux';
import Toaster from 'pages/Settings/TrackingPage/Toaster';
import RightPanel from './RightPanel';
import { useGetNeedActionsQuery } from '../DashboardApi';
import './style.scss';
import SkeletonDashboardPage from './SkeletonDashboardPage';
import { baseActions } from 'redux/store/baseSlice';
import MerchentSuccessManagerCard from 'pages/Billing1/components/MerchentSuccessManagerCard';
import BookTeamTimeCard from 'pages/Billing1/components/BookTeamTimeCard';

export default function Dashboard() {
  const [t] = useTranslation();

  const sendDataToGTM = useGTMDispatch();
  const dispatch = useDispatch();
  const shop = useSelector((state) => state.shop);
  const base = useSelector((state) => state.base);

  const needActionsResponse = useGetNeedActionsQuery();
  const {
    data = {},
    isLoading: shipmentsLoading,
    isFetching: shipmentsFetching,
    refetch,
  } = needActionsResponse || {};
  const shipmentNeedAction = data.shipment_actions || [];

  useEffect(refetch, [t]);

  useEffect(() => {
    if (ClientStorage.get(LOCAL_STORAGE.TRACK_ANALYTICS) !== true) {
      return;
    }
    if (!base.gtmAppVisit) {
      if (GOOGLE_TAG_ID && GOOGLE_TAG_ID !== 'null') {
        sendDataToGTM({
          event: 'appVisit',
        });
      }
      if (FULL_STORY_ORG && FULL_STORY_ORG !== 'null' && window.FS) {
        window.FS.event('appVisit');
      }
      dispatch(baseActions.markGtmAppVisitTrue());
    }
  }, [base.gtmAppVisit]);

  return (
    <>
      {shipmentsLoading ? (
        <SkeletonDashboardPage />
      ) : (
        <>
          <SmallTopMessage />
          <Page titleHidden fullWidth={false} narrowWidth={false}>
            <Layout>
              <Layout.Section>
                <Layout>
                  <Layout.Section>
                    <Heading>{t('dashboard.need_action.card.title')}</Heading>
                  </Layout.Section>
                  <Layout.Section>
                    <Card>
                      <div className="dashboard-resource-list">
                        <ResourceList
                          resourceName={{
                            singular: 'shipment',
                            plural: 'shipments',
                          }}
                          items={shipmentNeedAction}
                          renderItem={(item, index) => {
                            let url = item.shipment_get_query
                              ? `/s/${shop.storeUUID}${STORE_URLS.SHIPMENTS}?${item.shipment_get_query}`
                              : null;
                            if (_.isNull(url)) {
                              url = item.link ? item.link : null;
                            }
                            return (
                              <ResourceItem
                                id={index}
                                url={url}
                                accessibilityLabel={item.message}
                                external={!!item.link}
                              >
                                <div className="Dashboard-Shipments">
                                  <div className="Dashboard-Shipments-IconHolder">
                                    <div className="Image">
                                      <span>
                                        <img
                                          src={item.icon}
                                          className="NeedAction-Icon"
                                          alt={item.message}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="Dashboard-Shipments-Heading">
                                    <TranslateLink text={item.message} />
                                  </div>
                                  {url && (
                                    <div className="Dashboard-Shipments-Next">
                                      <Icon
                                        source={ChevronRightMinor}
                                        color="skyDark"
                                      />
                                    </div>
                                  )}
                                </div>
                              </ResourceItem>
                            );
                          }}
                        />
                      </div>
                    </Card>
                  </Layout.Section>
                  {ClientStorage.get(LOCAL_STORAGE.SHOW_MANAGER_CARD) !==
                  false ? (
                    <MerchentSuccessManagerCard showCloseButton={true} />
                  ) : (
                    ''
                  )}
                  {ClientStorage.get(LOCAL_STORAGE.SHOW_FOUNDER_CARD) !==
                  false ? (
                    <BookTeamTimeCard showCloseButton={true} />
                  ) : (
                    ''
                  )}
                </Layout>
              </Layout.Section>
              <Layout.Section secondary>
                <Layout>
                  <Layout.Section>
                    <Heading>{t('dashboard.analytics_sheet.title')}</Heading>
                  </Layout.Section>
                  <Layout.Section>
                    <RightPanel />
                  </Layout.Section>
                </Layout>
              </Layout.Section>
            </Layout>
            <Layout.Section>
              <FooterHelp>
                <TranslateLink text={t('footer_help.dashboard')} />
              </FooterHelp>
            </Layout.Section>
            <Toaster />
          </Page>
        </>
      )}
      {shipmentsFetching ? <Loading /> : ''}
    </>
  );
}

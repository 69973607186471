import React, { useState, useCallback } from 'react';
import {
  Popover,
  ActionList,
  Button,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

export default function BaseLanguageDropdown({
  handleIsUpdate,
  publicLanguagesList,
  baseLanguage,
  setBaseLanguage,
  loading,
}) {
  const [t] = useTranslation();

  const [isLanguageActive, setIsLanguageActive] = useState(false);
  const toggleActiveLanguage = useCallback(
    () => setIsLanguageActive((isLanguageActive) => !isLanguageActive),
    []
  );

  const languageActivator = (
    <Stack vertical spacing={'extraTight'}>
      <TextStyle>
        {t('settings.tracking_page.localisation.custom_language.base')}
      </TextStyle>
      <Button
        onClick={toggleActiveLanguage}
        disclosure
        fullWidth
        loading={loading}
      >
        {baseLanguage?.label_english}
      </Button>
    </Stack>
  );

  const handleLanguageSelection = useCallback(
    (language) => {
      handleIsUpdate();
      setBaseLanguage(language);
      toggleActiveLanguage();
    },
    [toggleActiveLanguage]
  );

  const formattedArray = useCallback(() => {
    const formatArray = [];
    publicLanguagesList &&
      publicLanguagesList
        .sort((a, b) => a.label_english.localeCompare(b.label_english))
        .map((language) => {
          formatArray.push({
            content: language?.label_english,
            onAction: () => handleLanguageSelection(language),
          });
          return false;
        });
    return formatArray;
  }, [publicLanguagesList, handleLanguageSelection]);

  return (
    <Popover
      active={isLanguageActive}
      activator={languageActivator}
      onClose={toggleActiveLanguage}
      preferredAlignment="left"
    >
      <ActionList items={formattedArray()} />
    </Popover>
  );
}

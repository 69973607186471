const initialState = require('./reports.initialState');
const reportsActions = require('./reports.actions');
const reportsReducer = require('./reports.reducer');
const reportsWebServices = require('./reports.webServices');
const { watchReportsRequests } = require('./reports.sagas.js');

module.exports = {
  initialState,
  reportsActions,
  reportsReducer,
  reportsWebServices,
  watchReportsRequests,
}

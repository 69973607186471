import React, { useState, useEffect, useCallback } from 'react';

import {
  Card,
  Button,
  TextStyle,
  DataTable,
  SkeletonBodyText,
  Badge,
} from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import trim from 'lodash/trim';
import { privateKeyValidator, timeAgo } from 'Utils/utils';
import './style.scss';
import ExternalLink from '../../../../components/ExternalLink';

export default function KlaviyoFlowTable({
  getKlaviyoSummaryFlow,
  loadingFlow,
  privateApiKey,
  klaviyoFlowSummary,
}) {
  const [t] = useTranslation();

  const [sortedRows, setSortedRows] = useState(null);
  const [durationLabel, setDurationLabel] = useState('');
  const [summaryFlows, setSummaryFlows] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [refreshedAtDate, setRefreshedAtDate] = useState(null);
  const tableRecords = useCallback(() => {
    const {
      flows = [],
      flows_total_revenue_label = 0,
      refreshed_at = '',
    } = klaviyoFlowSummary;
    let flowsArray = [];
    flows &&
      flows.map((value) => {
        const {
          name = '',
          link = '#',
          revenue_label = '',
          status = 'draft',
        } = value;
        return flowsArray.push([
          <ExternalLink url={link}>{name}</ExternalLink>,
          status === 'live' ? (
            <Badge status="success">Live</Badge>
          ) : status === 'draft' ? (
            <Badge>Draft</Badge>
          ) : (
            status === 'manual' && <Badge status="warning">Manual</Badge>
          ),
          revenue_label,
        ]);
      });
    setSummaryFlows(flowsArray);
    setTotalRevenue(flows_total_revenue_label);
    setRefreshedAtDate(refreshed_at);
  }, [klaviyoFlowSummary]);

  useEffect(() => {
    if (klaviyoFlowSummary) {
      tableRecords();
    }
  }, [klaviyoFlowSummary, tableRecords]);

  useEffect(() => {
    if (refreshedAtDate !== null) {
      let date = moment.utc().format(refreshedAtDate);
      let serverDate = moment.utc(date).local();
      setDurationLabel(timeAgo(serverDate.format('YYYY-MM-DD: hh:mm:ss')));
    }
  }, [refreshedAtDate, setDurationLabel]);

  const initiallySortedRows = summaryFlows || [];
  const rows = sortedRows ? sortedRows : initiallySortedRows;

  const handleSort = (index, direction) =>
    setSortedRows(sortCurrency(rows, index, direction));

  function sortCurrency(rows, index, direction) {
    return [...rows].sort((rowA, rowB) => {
      const amountA = parseFloat(rowA[index].substring(1));
      const amountB = parseFloat(rowB[index].substring(1));

      return direction === 'descending' ? amountB - amountA : amountA - amountB;
    });
  }

  function footerContent() {
    return (
      <>
        <TextStyle className="">
          {t('settings.klaviyo.flow_summary.last_update_text', {
            time_value: `${durationLabel.toString()}`,
          })}
        </TextStyle>
        <Button
          class="alignRight"
          onClick={() =>
            privateApiKey !== null &&
            privateKeyValidator(trim(privateApiKey)) &&
            getKlaviyoSummaryFlow(privateApiKey)
          }
          outline
          size="slim"
        >
          {t('settings.klaviyo.flow_summary.refresh_data')}
        </Button>
      </>
    );
  }
  return (
    <Card>
      {loadingFlow ? (
        <Card sectioned>
          <SkeletonBodyText lines={10} />
        </Card>
      ) : rows.length > 0 ? (
        <div className="dataFlowTable">
          <DataTable
            columnContentTypes={['text', 'text', 'numeric']}
            headings={[
              t('settings.klaviyo.flow_summary.table.column_f'),
              t('settings.klaviyo.flow_summary.table.column_s'),
              t('settings.klaviyo.flow_summary.table.column_t'),
            ]}
            rows={rows}
            totals={['', '', totalRevenue || 0]}
            sortable={[false, false, true]}
            defaultSortDirection="descending"
            initialSortColumnIndex={4}
            onSort={handleSort}
            footerContent={footerContent()}
          />
        </div>
      ) : (
        <Card sectioned>
          <div className="CustomEmptyState">
            <TextStyle variation="subdued">
              {t('settings.klaviyo.flow_summary.no_rush_flow_row_message')}
            </TextStyle>
          </div>
        </Card>
      )}
    </Card>
  );
}

import React, { useMemo } from 'react';
import { Button, Card, Stack } from '@shopify/polaris';
import { ArrowDownMinor, ArrowUpMinor } from '@shopify/polaris-icons';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import '../styles.scss';

function PreferencesCard({ preferences = [], onChange }) {
  const [t] = useTranslation();
  const prefs = useMemo(() => {
    return [
      { label: t('smart-edd.preference.options.carrier'), id: 'carrier' },
      { label: t('smart-edd.preference.options.rules'), id: 'rules' },
      { label: t('smart-edd.preference.options.ai'), id: 'ai' },
    ];
  }, [i18next.language]);

  const orderedPrefs = useMemo(() => {
    if (preferences.length === prefs.length) {
      const sorted = prefs.map((pref) => {
        return {
          ...pref,
          order: preferences.indexOf(pref.id),
        };
      });
      return sortBy([...sorted], 'order');
    } else {
      return [...prefs];
    }
  }, [prefs, preferences]);

  const reorderArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length || new_index < 0) {
      return arr;
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };
  const handleMoveUp = (index) => {
    let updated = reorderArray([...orderedPrefs], index, index - 1);
    onChange(updated.map((p) => p.id));
  };

  const handleMoveDown = (index) => {
    let updated = reorderArray([...orderedPrefs], index, index + 1);
    onChange(updated.map((p) => p.id));
  };

  return orderedPrefs.map((pref, index) => {
    return (
      <Card.Section key={pref.id}>
        <Stack distribution="fill">
          <Stack.Item>{pref.label}</Stack.Item>

          <Stack distribution="trailing">
            <Button
              disabled={index === 0}
              icon={ArrowUpMinor}
              plain
              onClick={() => handleMoveUp(index)}
            ></Button>
            <Button
              disabled={index === prefs.length - 1}
              icon={ArrowDownMinor}
              plain
              onClick={() => handleMoveDown(index)}
            ></Button>
          </Stack>
        </Stack>
      </Card.Section>
    );
  });
}

export default PreferencesCard;

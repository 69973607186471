import { connect } from 'react-redux';
import { billingActions } from 'redux/billing';
import ReviewModal from './ReviewModal';

function mapStateToProps(state) {
  return {
    shop: state.shop,
    isShowingFeedBackModal: state.billing.isShowingFeedBackModal,
  };
}

const mapDispatchToProps = {
  showFeedbackModal: billingActions.Creators.showFeedbackModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewModal);

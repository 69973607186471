const { createReducer } = require('reduxsauce');
const billingActions = require('./billing.actions');
const initialState = require('./billing.initialState');
const { BILLING_PLAN_REASON_CHARGE_LIMIT_ID } = require('Constants');
const stringHelper = require('helpers/string').default;

const getSubscriptionPlans = (state) => ({
  ...state,
  planLoading: true,
});
const getSubscriptionPlansSuccess = (state, action) => {
  return {
    ...state,
    subscriptionPlans: action.subscription_plans.data.subscription_plans,
    subscriptionPlanGroups: action.subscription_plans.data.groups,
    subscriptionPlanMeta: action.subscription_plans.data.meta,
    planLoading: false,
  };
};
const getSubscriptionPlansFailure = (state) => ({
  ...state,
  planLoading: false,
});

const getCurrentBillingPlan = (state) => ({
  ...state,
  loadingCurrentBillingPlan: true,
});
const getCurrentBillingPlanSuccess = (state, action) => ({
  ...state,
  currentPlan: action.currentPlan.data,
  loadingCurrentBillingPlan: false,
});
const getCurrentBillingPlanFailure = (state) => ({
  ...state,
  loadingCurrentBillingPlan: false,
});

const postSubscriptionPlan = (state) => ({
  ...state,
  subscriptionPlanLoading: true,
});
const resetSubscriptionPlan = (state) => ({
  ...state,
  chargeDetail: { charge_id: null, charge_url: null, type: null },
  subscriptionPlanLoading: false,
  promoCode: {},
});
const postSubscriptionPlanSuccess = (state, action) => {
  return {
    ...state,
    chargeDetail: action.chargeDetail.data,
    subscriptionPlanLoading: !!action.chargeDetail.data.charge_url,
  };
};
const postSubscriptionPlanFailure = (state) => ({
  ...state,
  subscriptionPlanLoading: false,
});

const getChargeLimit = (state) => ({
  ...state,
  chargeLimitLoading: true,
});
const getChargeLimitSuccess = (state, action) => ({
  ...state,
  chargeLimit: action.chargeLimitDetail.data,
  chargeLimitLoading: false,
});
const getChargeLimitFailure = (state) => ({
  ...state,
  chargeLimitLoading: false,
});

const updateChargeLimit = (state) => ({
  ...state,
  loading: true,
});
const updateChargeLimitSuccess = (state, action) => ({
  ...state,
  chargeLink: action.chargeLink.data,
  chargeLimitError: null,
  subscriptionPlanLoading: false,
});
const updateChargeLimitFailure = (state, action) => ({
  ...state,
  subscriptionPlanLoading: false,
  chargeLimitError: stringHelper.getErrorText(action),
  loading: false,
});

const changeChargeLimit = (state) => ({
  ...state,
  createBillingLoading: true,
});
const changeChargeLimitSuccess = (state) => {
  return {
    ...state,
    billingPlan: {
      billing_plan_reason: {
        id: BILLING_PLAN_REASON_CHARGE_LIMIT_ID,
      },
    },
    createBillingLoading: false,
  };
};
const changeChargeLimitFailure = (state, action) => {
  return {
    ...state,
    billingPlanError: stringHelper.getErrorText(action),
    loading: false,
    createBillingLoading: false,
  };
};

const getBillingTransactions = (state) => ({
  ...state,
  loading: true,
});
const getBillingTransactionsSuccess = (state, action) => {
  return {
    ...state,
    billingTransactions: {
      billingPlanTransactions:
        action.billingTransactions.data.billing_plan_transactions,
      meta: action.billingTransactions.meta,
      links: action.billingTransactions.links,
      exportMessage: null,
    },
    loading: false,
  };
};
const getBillingTransactionsFailure = (state) => ({
  ...state,
  loading: false,
});

const exportBillingTransactions = (state) => ({
  ...state,
  exportLoading: true,
});
const exportBillingTransactionsSuccess = (state, action) => {
  return {
    ...state,
    billingTransactions: {
      ...state.billingTransactions,
      exportMessage: action.billingTransactions.data.message,
    },
    exportLoading: false,
  };
};
const exportBillingTransactionsFailure = (state) => ({
  ...state,
  exportLoading: false,
});

const disableExportBillingTransactions = (state) => {
  return {
    ...state,
    billingTransactions: {
      ...state.billingTransactions,
      exportMessage: null,
    },
    exportLoading: false,
  };
};
const handleSubscriptionPlansModalChange = (state) => ({
  ...state,
  showSubscriptionPlansModal: !state.showSubscriptionPlansModal,
});

const getPlanSuggestion = (state) => ({
  ...state,
  subscriptionPlanLoading: true,
});
const disablePlanSuggestion = (state) => ({
  ...state,
  planSuggestions: { show_suggestion: false },
  subscriptionPlanLoading: false,
});
const getPlanSuggestionSuccess = (state, action) => ({
  ...state,
  planSuggestions: action.suggestion.data,
  subscriptionPlanLoading: false,
});
const getPlanSuggestionFailure = (state) => ({
  ...state,
  subscriptionPlanLoading: false,
});

const getBillingSummary = (state) => ({
  ...state,
  billingSummaryLoading: true,
});
const getBillingSummarySuccess = (state, action) => ({
  ...state,
  billingSummary: action.summary.data.billing_period_summary,
  billingSummaryLoading: false,
});
const getBillingSummaryFailure = (state) => ({
  ...state,
  billingSummaryLoading: false,
});

const getChargeByToken = (state) => ({
  ...state,
  loading: true,
});
const getChargeByTokenSuccess = (state, action) => ({
  ...state,
  charge: action.charge.data.charge,
  loading: false,
});
const getChargeByTokenFailure = (state) => ({
  ...state,
  loading: false,
});

const createBillingPlan = (state) => ({
  ...state,
  loading: true,
  createBillingLoading: true,
});
// this `disableBillingPlan` function on billing page and it also closes promo code with promo_type = 'promo'
const disableBillingPlan = (state) => ({
  ...state,
  billingPlan: null,
  billingPlanError: null,
  loading: false,
  createBillingLoading: false,
  showPromoCode: false,
});
const createBillingPlanSuccess = (state, action) => ({
  ...state,
  billingPlan: action.billingPlan.data.billing_plan,
  loading: false,
  promoCode: {},
  createBillingLoading: false,
});
const createBillingPlanFailure = (state, action) => {
  return {
    ...state,
    billingPlanError: stringHelper.getErrorText(action),
    loading: false,
    promoCode: {},
    createBillingLoading: false,
  };
};

const applyPromoCode = (state) => ({
  ...state,
  promoLoading: true,
});
const applyPromoCodeSuccess = (state, action) => ({
  ...state,
  promoCode: action.promo.data,
  promoError: {},
  promoLoading: true,
});
const applyPromoCodeFailure = (state, action) => ({
  ...state,
  promoError: action.errors,
  promoLoading: false,
});

const postPromoCharges = (state) => ({
  ...state,
  createBillingLoading: true,
});
const postPromoChargesSuccess = (state, action) => ({
  ...state,
  chargeDetail: action.chargeDetail.data,
  promoCode: {},
  createBillingLoading: true,
});
const postPromoChargesFailure = (state, action) => ({
  ...state,
  promoCode: {},
  promoError: action.errors,
  createBillingLoading: false,
});

const showPromoCodeModal = (state) => ({
  ...state,
  showPromoCode: !state.showPromoCode,
  promoLoading: false,
});
const showPromoCodeConfirmationModal = (state) => ({
  ...state,
  showPromoCodeConfirmation: !state.showPromoCodeConfirmation,
  promoLoading: false,
});
const showFeedbackModal = (state) => ({
  ...state,
  isShowingFeedBackModal: !state.isShowingFeedBackModal,
});

const getFeedbackModalCheck = (state) => ({
  ...state,
  canWriteFeedbackReviewLoading: true,
});
const getFeedbackModalCheckSuccess = (state, action) => ({
  ...state,
  canWriteFeedbackReview: action.data,
  canWriteFeedbackReviewLoading: false,
});
const getFeedbackModalCheckFailure = (state) => ({
  ...state,
  canWriteFeedbackReview: false,
  canWriteFeedbackReviewLoading: false,
});

const setBillingToasterMessage = (state, action) => ({
  ...state,
  billingToasterMessage: action.message,
});
const resetBillingToasterMessage = (state) => ({
  ...state,
  billingToasterMessage: null,
});

const { Types } = billingActions;

// map our action types to our reducer functions
const HANDLERS = {
  [Types.GET_SUBSCRIPTION_PLANS]: getSubscriptionPlans,
  [Types.GET_SUBSCRIPTION_PLANS_SUCCESS]: getSubscriptionPlansSuccess,
  [Types.GET_SUBSCRIPTION_PLANS_FAILURE]: getSubscriptionPlansFailure,

  [Types.GET_CURRENT_BILLING_PLAN]: getCurrentBillingPlan,
  [Types.GET_CURRENT_BILLING_PLAN_SUCCESS]: getCurrentBillingPlanSuccess,
  [Types.GET_CURRENT_BILLING_PLAN_FAILURE]: getCurrentBillingPlanFailure,

  [Types.POST_SUBSCRIPTION_PLAN]: postSubscriptionPlan,
  [Types.RESET_SUBSCRIPTION_PLAN]: resetSubscriptionPlan,
  [Types.POST_SUBSCRIPTION_PLAN_SUCCESS]: postSubscriptionPlanSuccess,
  [Types.POST_SUBSCRIPTION_PLAN_FAILURE]: postSubscriptionPlanFailure,

  [Types.GET_CHARGE_LIMIT]: getChargeLimit,
  [Types.GET_CHARGE_LIMIT_SUCCESS]: getChargeLimitSuccess,
  [Types.GET_CHARGE_LIMIT_FAILURE]: getChargeLimitFailure,

  [Types.UPDATE_CHARGE_LIMIT]: updateChargeLimit,
  [Types.UPDATE_CHARGE_LIMIT_SUCCESS]: updateChargeLimitSuccess,
  [Types.UPDATE_CHARGE_LIMIT_FAILURE]: updateChargeLimitFailure,

  [Types.CHANGE_CHARGE_LIMIT]: changeChargeLimit,
  [Types.CHANGE_CHARGE_LIMIT_SUCCESS]: changeChargeLimitSuccess,
  [Types.CHANGE_CHARGE_LIMIT_FAILURE]: changeChargeLimitFailure,

  [Types.GET_BILLING_TRANSACTIONS]: getBillingTransactions,
  [Types.GET_BILLING_TRANSACTIONS_SUCCESS]: getBillingTransactionsSuccess,
  [Types.GET_BILLING_TRANSACTIONS_FAILURE]: getBillingTransactionsFailure,

  [Types.EXPORT_BILLING_TRANSACTIONS]: exportBillingTransactions,
  [Types.EXPORT_BILLING_TRANSACTIONS_SUCCESS]: exportBillingTransactionsSuccess,
  [Types.EXPORT_BILLING_TRANSACTIONS_FAILURE]: exportBillingTransactionsFailure,
  [Types.DISABLE_EXPORT_BILLING_TRANSACTIONS]: disableExportBillingTransactions,

  [Types.GET_PLAN_SUGGESTION]: getPlanSuggestion,
  [Types.DISABLE_PLAN_SUGGESTION]: disablePlanSuggestion,
  [Types.GET_PLAN_SUGGESTION_SUCCESS]: getPlanSuggestionSuccess,
  [Types.GET_PLAN_SUGGESTION_FAILURE]: getPlanSuggestionFailure,

  [Types.GET_BILLING_SUMMARY]: getBillingSummary,
  [Types.GET_BILLING_SUMMARY_SUCCESS]: getBillingSummarySuccess,
  [Types.GET_BILLING_SUMMARY_FAILURE]: getBillingSummaryFailure,

  [Types.GET_CHARGE_BY_TOKEN]: getChargeByToken,
  [Types.GET_CHARGE_BY_TOKEN_SUCCESS]: getChargeByTokenSuccess,
  [Types.GET_CHARGE_BY_TOKEN_FAILURE]: getChargeByTokenFailure,

  [Types.CREATE_BILLING_PLAN]: createBillingPlan,
  [Types.DISABLE_BILLING_PLAN]: disableBillingPlan,
  [Types.CREATE_BILLING_PLAN_SUCCESS]: createBillingPlanSuccess,
  [Types.CREATE_BILLING_PLAN_FAILURE]: createBillingPlanFailure,

  [Types.APPLY_PROMO_CODE]: applyPromoCode,
  [Types.APPLY_PROMO_CODE_SUCCESS]: applyPromoCodeSuccess,
  [Types.APPLY_PROMO_CODE_FAILURE]: applyPromoCodeFailure,

  [Types.POST_PROMO_CHARGES]: postPromoCharges,
  [Types.POST_PROMO_CHARGES_SUCCESS]: postPromoChargesSuccess,
  [Types.POST_PROMO_CHARGES_FAILURE]: postPromoChargesFailure,

  [Types.SHOW_PROMO_CODE_MODAL]: showPromoCodeModal,
  [Types.SHOW_PROMO_CODE_CONFIRMATION_MODAL]: showPromoCodeConfirmationModal,
  [Types.SHOW_FEEDBACK_MODAL]: showFeedbackModal,

  [Types.GET_FEEDBACK_MODAL_CHECK]: getFeedbackModalCheck,
  [Types.GET_FEEDBACK_MODAL_CHECK_SUCCESS]: getFeedbackModalCheckSuccess,
  [Types.GET_FEEDBACK_MODAL_CHECK_FAILURE]: getFeedbackModalCheckFailure,

  [Types.HANDLE_SUBSCRIPTION_PLANS_MODAL_CHANGE]:
    handleSubscriptionPlansModalChange,

  [Types.SET_BILLING_TOASTER_MESSAGE]: setBillingToasterMessage,
  [Types.RESET_BILLING_TOASTER_MESSAGE]: resetBillingToasterMessage,
};

module.exports = createReducer(initialState, HANDLERS);

import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Page, Layout, FooterHelp } from '@shopify/polaris';

import TranslateLink from 'components/TranslateLink';
import { useTranslation } from 'react-i18next';
import {
  TRACKING_PAGE_ACTIONS,
  TRACKING_PAGE_THEME_MODERN,
  TRACKING_PAGE_THEME_TRADITIONAL,
  TRACKING_PAGE_THEME_DIY,
} from 'Constants';

import SmallTopMessage from 'components/SmallTopMessage';
import LiveTrackingPage from './LiveTrackingPage';
import MapLocation from './MapLocation';
import ShipmentTrackingBy from './ShipmentTrackingBy';
import Localization from './Localization';

import FaqSection from './FaqSection';
import Customization from './Customization';

import Toaster from './Toaster';
import PageURL from './PageURL';
import TrackingOptions from './TrackingOptions';
import HeaderLinks from './HeaderLinks';
import StoreInformation from './StoreInformation';
import SaveBar from './SaveBar';
import Configuration from './Configuration';
import HTMLText from './HTMLText';
import LoadingLayoutPage from 'components/LoadingLayoutPage';
import trackingPageReducer from './TrackingPageReducer';
import { ViewMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import Subtitle from '../../../components/Subtitle';
import TrackingPages from './TrackingPages';
import OS2WizardCard from './OS2WizardCard';
import './style.scss';
import i18next from 'i18next';

export default function TrackingPage({
  getTrackingPageDetail,
  trackingDetail,
  trackingSaved,
  trackingDetailMeta,
  showUnsavedModal,
}) {
  const [t] = useTranslation();
  const history = useHistory();
  const [publicLink, setPublicLink] = useState(null);
  const [trackingPageState, dispatchForTrackingPage] = useReducer(
    trackingPageReducer,
    null
  );

  useEffect(() => {
    if (!trackingDetail.dataLoaded && !trackingDetail.loading) {
      getTrackingPageDetail();
    } else if (
      trackingDetail.dataLoaded &&
      !trackingDetail.loading &&
      !trackingPageState
    ) {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.INIT,
        payload: trackingDetail,
      });
    }
  }, [
    getTrackingPageDetail,
    trackingDetail,
    dispatchForTrackingPage,
    trackingPageState,
  ]);

  useEffect(() => {
    if (trackingDetail.dataLoaded && !trackingDetail.loading) {
      getTrackingPageDetail();
    }
  }, [i18next.language]);

  useEffect(() => {
    if (trackingPageState && trackingPageState.isUpdated && trackingSaved) {
      dispatchForTrackingPage({
        type: TRACKING_PAGE_ACTIONS.RESET,
        payload: trackingDetail,
      });
    }
  }, [
    trackingSaved,
    trackingPageState,
    trackingDetail,
    dispatchForTrackingPage,
  ]);

  useEffect(() => {
    if (trackingPageState) {
      const pageLink = trackingPageState.page_link;
      setPublicLink(
        `${pageLink.domain}${pageLink.proxy}${pageLink.extension}${pageLink.append_test_link}`
      );
    }
  }, [trackingPageState, setPublicLink]);

  useEffect(() => {
    if (trackingPageState?.customizeTheme) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [trackingPageState?.customizeTheme]);

  const redirectBackToPrevious = useCallback(() => {
    if (trackingPageState) {
      if (trackingPageState.customizeTheme) {
        dispatchForTrackingPage({
          type: TRACKING_PAGE_ACTIONS.RESET,
          payload: { customizeTheme: false },
        });
      } else {
        history.push('/settings');
      }
    }
  }, [dispatchForTrackingPage, trackingPageState, history]);

  const discardChanges = useCallback(() => {
    let discardPayload = { ...trackingDetail };
    if (trackingDetail.theme === trackingPageState.theme) {
      discardPayload = {
        ...trackingDetail,
        customizeTheme: trackingPageState.customizeTheme,
      };
    }
    dispatchForTrackingPage({
      type: TRACKING_PAGE_ACTIONS.INIT,
      payload: discardPayload,
    });
  }, [trackingDetail, dispatchForTrackingPage, trackingPageState]);

  const callbackFunction = useCallback(() => {
    discardChanges();
    redirectBackToPrevious();
  }, [discardChanges, redirectBackToPrevious]);

  const redirectBack = useCallback(() => {
    if (trackingPageState.isUpdated) {
      showUnsavedModal(callbackFunction);
      return;
    }

    redirectBackToPrevious();
  }, [
    trackingPageState,
    showUnsavedModal,
    redirectBackToPrevious,
    callbackFunction,
  ]);

  if (!trackingDetail.dataLoaded) {
    return <LoadingLayoutPage />;
  }
  let selectedThemeObj = null;
  if (trackingPageState && trackingPageState.theme) {
    selectedThemeObj = trackingDetailMeta.themes.filter(
      (theme) => theme.slug === trackingPageState.theme
    )[0];
  }

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={
          trackingPageState?.customizeTheme
            ? [{ content: t('settings.breadcrumbs'), onAction: redirectBack }]
            : undefined
        }
        title={
          trackingPageState && trackingPageState.customizeTheme
            ? t('settings.tracking_page.card_theme.style_title', {
                theme_style: selectedThemeObj?.name,
              })
            : t('settings.tracking_page.header.title')
        }
        subtitle={
          <Subtitle
            text={t('settings_page.tracking_page.subtitle')}
            actions={[
              {
                text: t('billing.subscription_plans_modal.plan_get_started'),
                source:
                  'https://support.rush.app/everything-about-tracking-page',
              },
            ]}
          />
        }
        secondaryActions={
          trackingPageState && !trackingPageState.isUpdated
            ? [
                {
                  content: t('settings.tracking_page.header.button_label'),
                  icon: ViewMinor,
                  url: publicLink,
                  external: true,
                },
              ]
            : []
        }
      >
        <Layout>
          {trackingPageState && (
            <SaveBar
              discardChanges={discardChanges}
              trackingPageState={trackingPageState}
              dispatchForTrackingPage={dispatchForTrackingPage}
            />
          )}
          {trackingPageState && !trackingPageState.customizeTheme && (
            <>
              <LiveTrackingPage
                discardChanges={discardChanges}
                trackingPageState={trackingPageState}
                dispatchForTrackingPage={dispatchForTrackingPage}
              />
              <OS2WizardCard />
              <PageURL
                trackingPageState={trackingPageState}
                dispatchForTrackingPage={dispatchForTrackingPage}
                publicLink={publicLink}
              />
              <Localization
                trackingPageState={trackingPageState}
                dispatchForTrackingPage={dispatchForTrackingPage}
              />
              <TrackingPages
                trackingPageState={trackingPageState}
                dispatchForTrackingPage={dispatchForTrackingPage}
              />
            </>
          )}
          {trackingPageState &&
            trackingPageState.theme &&
            trackingPageState.customizeTheme && (
              <>
                {trackingPageState.theme === TRACKING_PAGE_THEME_MODERN && (
                  <>
                    <StoreInformation
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <FaqSection
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <HeaderLinks
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <TrackingOptions
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <ShipmentTrackingBy
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <MapLocation
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <Customization
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                  </>
                )}
                {trackingPageState.theme ===
                  TRACKING_PAGE_THEME_TRADITIONAL && (
                  <>
                    <ShipmentTrackingBy
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <Configuration
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <TrackingOptions
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                  </>
                )}
                {trackingPageState.theme === TRACKING_PAGE_THEME_DIY && (
                  <>
                    <ShipmentTrackingBy
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <TrackingOptions
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <Configuration
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                    <HTMLText
                      trackingPageState={trackingPageState}
                      dispatchForTrackingPage={dispatchForTrackingPage}
                    />
                  </>
                )}
              </>
            )}
          <Toaster />

          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t('footer_help.dashboard')} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
        <UnsavedChangesModal />
      </Page>
    </>
  );
}

import React from "react";
import {
  Page,
  Layout,
  Card,
  TextStyle,
  Button,
  Badge,
  Link,
  FooterHelp,
} from "@shopify/polaris";
import TranslateLink from "components/TranslateLink";
import { useTranslation } from "react-i18next";
import SmallTopMessage from "components/SmallTopMessage";

export default function SmsNotification() {
  const [t] = useTranslation();

  return (
    <>
      <SmallTopMessage />
      <Page
        breadcrumbs={[{ content: "Settings", url: "/settings" }]}
        title="SMS Notifications"
      >
        <Layout>
          <Layout.AnnotatedSection
            title="Phone Number Verification"
            description="Before we enable SMS notification we need to verify your phone number."
          >
            <Card title="Phone number">
              <div className="Localisation-EnableDiv">
                <div>
                  <div>
                    <TextStyle>+1 555-121-121</TextStyle>
                  </div>
                  <div className="Badge">
                    <Badge status="success">Verified</Badge>
                  </div>
                </div>
                <div>
                  <Button primary onClick={() => {}}>
                    Change Phone
                  </Button>
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Notification Events"
            description="Choose between different triggers for what and when to notify clients."
          >
            <Card
              title="SMS"
              actions={[
                { content: "Add SMS", url: "/settings/SMSNotificationRule" },
              ]}
            >
              <div className="EmailList">
                <div className="EmailList-Item NoLeftPadding">
                  <TextStyle>Trigger</TextStyle>
                </div>

                <div className="EmailList-Item">
                  <div>
                    <div>
                      When <TextStyle variation="strong">New status</TextStyle>{" "}
                      is{" "}
                      <TextStyle variation="strong">Out for delivery</TextStyle>
                    </div>
                    <div>
                      <Badge status="success">Active</Badge>
                    </div>
                  </div>
                  <div>
                    <Link>edit</Link>
                  </div>
                </div>

                <div className="EmailList-Item">
                  <div>
                    <div>
                      When <TextStyle variation="strong">New status</TextStyle>{" "}
                      is <TextStyle variation="strong">In Transit</TextStyle>
                    </div>
                    <div>
                      <Badge status="success">Active</Badge>
                    </div>
                  </div>
                  <div>
                    <Link>edit</Link>
                  </div>
                </div>

                <div className="EmailList-Item">
                  <div>
                    <div>
                      When <TextStyle variation="strong">New status</TextStyle>{" "}
                      is <TextStyle variation="strong">In Delivered</TextStyle>
                    </div>
                    <div>
                      <Badge status="success">Active</Badge>
                    </div>
                  </div>
                  <div>
                    <Link>edit</Link>
                  </div>
                </div>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.Section>
            <FooterHelp>
              <TranslateLink text={t("footer_help.dashboard")} />
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
}
